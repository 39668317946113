const initialState = {
	dirtyFields: []
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case "NEW_SALE_CHANGE_TEXT": {
			let newState = {...state}
			newState[action.payload.fieldName] = action.payload.text
			return newState
		}
		case "REMOVE_IS_DIRTY_FIELDS": {
			return {...state, dirtyFields: [...state.dirtyFields.filter(()=>action.payload)]}
		}
		case "SELF_SALE_RESET_FORM": {
			return {...initialState}
		}
	}
	return state
}


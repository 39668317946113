import React, {useState} from 'react';
import {RangeSlider} from "components/shared/forms/index"
import {withRouter} from "react-router-dom"
import {graphql} from 'react-apollo'
import {connect} from 'react-redux'
import * as utils from 'utils'
import {Form, Field, useField} from 'react-final-form' //change, formValueSelector
import {CheckBoxBlock} from "../../../shared/finalFormFields";
import {
    ALL_REGIONS_QUERY,
    AVAILABLE_FACILITIES_SWAP_REQUIREMENTS
} from "queries";
import "./foldable.css"
import * as validation from "../../../../validation";
import {useFormState} from 'react-final-form'
import * as actions from "actions"
import Modal from "../../../Modals/Modal";


const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

const MIN_SIZE = 30
const MAX_SIZE = 200
const MIN_ROOMS = 1
const MAX_ROOMS = 5
const MIN_PRICE = 0
const MAX_PRICE = 5000000
const MIN_RENT = 1000
const MAX_RENT = 20000


const RenderRangeSlider = ({label, minName, maxName, minValue, maxValue, formatValue, minDistance = 1, step = 1}) => {
    const minField = useField(minName)
    const maxField = useField(maxName)

    const onChange = (e) => {
        let minValue = Math.round(e[0] / step) * step
        let maxValue = Math.round(e[1] / step) * step
        minField.input.onChange(minValue)
        maxField.input.onChange(maxValue)
    }

    return <RangeSlider
        label={label}
        value={[minField.input.value, maxField.input.value]}
        min={minValue}
        max={maxValue}
        minDistance={minDistance}
        onChange={onChange}
        formatValue={formatValue} //utils.plus(utils.kvm, MAX_SIZE)
    />
}


const RequirementsBlockWithData = graphql(AVAILABLE_FACILITIES_SWAP_REQUIREMENTS,
    {
        props: ({data: {loading, availableFacilitiesSwapRequirements}}) => ({
            loading: loading,
            options: availableFacilitiesSwapRequirements,
            labelName: "facilityDescription"
        })
    }
)(CheckBoxBlock)


const RegionBlock = ({name, areas, loading, pos}) => {

    const [isOpen, setIsOpen] = useState(pos === 0 ? true : false)

    const className = isOpen ? "active" : ""

    return (
        <div>
            <div className={`collapsible ${className}`} onClick={() => setIsOpen(!isOpen)}>
                <span style={{display: "inline-block", lineHeight: 1, verticalAlign: 'middle'}}>{name}</span>
            </div>
            <div className={`collapsible-content ${className}`}>
                <Field
                    name='preferredAreas'
                    component={
                        (props) => <CheckBoxBlock
                            {...props}
                            loading={loading}
                            options={areas}
                            labelName={'name'}
                        />
                    }
                    validate={composeValidators(validation.notEmptyArray)}
                />
            </div>

        </div>


    )
}

const RegionsBlock = props => {
    const {data} = props
    if (data.loading) {
        return <div className="section--loading"></div>
    }
    return (
        <div>
            {data.allRegions.map((x, i) => <RegionBlock key={i} pos={i} name={x.name} areas={x.areas}
                                                        loading={false}/>)}
        </div>
    )

}

const RegionsBlockWithData = graphql(ALL_REGIONS_QUERY)(RegionsBlock)


let SwapWishesForm = ({handleSubmit, valid, pristine, submitLabel = "Næste", includePristine}) => {


    const [isShown, setIsShown] = useState(false)

    const formState = useFormState()

    const showModal = !isShown && formState.values.requirements.length === 1 && formState.initialValues.requirements.length === 0

    return (
        <div className="buyerform-component">
            <section className="section u-lightgrey ">
                <div className="container">
                    <h2 className="u-green-text section-title__block subsection">Udfyld dine ønsker til boligen du
                        ønsker at bytte med</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="row bordered-columns">
                            <div className="col-s-12 col-m-6">
                                <div className="subsection">
                                    <RenderRangeSlider
                                        minName='minFloorArea'
                                        maxName='maxFloorArea'
                                        minValue={MIN_SIZE}
                                        maxValue={MAX_SIZE}
                                        formatValue={utils.plus(utils.kvm, MAX_SIZE)}
                                        label="Størrelse - min/max"
                                    />
                                </div>
                                <div className="subsection">
                                    <RenderRangeSlider
                                        minName='minRooms'
                                        maxName='maxRooms'
                                        minValue={MIN_ROOMS}
                                        maxValue={MAX_ROOMS}
                                        formatValue={utils.plus((val) => val, MAX_ROOMS)}
                                        label="Antal Rum"
                                    />
                                </div>
                                <div className="subsection">

                                    <RenderRangeSlider
                                        minName='minPrice'
                                        maxName='maxPrice'
                                        minValue={MIN_PRICE}
                                        maxValue={MAX_PRICE}
                                        minDistance={100000}
                                        step={50000}
                                        formatValue={utils.plus(utils.kr, MAX_PRICE)}
                                        label="Anskaffelsespris - min/max"
                                    />

                                </div>
                                <div className="subsection">

                                    <RenderRangeSlider
                                        minName='minMonthlyFee'
                                        maxName='maxMonthlyFee'
                                        minValue={MIN_RENT}
                                        maxValue={MAX_RENT}
                                        step={100}
                                        minDistance={1000}
                                        formatValue={utils.plus(utils.kr, MAX_RENT)}
                                        label="Boligafgift ex. forbrug - min./max."
                                    />
                                </div>

                                <div className="subsection">
                                    <Field name='requirements'
                                           title="Og har du nogle ultimative krav til din nye bolig?"
                                           component={RequirementsBlockWithData}
                                    />
                                </div>

                            </div>
                            <div className="col-s-12 col-m-6">
                                <div className="subsection">
                                    <RegionsBlockWithData/>
                                </div>
                                <button className="button"
                                        disabled={!valid || includePristine ? pristine : false}

                                >{submitLabel}</button>
                            </div>

                        </div>

                    </form>
                    <Modal show={showModal}>
                        Hvis du tilvælger ultimative krav vil du minimere antallet henvendelser på din
                        byttebolig
                        <button className="button" onClick={() => setIsShown(true)}>
                            Ok - jeg er med
                        </button>
                    </Modal>
                </div>
            </section>
        </div>
    )
}


const SwapWishesFinalForm = ({initialValues, onSubmit, submitLabel, includePristine = true}) => {
    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            submitLabel={submitLabel}
            includePristine={includePristine}
        >
            {props => <SwapWishesForm {...props}/>}

        </Form>
    )
}

SwapWishesForm.displayName = 'SwapWishesForm';
SwapWishesForm.propTypes = {};
SwapWishesForm.defaultProps = {};

export default SwapWishesFinalForm

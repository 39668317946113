import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'


const DeletedModal = () => {
  return (
    <div className="successmodal-component">
      <h2>Profil slettet</h2>
      <p>Din profil samt alle dine oplysninger er nu blevet slettet.</p>
      <Link className="button" to={'/'}>Til forsiden</Link>    
    </div>
  );
}

DeletedModal.displayName = 'DeletedModal';
DeletedModal.propTypes = {};
DeletedModal.defaultProps = {};

export default connect()(DeletedModal);

import React from 'react';
import SellCard from '../../shared/SellCard.js';
import { URLS } from "../../../urls";

const data = [
    {
        price: '0',
        title: "Selvsalg",
        subtitle: "For dig der både kan og vil selv. <br/>Og det er ovenikøbet helt gratis!",
        list: [
            "100% gratis",
            "Sikre rammer - Vi guider dig igennem",
            "Professionelle salgsværktøjer til rådighed",
            "Vi har mange opskrevne købere",
            "Det er nemt, og tager kun 5 min",
            "Professionel hjælp lige ved hånden",
        ],
        linkText: "Kom igang",
        url: URLS.SALE.SELF_SALE.FRONT
    },
    {
        price: "19.500",
        title: "Medsalg",
        subtitle: "Spar MANGE penge ved at fremvise selv, <br/>og overlad trygt resten til os.",
        list: [
            "Fremvis selv og SPAR",
            "Annoncering på alle store portaler",
            "Prof fotos/plantegning inklusiv",
            "Vi står for ALT andet end fremvisninger",
            "Vi har ansvarsforsikring",
            "Solgt eller 100% gratis",
            "Vi har mange opskrevne købere"
        ],
        linkText: "Fortæl mig mere",
        modal: "CO_SALE_CONTACT_MODAL",
        url: '/saelg/medsalg'
    },
    {
        price: "39.500",
        title: "Totalsalg",
        subtitle: "Traditionelt mæglerydelse hvor vi står for alt! <wbr/>Blot til en reduceret pris.",
        list: [
            "Vi står for ALT! Også fremvisninger",
            "Samme mægler fra start til slut",
            "Optimering af salgspris",
            "Maksimal tryghed i salget",
            "Hurtigst mulige salgsproces",
            "Vi har ansvarsforsikring",
            "Annoncering på alle store portaler",
            "Prof fotos/plantegning inklusiv i prisen",
            "Solgt eller 100% gratis",
        ],
        linkText: "Bestil gratis vurdering",
        modal: "TOTAL_SALE_CONTACT_MODAL",
        url: '/saelg/totalsalg'
    }
]


const SaleFrontCards = () => (
    <section className="section sale-cards cards ">
        <div className="container">
            <div className="row">
                {data.map((item, index) =>
                    <div key={index} className="col-s-12 col-m-4">
                        <SellCard {...item} />
                    </div>
                )}
            </div>
        </div>
    </section>
)

SaleFrontCards.displayName = 'SaleFrontCards';
SaleFrontCards.propTypes = {};
SaleFrontCards.defaultProps = {};

export default SaleFrontCards;

import React from 'react';
import * as actions from 'actions';
import {connect} from 'react-redux';
import {setPersistant} from "../../../tokenManager";
import Errors from 'components/shared/Errors';


const LoginForm = ({dispatch, loginErrors, rememberPassActive}) => {
    const onSubmit = (e) => {
        e.preventDefault()
        const {email, password, persistant} = e.target
        setPersistant(persistant.checked)
        dispatch(actions.login(email.value, password.value))
    }

    const onSumbitResetPass = (e) => {
        e.preventDefault()
        const {email} = e.target
        dispatch(actions.sendPasswordResetEmail(email.value))
            //.then((res)=> )
    }

    const onChangePersistancy = (e) => {
        setPersistant(e.target.checked)
    }

    return rememberPassActive ? (
        <form className="login-window " onSubmit={onSumbitResetPass}>
            <h2 className="u-green-text">Glemt password?</h2>
            <p>Skriv din email her så sender vi et link så du kan nulstille det.</p>
            <Errors errors={loginErrors}/>
            <label htmlFor="email">Email</label>
            <input tabIndex="1" id="email" name="email" type="text" className="login-window__user"/>
            <a className="login-window__forgot-password" onClick={() => dispatch(actions.setRememberPass(false))}>{"Tilbage"}</a>
            <button tabIndex="1" type="submit" className="button login-window__submit">Send link</button>

        </form>
    ) : (
        <form className="login-window " onSubmit={onSubmit}>
            <h2 className="u-green-text">Log ind</h2>
            <Errors errors={loginErrors}/>
            <label htmlFor="user">Email</label>
            <input tabIndex="1" id="email" name="email" type="text" className="login-window__user"/>
            <label htmlFor="password">Password</label>
            <input tabIndex="1" id="password" name="password" type="password" className="login-window__password"/>
            <a className="login-window__forgot-password" onClick={() => dispatch(actions.setRememberPass(true))}>Glemt password?</a>
            <button tabIndex="1" type="submit" className="button login-window__submit">Log ind</button>
            <br/>
            <div className="input-field__checkbox">
                <input type="checkbox" id="persistant" name="persistant" onChange={onChangePersistancy}/>
                <label htmlFor="persistant">Husk mig
                    <svg className="icon icon--black icon--check">
                        <use xlinkHref="#icon-check"></use>
                    </svg>
                </label>
            </div>
        </form>
    );
}

LoginForm.displayName = 'LoginForm';
LoginForm.propTypes = {};
LoginForm.defaultProps = {};

const mapStateToProps = state => {
    return state.user
}

export default connect(mapStateToProps)(LoginForm)

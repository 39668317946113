export const BACKEND_URL = process.env.HOST

export const RANGES = {
    MIN_SIZE: 30,
    MAX_SIZE: 200,
    MIN_ROOMS: 1,
    MAX_ROOMS: 5,
    MIN_PRICE: 0,
    MAX_PRICE: 5000000,
    MIN_RENT: 1000,
    MAX_RENT: 20000
}

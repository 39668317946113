import * as validation from 'validation'
import * as utils from 'utils'


const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)

export const associationFieldsWithPlaceholders = [
    {
        placeholder: 'Navn på andelsboligforening',
        name: 'cooperativeName',
        required: true,
        type: "text",
        validate: composeValidators(validation.required)

    },
    {
        placeholder: 'Navn på boligadministrator (hvis kendes)',
        name: 'administrationAssociationName',
        type: 'text',
        required: false,
        validate: composeValidators()
    }
]

export const associationFields = [
    {
        label: 'Forening',
        name: 'cooperativeName',
        required: true,
        type: "text",
        validate: composeValidators(validation.required)

    },
    {
        label: 'Navn på boligadministrator (hvis kendes)',
        name: 'administrationAssociationName',
        type: 'text',
        required: false,
        validate: composeValidators()
    }
]

export const addressFields = [
    {
        label: 'Adresse',
        name: 'fullAddress',
        required: true,
        type: "text",
        validate: composeValidators(validation.required)

    },
    {
        label: 'Post nr',
        name: 'zipCode',
        type: 'number',
        required: true,
        validate: composeValidators(validation.required)
    }
]


export const infoFields = [
    {
        label: 'Boligens størrelse i m2',
        name: 'apartmentSquareMetres',
        help: "Du kan finde oplysningen i ejendommens BBR meddelelse, som du finder på fx boligejer.dk eller via din forening. Oplysningen kan måske også stå i foreningens regnskab.",
        type: 'number',
        required: true,
        validate: composeValidators(validation.required, validation.number)
    },
    {
        label: 'Antal værelser',
        name: 'roomsCount',
        help: `<p>
Står i BBR meddelelsen som du kan på fx boligejer.dk. <br>
Har du lavet flere lovlige værelser eller nedlagt værelser, så ring til din kommune og få oplysningen rettet i BBR. <br>
</p> Bemærk at der er visse krav til et nyetableret værelse for at det kan godkendes som værelse. Er du tvivl så kontakt din kommunes teknisk forvaltning.`,
        type: 'number',
        required: true,
        validate: composeValidators(validation.required, validation.number)
    },
    {
        label: 'Andelsværdi',
        name: 'apartmentValue',
        help: `Oplysningen kan du enten aflæse direkte i det nyeste årsregnskab for foreningen, eller du kan beregne den via oplysninger om andelskronen i regnskabet.`,
        type: 'text',
        format: utils.thousandSepparator,
        parse: utils.parseKr,
        required: true,
        validate: composeValidators(validation.required, validation.number)
    },
    {
        label: 'Forbedringer',
        name: 'enhancementsValue',
        help: `<p>Oplysningen finder du i den udarbejdede "Vurderingsrapport".<br>
                Bemærk du ikke må anføre et beløb større end det beløb der fremgår af vurderingsrapporten.</p>
                <p>Har du endnu ikke bestilt eller modtaget din vurderingsrapport så kan du godt selv anslå et beløb her. Basér det gerne på noget faktuelt, fx kvitteringer og fakturaer.</p>
                <p><strong>HUSK</strong> at rette beløbet til i din annonce straks du modtager din rapport. Det vil altid være beløbet i vurderingsrapporten der er gældende.</p>
                <p>  Er der ikke krav til vurderingsrapport i din forening, og ønsker du ikke at få en vurderingsrapport lavet, så kan du desværre ikke anføre eller opkræve noget beløb for forbedringer.</p>`,
        type: 'text',
        format: utils.thousandSepparator,
        parse: utils.parseKr,
        required: true,
        validate: composeValidators(validation.required, validation.number)
    },
    {
        label: 'Særligt tilpasset inventar',
        name: 'specialInventoryValue',
        help: `<p>Oplysningen finder du i den udarbejdede "Vurderingsrapport". <br> Bemærk du ikke må anføre et beløb større end det beløb der fremgår af vurderingsrapporten.</p>
               <p> Har du endnu ikke bestilt eller modtaget din vurderingsrapport så kan du godt selv anslå et beløb her.  Basér det gerne på noget faktuelt, fx kvitteringer og fakturas. </p>
               <p><strong>HUSK</strong> at ret beløbet til i din annonce straks du modtager din rapport. Det vil altid være beløbet i vurderingsrapporten der er gældende.</p>
               <p>Er der ikke krav til vurderingsrapport i din forening, og ønsker du ikke at få lavet en vurderingsrapport, så kan du desværre ikke anføre noget beløb for særligt tilpasset inventar eller opkræve et beløb ved salget.</p>
               <p>  <strong>Hvad med Løsøre?</strong> Løsøre kan du ikke tvinge en køber til at købe med, hvorfor det værdien af løsøre ikke skal medtages i selvekøbsaftalen. Har du løsøre som køber ønsker at købe og du ønsker at sælge med, så skal i aftale salget af dette separat og ubetinget salget af selve andelsboligen, og du må ikke sælge for en højere pris end markedsprisen. Et tip til hvad markedsprisen er, kan være at søge efter samme genstand på dba.dk eller lignende sider.</p>`,
        type: 'text',
        format: utils.thousandSepparator,
        parse: utils.parseKr,
        required: true,
        validate: composeValidators(validation.required, validation.number)
    },
    {
        label: 'Mangler',
        name: 'deductionForShortcomings',
        help: `<p>Oplysningen finder du i den udarbejdede "Vurderingsrapport". <br>Bemærk du ikke må anføre et beløb mindre end det beløb der fremgår af vurderingsrapporten, medmindre det er forhold som du har udbedret eller udbedrer inden overdragelse af boligen.</p>
               <p>De mangler som er udbedret af dig skal du fremlægge dokumentation for overfor din administrator samtog køber. Dette skal når du informere administrator om senest samtidig med du informerer om hvem den nye andelshaver er.</p> 
               <p>Har du endnu ikke bestilt eller modtaget din vurderingsrapport, så kan du godt selv anslå et beløb ud fra dit eget skøn. Husk at ret beløbet til i din annonce <strong>STRAKS</strong> du modtager din vurderingsrapport. Det vil altid være beløbet i vurderingsrapporten der er gældende.</p>
               <p>Er der ikke krav til vurderingsrapport i din forening, og ønsker du ikke at få en vurderingsrapport lavet, så vær opmærksom på, at du fortsat er pligtig til at oplyse køber, om forhold i boligen der ikke er i orden.</p>
               <p>Du har som sælger et 10-årigt sælgeransvar i forhold til skjulte fejl og mangler. Dette er ansvar er stadfæstet i gældende lovgivning for salg af andelsboliger, og man kan ikke aftale sig ud af det ansvar uafgtet om en køber har skrevet under på at frasige sig dette.</p>`,
        type: 'text',
        format: utils.thousandSepparator,
        parse: utils.parseKr,
        required: true,
        validate: composeValidators(validation.required, validation.number)
    }
]


export const expensesFields = [
    {
        label: 'Boligafgift',
        name: 'monthlyFee',
        help: "Find oplysningen på din seneste opkrævning fra foreningen/administrator.",
        type: 'text',
        format: utils.thousandSepparator,
        parse: utils.parseKr,
        required: true,
        validate: composeValidators(validation.required, validation.number)
    },
    {
        label: 'A conto varme',
        name: 'utilitiesHeating',
        help: "Find oplysningen på din seneste opkrævning fra foreningen/administrator.",
        type: 'text',
        format: utils.thousandSepparator,
        parse: utils.parseKr,
        required: true,
        validate: composeValidators(validation.required, validation.number)
    },
    {
        label: 'A conto vand',
        name: 'utilitiesWater',
        help: "Find oplysningen på din seneste opkrævning fra foreningen/administrator.",
        type: 'text',
        format: utils.thousandSepparator,
        parse: utils.parseKr,
        required: false,
        validate: composeValidators(validation.number)
    },
    {
        label: 'Andre driftsomkostninger samlet',
        name: 'additionalFees',
        help: "Find oplysningen på din seneste opkrævning fra foreningen/administrator.",
        type: 'text',
        format: utils.thousandSepparator,
        parse: utils.parseKr,
        required: true,
        validate: composeValidators(validation.required, validation.number)
    },
    {
        label: 'Tilføjelser',
        name: 'utilitiesAdditional',
        type: 'text',
        format: utils.thousandSepparator,
        parse: utils.parseKr,
        required: true,
        validate: composeValidators(validation.required, validation.number)
    }
]


export const descriptionFields = [
    {
        label: 'Yderligere beskrivelse af din bolig',
        name: 'description',
        required: true,
        type: 'textarea',
        validate: composeValidators(validation.required)
    }
]

/*
Validators
A validator takes a message returns a function that takes a value
and returns either true if validated or a message

*/

export const notEmptyValidator = (value) => {
	const message = "Skal være udfyldt"
	const EMPTY = [null, "", undefined]
	if (EMPTY.indexOf(value) >= 0) return message

	return true
}

export const notAsadValidator = (value) => {
	const message = "Bare ikke Asad"
	if (value === 'Asad') return message
	return true
}

export const betweenValidator = (min, max) => ( value ) => {
	const message = `Skal være mellem ${min} og ${max}`
	if (min<value && value<max) return true
	return message
}


/*
Default validators`
Define defualt validators depending of type of field
 */
const DEFAULT_VALIDATORS_FOR_TYPE = {
	'text': [],
	'number': [],
	'email': []
}

const defaultValidatorsFromType = (type)  => {
	return DEFAULT_VALIDATORS_FOR_TYPE[type] || []
}

const defaultValidatorsFromRequired = (required, type)  => {
	return required ? [notEmptyValidator] : []
}


export const required = value => (value ? undefined : 'Skal udfyldes')

export const validateIsTrue = value => value === true ? undefined : 'Skal Markeres'

export const notEmptyArray = (value) => value.length > 0 ? undefined : 'Du skal vælge mindst én'

const maxLength = max => value =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined
const maxLength15 = maxLength(15)
export const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined
export const minLength2 = minLength(2)
export const number = value =>
  value && isNaN(Number(value)) ? 'Skal være en tal værdi' : undefined
const minValue = min => value =>
  value && value < min ? `Must be at least ${min}` : undefined
const minValue18 = minValue(18)
export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Skal være en email'
    : undefined
const tooOld = value =>
  value && value > 65 ? 'You might be too old for this' : undefined
const aol = value =>
  value && /.+@aol\.com/.test(value)
    ? 'Really? You still use AOL for your email?'
    : undefined
const alphaNumeric = value =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? 'Only alphanumeric characters'
    : undefined
export const phoneNumber = value =>
  value && !/^(0|[1-9][0-9]{7})$/i.test(value)
    ? 'Telefon nummeret skal være 8 karakterer og uden landekode'
    : undefined

export const zipCode = value => (value > 999 && value < 9999) ? undefined : "Skal være et post nr."

const validateNotEmpty = (value, allValues, props, name) => {
    return "Its an error"
}


/*
Public functions should be imported and used on Fields
 */

export const validateField = ({value,  fieldValidators=[], required=false, type='text'}) => {
	const requiredValidators = defaultValidatorsFromRequired(required, type)
	const defaultValidators = defaultValidatorsFromType(type)
	const validators = [...requiredValidators, ...defaultValidators, ...fieldValidators]
	let errors = []
	for (const validator of validators){
		const isValidOrMessage = validator(value)
		if (isValidOrMessage !== true) {
			errors.push({message: isValidOrMessage})
		}
	}
	return errors
}

export const validateForm = ({fieldNames, errors}) => {
	for (const fieldName of fieldNames){
		const fieldErrors = errors[fieldName]
		console.log(errors)
		if (fieldErrors!==undefined && fieldErrors.length > 0) return false
	}
	return true
}

export const validateFormAsPromise = ({fieldNames, errors}) => {
	return new Promise((resolve, reject) => {
		if (validateForm({fieldNames, errors})){
			resolve()
		}
		else reject(errors)
	})
}
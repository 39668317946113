import React from "react"
import BuyerIndexFront from "./BuyerIndexFront"
import CreateBuyerIndex from "./CreateBuyerIndex"
import DoesNotExist from "../DoesNotExist"
import { Route, Switch } from "react-router-dom"
import { URLS } from "urls"

const BuyerIndex = () => {
  return (
    <div className="buyerindex-component animate--down">
      <Switch>
        <Route path={URLS.BUYER_INDEX.FRONT} exact component={BuyerIndexFront} />
        <Route path={URLS.BUYER_INDEX.CREATE.FRONT} component={DoesNotExist} />
        <DoesNotExist />
      </Switch>
    </div>
  )
}

BuyerIndex.displayName = "BuyerIndex"
BuyerIndex.propTypes = {}
BuyerIndex.defaultProps = {}

export default BuyerIndex

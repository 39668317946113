import React from 'react';
import * as actions from 'actions'
import {connect} from 'react-redux'

const SubscriptionCancelModal = ({dispatch}) => {
  return (
    <div className="successmodal-component">
        <h2>Er du sikker på at du vil opsige dit abonnement</h2>
        <p>Hvis du opsiger dit abonnement vil du ikke længere modtage invitation til åbenthus på nye andelsboliger</p>
        <button className="button" onClick={()=> dispatch(actions.reepayUnsubscribe()).then(
            () => {
                dispatch(actions.setVisibleModal(null))
            }
        )}>Ja</button>
        <button className="button" onClick={()=> dispatch(actions.setVisibleModal(null))}>Nej</button>
    </div>
  );
}

SubscriptionCancelModal.displayName = 'SubscriptionCancelModal';
SubscriptionCancelModal.propTypes = {};
SubscriptionCancelModal.defaultProps = {};

export default connect()(SubscriptionCancelModal);
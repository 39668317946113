import React from 'react';
import {connect} from 'react-redux'
import {URLS} from "urls";
import {withRouter, Redirect} from 'react-router-dom'
import {graphql, compose} from 'react-apollo'
import * as queries from 'queries'
import {Link} from 'react-router-dom'
import {setHelpModalContent} from "../../../../actions/index";
import {submitBuyerCase} from "actions/buyerCaseActions";
import {LOAN_PROPOSAL_CONTACT_TIMESPAN_TYPES} from '../../../../values'
import {CheckBox} from "../../../shared/forms/index";
import {StaticLoanText} from "./LoanProposal1";

const TermsCheckBox = ({name, value, onChange, onShowModal, className = false}) => {
    return <div className={className ? "input-field__checkbox " + className : "input-field__checkbox"}
                onClick={onChange}>
        <input id={name} className="check-list" name={name} type="checkbox" checked={value}></input>
        <label><span>Jeg accepterer <a onClick={onShowModal}> vilkårene </a> for lånetilbud</span>
            <svg className="icon icon--black icon--check">
                <use xlinkHref="#icon-check"></use>
            </svg>
        </label>
    </div>
}


const LoanProposal2 = ({dispatch, data, textData}) => {
    if (data.loading || textData.loading) return <span>Loading...</span>

    const user = data.ubUser
    if (user === null) return <Redirect to={URLS.BUYER_INDEX.CREATE.USER}/>
    if (!user.verified) return <Redirect to={URLS.BUYER_INDEX.CREATE.ACTIVATION}/>

    if (user.buyerCase === null) return <div>Du mangler at oprette dine ønsker</div>

    const setLoanProposalTimespan = v => {
        dispatch(submitBuyerCase({loanProposalContactTimespan: v}))
    }

    const onShowModal = e => {
        e.stopPropagation()

        dispatch(setHelpModalContent({title: textData.siteText.headline, body: textData.siteText.text}))

    }

    const isDisabled = (user.buyerCase.loanProposalContactTimeSpan!==LOAN_PROPOSAL_CONTACT_TIMESPAN_TYPES.NOT_INTERESTED
        && !user.buyerCase.loanProposalTermsAccepted)

    const onToggleTerms = () => {
        dispatch(submitBuyerCase({loanProposalTermsAccepted: !user.buyerCase.loanProposalTermsAccepted}))
    }

    const isType = v => {
        return v === user.buyerCase.loanProposalContactTimespan
    }


    return (
        <div className="al2-component">
            <div>
                <section className="section u-lightgrey">
                    <div className="container">
                        <StaticLoanText/>
                        <form action={e => e.preventDefault()}>
                            <div className="subsection">
                                <CheckBox label="I tidsrummet kl. 09-16"
                                       type="radio"
                                       name='time-1' value={isType(LOAN_PROPOSAL_CONTACT_TIMESPAN_TYPES.WEEK_9_16)}
                                       onChange={() => setLoanProposalTimespan(1)}/>
                                <CheckBox label="I tidsrummet kl. 16-19" type="radio"
                                       name='time-2' value={isType(LOAN_PROPOSAL_CONTACT_TIMESPAN_TYPES.WEEK_16_19)}
                                       onChange={() => setLoanProposalTimespan(2)}/>
                                <CheckBox label="Weekend kl. 09-13" type="radio"
                                       name='time-3' value={isType(LOAN_PROPOSAL_CONTACT_TIMESPAN_TYPES.WEEKEND_9_13)}
                                       onChange={() => setLoanProposalTimespan(3)}/>
                            </div>


                            <TermsCheckBox value={user.buyerCase.loanProposalTermsAccepted}
                                           onChange={onToggleTerms}
                                           onShowModal={onShowModal}/>

                            <Link className="button" to={URLS.BUYER_INDEX.CREATE.PAYMENT}
                                  disabled={isDisabled}>Næste</Link>
                        </form>
                    </div>
                </section>
            </div>

        </div>
    );
}

LoanProposal2.displayName = 'LoanProposal2' + '';
LoanProposal2.propTypes = {};
LoanProposal2.defaultProps = {};

const LoanProposal2WithData = compose(
    graphql(queries.UB_USER_QUERY),
    graphql(queries.SITE_TEXT_QUERY, {
        name: 'textData',
        options: {
            variables: {
                textId: "user_conditions_al"
            }
        }
    }))(LoanProposal2)

export default withRouter(connect()(LoanProposal2WithData));

import React from 'react';
import Slider from 'react-slick'
import {Link} from 'react-router-dom';
import {graphql} from "react-apollo"
import {ensure_ssl, kr, getFrontImage} from "../../../utils";
import * as queries from 'queries'
import {URLS} from "urls";
import * as searchActions from 'actions/searchActions'
import * as utils from 'utils'
import { connect } from 'react-redux'
import Banner from 'components/shared/Banner'

function makeid() {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

  for (var i = 0; i < 5; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

const CaseImage = (props) => {
    const {url, to} = props
    const id = makeid()
    return <Link className="slider__slide__img__wrapper" to={to} style={{position: 'relative'}}>
        <style dangerouslySetInnerHTML={{
          __html: [
             `.${ id }:before {`,
             `background-image: url( ${ url } )`,
             '}'
            ].join('\n')
          }}>
        </style>
            <div className={`slider__slide__img ${ id }`}>
                <Banner {...props}/>
            </div>
    </Link>
}



const RoomsCount = ({roomsCount}) => {
    if (roomsCount===1) {
        return <div className="slider__slide__info slider__slide--rooms">{roomsCount} værelse</div>
    }
    return <div className="slider__slide__info slider__slide--rooms">{roomsCount} værelser</div>
}

const Case = (props) => {
    const {
                  id, __typename, caseId,
                  caseimages, street, number, floorSide,
                  roomsCount, livingSpace, city,
                  initialPrice, frontpageImage
    } = props
    const frontImage = getFrontImage(caseimages, frontpageImage)
    const url = utils.caseUrlFromTypename(__typename, caseId)
    return (
        <div className="slider__slide">
            <CaseImage {...props} id={id} key={frontImage.id} caption={frontImage.caption}
                       url={ensure_ssl(frontImage.url)} to={url}/>

            <div className="slider__slide__info slider__slide--street">{street} {number}, {floorSide}</div>
            <div className="slider__slide__info slider__slide--zip-city">{city}</div>
            <div className="slider__slide__info slider__slide--size">Boligareal: {livingSpace} m²</div>
            <RoomsCount roomsCount={roomsCount}/>
            <div className="slider__slide__info slider__slide--price">Kontantpris {kr(initialPrice)}</div>
        </div>
    )
}

Case.displayName = 'Case';
Case.propTypes = {};
Case.defaultProps = {};


function SlickNextArrow(props) {
    const {onClick} = props
    return (
        <svg onClick={onClick} className="icon icon--bordered-arrow slider--next">
            <use href="#icon-arrow"></use>
        </svg>
    );
}

function SlickPrevArrow(props) {
    const {onClick} = props
    return (
        <svg onClick={onClick} className="icon icon--bordered-arrow slider--prev">
            <use href="#icon-arrow"></use>
        </svg>
    );
}


const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SlickNextArrow/>,
    prevArrow: <SlickPrevArrow/>,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                arrows: false
            }
        }, {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                arrows: false
            }
        }
    ]
}


const NewCases = ({data, dispatch}) => {
    if (data.loading) return <div className="section--loading"></div>
    if (data.error) return <div>{data.error.message}</div>
    return (
        <section className="slider slider--front animate--in">
            <div className="container">
                <div className="row">
                    <div className="col-s-12 col-m-8">
                        <h2>Nyeste andelsboliger til salg</h2>
                    </div>
                    <div className="col-s-12 col-m-4">
                        <Link to={URLS.BUY.FRONT} className="button" onClick={()=> dispatch(searchActions.resetSearch())}>Se de nyeste boliger</Link>
                    </div>
                    <div className="col-s-12">
                        <div className="slider__container row">
                            <Slider {...sliderSettings} >
                                {data.newCases.map((c) => <div key={c.id}><Case {...c}/></div>)}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

NewCases.displayName = 'NewCases';
NewCases.propTypes = {};

const NewCasesWithData = graphql(queries.NEW_CASES_QUERY, {
    options: {
        fetchPolicy: 'cache-and-network'
    }
})(NewCases)
//const NewCasesWithData = NewCases

export default connect()(NewCasesWithData);


import React from 'react';
import {CheckBox} from "components/shared/forms"
import {some} from 'lodash'

const CheckBoxBlock = ({loading, options, value, onToggle, labelName, title}) => {
	if (loading) {
		return <div className="section--loader"></div>
	}
	const getValue = (id) => some(value, {id: id})
	const _onToggle = (id) => onToggle(id, value, getValue(id), options)
	return (
		<div>
			<h3 className="u-green-text">{title}</h3>
			{options.map(x => <CheckBox
					key={x.id}
					label={x[labelName]}
					onChange={_onToggle(x.id)}
					value={getValue(x.id)}
				/>
			)}
		</div>
	)
}

export default CheckBoxBlock
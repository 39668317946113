import React from 'react';
import UploadWidget from '../../../shared/forms/UploadWidget'
import {uploadSelfSaleImages} from "uploadApi";
import DeletableImage from 'components/shared/forms/DeletableImage'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {URLS} from 'urls'
import * as actions from 'actions'


const PhotoUpload = ({history, selfSaleCase, refetch, dispatch}) => {
	const onSubmit = (e) => {
		e.preventDefault()
		history.push(URLS.SALE.SELF_SALE.SELECT_PRO_HELP)
	}

	const onUploadSelfSaleImages = (e) => {
		const files = e.target.files
		uploadSelfSaleImages(files, selfSaleCase.id)
			.then(() => refetch())
			.catch((err) => console.log(err))
	}

	const onDeleteImage = (id) => () => {
	    dispatch(actions.deleteSelfSaleCaseImage({id: id}))
    }

	return (
		<div className="selfsale__upload-images">
			<section className="section subsection u-lightgrey u-full-height flex-center">
				<div className="container">
					<div className="row">
						<div className="col-s-12 col-l-12">
							<div className="flex-column u-center-text">
								<form onSubmit={onSubmit} className="selfsale__upload-images__form">
									<h1 className="u-green-text">Upload dine billeder her</h1>
									<p className="green-text">Upload 5-10 gode fotos</p>
									<div className="flex-column">
										{selfSaleCase.selfsalecaseimages.map((x, i) => (
											<DeletableImage key={i} {...x} onDelete={onDeleteImage(x.id)}/>))
										}
										<UploadWidget 
											multiple={true}
											onUpload={onUploadSelfSaleImages}
											label={'asdf'} />
									</div>
									<button type="submit" className="button">Næste</button>
								</form>

							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

PhotoUpload.displayName = 'PhotoUpload';
PhotoUpload.propTypes = {};
PhotoUpload.defaultProps = {};

export default withRouter(connect()(PhotoUpload));


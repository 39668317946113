import React from "react"
import CaseContactForm from "components/shared/forms/CaseContactForm"
import { CONTACT_TYPES } from "../../values"
import { submitCaseContactForm, setVisibleModal } from "../../actions/index"
import { connect } from "react-redux"
import SellerInfo from "components/shared/SellerInfo"
import CaseInfo from "components/shared/CaseInfo"
import { isSelfSale } from "../../utils"

export const onSubmitContactForm =
  (dispatch, defaultData = {}) =>
  (data) => {
    const input = { ...data, ...defaultData }
    dispatch(submitCaseContactForm(input)).then((data) => {
      dispatch(setVisibleModal("CASE_CONTACT_SUBMITTED_MODAL", null))
    })
  }

const _BookAShowModalContent = ({ dispatch, data }) => {
  const { caseId, initialPrice } = data
  const body =
    isSelfSale(data) || data.sellerIsAgent ? (
      <p>Skriv til sælger her, så I kan aftale et tidspunkt for fremvisningen.</p>
    ) : (
      <p>
        Vi glæder os til at vise dig boligen, og vil kontakte dig snarest så vi kan aftale et tidspunkt for
        fremvisningen.
      </p>
    )

  return (
    <div>
      <h2>BOOK EN FREMVISNING</h2>
      {body}
      <CaseInfo {...data} price={initialPrice} />
      <SellerInfo {...data} />
      <CaseContactForm
        onSubmit={onSubmitContactForm(dispatch, { contactType: CONTACT_TYPES.BOOK_SHOW, caseId: caseId })}
      />
    </div>
  )
}

export const BookAShowModalContent = connect((state) => ({ data: state.app.visibleModalData }))(_BookAShowModalContent)

const _BookOpenHouseModalContent = ({ dispatch, data }) => {
  const { caseId, initialPrice } = data
  const body =
    isSelfSale(data) || data.sellerIsAgent ? (
      <p>Skriv til sælger her, så I kan aftale et tidspunkt for fremvisningen.</p>
    ) : (
      <p>
        Vi glæder os til at vise dig boligen, og vil kontakte dig snarest så vi kan aftale et tidspunkt for
        fremvisningen.
      </p>
    )

  return (
    <div>
      <h2>TILMELD TIL ÅBENT HUS</h2>
      {body}
      <CaseInfo {...data} type="openHouse" price={initialPrice} />
      <SellerInfo {...data} />
      <CaseContactForm
        onSubmit={onSubmitContactForm(dispatch, { contactType: CONTACT_TYPES.OPEN_HOUSE, caseId: caseId })}
      />
    </div>
  )
}

export const BookOpenHouseModalContent = connect((state) => ({ data: state.app.visibleModalData }))(
  _BookOpenHouseModalContent
)

const _CaseContactModalContent = ({ dispatch, data }) => {
  const { caseId, initialPrice } = data

  const body =
    isSelfSale(data) || data.sellerIsAgent ? (
      <div>
        <h2>KONTAKT SÆLGER?</h2>
        <p>Skriv til sælger her og få svar på eventulle spørgsmål vedrørende boligen.</p>
      </div>
    ) : (
      <div>
        <h2>KONTAKT MÆGLER?</h2>
        <p>
          Vi glæder os til at vise dig boligen, og vil kontakte dig snarest så vi kan aftale et tidspunkt for
          fremvisningen.
        </p>
      </div>
    )
  return (
    <div>
      {body}
      <CaseInfo {...data} price={initialPrice} />
      <SellerInfo {...data} />
      <CaseContactForm
        onSubmit={onSubmitContactForm(dispatch, { contactType: CONTACT_TYPES.CONTACT_AGENT, caseId: caseId })}
      />
    </div>
  )
}

export const CaseContactModalContent = connect((state) => ({ data: state.app.visibleModalData }))(
  _CaseContactModalContent
)

const _CoSaleContactModalContent = ({ dispatch }) => {
  return (
    <div>
      <h2>MEDSALG</h2>
      <h4>Du fremviser, vi tager os af resten</h4>
      <p>Skriv eller ring til os for at høre mere</p>
      <p>Tlf: 7199 6939 - Mail: info@andelshandel.dk</p>
      <CaseContactForm
        onSubmit={onSubmitContactForm(dispatch, { contactType: CONTACT_TYPES.CO_SALE, caseId: null })}
        withCurrentAddressFields={true}
      />
    </div>
  )
}

export const CoSaleContactModalContent = connect((state) => ({ data: state.app.visibleModalData }))(
  _CoSaleContactModalContent
)

const _TotalSaleContactModalContent = ({ dispatch }) => {
  return (
    <div>
      <h2>TOTALSALG</h2>
      <h4>Du kan slappe af imens vi klarer ALT</h4>
      <p>Skriv eller ring til os for at høre mere</p>
      <p>Tlf: 7199 6939 - Mail: info@andelshandel.dk</p>
      <CaseContactForm
        onSubmit={onSubmitContactForm(dispatch, { contactType: CONTACT_TYPES.TOTAL_SALE, caseId: null })}
        withCurrentAddressFields={true}
      />
    </div>
  )
}

export const TotalSaleContactModalContent = connect((state) => ({ data: state.app.visibleModalData }))(
  _TotalSaleContactModalContent
)

const _CaseContactSubmittedContent = ({ dispatch }) => {
  return (
    <div>
      <h2>Tak for din henvendelse?</h2>
      <p>Vi vender tilbage indenfor 24 timer</p>
      <button className="button" onClick={() => dispatch(setVisibleModal(null, null))}>
        OK
      </button>
    </div>
  )
}

export const CaseContactSubmittedContent = connect((state) => ({ data: state.app.visibleModalData }))(
  _CaseContactSubmittedContent
)

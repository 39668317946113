import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom'
import ProfilePage from "./ProfilePage"
import BuyerIndexPage from "./BuyerIndex"
import MySelfSale from "./MySelfSale"
import DoesNotExists from 'components/pages/DoesNotExist'
import { connect }  from 'react-redux'
import { URLS } from 'urls'
import MySwapCase from "./MySwapCase";

const Profile = () => {
  return (
    <div className="profile-component">
        <Switch>
    	    <Route path={ URLS.PROFILE.HOME } exact component={ ProfilePage }/>
            <Route path={ URLS.PROFILE.BUYER_INDEX } exact component={ BuyerIndexPage }/>
    	    <Route path={ URLS.PROFILE.SELF_SALE } exact component={ MySelfSale }/>
            <Route path={ URLS.PROFILE.SWAP } exact component={ MySwapCase }/>
            <DoesNotExists/>
        </Switch>
    </div>
  );
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

Profile.displayName = 'Profile';
Profile.propTypes = {};
Profile.defaultProps = {};

export default withRouter(connect(mapStateToProps)(Profile));

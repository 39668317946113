import React from 'react';
import UploadWidget from '../../../shared/forms/UploadWidget'
import {uploadSwapImages} from "uploadApi";
import DeletableImage from 'components/shared/forms/DeletableImage'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {URLS} from 'urls'
import * as actions from 'actions'


const PhotoUpload = ({history, swapCase, refetch, dispatch}) => {
	const onSubmit = (e) => {
		e.preventDefault()
		history.push(URLS.SWAP.CREATE.S7_WHISHES)
	}

	const onUploadSwapImages = (e) => {
		const files = e.target.files
		uploadSwapImages(files, swapCase.id)
			.then(() => refetch())
			.catch((err) => {
				alert(err.response.data)
				refetch()
				}

			)
	}

	const onDeleteImage = (id) => () => {
	    dispatch(actions.deleteSwapCaseImage({id: id}))
    }

	return (
		<div className="selfsale__upload-images">
			<section className="section subsection u-lightgrey u-full-height flex-center">
				<div className="container">
					<div className="row">
						<div className="col-s-12 col-l-12">
							<div className="flex-column u-center-text">
								<form onSubmit={onSubmit} className="selfsale__upload-images__form">
									<h1 className="u-green-text">Upload dine billeder nu, eller vent til senere</h1>
									<p className="green-text">Upload 5-10 gode fotos (.jpg, .png, mindre end 10mb, pr. billede)</p>
									<div className="flex-column">
										{swapCase.swapcaseimages.map((x, i) => (
											<DeletableImage key={i} {...x} onDelete={onDeleteImage(x.id)}/>))
										}
										<UploadWidget 
											multiple={true}
											onUpload={onUploadSwapImages}
											label={'asdf'}
											accept="image/*"
										/>
									</div>
									<button className="button">Næste</button>
								</form>

							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

PhotoUpload.displayName = 'PhotoUpload';
PhotoUpload.propTypes = {};
PhotoUpload.defaultProps = {};

export default withRouter(connect()(PhotoUpload));


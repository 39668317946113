import React from 'react';
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import HTMLContent from '../HTMLContent'


const SiteTextModal = ({dispatch, data, history}) => {
    const { textId, withHeadline, button_close_text } = data
    return (
        <div className="successmodal-component">
            <HTMLContent textId={textId} withHeadline={withHeadline}/>
        </div>
    );
}

SiteTextModal.displayName = 'SiteTextModal';
SiteTextModal.propTypes = {};
SiteTextModal.defaultProps = {};

const mapStateToProps = state => ({
    data: state.app.visibleModalData
})

export default withRouter(connect(mapStateToProps)(SiteTextModal));

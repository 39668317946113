import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { graphql, Query } from "react-apollo"
import { Link } from "react-router-dom"
import { Upload } from "components/shared/forms"
import { STATUS_TYPES, SWAP_CASE_TYPE_NAME } from "../../../values"

import {
  ALL_FACILITIES_QUERY,
  SWAP_CASE_QUERY_CASE_PAGE,
  AVAILABLE_FACILITIES_SWAP,
  SWAP_RESPONSE_FILTER_QUERY,
  SWAP_MATCHES,
  NEW_MATCHING_SWAP_CASES,
} from "queries"

import * as actions from "actions"
import * as queries from "queries"
import { pick, some } from "lodash"
import * as utils from "utils"

import DeletableImage from "components/shared/forms/DeletableImage"
import DeletableDocument from "components/shared/forms/DeletableDocument"

import { uploadFile, uploadSwapImages } from "uploadApi"
import { FieldSection, CheckBoxBlock } from "../../shared/finalFormFields"
import { Form, Field } from "react-final-form"
import SwapWishesForm from "../Swap/CreateSwap/SwapWhishesForm"

import * as Dragula from "react-dragula"
import {
  associationFieldsWithPlaceholders,
  addressFields,
  infoFields,
  expensesFields,
  descriptionFields,
  wishesFields,
} from "../Swap/swapFields"

import "./dragula.css"

import { enumToInt } from "../../../utils"
import SwapApartment from "../Swap/SwapApartment"
import SwapMap from "../Swap/SwapMap"
import { URLS } from "../../../urls"
import { getSwapSubmitFn } from "../Swap/swapHelpers"
import SwapResult from "../Swap/SwapResult"

import { withRouter } from "react-router"
import swap from "components/pages/Swap"
import LoginRequiredPage from "components/pages/LoginRequiredPage"
import { mapSwapApartmentProps } from "components/pages/Swap/swapHelpers"
import SwapApartmentCard from "components/pages/Swap/SwapApartmentCard"

const formBlocks = [
  {
    title: "Forening og administrator",
    fields: associationFieldsWithPlaceholders,
    formName: "mySwapCaseAssociationForm",
  },
  {
    title: "Din bolig",
    fields: addressFields,
    formName: "mySwapCaseAddressForm",
  },
  {
    title: "Info om Boligen",
    fields: infoFields,
    formName: "mySwapCaseInfoForm",
  },
  {
    title: "Generelle udgifter",
    fields: expensesFields,
    formName: "mySwapCaseExpensesForm",
  },
]

const descriptionBlock = {
  title: "Yderligere beskrivelse af din bolig",
  fields: descriptionFields,
  formName: "mySwapCaseDescriptionForm",
}

const documents = [
  {
    label: "Nyeste Årsregnskab",
    key: "annualAccounts",
  },
  {
    label: "Gældende vedtægter",
    key: "rulesRegulations",
  },
  {
    label: "Referat fra seneste generalforsamling",
    key: "latestBoardMeetingReport",
  },

  {
    label: "Energimærke",
    key: "energyLabel",
  },
  {
    label: "Næste års budget",
    key: "nextYearsBudget",
  },
  {
    label: "Husorden",
    key: "houseRules",
  },
]

const FacilitiesBlockWithData = graphql(AVAILABLE_FACILITIES_SWAP, {
  props: ({ data: { loading, availableFacilitiesSwap } }) => ({
    loading: loading,
    options: availableFacilitiesSwap,
    labelName: "facilityDescription",
  }),
})(CheckBoxBlock)

const FacilitiesFormRender = (props) => {
  const { handleSubmit, modified } = props
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Field name="facilities" title="Har din bolig?" component={FacilitiesBlockWithData} />
      </div>
      <button className="button" disabled={!modified.facilities}>
        Gem
      </button>
    </form>
  )
}

const Tabify = ({ children, initialTabName }) => {
  var initialTabNum = 0
  if (initialTabName) {
    const reducedChildren = children.reduce((map, x, i) => {
      map[x.props.tabname] = i
      return map
    }, new Map())
    initialTabNum = reducedChildren[initialTabName] || initialTabNum
  }
  const [tabNum, setTabNum] = useState(initialTabNum)

  return (
    <div className="tabify">
      <div className="tabify-tabs">
        {children.map((x, i) => (
          <span className="tabify-tab" key={i} current={tabNum === i ? "true" : "false"} onClick={() => setTabNum(i)}>
            {x.props.tablabel}
          </span>
        ))}
      </div>
      <div className="subsection"></div>
      <div>{children[tabNum]}</div>
    </div>
  )
}

const SwapMatches = ({ swapMatches }) => {
  return (
    <section className="apartments-grid">
      <div className="container">
        <div className="apartments-grid__wrapper">
          <div className="apartments-grid__apartments">
            <div className="row">
              {swapMatches.map((item, index) => {
                const swapCaseUuid = item.uuid
                return (
                  <Query
                    key={index}
                    query={SWAP_CASE_QUERY_CASE_PAGE}
                    variables={{ caseUuid: swapCaseUuid }}
                    fetchPolicy="network-and-cache">
                    {({ loading, error, data }) => {
                      const { swapCaseByUuid } = data
                      return <SwapApartment loading={loading} key={index} {...swapCaseByUuid} />
                    }}
                  </Query>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const SwapResponses = ({ swapResponses, responseModelName }) => {
  return (
    <section className="apartments-grid">
      <div className="container">
        <div className="apartments-grid__wrapper">
          <div className="apartments-grid__apartments">
            <div className="row">
              {swapResponses.map((item, index) => {
                const swapCaseUuid = item[responseModelName].uuid
                return (
                  <Query
                    key={index}
                    query={SWAP_CASE_QUERY_CASE_PAGE}
                    variables={{ caseUuid: swapCaseUuid }}
                    fetchPolicy="network-and-cache">
                    {({ loading, error, data }) => {
                      const { swapCaseByUuid } = data
                      return <SwapApartment loading={loading} key={index} {...swapCaseByUuid} />
                    }}
                  </Query>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

let StatusForm = (props) => {
  const { initialValues } = props
  console.log(initialValues)
  switch (initialValues.status) {
    case STATUS_TYPES.BEEING_CREATED:
      return (
        <form style={{ position: "relative" }}>
          <Field name="status" component="select" style={{ width: "100%", color: "red" }}>
            <option value={STATUS_TYPES.BEEING_CREATED} disabled>
              • Din annonce er inaktiv - afventer godkendelse
            </option>
          </Field>
          <div style={{ position: "absolute", right: "20px", top: "10px", pointerEvents: "none" }}>
            <svg width="25px" height="25px" viewBox="0 0 24 24" fill="#2fab66" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.00003 8.5C6.59557 8.5 6.23093 8.74364 6.07615 9.11732C5.92137 9.49099 6.00692 9.92111 6.29292 10.2071L11.2929 15.2071C11.6834 15.5976 12.3166 15.5976 12.7071 15.2071L17.7071 10.2071C17.9931 9.92111 18.0787 9.49099 17.9239 9.11732C17.7691 8.74364 17.4045 8.5 17 8.5H7.00003Z"
                fill="red"
              />
            </svg>
          </div>
        </form>
      )
    case STATUS_TYPES.ACTIVE:
      return (
        <form style={{ position: "relative" }}>
          <Field name="status" component="select" style={{ width: "100%", color: "green" }} onChange={props.onChange}>
            <option value={STATUS_TYPES.ACTIVE}>• Din annonce er aktiv</option>
            <option value={STATUS_TYPES.SOLD}>Sæt til solgt</option>
            <option value={STATUS_TYPES.PAUSE}>Sæt på pause</option>
            <option value={STATUS_TYPES.REMOVED}>Tag ned - vil ikke sælge alligevel</option>
          </Field>
          <div style={{ position: "absolute", right: "20px", top: "10px", pointerEvents: "none" }}>
            <svg width="25px" height="25px" viewBox="0 0 24 24" fill="#2fab66" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.00003 8.5C6.59557 8.5 6.23093 8.74364 6.07615 9.11732C5.92137 9.49099 6.00692 9.92111 6.29292 10.2071L11.2929 15.2071C11.6834 15.5976 12.3166 15.5976 12.7071 15.2071L17.7071 10.2071C17.9931 9.92111 18.0787 9.49099 17.9239 9.11732C17.7691 8.74364 17.4045 8.5 17 8.5H7.00003Z"
                fill="#2fa66"
              />
            </svg>
          </div>
        </form>
      )
    case STATUS_TYPES.SOLD:
      return (
        <form style={{ position: "relative" }}>
          <Field name="status" component="select" style={{ width: "100%", color: "red" }} onChange={props.onChange}>
            <option value={STATUS_TYPES.SOLD}>• Din annonce er inaktiv - sat til solgt</option>
            <option value={STATUS_TYPES.ACTIVE}>Aktivér igen</option>
          </Field>
          <div style={{ position: "absolute", right: "20px", top: "10px", pointerEvents: "none" }}>
            <svg width="25px" height="25px" viewBox="0 0 24 24" fill="#2fab66" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.00003 8.5C6.59557 8.5 6.23093 8.74364 6.07615 9.11732C5.92137 9.49099 6.00692 9.92111 6.29292 10.2071L11.2929 15.2071C11.6834 15.5976 12.3166 15.5976 12.7071 15.2071L17.7071 10.2071C17.9931 9.92111 18.0787 9.49099 17.9239 9.11732C17.7691 8.74364 17.4045 8.5 17 8.5H7.00003Z"
                fill="red"
              />
            </svg>
          </div>
        </form>
      )
    case STATUS_TYPES.PAUSE:
      return (
        <form style={{ position: "relative" }}>
          <Field name="status" component="select" style={{ width: "100%", color: "red" }} onChange={props.onChange}>
            <option value={STATUS_TYPES.PAUSE}>• Din annonce er på pause</option>
            <option value={STATUS_TYPES.ACTIVE}>Aktivér igen</option>
          </Field>
          <div style={{ position: "absolute", right: "20px", top: "10px", pointerEvents: "none" }}>
            <svg width="25px" height="25px" viewBox="0 0 24 24" fill="#2fab66" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.00003 8.5C6.59557 8.5 6.23093 8.74364 6.07615 9.11732C5.92137 9.49099 6.00692 9.92111 6.29292 10.2071L11.2929 15.2071C11.6834 15.5976 12.3166 15.5976 12.7071 15.2071L17.7071 10.2071C17.9931 9.92111 18.0787 9.49099 17.9239 9.11732C17.7691 8.74364 17.4045 8.5 17 8.5H7.00003Z"
                fill="red"
              />
            </svg>
          </div>
        </form>
      )
    case STATUS_TYPES.REMOVED:
      return (
        <form style={{ position: "relative" }}>
          <Field name="status" component="select" style={{ width: "100%", color: "red" }} onChange={props.onChange}>
            <option value={STATUS_TYPES.REMOVED}>• Din annonce er nedtaget</option>
            <option value={STATUS_TYPES.ACTIVE}>Aktivér igen</option>
            <option value={STATUS_TYPES.SOLD}>Sæt til solgt</option>
          </Field>
          <div style={{ position: "absolute", right: "20px", top: "10px", pointerEvents: "none" }}>
            <svg width="25px" height="25px" viewBox="0 0 24 24" fill="#2fab66" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.00003 8.5C6.59557 8.5 6.23093 8.74364 6.07615 9.11732C5.92137 9.49099 6.00692 9.92111 6.29292 10.2071L11.2929 15.2071C11.6834 15.5976 12.3166 15.5976 12.7071 15.2071L17.7071 10.2071C17.9931 9.92111 18.0787 9.49099 17.9239 9.11732C17.7691 8.74364 17.4045 8.5 17 8.5H7.00003Z"
                fill="red"
              />
            </svg>
          </div>
        </form>
      )
  }
  return null
}

let StatusSection = (props) => {
  const { swapCase, dispatch } = props
  return (
    <div className="subsection">
      <h3 className="u-green-text">Status</h3>
      <Form
        onSubmit={(e) => console.log("Is required but not used here")}
        initialValues={{ status: swapCase.status }}
        onChange={(e) => {
          dispatch(actions.modifySwapCase({ status: enumToInt(e.target.value) }))
        }}>
        {(props) => <StatusForm {...props} />}
      </Form>
      <Link to={utils.caseUrlFromTypename(SWAP_CASE_TYPE_NAME, swapCase.uuid)}>
        <a>Se din boligannonce her</a>
      </Link>
    </div>
  )
}

StatusSection = connect()(StatusSection)

const FormSection = ({ title, fields, formName, initialObject, onSubmit }) => {
  const initialValues = pick(initialObject, utils.listByKey(fields, "name"))
  const FinalForm = () => (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize={true}
      render={({ invalid, handleSubmit, pristine }) => (
        <div className="subsection">
          <form onSubmit={handleSubmit}>
            <FieldSection title={title} fields={fields} className="" />
            <button className="button" disabled={invalid || pristine}>
              Gem
            </button>
          </form>
        </div>
      )}
    />
  )
  return <FinalForm />
}

const TabLabelWithCount = ({ uuid }) => {
  const [responseCount, setResponseCount] = useState(0)

  return (
    <Query
      query={SWAP_RESPONSE_FILTER_QUERY}
      variables={{ swapCaseOther_Uuid: uuid, isInterested: 1 }}
      onCompleted={(data) => setResponseCount(data.swapResponses.length)}>
      {({ loading, error }) => {
        if (loading) return "Loading..."
        if (error) return `Error: ${error.message}`
        return (
          <span>
            Interesserede i min bolig{" "}
            <span
              style={{
                backgroundColor: "#2FAB66",
                color: "white",
                padding: "0 5px",
                borderRadius: "15px",
                marginLeft: "2px",
              }}>
              {responseCount}
            </span>
          </span>
        )
      }}
    </Query>
  )
}

const MySwapCase = ({ dispatch, data, location }) => {
  if (data.loading) {
    return <div className="section--loading"></div>
  }

  const ubUser = data.ubUser
  if (ubUser === null || ubUser === undefined) return <LoginRequiredPage />
  const swapCase = ubUser.swapCase
  if (swapCase === null) return <div>Du har ikke nogen byttebolig endnu</div>

  const onSubmit = (data) => {
    dispatch(actions.modifySwapCase(data))
  }

  const onUploadDocument = (fieldName) => (e) =>
    uploadFile(e.target.files[0])
      .then((res) => {
        const input = {}
        input[fieldName] = res.data
        dispatch(actions.modifySwapCase(input))
      })
      .catch((err) => console.log(err))

  const onDeleteDocument = (id) => () => {
    dispatch(actions.deleteSwapCaseDocument({ id: id }))
  }

  const onUploadImages = (e) =>
    uploadSwapImages(e.target.files, swapCase.id)
      .then(() => {
        data.refetch()
      })
      .catch((err) => console.log(err))

  const onDeleteImage = (id) => () => {
    dispatch(actions.deleteSwapCaseImage({ id: id }))
  }

  const dragulaDecorator = (componentBackingInstance) => {
    if (componentBackingInstance) {
      let options = {}
      Dragula([componentBackingInstance], options)
    }
  }

  let wishesInitialValues = pick(swapCase, utils.listByKey(wishesFields, "name"))

  wishesInitialValues.requirements = swapCase.requirements.map((x) => x.id)
  wishesInitialValues.preferredAreas = swapCase.preferredAreas.map((x) => x.id)
  return (
    <div className="selfsale__sellform myselfsale">
      <section className="section u-lightgrey">
        <div className="container">
          <Tabify initialTabName={location.hash}>
            <section tablabel="Gensidige Match" tabname="#match">
              <Query query={SWAP_MATCHES} fetchPolicy="network-and-cache">
                {({ loading, error, data }) => {
                  if (loading) return <div className="section--loading" />
                  const { swapMatches } = data
                  if (swapMatches && swapMatches.length > 0) {
                    const length = swapMatches.length
                    return (
                      <div>
                        <h3>
                          Tillykke! Du har {length === 1 ? "ét" : length} gensidig{length === 1 ? "t" : "e"} match
                        </h3>
                        <SwapMatches swapMatches={swapMatches} />
                      </div>
                    )
                  }
                  return "Du har stadig tilgode at få det første match"
                }}
              </Query>
            </section>
            <section tablabel="Mulige match" tabname="#possible">
              <div>
                <Query query={NEW_MATCHING_SWAP_CASES} fetchPolicy="network-and-cache">
                  {({ loading, error, data }) => {
                    if (loading) return <div className="section--loading" />
                    if (error) return `Error! ${error.message}`
                    const { newMatchingSwapCases } = data
                    const numCases = newMatchingSwapCases.length
                    const casesText = newMatchingSwapCases.length === 1 ? "ny byttebolig " : "nye bytteboliger "
                    const numCards = Math.min(4, newMatchingSwapCases.length + 1)
                    const maxAngle = 15

                    return (
                      <section className={"section animate--in apartments-grid grid "}>
                        <div className="container">
                          <div className="row">
                            <div className="col-s-12 col-m-6 u-align-self-center">
                              {numCases === 0 ? (
                                <h2 className="u-no-margin animate--down animate--slow">
                                  Vi har i øjeblikket <span className="u-bold-text u-lightgreen-text">ingen</span>{" "}
                                  {casesText}
                                  som matcher din bolig
                                </h2>
                              ) : (
                                <h2 className="u-no-margin animate--down animate--slow">
                                  Vi har i øjeblikket <span className="u-bold-text u-lightgreen-text">{numCases}</span>{" "}
                                  {casesText}
                                  som matcher din bolig
                                </h2>
                              )}
                              <p>Du kan altid se evt. matches og redigere i dine ønsker på din bytteprofil</p>
                            </div>
                            <div className="col-s-12 col-m-6">
                              <div className="flex-column">
                                {numCases > 0 ? (
                                  <div className="stacked-images animate--in">
                                    {newMatchingSwapCases.slice(0, numCards).map((item, index) => {
                                      const swapCaseUuid = item.uuid
                                      return (
                                        <Query
                                          key={index}
                                          query={SWAP_CASE_QUERY_CASE_PAGE}
                                          variables={{ caseUuid: swapCaseUuid }}
                                          fetchPolicy="network-and-cache">
                                          {({ loading, error, data }) => {
                                            if (loading) {
                                              return ""
                                            }
                                            const { swapCaseByUuid } = data
                                            const mappedProps = mapSwapApartmentProps(swapCaseByUuid)
                                            return (
                                              <div
                                                style={{
                                                  transform: `rotate(${
                                                    (maxAngle / numCards) * index - maxAngle / 2
                                                  }deg)`,
                                                }}>
                                                <SwapApartmentCard
                                                  loading={loading}
                                                  key={index}
                                                  {...swapCaseByUuid}
                                                  {...mappedProps}
                                                />
                                              </div>
                                            )
                                          }}
                                        </Query>
                                      )
                                    })}
                                  </div>
                                ) : (
                                  ""
                                )}

                                {numCases > 0 ? (
                                  <Link className="button center" to={URLS.SWAP.NEW}>
                                    Se dem her
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    )
                  }}
                </Query>
              </div>
            </section>
            <section tablabel="Mine likede boliger" tabname="#own">
              <Query
                query={SWAP_RESPONSE_FILTER_QUERY}
                variables={{ swapCaseOwn_Uuid: ubUser.swapCase.uuid, isInterested: 1 }} // 242424 Change to 0 to show denied
                fetchPolicy="network-and-cache">
                {({ loading, error, data }) => {
                  if (loading) return <div className="section--loading" />
                  const { swapResponses } = data
                  if (swapResponses && swapResponses.length > 0) {
                    const length = swapResponses.length
                    return (
                      <div>
                        <h3>
                          Du har vist interesse for {length === 1 ? "en" : length} byttebolig{length === 1 ? "" : "er"}
                        </h3>
                        <SwapResponses swapResponses={swapResponses} responseModelName="swapCaseOther" />
                      </div>
                    )
                  }
                  return <h3>"Du har ikke vist interesse for nogle boliger endnu"</h3>
                }}
              </Query>
            </section>

            <section tablabel={<TabLabelWithCount uuid={swapCase.uuid} />} tabname="#other">
              <Query
                query={SWAP_RESPONSE_FILTER_QUERY}
                variables={{ swapCaseOther_Uuid: ubUser.swapCase.uuid, isInterested: 1 }}
                fetchPolicy="network-and-cache">
                {({ loading, error, data }) => {
                  if (loading) return <div className="section--loading" />
                  const { swapResponses } = data
                  if (swapResponses && swapResponses.length > 0) {
                    const length = swapResponses.length
                    return (
                      <div>
                        <h3>
                          {length === 1 ? "Én anden bruger" : length + " andre brugere"} har vist interesse for din
                          byttebolig
                        </h3>
                        <SwapResponses swapResponses={swapResponses} responseModelName="swapCaseOwn" />
                      </div>
                    )
                  }
                  return <h3>"Der er ikke nogen der har vist interesse for din bolig endnu"</h3>
                }}
              </Query>
            </section>
            <section tablabel="Afviste boliger" tabname="#denied">
              <Query
                query={SWAP_RESPONSE_FILTER_QUERY}
                variables={{ swapCaseOwn_Uuid: ubUser.swapCase.uuid, isInterested: 0 }} // 242424 Change to 0 to show denied
                fetchPolicy="network-and-cache">
                {({ loading, error, data }) => {
                  if (loading) return <div className="section--loading" />
                  const { swapResponses } = data
                  if (swapResponses && swapResponses.length > 0) {
                    const length = swapResponses.length
                    return (
                      <div>
                        <h3>
                          Du har afvist interesse for {length === 1 ? "en" : length} byttebolig
                          {length === 1 ? "" : "er"}
                        </h3>
                        <SwapResponses swapResponses={swapResponses} responseModelName="swapCaseOther" />
                      </div>
                    )
                  }
                  return <h3>"Du har ingen afviste boliger"</h3>
                }}
              </Query>
            </section>
            <section tablabel="Ønsker til ny bolig" tabname="#wishes">
              <SwapWishesForm
                initialValues={wishesInitialValues}
                onSubmit={getSwapSubmitFn({ dispatch, swapCase })}
                submitLabel="Gem"
              />
            </section>
            <section className="subsection" tablabel="Min Bolig" tabname="#myspace">
              <h2 className="u-darkgreen-text section-title__block subsection">Min byttebolig</h2>
              <div className="row bordered-columns">
                <div className="col-s-12 col-m-6">
                  {<StatusSection swapCase={swapCase} />}

                  {formBlocks.map((x, i) => (
                    <FormSection key={i} {...x} initialObject={swapCase} onSubmit={onSubmit} />
                  ))}
                </div>
                <div className="col-s-12 col-m-6">
                  <div className="subsection">
                    <Form
                      onSubmit={onSubmit}
                      initialValues={{ facilities: utils.listByKey(swapCase.facilities, "id") }}
                      keepDirtyOnReinitialize={true}
                      render={FacilitiesFormRender}
                    />
                  </div>

                  <FormSection {...descriptionBlock} initialObject={swapCase} onSubmit={onSubmit} />
                  <div className="subsection">
                    <h3 className="u-green-text">Dokumenter</h3>
                    <ul>
                      {documents.map((x, i) => {
                        return (
                          <DeletableDocument
                            key={i}
                            fieldName={x.key}
                            label={x.label}
                            onDelete={swapCase[x.key] === null ? null : onDeleteDocument(swapCase[x.key].id)}
                            onUpload={onUploadDocument(x.key)}
                            {...swapCase[x.key]}
                          />
                        )
                      })}
                    </ul>
                  </div>
                  <div className="subsection">
                    <h3 className="u-green-text">Billeder</h3>
                    <div className="container dragula" ref={dragulaDecorator}>
                      {/*<div className="container">*/}
                      {swapCase.swapcaseimages.map((x, i) => (
                        <DeletableImage key={i} {...x} onDelete={onDeleteImage(x.id)} />
                      ))}
                    </div>
                    <Upload onUpload={onUploadImages} multiple={true} fieldName="imagesUploader" />
                  </div>
                </div>
              </div>
            </section>
          </Tabify>
        </div>
      </section>
    </div>
  )
}

const MySwapCaseWithData = graphql(queries.UB_USER_QUERY)(MySwapCase)

export default withRouter(connect()(MySwapCaseWithData))

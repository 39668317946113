import * as actions from 'actions'
import {withRouter} from "react-router-dom"
import {connect} from 'react-redux'
import {graphql} from 'react-apollo/index'
import {UB_USER_QUERY} from "queries";
import * as values from 'values'
import * as utils from "utils";


export const getSwapSubmitFn = ({dispatch, nextUrl = undefined, history = undefined, swapCase}) => (data) => {
    const _submitAction = swapCase ? actions.modifySwapCase : actions.addSwapCase
    dispatch(_submitAction(data))
        .then(() => {
            if (history && nextUrl) {
                history.push(nextUrl)
            }
        })
        .catch((err) => console.log(err.message, err.path))
}


export const connectAll = (component) => withRouter(connect()(graphql(UB_USER_QUERY)(component)))

export const getSwapBannerDisplayState = (caseData) => {
  /*
  * This seams opposite of what would be logical at first,
  * but think of it, as reading the received sent state from the case we are looking at,
  * but seeing it as the user that has done something with that state
  *
  * */
  if (caseData.isRequestReceivedFromUser) return values.SWAP_BANNER_STATES.SENT
  if (caseData.isRequestSentToUser) return values.SWAP_BANNER_STATES.RECEIVED
  return values.SWAP_BANNER_STATES.NONE
}

export const mapSwapApartmentProps = (props, url=undefined) => (
  {
    img: utils.ensure_ssl(utils.getFrontImage(props.swapcaseimages, props.frontpageImage).url),
    floor_plan: utils.ensure_ssl(utils.getFrontPlandrawing(props.swapcaseimages).url),
    // map:s tempMapLocation,
    address: props.addressStreetName,
    zip_city: props.zipCode + " " + props.city,
    size: props.size,
    rooms: props.rooms,
    price: props.expectedApartmentValue,
    lat: props.latitude,
    lng: props.longitude,
    url: url,
    id: props.id
  }
)

import React from 'react';
import * as actions from 'actions'
import {connect} from 'react-redux'
import { Link } from 'react-router-dom'
import { URLS } from 'urls'

const Logout = ({dispatch}) => {
    return (
        <div className="myaccount-component flex-column u-center-text">
            <h2 className="u-green-text">Er du sikker på du vil logge ud?</h2>
            <Link to={URLS.HOME}><button className="button" onClick={()=>dispatch(actions.logout()).then(()=>dispatch(actions.setVisibleModal(null)))}>Ja</button></Link>
        </div>
    );
}

Logout.displayName = 'Logout';
Logout.propTypes = {};
Logout.defaultProps = {};

export default connect()(Logout);

import {responseErrors} from "../utils";

const initialState = {
    visibleModal: null,
    visibleModalData: {},
    helpModalContent: null,
    loadingProcesses: 0,
    caseSliderIsOpen: false,
    mobileMenuIsOpen: false,
    currentLocation: '/',
    validationErrors: {},
    errors: [],
    globalErrorMessage: null,
    dirtyFields: [],
    savedScrollTop: null,
    appInitiated: false,
    testMode: false
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "SET_VISIBLE_MODAL": {
            const {key, data} = action.payload
            const scrollTop = document.body.scrollTop
            if (key === null){
                document.body.scrollTop = state.savedScrollTop
            }
            else {
                document.body.scrollTop = 0
            }
            return {...state, visibleModal: key,
                visibleModalData: data, savedScrollTop: scrollTop}
        }

        case "SET_HELP_MODAL_CONTENT": {
            return {...state, helpModalContent: action.payload}
        }

        case "APOLLO_QUERY_INIT": {
            return {...state, loadingProcesses: 1}
        }

        case "APOLLO_QUERY_RESULT": {
            return {...state, loadingProcesses: 0}
        }
        case "APOLLO_QUERY_STOP": {
            return {...state, loadingProcesses: 0}
        }

        case "APOLLO_QUERY_INIT_CLIENT": {
            return {...state, loadingProcesses: 1}
        }
        case "APOLLO_MUTATION_INIT": {
            return {...state, loadingProcesses: 1}
        }

        case "APOLLO_MUTATION_RESULT": {
            return {...state, loadingProcesses: 0}
        }
        case "APOLLO_MUTATION_RESULT_CLIENT": {
            return {...state, loadingProcesses: 0}
        }
        case "TOGGLE_CASE_SLIDER": {
            return {...state, caseSliderIsOpen: !state.caseSliderIsOpen}
        }
        case "TOGGLE_MOBILE_MENU": {
            return {...state, mobileMenuIsOpen: action.payload===null ? !state.mobileMenuIsOpen : action.payload}
        }
        case "SET_CURRENT_LOCATION": {
            return {...state, currentLocation: action.payload}
        }
        case "SET_VALIDATION_ERRORS": {
            return {...state, validationErrors: action.payload}
        }
	    case "ADD_FIELD_VALIDATION_ERRORS": {
		    return {...state, validationErrors: {...state.validationErrors, ...action.payload}}
	    }
        case "ADD_DIRTY_FIELD": {
            return {}
        }
        case "SET_GLOBAL_ERROR_MESSAGE": {
            return {...state, globalErrorMessage: action.payload}
        }


        case "SEND_PASSWORD_RESET_EMAIL_PENDING": {
            return {...state, errors: []}
        }

        case "SEND_PASSWORD_RESET_EMAIL_REJECTED": {
            return {...state}
        }

        case "SEND_PASSWORD_RESET_EMAIL_FULFILLED": {
            return {...state, visibleModal: 'SEND_RESET_PASSWORD_EMAIL_SUCCESS'}
        }

        case "RESET_PASSWORD_PENDING": {
            return {...state, errors: []}
        }

        case "RESET_PASSWORD_REJECTED": {
            return {...state, errors: responseErrors(action.payload)}
        }

        case "RESET_PASSWORD_FULFILLED": {
            return {...state, visibleModal: 'RESET_PASSWORD_SUCCESS'}
        }

        case "APOLLO_MUTATION_ERROR": {
            return {...state, errors: action.error.graphQLErrors}
        }

        case "INITIATE_APP": {
            return {...state, appInitiated: true}
        }

        case "SET_TEST_MODE": {
            return {...state, testMode: action.payload}
        }

    }
    return state
}


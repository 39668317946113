import React from 'react'

const Query = {
  "errors": [
    {
      "message": "Cannot combine queries on two different base models.",
      "locations": [
        {
          "line": 121,
          "column": 5
        }
      ]
    }
  ],
  "data": {
    "case": {
      "id": "Q2FzZU5vZGU6NTg4",
      "caseId": "CW126",
      "uuid": "e9debc65-4b71-448d-bcb7-914ab30f60e6",
      "fullAddress": "Lyngby Hovedgade 106,th, 2800 Lyngby",
      "street": "Lyngby Hovedgade",
      "number": "106",
      "commune": "A_101",
      "caseHeadline": "Lækker 3V andelsbolig med to solrige altaner",
      "caseDescription": "Velkommen til dejlige Lyngby og velkommen til AB Lyngby Port...Her bor man centralt samtidig med der er masser af luft og ro omkring sig...Lejligheden her byder på en god indretning hvor alle m2 er udnyttet godt. Fordelingsentré med plads til overtøjet og et stort indbygget skab, hvorfra der er adgang til alle boligens 5 rum som består af, et moderne, lækkert og lyst køkken med praktisk spiseplads. Godt med skuffer og arbejdsplads, og plads til den lille børnefamilie og/eller en god køkkenfest. Flot flisebadeværelse med el-gulvvarme og lækker sanitet, separat bruseniche og der er endda plads til en lille vaskemaskine. Fra gangen er der endvidere udgang til den ene altan (sol om eftermiddagen og aften), hvorfra der er en dejlig udsigt udover gårdhaven og mange soltimer. Videre ind i boligen har vi stor opholdsstue med gennemlys, og udgang til altan nummer to, som også byder på mange solskinstimer (morgen og formiddag). Stuen har en god størrelse til både tv/hyggestue og spiseafdeling. Endvidere to gode værelser, det ene større, og med indbygget skab, end det andet, oplagt som hhv. forældre- og børneværelse. Lejligheden fremgår generelt i rigtigt god stand og boligen er blevet omfattet moderniseret i 2004 (blandt andet køkken og bad), med flotte massive trægulve, termovinduer og pæne vægge. Til lejligheden hører et privat kælderrum samt fælles vaskeriet til fri afbenyttelse for beboere, men mod betaling af eget forbrug.",
      "apartmentType": "andel",
      "status": "A_2",
      "startSaleDate": "2017-04-17",
      "soldDate": null,
      "finalPrice": null,
      "takeOverDate": null,
      "isActive": true,
      "showExternally": true,
      "isUnderBuyerRetraction": true,
      "cohousingApartmentNumber": "175-1068",
      "cadastreApartmentNumber": "175-1068",
      "distributionNumberProperty": 43000,
      "distributionNumberRent": 106698,
      "utilisation": "beboelse",
      "livingSpace": 82,
      "roomsCount": 3,
      "floor": 2,
      "floors": 1,
      "inventoryFreeOfCharge": "HHV medfølger.",
      "inventoryToBuy": "Ingen.",
      "inventoryPrice": null,
      "eSoftId": "CW126",
      "hasOpenHouse": true,
      "openHouseDateTime": "2017-04-25T00:00:00+00:00",
      "openHouseInfo": "Tilmelding ved mail til cw@andelshandel.dk",
      "notesToSeller": "",
      "initialPrice": 355222,
      "agentEvaluation": 355222,
      "agentEvaluationDate": "2017-03-27",
      "partOfPool": 182836,
      "enhancements": 172386,
      "specialInventory": 0,
      "conditionDeduction": 0,
      "maxPrice": 355222,
      "deduction": null,
      "monthlyFee": 8891,
      "antennaFee": null,
      "tvFee": null,
      "internetFee": null,
      "utilitiesHeating": 870,
      "utilitiesWater": 0,
      "utilitiesTotalExport": 870,
      "salary": 26000,
      "mortgageChangeFee": 5000,
      "bookingFeePercentage": 1.5,
      "bookingFeeAbsolute": 1660,
      "averageNationalInterest": 7.5,
      "monthlyStandardInstallment": null,
      "monthlyInterest": null,
      "grossFinancing": 3920,
      "netFinancing": 3129,
      "valuationFee": 3000,
      "grossNetCalculationFee": 2000,
      "payoutFinancing": 0,
      "salesProceedsCalculationFee": 2000,
      "particularsPreparationFee": 2000,
      "advertisingFee": 3000,
      "contractPreparationFee": 3000,
      "informationProcuringFee": 3000,
      "photographyFee": 2000,
      "buyerContactFee": 6000,
      "thirdPartyOutlay": 0,
      "otherPromotionFee": 0,
      "salesBlanket": "",
      "salesBudget": "",
      "listingAgreement": "",
      "sellerName": "Karina Dall Pedersen",
      "sellerEmail": "Ka80dall@gmail.com",
      "sellerPhone": "26842064",
      "sellerBoligsidenOk": true,
      "sellerCprNr": "",
      "additionalSellerName": "",
      "additionalSellerEmail": "",
      "additionalSellerPhone": "2829 7981",
      "additionalSellerBoligsidenOk": true,
      "additionalSellerCprNr": "",
      "sellerBankName": "",
      "sellerBankAddress": "",
      "sellerBankPhone": "",
      "sellerBankAccountantName": "",
      "sellerIsAgent": false,
      "showSellerPhone": false,
      "showSellerEmail": false,
      "buyerName": "",
      "buyerEmail": "",
      "buyerPhone": "",
      "buyerBoligsidenOk": false,
      "additionalBuyerName": "",
      "additionalBuyerEmail": "",
      "additionalBuyerPhone": "",
      "additionalBuyerBoligsidenOk": false,
      "createdDate": null,
      "caseimages": [
        {
          "id": "17265",
          "size1Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411622/BEST_CROP/220/121/72/IMG_8427.jpg",
          "size2Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411622/BEST_CROP/1173/527/72/IMG_8427.jpg",
          "size3Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411622/BEST_CROP/1920/1080/72/IMG_8427.jpg",
          "size4Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411622/BEST_FIT/1024/683/72/IMG_8427.jpg",
          "size5Url": "",
          "resizeRule": "bestcrop",
          "frontpage": false,
          "imageType": "A_1",
          "esoftObjectId": 113626044,
          "orientation": "horizontal",
          "plandrawingType": "",
          "sortOrder": 1,
          "caption": "Stue"
        },
        {
          "id": "17266",
          "size1Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411624/BEST_CROP/220/121/72/IMG_8436.jpg",
          "size2Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411624/BEST_CROP/1173/527/72/IMG_8436.jpg",
          "size3Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411624/BEST_CROP/1920/1080/72/IMG_8436.jpg",
          "size4Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411624/BEST_FIT/1024/683/72/IMG_8436.jpg",
          "size5Url": "",
          "resizeRule": "bestcrop",
          "frontpage": false,
          "imageType": "A_1",
          "esoftObjectId": 113626046,
          "orientation": "horizontal",
          "plandrawingType": "",
          "sortOrder": 2,
          "caption": "Stue"
        },
        {
          "id": "17267",
          "size1Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411623/BEST_CROP/220/121/72/IMG_8430.jpg",
          "size2Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411623/BEST_CROP/1173/527/72/IMG_8430.jpg",
          "size3Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411623/BEST_CROP/1920/1080/72/IMG_8430.jpg",
          "size4Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411623/BEST_FIT/1024/683/72/IMG_8430.jpg",
          "size5Url": "",
          "resizeRule": "bestfit",
          "frontpage": false,
          "imageType": "A_1",
          "esoftObjectId": 113626045,
          "orientation": "horizontal",
          "plandrawingType": "",
          "sortOrder": 3,
          "caption": "Stue"
        },
        {
          "id": "17268",
          "size1Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411621/BEST_CROP/220/121/72/IMG_8424.jpg",
          "size2Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411621/BEST_CROP/1173/527/72/IMG_8424.jpg",
          "size3Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411621/BEST_CROP/1920/1080/72/IMG_8424.jpg",
          "size4Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411621/BEST_FIT/1024/683/72/IMG_8424.jpg",
          "size5Url": "",
          "resizeRule": "bestfit",
          "frontpage": false,
          "imageType": "A_1",
          "esoftObjectId": 113626043,
          "orientation": "horizontal",
          "plandrawingType": "",
          "sortOrder": 4,
          "caption": "Soveværelse"
        },
        {
          "id": "17269",
          "size1Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411620/BEST_CROP/220/121/72/IMG_8417.jpg",
          "size2Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411620/BEST_CROP/1173/527/72/IMG_8417.jpg",
          "size3Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411620/BEST_CROP/1920/1080/72/IMG_8417.jpg",
          "size4Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411620/BEST_FIT/1024/683/72/IMG_8417.jpg",
          "size5Url": "",
          "resizeRule": "bestcrop",
          "frontpage": false,
          "imageType": "A_1",
          "esoftObjectId": 113626042,
          "orientation": "horizontal",
          "plandrawingType": "",
          "sortOrder": 5,
          "caption": "Køkken"
        },
        {
          "id": "17270",
          "size1Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83426810/BEST_CROP/220/121/72/IMG_8451.jpg",
          "size2Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83426810/BEST_CROP/1173/527/72/IMG_8451.jpg",
          "size3Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83426810/BEST_CROP/1920/1080/72/IMG_8451.jpg",
          "size4Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83426810/BEST_FIT/1024/683/72/IMG_8451.jpg",
          "size5Url": "",
          "resizeRule": "bestcrop",
          "frontpage": false,
          "imageType": "A_1",
          "esoftObjectId": 113626050,
          "orientation": "horizontal",
          "plandrawingType": "",
          "sortOrder": 6,
          "caption": "Badeværelse"
        },
        {
          "id": "17271",
          "size1Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411619/BEST_CROP/220/121/72/IMG_8414.jpg",
          "size2Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411619/BEST_CROP/1173/527/72/IMG_8414.jpg",
          "size3Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411619/BEST_CROP/1920/1080/72/IMG_8414.jpg",
          "size4Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411619/BEST_FIT/1024/683/72/IMG_8414.jpg",
          "size5Url": "",
          "resizeRule": "bestfit",
          "frontpage": false,
          "imageType": "A_1",
          "esoftObjectId": 113626041,
          "orientation": "horizontal",
          "plandrawingType": "",
          "sortOrder": 7,
          "caption": "Soveværelse"
        },
        {
          "id": "17272",
          "size1Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411626/BEST_CROP/220/121/72/IMG_8442.jpg",
          "size2Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411626/BEST_CROP/1173/527/72/IMG_8442.jpg",
          "size3Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411626/BEST_CROP/1920/1080/72/IMG_8442.jpg",
          "size4Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411626/BEST_FIT/1024/683/72/IMG_8442.jpg",
          "size5Url": "",
          "resizeRule": "bestcrop",
          "frontpage": false,
          "imageType": "A_1",
          "esoftObjectId": 113626048,
          "orientation": "horizontal",
          "plandrawingType": "",
          "sortOrder": 8,
          "caption": "Gang"
        },
        {
          "id": "17273",
          "size1Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411625/BEST_CROP/220/121/72/IMG_8439.jpg",
          "size2Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411625/BEST_CROP/1173/527/72/IMG_8439.jpg",
          "size3Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411625/BEST_CROP/1920/1080/72/IMG_8439.jpg",
          "size4Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411625/BEST_FIT/1024/683/72/IMG_8439.jpg",
          "size5Url": "",
          "resizeRule": "bestcrop",
          "frontpage": false,
          "imageType": "A_1",
          "esoftObjectId": 113626047,
          "orientation": "horizontal",
          "plandrawingType": "",
          "sortOrder": 9,
          "caption": "Gang"
        },
        {
          "id": "17274",
          "size1Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467211/83409323/BEST_CROP/220/121/72/IMG_8420.jpg",
          "size2Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467211/83409323/BEST_CROP/1173/527/72/IMG_8420.jpg",
          "size3Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467211/83409323/BEST_CROP/1920/1080/72/IMG_8420.jpg",
          "size4Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467211/83409323/BEST_FIT/1024/683/72/IMG_8420.jpg",
          "size5Url": "",
          "resizeRule": "bestcrop",
          "frontpage": false,
          "imageType": "A_1",
          "esoftObjectId": 113624446,
          "orientation": "horizontal",
          "plandrawingType": "",
          "sortOrder": 10,
          "caption": "Altan"
        },
        {
          "id": "17275",
          "size1Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411627/BEST_CROP/220/121/72/IMG_8448.jpg",
          "size2Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411627/BEST_CROP/1173/527/72/IMG_8448.jpg",
          "size3Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411627/BEST_CROP/1920/1080/72/IMG_8448.jpg",
          "size4Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467209/83411627/BEST_FIT/1024/683/72/IMG_8448.jpg",
          "size5Url": "",
          "resizeRule": "bestfit",
          "frontpage": false,
          "imageType": "A_1",
          "esoftObjectId": 113626049,
          "orientation": "horizontal",
          "plandrawingType": "",
          "sortOrder": 11,
          "caption": "Arbejdsværelse"
        },
        {
          "id": "17276",
          "size1Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467211/83409325/BEST_CROP/220/121/72/IMG_8456.jpg",
          "size2Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467211/83409325/BEST_CROP/1173/527/72/IMG_8456.jpg",
          "size3Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467211/83409325/BEST_CROP/1920/1080/72/IMG_8456.jpg",
          "size4Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467211/83409325/BEST_FIT/1024/683/72/IMG_8456.jpg",
          "size5Url": "",
          "resizeRule": "bestfit",
          "frontpage": false,
          "imageType": "A_1",
          "esoftObjectId": 113624447,
          "orientation": "horizontal",
          "plandrawingType": "",
          "sortOrder": 12,
          "caption": "Facade"
        },
        {
          "id": "17277",
          "size1Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467211/83409326/BEST_CROP/220/121/72/IMG_8457.jpg",
          "size2Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467211/83409326/BEST_CROP/1173/527/72/IMG_8457.jpg",
          "size3Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467211/83409326/BEST_CROP/1920/1080/72/IMG_8457.jpg",
          "size4Url": "http://cdn-m2.esoftsystems.com/10100654/CW126/10042467211/83409326/BEST_FIT/1024/683/72/IMG_8457.jpg",
          "size5Url": "",
          "resizeRule": "bestcrop",
          "frontpage": false,
          "imageType": "A_1",
          "esoftObjectId": 113624448,
          "orientation": "horizontal",
          "plandrawingType": "",
          "sortOrder": 13,
          "caption": "Facade"
        }
      ],
      "facilities": null
    }
  }
}
export default Query
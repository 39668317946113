import React from "react"
// import { Link } from "react-router-dom"

const ListItem = ({ label }) => (
  <li className="card-hero__list__item">
    <svg className="icon icon--check icon--white">
      <use xlinkHref="#icon-check"></use>
    </svg>
    <div dangerouslySetInnerHTML={{ __html: label }} />
  </li>
)

const ImageFull = ({ title, subtitleStart, subtitleEnd, list, linkText, imageUrl, linkUrl }) => {
  return (
    <section className="image-block-full" style={{ background: `url( ${imageUrl} ) center / cover no-repeat` }}>
      <div className="card-hero lightgreen">
        <div className="card-hero__title">
          {" "}
          {subtitleStart}
          <span className="number--big">500+</span>
          {subtitleEnd}
        </div>
        <ul className="card-hero__list">
          {list.map((string, index) => (
            <ListItem key={index} label={string} />
          ))}
        </ul>
        <a href={linkUrl} className="button button--darkgreen">
          {linkText}
        </a>
      </div>
    </section>
  )
}

ImageFull.displayName = "ImageFull"
ImageFull.propTypes = {}
ImageFull.defaultProps = {}

export default ImageFull

import React from 'react';
import { Route } from 'react-router-dom' 
import { URLS } from 'urls' 

import SaleFrontPage from "./SaleFrontPage"
import SelfSale from "./SelfSale"
import CoSalePage from './CoSalePage'
import TotalSalePage from './TotalSalePage'

const Sale = ({match}) => {

  return (
    <div className="router animate--down">
    	<Route path={ URLS.SALE.FRONT } exact component={ SaleFrontPage }/>
    	<Route path={ URLS.SALE.SELF_SALE.FRONT } component={ SelfSale }/>
    	<Route path={ match.url + '/medsalg' } exact component={ CoSalePage }/>
    	<Route path={ match.url + '/totalsalg' } exact component={ TotalSalePage }/>
    </div>
  );
}

Sale.displayName = 'Sale';
Sale.propTypes = {};
Sale.defaultProps = {};

export default Sale;

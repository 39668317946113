import React from 'react';
import {FieldSection} from "../../../shared/finalFormFields";
import {withRouter} from 'react-router-dom'
import * as queries from 'queries'
import {URLS} from 'urls'
import {graphql} from 'react-apollo'
import {connect} from 'react-redux'
import {Form} from 'react-final-form'
import * as _ from 'lodash'
import {getSwapSubmitFn} from "../swapHelpers";
import * as swapFields from "../swapFields"


let SwapAssociationForm = props => {
    const {handleSubmit, submitting, invalid} = props
    return (
        <section className="section subsection u-lightgrey u-full-height flex-center">
            <div className="container flex-column u-center-text">
                <img src="/assets/img/img-house.png" className="icon icon--padded icon--house"/>
                <h1 className="u-green-text">Først skal du indtaste foreningens navn og hvem der er
                    administrator</h1>

                <form onSubmit={handleSubmit}>
                    <FieldSection fields={swapFields.associationFieldsWithPlaceholders}/>
                    <div>
                        <button className="button" disabled={invalid || submitting}>Næste</button>
                    </div>
                </form>
            </div>
        </section>
    )
}


let SwapAssociation = ({dispatch, history, data}) => {
    if (data.loading) return <div className="section--loading u-full-height"></div>
    const user = data.ubUser || null
    const swapCase = user.swapCase
    const initialValues = swapCase ? _.pick(swapCase, _.map(swapFields.associationFieldsWithPlaceholders, 'name')) : {}
    const onSubmit = getSwapSubmitFn({dispatch, nextUrl: URLS.SWAP.CREATE.S4_OWN_RESIDENCE, history, swapCase})
    return (
        <div className="swap__createuser">

            <Form onSubmit={onSubmit} initialValues={initialValues}>
                {props => <SwapAssociationForm
                    {...props}
                />
                }
            </Form>

        </div>
    )
}

SwapAssociation.displayName = 'SwapAssociation';
SwapAssociation.propTypes = {};
SwapAssociation.defaultProps = {};

SwapAssociation = graphql(queries.UB_USER_QUERY)(SwapAssociation)

export default withRouter(connect()(SwapAssociation));
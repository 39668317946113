import React from 'react';
import * as actions from 'actions'
import {connect} from "react-redux"
import { bindActionCreators } from 'redux'


const LoginRequiredPage = ({actions}) => {
    return (
        <div>
            <section className="section u-lightgrey u-full-height flex-center">
                <div className="row">
                    <div className="col-s-12">
                        <h1>Du skal være logget ind for at se siden</h1>
                        <div className="flex-column">
                            <button className="button" onClick={() => actions.setVisibleModal('LOGIN')}>Log ind</button>
                        </div>

                    </div>
                </div>


            </section>
        </div>
    )
}
LoginRequiredPage.displayName = 'LoginRequiredPage';
LoginRequiredPage.propTypes = {};
LoginRequiredPage.defaultProps = {};

const mapStateToProps = state => {
    return {
        ...state.app
    }
}

const mapDispatchToActions = dispatch => {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}


export default connect(mapStateToProps, mapDispatchToActions)(LoginRequiredPage);
import React from 'react';
import {connect} from 'react-redux'

const ErrorMessage = ({globalErrorMessage}) => {
    if (globalErrorMessage === null) return <span></span>
    else return (
        <div className="error-message">
            {globalErrorMessage}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        globalErrorMessage: state.app.globalErrorMessage
    }
}

export default connect(mapStateToProps)(ErrorMessage)

import React from 'react';
import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {URLS} from 'urls'
import * as actions from 'actions'
import {submitBuyerCase} from "../../../../actions/buyerCaseActions";


class Payment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            numberIsValid: null,
            type: null,
            expiryIsValid: null,
            cvvIsValid: null,
            serverError: null,
            card: null,
            loading: false
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.validateNumber = this.validateNumber.bind(this);
        this.reepay = window.reepay
    }

    componentDidMount() {
        this.reepay.validate.formatCardNumber(this.refs.number,
            (card) => {
                if (card!==null) {
                    this.setState({card: card})

                }
                else {
                    console.log("no Card")
                }
            }
        )
    }

    onSubmit(e) {
        e.preventDefault()
        this.setState({serverError: null})
        const form = this.refs.reepayForm
        this.setState({loading: true})
        this.reepay.token(form, (err, token) => {
            if (err) {
                this.setState({serverError: err})
                this.setState({loading: false})
            } else {
                this.props.dispatch(actions.reepaySubscribe({cardToken: token.id}))
                    .then(() => {
                        return this.props.dispatch(submitBuyerCase({wizardCompleted: true}))
                    })
                    .then(() => {
                        this.setState({loading: false})
                        return this.props.dispatch(actions.setVisibleModal('BUYER_INDEX_SUCCESS'))
                    })
            }
        })
    }

    validateNumber(e) {
        this.setState({numberIsValid: this.reepay.validate.cardType(e.target.value)})
    }

    translateErrorString(string) {
        switch (string) {
            case 'validation' :
                return 'Der opstod en fejl ved valideringen af din anmodning';
            case 'credit_card_expired' :
                return 'Kreditkortet er udløbet';
            case "declined_by_acquirer" :
                return "Transaktionen blev afvist";
            case "credit_card_lost_or_stolen" :
                return "Kreditkort tabt eller stjålet";
            case "credit_card_suspected_fraud" :
                return "Kreditkort mistænkt for bedrageri";
            case "refund_amount_too_high" :
            case "insufficient_funds" :
                return "Det anmodede beløb er for højt";
            case "authorization_expired":
                return "Afregner mislykkedes, fordi tilladelsen er udløbet";
            case "authorization_amount_exceeded":
                return "Afvikle mislykkedes, fordi det ønskede beløb oversteg godkendt beløb";
            case "authorization_voided":
                return "Afvikle mislykkedes, fordi autorisation er blevet annulleret";
            case "acquirer_communication_error":
            case "acquirer_error":
            case "acquirer_authentication_error":
            case "acquirer_integration_error":
            case "acquirer_rejected_error":
            case "acquirer_configuration_error":
                return "Kommunikation med erhververen mislykkedes";
            case "card-type-not-supported":
                return "Korttype er ikke tilladt";
            default:
                return string;

        }
    }
    getYearsOption(){
        let currentYearString = parseInt( new Date().getFullYear().toString().substr(-2) )
        var optionsArray = []
        for (var i = 0; i <= 10; i++) {
            optionsArray.push(<option key={i} value={currentYearString + i}>{currentYearString + i}</option>)
        }
        return optionsArray
    }
    render() {
        const {user, loading} = this.props
        const errorMessage = ( this.state.serverError!==null ) ? this.translateErrorString(this.state.serverError.code) : ''
        // console.log('error', errorMessage)
        if (loading) return <div>Loading ...</div>
        if (user===null) return <Redirect to={URLS.BUYER_INDEX.CREATE.USER}/>
        if (!user.verified) return <Redirect to={URLS.BUYER_INDEX.CREATE.ACTIVATION}/>
        const submitDisabled = this.state.loading ? true : false
        const cardType = this.state.card ? this.state.card.type.replace('visaelectron', 'visa electron').replace('jcb', 'JCB') : ''
        const loadingClass = this.state.loading ? 'section--loading' : ''
        const cvcLength = this.state.card===null ? 3 : this.state.card.cvcLength
        const fieldErrors = this.state.serverError===null ? [] : this.state.serverError.fields
        const className = (fieldName) => fieldErrors.indexOf(fieldName)===-1 ? "input-field__text" : "has-error input-field__text"
        return (
            <div className="payment-component">
                <section
                    className={"subsection u-lightgrey u-center-text u-full-height flex-center payment-form " + loadingClass}>
                    <div className="container">
                        <h1 className="u-green-text">Nu skal du tilknytte dit betalingskort</h1>
                        <div className="error-message">{errorMessage}</div>
                        <form onSubmit={this.onSubmit} ref="reepayForm">
                            <div className="row">
                                <div className="col-s-12 col-l-6 col-l-push-3">
                                    <div className={className('number')} style={{ width: "100%" }}>
                                        <label>Kortnummer</label>
                                        <input placeholder="xxxx xxxx xxxx xxxx" type="text" data-reepay="number"
                                               ref="number"
                                               onBlur={this.validateNumber} required/>
                                        <span className="payment-form__card-type">{cardType}</span>
                                    </div>
                                    <div className="row">
                                        <div className="col-s-12 col-m-4 ">
                                            <label>Måned</label>
                                            <div className="select-wrapper">
                                                <select name="CardExpiry" data-reepay="month">
                                                    <option value="">MM</option>
                                                    <option value="01">1</option>
                                                    <option value="02">2</option>
                                                    <option value="03">3</option>
                                                    <option value="04">4</option>
                                                    <option value="05">5</option>
                                                    <option value="06">6</option>
                                                    <option value="07">7</option>
                                                    <option value="08">8</option>
                                                    <option value="09">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                </select>
                                                <svg className="icon icon--chevron">
                                                    <use xlinkHref="#icon-chevron"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="col-s-12 col-m-4 ">
                                            <label>År</label>
                                            <div className="select-wrapper">
                                                <select name="CardExpiry" data-reepay="year"  id="">
                                                  <option value="">YY</option>
                                                  {this.getYearsOption()}
                                                </select>
                                                <svg className="icon icon--chevron">
                                                    <use xlinkHref="#icon-chevron"></use>
                                                </svg>
                                            </div>
                                        </div>
                                        <div className="col-s-12 col-m-4">
                                            <div className={className('cvv')}>
                                                <label>Kontrolcifre</label>
                                                <input placeholder="xxx" className={className('cvv')} type="text"
                                                       data-reepay="cvv" maxLength={cvcLength}
                                                       required/>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="hidden" name="reepay-token" data-reepay="token"/>
                                    <button disabled={submitDisabled} type="submit"
                                            className="button profile-form__submit">Gem ændringer
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
            </div>
        )
    }
}

Payment.displayName = 'Payment';
Payment.propTypes = {};
Payment.defaultProps = {};

export default connect()(Payment);


import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import CaseMap from "./CaseMap"
import Slider from "react-slick"
import * as queries from "queries"
import { graphql, Query } from "react-apollo"
import * as actions from "actions"
import { connect } from "react-redux"
import { ensure_ssl, getFrontImage, listByKey, kr, kvm, extractValues } from "utils"
import moment from "moment"
import Text from "components/shared/Text"
import { CASE_STATUS_TYPES, SWAP_IS_INTERESTED_CHOICES } from "../../../values"
import { URLS } from "../../../urls"
import SellerInfo from "../../shared/SellerInfo"
import { DEFAULT_AGENT_EMAIL } from "../../../values"
import PercentageCircle from "./PercentageCircle"
import { UB_USER_QUERY } from "queries"
import { SwapBanner } from "./SwapComponents"
import LoginRequiredPage from "../LoginRequiredPage"
import { newSwapResponseCase } from "actions"
import { values } from "lodash"

moment.locale("da")

const zoom = 17

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  // fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SlickNextArrow />,
  prevArrow: <SlickPrevArrow />,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: true,
        dots: false,
        // 'dots': false
      },
    },
  ],
}

function SlickNextArrow(props) {
  const { onClick } = props
  return (
    <svg onClick={onClick} className="icon icon--bordered-arrow slider--next">
      <use xlinkHref="#icon-arrow"></use>
    </svg>
  )
}

function SlickPrevArrow(props) {
  const { onClick } = props
  return (
    <svg onClick={onClick} className="icon icon--bordered-arrow slider--prev">
      <use xlinkHref="#icon-arrow"></use>
    </svg>
  )
}

const Slide = ({ image }) => {
  return (
    <div className="case__slider__slide">
      <img className="case__slider__slide__img" src={ensure_ssl(image.size2Url)} alt="" />
      <div
        className="case__slider__slide__img-full"
        style={{ backgroundImage: `url(${ensure_ssl(image.size2Url)})` }}></div>
    </div>
  )
}

const caseCardList = [
  {
    key: "expectedApartmentValue",
    label: "pris",
    format: kr,
  },
  {
    key: "monthlyFee",
    label: "Boligydelse",
    format: kr,
  },
  {
    key: "utilitiesTotal",
    label: "Forbrugsudgifter",
    format: kr,
  },
  // {
  //     key: 'building.constructionYear',
  //     label: 'Byggeår'
  // },
  {
    key: "apartmentSquareMetres",
    label: "Bolig m2",
    format: kvm,
  },
  {
    key: "roomsCount",
    label: "Værelser",
  },
  // {
  //   key: "userEmail",
  //   label: "Email",
  // },
  // {
  //     key: 'building.energyClass',
  //     label: 'Energimærke'
  // }
]

const CaseSlider = ({ sliderOpen, images, onToggleSlider }) => {
  const isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]"
    })(!window["safari"] || (typeof safari !== "undefined" && window.safari.pushNotification))
  const isIE = /*@cc_on!@*/ false || !!document.documentMode
  const isEdge = !isIE && !!window.StyleMedia
  const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  const positionY = isiOS || isSafari || isEdge ? 0 : window.scrollY - 100
  const setDimensionClass = () => {
    if (window.innerWidth > window.innerHeight) {
      document.querySelector("#app").classList.add("viewport-wide")
    } else {
      document.querySelector("#app").classList.remove("viewport-wide")
    }
  }
  window.addEventListener("orientationchange", setDimensionClass)
  return (
    <div>
      <button onClick={onToggleSlider} className="button case__slider__button--open">
        SE BILLEDER
      </button>
      <div className={sliderOpen ? "case__slider is-active" : "case__slider"}>
        <Slider {...sliderSettings}>
          {images.map((image, index) => (
            <div key={index}>
              <Slide image={image} />
            </div>
          ))}
        </Slider>
        <button onClick={onToggleSlider} className="case__slider__button--close"></button>
      </div>
    </div>
  )
}

export const CaseSection = ({
  data,
  caseSliderIsOpen,
  dispatch,
  requestSenderSwapCases,
  ubUserData,
  onNext = null,
}) => {
  const [interestUpdated, setInterestUpdated] = useState(false) // <-- Move useState to the top

  if (data.loading) return <div className="section--loading"></div>

  const caseData = data.swapCaseByUuid

  if (caseData === null) return <div>Findes ikke</div>
  const caseHasBeenContactedByUser = caseData.isRequestReceivedFromUser // current user has sent a request from

  const address = caseData.addressStreetName

  const bgImageUrl = getFrontImage(caseData.swapcaseimages, caseData.frontpageImage).url

  const facilities = listByKey(caseData.facilities, "facilityDescription")

  const onToggleSlider = () => {
    dispatch(actions.toggleCaseSlider())
    !caseSliderIsOpen
      ? document.body.parentNode.classList.add("slider-is-open")
      : document.body.parentNode.classList.remove("slider-is-open")
  }

  const onShowContactModal = () => {
    dispatch(actions.setVisibleModal("SWAP_CONTACT_MODAL", caseData))
  }

  const onSelectSwapResponse = (isInterested, onNext) => {
    dispatch(actions.newSwapResponse({ otherSwapCaseId: caseData.id, isInterested: isInterested }, caseData.uuid))
    if (onNext) {
      onNext()
    }
  }
  const onUpdateSwapResponse = (isInterested) => {
    dispatch(actions.updateSwapResponse(swapResponse.id, isInterested))
    setInterestUpdated(true)
  }

  const onShowSwapDocumentsModal = () => {
    dispatch(
      actions.setVisibleModal("CASE_DOCUMENTS_MODAL", {
        caseDocuments: caseData.documents,
        buildingDocuments: [],
        documentUrl: null, /// caseData.documentUrl // not working right now
      })
    )
  }

  const sideBarFacilities = (
    <div className="sidebar__column">
      <h4>Faciliteter</h4>
      <ul>
        {facilities.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  )

  const sideBarLinks = caseData.hasDocuments ? (
    <button onClick={onShowSwapDocumentsModal} disabled={!caseData.hasDocuments} className="button">
      Dokumenter
    </button>
  ) : (
    <div className="tooltip">
      <button onClick={onShowSwapDocumentsModal} disabled={!caseData.hasDocuments} className="button">
        Dokumenter
      </button>
      <span className="tooltiptext">Der er ikke uploadet nogen dokumenter endnu.</span>
    </div>
  )

  // TODO Phone
  const sideBarMatch = (
    <div className="sidebar__column">
      <h4>Match</h4>
      {/*<div className="flex-wrapper">*/}
      {/*    <PercentageCircle percentage={caseData.matchTotal}/><span className="apartment-card__value">Samlet match på denne bolig</span>*/}
      {/*</div>*/}
      <Query query={UB_USER_QUERY} fetchPolicy="cache-first">
        {({ loading, data, error }) => {
          if (loading) return "loading"
          if (data.ubUser)
            return (
              <div>
                <div className="subsection flex-wrapper">
                  <div className="flex-circle-space">
                    <PercentageCircle percentage={caseData.matchPercent} />
                    <label>Mit match</label>
                  </div>

                  <div className="flex-circle-space">
                    <PercentageCircle percentage={caseData.matchOther} />
                    <label>Modparts match</label>
                  </div>
                </div>
                <p>
                  <a
                    onClick={(e) =>
                      dispatch(
                        actions.setVisibleModal("SITE_TEXT_MODAL", {
                          textId: "how_does_match_work_modal",
                          withHeadline: true,
                        })
                      )
                    }>
                    Hvordan fungerer match?
                  </a>
                </p>
              </div>
            )
          return (
            <div>
              <label>Log ind for at se match</label>
              <button className="button" onClick={(e) => dispatch(actions.setVisibleModal("LOGIN"))}>
                Log ind
              </button>
            </div>
          )
        }}
      </Query>
    </div>
  )

  const mailToUrlEncoded = (caseData, ubUserData) => {
    const subject = "Vedrørende din byttebolig på andelshandel.dk"
    const body = `Hej ${caseData.userFirstName}\n\nTak for din henvendelse vedr. bytte med min bolig på ${caseData.fullAddress}.\n(skriv din besked her)\n\nMed venlig hilsen\n${ubUserData.firstName} ${ubUserData.lastName}`

    return `mailto:${caseData.userEmail}?subject=${subject}&body=${encodeURIComponent(body)}`
  }
  // This button should be different depending of state of case
  const sideBarContact = caseData.isRequestReceivedFromUser ? (
    <button className="button button--grey" disabled={true}>
      ANMODNING AFSENDT
    </button>
  ) : caseData.isRequestSentToUser ? (
    <a className="button" href={mailToUrlEncoded(caseData, ubUserData)}>
      SVAR ANMODNING
    </a>
  ) : (
    <button className="button" onClick={onShowContactModal}>
      KONTAKT BYTTEMODPART
    </button>
  )

  const swapResponse = caseData.swapResponse

  const getSideBarSwap = () => {
    switch (swapResponse.isInterested) {
      case SWAP_IS_INTERESTED_CHOICES.NOT_INTERESTED:
        return <div>Du er ikke intereseret i at bytte med denne bolig</div>
      case SWAP_IS_INTERESTED_CHOICES.INTERESTED:
        return <div>Du har vist interesse i at bytte med denne bolig</div>
      case SWAP_IS_INTERESTED_CHOICES.LATER:
        return <div>Du er måske intereseret</div>
      default:
        return ""
    }
  }

  const sideBarSwap = swapResponse ? (
    getSideBarSwap()
  ) : (
    <div className="sticky-mobile-cta slide-animation">
      <h3 className="u-white-text">Interesseret?</h3>
      {/*<p>Når du vælger bliver du sendt videre til det næste match</p>*/}
      <div className="flex-evenly">
        <button className="swapbutton no" onClick={() => onSelectSwapResponse(0, onNext)}>
          Nej
        </button>
        <button className="swapbutton yes" onClick={() => onSelectSwapResponse(1, onNext)}>
          Ja
        </button>
      </div>
      {/*<div className="flex-center">*/}
      {/*    <button className="swapbutton later" onClick={() => onSelectSwapResponse(2, onNext)}>senere</button>*/}
      {/*</div>*/}
    </div>
  )

  const sideBarSwapAnswered = swapResponse && (
    <div className="sticky-mobile-cta slide-animation">
      {!interestUpdated ? (
        <>
          <h3 className="u-white-text">Skiftet mening?</h3>
          <p>Har du skiftet din mening omkring denne bolig kan du skifte din interesse her.</p>
          {/*<p>Når du vælger bliver du sendt videre til det næste match</p>*/}
          <div className="flex-evenly">
            <button className="swapbutton no" onClick={() => onUpdateSwapResponse(0)}>
              Nej
            </button>
            <button className="swapbutton yes" onClick={() => onUpdateSwapResponse(1)}>
              Ja
            </button>
          </div>
        </>
      ) : (
        <div>
          <h3 className="u-white-text">Interesse opdateret!</h3>
        </div>
      )}
    </div>
  )

  const caseSlider =
    caseData.swapcaseimages.length > 0 ? (
      <CaseSlider sliderOpen={caseSliderIsOpen} images={caseData.swapcaseimages} onToggleSlider={onToggleSlider} />
    ) : (
      ""
    )

  const caseMap =
    caseData.latitude === null || caseData.longitude === null ? (
      ""
    ) : (
      <div className="subsection">
        <CaseMap center={{ lat: caseData.latitude, lng: caseData.longitude }} zoom={zoom} address={address} />
      </div>
    )

  {
    window.faci = facilities
  }
  return (
    <section className="case">
      <div className="case__img bg-img bg-img--green" style={{ backgroundImage: `url( ${ensure_ssl(bgImageUrl)} )` }}>
        <SwapBanner caseData={caseData} />
      </div>
      <div className="container">
        {caseSlider}
        <div className="row">
          <div className="col-s-12">
            <div className="case__wrapper">
              <div className="content-card">
                <div className="row bordered-columns">
                  <div className="col-s-12 col-m-8 col-l-9">
                    <div className="case__content">
                      <div className="subsection">
                        <h1 className="case__title title u-green-text">{caseData.heading}</h1>
                        <div className="case__content">
                          <Text text={caseData.description} />
                        </div>
                      </div>
                      {caseMap}
                    </div>
                  </div>
                  <div className="col-s-12 col-m-4 col-l-3">
                    <div className="case__card card">
                      <div className="case__card__header u-lightgreen">
                        <h3 className="case__card__title u-white-text">{address} </h3>
                        <h3 className="case__card__title u-white-text">
                          {caseData.zipCode} {caseData.city}{" "}
                        </h3>
                        {/*<p className="case__card__caseid">Sagsnr: {caseData.caseId}</p>*/}
                      </div>
                      <ul className="case__card__list">
                        {extractValues(caseCardList, caseData).map((item, index) => {
                          return (
                            <li key={index} className="case__card__list__item case__">
                              <p className="case__card__list__label">{item.label}</p>
                              <p className="case__card__list__value">{item.value}</p>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                    <div className="case__sidebar sidebar">
                      {sideBarMatch}
                      {facilities.length ? sideBarFacilities : ""}
                      {sideBarLinks}

                      <Query query={UB_USER_QUERY} fetchPolicy="cache-first">
                        {({ loading, data, error }) => {
                          if (loading) return "loading"
                          if (data.ubUser) {
                            if (caseData.user.id === data.ubUser.id) {
                              return ""
                            }
                            return (
                              <div>
                                <div>{sideBarSwap}</div>
                                <div>{sideBarSwapAnswered}</div>
                              </div>
                            )
                          }
                          return ""
                        }}
                      </Query>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const CaseSectionWithData = graphql(queries.SWAP_CASE_QUERY_CASE_PAGE, {
  options: (props) => {
    return {
      variables: {
        caseUuid: props.caseUuid,
      },
    }
  },
})(CaseSection)

const mapStateToProps = (state) => {
  return {
    ...state.app,
  }
}

const CaseSectionWithDataAndState = connect(mapStateToProps)(CaseSectionWithData)

export const SwapPageByUuid = ({ uuid, onNext = null, index = null, countAll = null }) => {
  return (
    <div className="casepage-component animate--down">
      <Query query={UB_USER_QUERY}>
        {({ loading, data }) => {
          if (loading) return ""
          const { ubUser } = data
          return (
            <CaseSectionWithDataAndState
              caseUuid={uuid}
              requestSenderSwapCases={ubUser ? ubUser.swapCase.requestSenderSwapCases : []}
              ubUserData={ubUser}
              onNext={onNext}
            />
          )
        }}
      </Query>
      {/*<SimilarCases/>*/}
      {/*<ImageFullSection/>*/}
    </div>
  )
}

const SwapPage = ({ match }) => {
  return (
    <div className="casepage-component animate--down">
      <Query query={UB_USER_QUERY}>
        {({ loading, data }) => {
          if (loading) return ""
          const { ubUser } = data
          return (
            <CaseSectionWithDataAndState
              caseUuid={match.params.uuid}
              requestSenderSwapCases={ubUser ? ubUser.swapCase.requestSenderSwapCases : []}
              ubUserData={ubUser}
            />
          )
        }}
      </Query>
      {/*<SimilarCases/>*/}
      {/*<ImageFullSection/>*/}
    </div>
  )
}

SwapPage.displayName = "SwapPage"
SwapPage.propTypes = {}
SwapPage.defaultProps = {}

export default withRouter(SwapPage)

import React from 'react';
import NewUserForm from './NewUserForm'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

const CreateUser = ({successModal, confirmOwner=false}) => {
    return (
        <div className="buyerindex__createuser">
            <NewUserForm successModal={successModal} confirmOwner={confirmOwner} />
        </div>
    );
}

CreateUser.displayName = 'CreateUser';
CreateUser.propTypes = {};
CreateUser.defaultProps = {};


const mapStateToProps = state => {
    return {
        user: state.user
    }
}

export default withRouter(connect(mapStateToProps)(CreateUser));

import React from 'react';
import {CheckBox} from "components/shared/forms"
import {Field} from 'redux-form'
import {connect} from 'react-redux'
import {setHelpModalContent} from "../../actions/index";


let renderField = ({
                       dispatch,
                       input,
                       label,
                       type,
                       required,
                       placeholder,
                       help,
                       meta: {touched, error, warning},
                       options = [],
                       disabled = false
                   }) => {

    let inputClass = touched && error ? ("has-error input-field__" + type) : ("input-field__" + type)
    inputClass += help ? " help" : ""
    const renderLabel = label === undefined ? "" : <label htmlFor={input.name} required={required}>{label}</label>
    const renderHelp = help === undefined ? '' :
        <svg onClick={() => dispatch(setHelpModalContent({title: label, body: help}))}
             className="icon icon--green icon--question">
            <use xlinkHref="#icon-question"></use>
        </svg>

    switch (type) {
        case 'textarea':
            return (
                <div className={inputClass}>
                    {renderLabel} {renderHelp}
                    <div>
                        {touched &&
                        ((error && <div className="error">{error}</div>) ||
                            (warning && <span>{warning}</span>))}
                        <textarea {...input} placeholder={placeholder} type={type} disabled={disabled}/>

                    </div>
                </div>
            )
        case 'select':
            return (
                <div>
                    {renderLabel}{renderHelp}
                    <div>
                        {touched &&
                        ((error && <div className="error">{error}</div>) ||
                            (warning && <span>{warning}</span>))}

                        <Field
                            {...input}
                            component="select"
                            disabled={disabled}
                        >
                            <option/>
                            {options.map((o, i) => {
                                    const [value, text] = Array.isArray(o) ? [o[0], o[1]] : [o, o]
                                    return <option key={i} value={value}>{text}</option>
                                }
                            )}
                        </Field>
                    </div>
                </div>
            )
        case 'checkbox':
            return (
                <div className={inputClass}
                     onClick={() => {
                         input.onChange(!input.checked)
                     }}>
                    <input {...input} type="checkbox" disabled={disabled}></input>
                    <label htmlFor={input.name}> {label}
                        <svg className="icon icon--black icon--check">
                            <use xlinkHref="#icon-check"></use>
                        </svg>
                    </label>
                </div>
            )

        default:
            return (
                <div className={inputClass}>
                    {renderLabel}
                    <div>
                        <input {...input} placeholder={placeholder} type={type} disabled={disabled}/>
                        {touched &&
                        ((error && <div className="error">{error}</div>) ||
                            (warning && <span>{warning}</span>))}
                        {renderHelp}
                    </div>
                </div>
            )

    }
}


export const RenderField = connect()(renderField)


export const RFInput = props => (
    <Field {...props} component={RenderField}/>
)

export const FieldSection = ({fields, title, className = "subsection", disabledFieldNames = []}) => (
    <div className={className}>
        <h3 className="u-green-text">{title}</h3>
        {fields.map((field, i) => {
            if (disabledFieldNames.indexOf(field.name) !== -1 ){
                field.disabled = true
            }
                return <Field {...field} component={RenderField} key={i}/>
            }
        )
        }
    </div>
)

export const CheckBoxBlock = ({loading, options, labelName, title, input}) => {
    if (loading) {
        return <div className="section--loader"></div>
    }
    const getValue = (id) => input.value.indexOf(id) !== -1
    const _onToggle = (id) => () => {
        if (getValue(id)) {
            // Remove from object
            input.onChange(input.value.filter(x => x !== id))
        }
        else {
            input.onChange([...input.value, id])
        }
    }

    return (
        <div>
            <h3 className="u-green-text">{title}</h3>
            {options.map((x, i) => <CheckBox
                    key={i}
                    label={x[labelName]}
                    onChange={_onToggle(x.id)}
                    value={getValue(x.id)}
                />
            )}
        </div>
    )
}
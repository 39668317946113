import React from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {URLS} from "urls";
import {withRouter, Redirect} from 'react-router-dom'
import {graphql} from 'react-apollo'
import * as queries from 'queries'
import {submitBuyerCase} from "actions/buyerCaseActions";
import CheckBox from "../../../shared/forms/CheckBox/index";


export const StaticLoanText = () => (
    <div className="subsection">
        <h1 className="u-green-text">Bliv forhåndsgodkendt af Arbejdernes Landsbank</h1>
            <p className="u-darkgrey-text">Andelshandel har et samarbejde med Arbejdernes Landsbank. Det giver dig
                mulighed for at blive forhåndsgodkendt og få et lånetilbud til køb af andelsbolig.</p>
            <strong className="u-green-text">Eksperter i andelsbolig</strong>
            <p className="u-darkgrey-text">
                Arbejdernes Landsbank har undersøgt og rådgivet om andelsboligmarkedet i næsten 100 år og har en stor
                viden om netop den type boliger. Vi har et hold af specialuddannede rådgivere, som beskæftiger sig med
                andelsboliger, blåstempling af sunde foreninger, og som derfor har en dyb indsigt i de særlige lån og
                muligheder, der er i forbindelse med køb af andelsboliger.
            </p>
            <strong className="u-green-text">AL-BoligKlar: Få svar inden for 24 timer</strong>
            <p>
                Står du over for et boligkøb, som kræver hurtig handling, rydder vi gerne skrivebordet. Med AL-BoligKlar
                kan du booke et boligmøde med kun 1 times varsel – og inden for 24 timer få svar på, om du kan gøre
                boligdrømmen til virkelighed.
            </p>
    </div>
)


const LoanProposal1 = ({dispatch, data}) => {
    if (data.loading) return <span>Loading...</span>
    const user = data.ubUser
    if (user === null) return <Redirect to={URLS.BUYER_INDEX.CREATE.USER}/>
    if (!user.verified) return <Redirect to={URLS.BUYER_INDEX.CREATE.ACTIVATION}/>
    if (user.buyerCase === null) return <div>Du mangler at oprette dine ønsker</div>

    const setWantLoanProposal = v => {
        dispatch(submitBuyerCase({
            wantLoanProposal: v,
            loanProposalContactTimespan: 0,
            loanProposalTermsAccepted: false
        }))
    }


    const next_url = user.buyerCase.wantLoanProposal ? URLS.BUYER_INDEX.CREATE.LOAN_PROPOSAL2 : URLS.BUYER_INDEX.CREATE.PAYMENT


    return (
        <div className="al1-component">
            <div>
                <section className="section u-lightgrey">
                    <div className="container">
                        <StaticLoanText/>
                        <form action={e => e.preventDefault()}>

                            <CheckBox label="Ja tak, jeg vil gerne kontaktes af AL"
                                      type="radio"
                                      name='ALYes' value={user.buyerCase.wantLoanProposal}
                                      onChange={() => setWantLoanProposal(true)}/>
                            <CheckBox label="Nej tak" type="radio"
                                      name='ALNo' value={!user.buyerCase.wantLoanProposal}
                                      onChange={() => setWantLoanProposal(false)}/>
                            <Link className="button" to={next_url}>Næste</Link>
                        </form>
                    </div>
                </section>
            </div>

        </div>
    );
}

LoanProposal1.displayName = 'LoanProposal1';
LoanProposal1.propTypes = {};
LoanProposal1.defaultProps = {};

const LoanProposal1WithData = graphql(queries.UB_USER_QUERY)(LoanProposal1)

export default withRouter(connect()(LoanProposal1WithData));

import React from 'react';
import {connect} from 'react-redux'
import * as actions from 'actions'

const PasswordForm = ({dispatch, email}) => {
    return (
        <section className="subsection password-form">
            <button type="submit" className="button password-form__submit" onClick={() => {
                dispatch(actions.sendPasswordResetEmail(email))
            }}>Skift adgangskode
            </button>
        </section>
    )
}


PasswordForm.displayName = 'PasswordForm';
PasswordForm.propTypes = {};
PasswordForm.defaultProps = {};

export default connect()(PasswordForm);

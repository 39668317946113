import React from "react"
import BuyerForm from "../../../shared/forms/BuyerForm"
import { URLS } from "urls"
import { Redirect } from "react-router-dom"

const Whishes = ({ user, loading }) => {
  if (loading) return <div>...Loading</div>
  if (user == null) return <Redirect to={URLS.HOME} />
  if (user) return <Redirect to={URLS.HOME} />
  // if (user == null) return <Redirect to={URLS.BUYER_INDEX.CREATE.USER} />
  // if (!user.verified) return <Redirect to={URLS.BUYER_INDEX.CREATE.ACTIVATION} />

  return (
    <div className="whishes-component">
      {/* <section className="section u-lightgrey">
        <div className="container"><BuyerForm nextUrl={URLS.BUYER_INDEX.CREATE.PROFILE}/></div>
      </section> */}
    </div>
  )
}

Whishes.displayName = "Whishes"
Whishes.propTypes = {}
Whishes.defaultProps = {}

export default Whishes

export function changePriceRange(priceRange) {
    return {
        type: "CHANGE_PRICE_RANGE",
        payload: priceRange
    }
}

export function changeRange(fieldName, range) {
    return {
        type: "CHANGE_RANGE",
        payload: {fieldName: fieldName, range: range}
    }
}

export function changeStreetPostalCity(value) {
    return {
        type: "CHANGE_STREET_POSTAL_CITY",
        payload: value
    }
}

export function selectSortByFilter(filterName) {
    return {
        type: "SELECT_SORT_BY_FILTER",
        payload: filterName
    }
}

export function initSearch() {
    return dispatch => dispatch({
        type: "INIT_SEARCH"
    })
}

export function toggleAdvanced() {
    return {
        type: "TOGGLE_ADVANCED"
    }
}


export function changeRentalRange(rentalRange) {
    return {
        type: "CHANGE_RENTAL_RANGE",
        payload: rentalRange
    }
}

export function toggleMapOnApartmentCard(id) {
    return {
        type: "TOGGLE_MAP_ON_APARTMENT_CARD",
        payload: id
    }
}

export function toggleFloorPlanOnApartmentCard(id) {
	return {
		type: "TOGGLE_FLOOR_PLAN_ON_APARTMENT_CARD",
		payload: id
	}
}

export function toggleMapOnSearch(value) {
    return {
        type: "TOGGLE_MAP_ON_SEARCH",
        payload: value

    }
}

export function resetSearch() {
    return {
        type: "RESET_SEARCH"
    }
}

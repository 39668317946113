import { combineReducers } from "redux"
import newUser from "./newUserReducer"
import auth from "./authReducer"
import nav from "./navReducer"
import buyerCase from "./buyerCaseReducer"
import saleReducer from "./saleReducer"
import searchReducer from "./searchReducer"
import userReducer from "./userReducer"
import appReducer from './appReducer'
import selfSaleFormReducer from './selfSaleFormReducer'
import { reducer as formReducer } from 'redux-form'

export default combineReducers({
    app: appReducer,
    nav: nav,
    auth: auth,
    user: userReducer,
    newUser: newUser,
    buyerCase: buyerCase,
    sale: saleReducer,
    search: searchReducer,
    selfSaleForm: selfSaleFormReducer,
    form: formReducer
})

import React from 'react';
import {Link} from 'react-router-dom'
import Slider from 'react-slick'

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  autoplay: true, 
  autoplaySpeed: 3000,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrow: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      }   
    }
  ]
}

const quotes = [
    {
        img: "assets/img/quotes/img-quote-1.png",
        content: "I mit virke som formand for AB Krohaven II har jeg været meget glad for samarbejdet med Andelshandel.\n" +
        "De har håndteret handlerne godt, og altid  haft et stort personligt engagement og oprigtig interesse for at finde\n" +
        "gode nye andelshavere til os.",
        name: "Peter Jensen",
        address: "2000 Frederiksberg"
    },{
        img: "assets/img/quotes/img-quote-2.png",
        content: "Efter lang tids søgen uden held fandt jeg endelig en fed andelsbolig via Andelshandel. Tak!",
        name: "Peter Jensen",
        address: "2000 Frederiksberg"
    },{
        img: "assets/img/quotes/img-quote-3.png",
        content: "Når man bor i Kolding, og skal sælge lejlighed i København, er man afhængig af en ejendomsmægler, der forstår netop denne præmis og er god til at samarbejde og kommunikere.\n" +
        "Vi har kun lovord tilovers for samarbejdet med Unik-Boligsalg.",
        name: "Peter Jensen",
        address: "2000 Frederiksberg"
    }
]

const Quote = ({img, content}) => {
    return (
        <div className="quotes__quote u-lightgrey">
            <img src={img} alt="" className="quotes__quote__img"/>
            <p>{content}</p>
        </div>
    )
}
const QuoteSection = () => {
    return (
        <section className="section quotes">
            <div className="container">
            <h2 className="section-title u-center-text">Det siger vores tilfredse brugere</h2>
            <div className="quotes__container">
                <div className="slider__container row">
                    <div className="col-s-12">
                    <Slider {...sliderSettings} >
                        {quotes.map( (item, index) => 
                            <div key={index}><Quote  {...item} /></div>
                        )}
                    </Slider>
                    </div>
				</div>
			</div>
			<Link to={'/kontakt'} className="button u-center"> Kontakt os i dag </Link>
			</div>
		</section>
	)
}
QuoteSection.displayName = 'QuoteSection';
QuoteSection.propTypes = {};
QuoteSection.defaultProps = {};

export default QuoteSection;

import React from 'react';
import {connect} from 'react-redux'
import {submitBuyerCase} from "actions/buyerCaseActions";
import {URLS} from "urls";
import {withRouter, Redirect} from 'react-router-dom'
import Photo from 'components/shared/forms/Photo'
import {graphql} from 'react-apollo'
import * as queries from 'queries'
import {uploadImage} from 'uploadApi'


const Profile = ({dispatch, data, history}) => {
	if (data.loading) return <span>Loading...</span>
	const user = data.ubUser
    if (user === null) return <Redirect to={URLS.BUYER_INDEX.CREATE.USER}/>
    if (!user.verified) return <Redirect to={URLS.BUYER_INDEX.CREATE.ACTIVATION}/>

	if (user.buyerCase === null) return <div>Du mangler at oprette dine ønsker</div>

	const nextUrl = URLS.BUYER_INDEX.CREATE.LOAN_PROPOSAL1

	const onSubmit = (e) => {
		e.preventDefault()

		const extra = {}

		extra['wantLoanProposal'] = true
		dispatch(submitBuyerCase({profileDescription: e.target.description.value, ...extra}))
			.then(() => history.push(nextUrl))
	}

	const onChangeProfileImage = (e) => {
		const file = e.target.files[0]
		uploadImage(file)
			.then((res) => dispatch(submitBuyerCase({profileImage: res.data})))
			.catch((err) => console.log(err))
	}

	return (
		<div className="profile-component">
			<section className="section u-lightgrey">
				<div className="container">
					<h1 className="u-green-text u-center-text subsection">Beskriv dig selv og tilknyt et
						profilbillede</h1>
					<form onSubmit={onSubmit}>
						<div className="row">
							<div className="col-s-12 col-l-6">
								<div className="input-field__textarea">
									<textarea name="description" defaultValue={data.ubUser.buyerCase.profileDescription ? data.ubUser.buyerCase.profileDescription : ''} id="" cols="30" rows="10"></textarea>
								</div>
							</div>
							<div className="col-s-12 col-l-6">
								<div className="flex-column u-center-text">
									<Photo onChange={onChangeProfileImage} url={data.ubUser.buyerCase.profileImage ? data.ubUser.buyerCase.profileImage.url : null}/>
								</div>
							</div>
						</div>

						<button className="button u-center">Næste</button>
					</form>

				</div>
			</section>
		</div>
	);
}

Profile.displayName = 'Profile';
Profile.propTypes = {};
Profile.defaultProps = {};

const ProfileWithData = graphql(queries.UB_USER_QUERY)(Profile)

export default withRouter(connect()(ProfileWithData));

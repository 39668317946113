import React from 'react'
import { connect } from 'react-redux';
import { graphql } from 'react-apollo'
import { ALL_FACILITIES_QUERY } from 'queries'
import * as actions from 'actions'

import * as queries from 'queries'
import { withRouter } from 'react-router-dom'
import * as utils from 'utils'
import { URLS } from 'urls'
import { pick } from 'lodash'
import { Field, reduxForm } from 'redux-form'
import { FieldSection, CheckBoxBlock } from "../../../shared/reduxFormsFields";
import { addressFields, infoFields, expensesFields, descriptionFields } from "./saleFields";


const fieldNames = utils.listByKey(
    [...addressFields, ...infoFields, ...expensesFields, ...descriptionFields], 'name')


const FacilitiesBlockWithData = graphql(ALL_FACILITIES_QUERY,
    {
        props: ({data: {loading, availableFacilities}}) => ({
            loading: loading,
            options: availableFacilities,
            labelName: "facilityDescription"
        })
    }
)(CheckBoxBlock)


let SelfSaleForm = (props) => {
    const {invalid, handleSubmit, pristine} = props
    return (
        <form onSubmit={handleSubmit}>
            <h1 className="u-green-text subsection ">Så skal vi blot bruge lidt info om din andelsbolig</h1>
            <div className="row bordered-columns">
                <div className="col-s-12 col-m-6">
                    <FieldSection title="Adresse" fields={addressFields}/>
                    <FieldSection title="Info om Boligen" fields={infoFields}/>
                    <FieldSection title="Generelle udgifter" fields={expensesFields}/>
                </div>
                <div className="col-s-12 col-m-6">
                    <div className="subsection">
                        <Field name='facilities'
                               title="Vælg boligens faciliteter"
                               component={FacilitiesBlockWithData}
                        />
                    </div>
                    <FieldSection title="Yderligere beskrivelse af din bolig" fields={descriptionFields}/>
                </div>
            </div>
            <button className="button" disabled={invalid || pristine}>Send</button>
        </form>
    )
}

SelfSaleForm = reduxForm({
    // a unique name for the form
    form: 'selfSaleCreate'
})(SelfSaleForm)


const SaleAppartmentInfo = ({dispatch, data, history}) => {
    if (data.loading) return <div className="section--loading"></div>
    const {selfSaleCase} = data.ubUser

    const onSubmit = (data) => {
        dispatch(actions.modifySelfSale(data))
            .then(() => history.push(URLS.SALE.SELF_SALE.SELECT_PHOTO_PACKAGE))
    }

    const initialValues = pick(selfSaleCase, fieldNames)
    const initialValuesWithFacilities = {...initialValues, facilities: utils.listByKey(selfSaleCase.facilities, 'id')}

    return (
        <div className="selfsale__sellform">
            <section className="section u-lightgrey">
                <div className="container">
                    <SelfSaleForm initialValues={initialValuesWithFacilities} onSubmit={onSubmit}/>
                </div>
            </section>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        app: state.app,
        sale: state.sale
    }
}

SaleAppartmentInfo.displayName = 'SaleAppartmentInfo';
SaleAppartmentInfo.propTypes = {};
SaleAppartmentInfo.defaultProps = {};

const SaleAppartmentInfoWithData = graphql(queries.UB_USER_QUERY)(SaleAppartmentInfo)

export default withRouter(connect(mapStateToProps)(SaleAppartmentInfoWithData));

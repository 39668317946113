import React from 'react';
import {graphql} from 'react-apollo'
import * as queries from 'queries'


import ProfileForm from '../../shared/forms/ProfileForm'
import PasswordForm from '../../shared/forms/PasswordForm'
import DeleteUserForm from '../../shared/forms/DeleteUserForm'
import SubscriptionForm from '../../shared/forms/SubscriptionForm'
import PaymentHistory from '../../shared/forms/PaymentHistory'
import moment from 'moment'
import LoginRequiredPage from "components/pages/LoginRequiredPage";


const SubscriptionStatus = graphql(queries.SUBSCRIPTION_STATUS_QUERY)(({data, user}) => {
	if (data.loading) return <div className="section--loading"></div>
	const subscriptionData = JSON.parse(data.ubUser.subscriptionStatus)
	return (
        <div>
			<SubscriptionForm user={user} subscriptionData={subscriptionData}/>
		</div>
    )

})

const PaymentMethod = ({masked_card, card_type, exp_date}) => {
	return (
		<div className="payment-history__item">
			<span className="payment-history__date">{ card_type}</span>
			<span className="payment-history__title">nr. { masked_card }</span>
			<span className="payment-history__price">udløber. { exp_date }</span>
			{/*<span className="payment-history__status">{ status }</span>*/}
		</div>
	)
}


const PaymentMethods = graphql(queries.PAYMENT_METHODS_QUERY)(({data}) => {
    if (data.loading) return <div className="section--loading"></div>
	const paymentMethodsData = JSON.parse(data.ubUser.paymentMethods)

	return (
        <section className="subsection payment-form">
			<h3 className="u-green-text">Betaling</h3>
            {paymentMethodsData.map((x,i) => <PaymentMethod key={i} {...x}/>)}
        </section>
    )

})

const Invoices = graphql(queries.INVOICES_QUERY)(({data}) => {
    if (data.loading) return <div className="section--loading"></div>
	const InvoicesData = JSON.parse(data.ubUser.invoices)
    const mappedItems = InvoicesData.content.map(x => ({
        date: moment(x.created).format('LL'),
		title: "Køberservice",
		price: `${x.amount/100} kr./md`,
		status: "Gennemført"
    }))
	return (
        <PaymentHistory items={mappedItems}/>
    )

})

const ProfilePage = ({data}) => {
	if (data.loading) return <div>Loading...</div>
	const user = data.ubUser
	if (user === null ) return <LoginRequiredPage/>
	const renderPayment = (user.buyerCase === null || user.buyerCase.wizardCompleted === false) ? "" : (
		<div>
			<PaymentMethods/>
			<Invoices/>
		</div>
	)
	return (
		<section className="section u-lightgrey">
			<div className="container">
				<h2 className="u-darkgreen-text section-title__block subsection">Min konto</h2>
				<div className="row bordered-columns">
					<div className="col-s-12 col-m-6">
						<ProfileForm />
                        <PasswordForm email={user.user.email}/>
						<DeleteUserForm />
					</div>
					<div className="col-s-12 col-m-6">	
						<SubscriptionStatus user={user}/>
						{renderPayment}
					</div>
				</div>
			</div>
		</section>
	)
}

ProfilePage.displayName = 'ProfilePage';
ProfilePage.propTypes = {};
ProfilePage.defaultProps = {};

export default graphql(queries.UB_USER_QUERY)(ProfilePage);

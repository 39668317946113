import React from 'react';
import { Link } from 'react-router-dom'
import {connect} from 'react-redux'

const SuccessModal = ({nextUrl}) => {
  return (
    <div className="successmodal-component">
        <h2>Voilá!</h2>
        <p>Du er nu oprettet i køberservice,
            og kan modtage invitationer til
            åbent hus.</p>
        <p>Husk at du altid kan tilrette dine søgekriterier, tekst og billede i "Min Profil"</p>
        <Link className="button" to={nextUrl}>Ok - det lyder godt</Link>
    </div>
  );
}

SuccessModal.displayName = 'SuccessModal';
SuccessModal.propTypes = {};
SuccessModal.defaultProps = {};

export default connect()(SuccessModal);

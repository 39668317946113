import * as utils from 'utils'

const initialState = {
    showModal: false,
    showModal2: false,
    newSale: {
        cooperativeName: "",
        administrationAssociationName: "",
        annualAccounts: null,
        energyLabel: null,
        rulesRegulations: null,
        houseRules: null,
        latestBoardMeetingReport: null,
        fullAddress: "",
        zipCode: "",
        city: "",
        roomsCount: "",
        apartmentSquareMetres: "",
        apartmentValue: "",
        enhancementsValue: "",
        specialInventoryValue: "",
        deductionForShortcomings: "",
        monthlyFee: "",
        additionalFees: "",
        utilitiesWater: "",
        utilitiesHeating: "",
        utilitiesAdditional: "",
        description: "",
        externalPhotographer: false,
        isActive: false,
        facilities: [],
        // Facilities
        privateCarPort: false,
        roofTerrace: false,
        freeCommonWashing: false,
        animalsNotAllowd: false,
        terasse: false,
        bikeParking: false,
        ownGarden: false,
        ownParking: false,
        elevator: false,
        parentBuyAllowed: false,
        commonRoom: true,
        gardenFacilities: false,
        animalsAllowed: false,
        animalsAllowedNoDogs: false,
        internet: false,
        playground: false,
        washingFacilities: false,

    }
}
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "NEW_SALE_CHANGE_TEXT": {
            let newState = {}
            const objectName = action.payload.objectName
            newState[objectName] = {...state[objectName]}
            const fieldName = action.payload.fieldName
            newState[objectName][fieldName] = action.payload.text
            return {...state, ...newState}
        }

        case "NEW_SALE_TOGGLE_CHECKBOX": {
            let newState = {}
            const fieldName = action.payload.fieldName
            const objectName = action.payload.objectName
            newState[objectName] = {...state[objectName],}
            newState[objectName][fieldName] = !state[objectName][fieldName]
            return {...state, ...newState}
        }

        case "NEW_SALE_CHANGE_USE_PRO_PHOTOGRAPHER": {
            return {...state, useProPhotographer: action.payload}
        }

        case "NEW_SALE_TOGGLE_FACILITY": {
            const id = action.payload
            const facilities = [...state.newSale.facilities]
            let newSale = {...state.newSale}
            const index = facilities.indexOf(id)
            newSale.facilities = index===-1 ? [...facilities, id] :
                [...facilities.slice(0, index), ...facilities.slice(index + 1)]
            return {...state, newSale: newSale}
        }
        case "MODIFY_SELF_SALE_FULFILLED": {
            const selfSaleCase = action.payload.data.modifySelfSale.selfSaleCase
            return {...state, newSale: {...state.newSale, facilities: utils.listByKey(selfSaleCase.facilities, 'id')}}
        }
    }
    return state
}

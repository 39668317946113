import React from "react"
import {Route, Switch} from "react-router-dom"
import * as pages from "components/pages"
import ErrorMessage from 'components/ErrorMessage'
import {URLS} from 'urls'
import SwapTestPage from "./pages/Swap/SwapTestPage";


class ContentContainer extends React.Component {
	render() {
		return (
			<main className="main">
				<ErrorMessage />
				<Switch>
					<Route path={URLS.HOME} exact component={pages.Home}/>
					<Route path={URLS.BUY.FRONT} component={pages.Buy}/>
					<Route path={URLS.BUYER_INDEX.FRONT} component={pages.BuyerIndex}/>
					<Route path={URLS.SALE.FRONT} component={pages.Sale}/>
					<Route path={URLS.SWAP.FRONT} component={pages.Swap}/>
					<Route path={URLS.PARTNERS} component={pages.Partners} />
					<Route path={URLS.CONTACT} component={pages.Contact}/>
					<Route path={URLS.PROFILE.HOME} component={pages.Profile}/>
					<Route path={URLS.RESET_PASSWORD} component={pages.ResetPassword}/>
					<Route path={URLS.TEST} component={SwapTestPage}/>
					<pages.DoesNotExist/>
				</Switch>
			</main>
		)
	}
}

export default ContentContainer

import React from 'react';
import { connect } from 'react-redux';
import { graphql } from 'react-apollo';
import * as actions from 'actions';
import * as queries from 'queries';

const ConfirmationModal = ({ dispatch, data }) => {
    const ubUser = data.ubUser;
    
    const onSubmit = async (e) => {
        e.preventDefault();

        try {
            await dispatch(actions.deleteUser({ email: ubUser.user.email }));
            console.log('Deleted successfully');
        } catch (error) {
            console.error('GraphQL Client Error: ', error.message);
        } finally {
            dispatch(actions.setVisibleModal(null));
            dispatch(actions.setVisibleModal('DELETED_MODAL'));
        }
    };

    return (
        <div className="confirmationmodal-component">
            <h2>Er du sikker på du ønsker at slette din bruger?</h2>
            <button className="button" onClick={onSubmit}>Ja</button>
            <button className="button button--red" onClick={() => dispatch(actions.setVisibleModal(null))}>Nej</button>
        </div>
    );
};

const ConfirmationModalWithGraphQL = graphql(queries.UB_USER_QUERY)(ConfirmationModal);

ConfirmationModal.displayName = 'ConfirmationModal';
ConfirmationModal.propTypes = {};
ConfirmationModal.defaultProps = {};

export default connect()(ConfirmationModalWithGraphQL);

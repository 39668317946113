import React from 'react';
import {connect} from 'react-redux';
import * as queries from 'queries'
import {RangeSlider} from "components/shared/forms/index"
import {changeRange, setBuyerCaseRequirements, setBuyerCaseAreas, submitBuyerCase, toggleTerms} from "actions/buyerCaseActions"
import {setHelpModalContent} from "../../../actions/index";
import {withRouter} from "react-router-dom"
import {graphql, compose} from 'react-apollo'
import {find} from 'lodash'
import * as utils from 'utils'
import CheckBoxBlock from 'components/shared/forms/CheckBoxBlock'

const MIN_SIZE = 30
const MAX_SIZE = 200
const MIN_ROOMS = 1
const MAX_ROOMS = 5
const MIN_PRICE = 0
const MAX_PRICE = 5000000
const MIN_RENT = 1000
const MAX_RENT = 20000


const INPUT_FIELD_NAMES = [
	'minFloorArea',
	'maxFloorArea',
	'minRooms',
	'maxRooms',
	'minMonthlyFee',
	'maxMonthlyFee',
	'minPrice',
	'maxPrice',
	'preferredAreas',
	'requirements',
	'termsAccepted'
]


const RequirementsBlockWithData = graphql(queries.AVAILABLE_FACILITIES_UB_USER,
	{
		props: ({data: {loading, availableFacilitiesUbUser}}) => ({
			loading: loading,
			options: availableFacilitiesUbUser
		}),
		options: {
			fetchPolicy: 'cache-and-network'
		}

	}
)(CheckBoxBlock)


const AreasBlockWithData = graphql(queries.ALL_AREAS_QUERY,
	{
		props: ({data: {loading, allAreas}}) => ({
			loading: loading,
			options: allAreas
		}),
		options: {
			fetchPolicy: 'cache-and-network'
		}
	}
)(CheckBoxBlock)


const TermsCheckBox = ({name, value, onChange, onShowModal, className=false}) => {
		return <div className={className ? "input-field__checkbox " + className : "input-field__checkbox"} onClick={onChange}>
			<input id={name} className="check-list" name={name} type="checkbox" checked={value}></input>
            <label><span>Jeg accepterer <a onClick={onShowModal}> vilkårene </a> for køberservice</span>
    			<svg className="icon icon--black icon--check">
    				<use xlinkHref="#icon-check"></use>
    			</svg>
            </label>
		</div>
	}


const BuyerForm = ({userData, textData, newBuyerCase, dispatch, history, nextUrl}) => {
	if (userData.loading || textData.loading) {
		return <div>loading...</div>
	}
	const data = userData

	const isNew = data.ubUser.buyerCase===null
	const buyerCase = isNew ? newBuyerCase : data.ubUser.buyerCase

	const getFieldValue = (fieldName) => {
		return [buyerCase['min' + fieldName], buyerCase['max' + fieldName]]
	}

	const onChangeRange = (fieldName) => (value) => {
		if (isNew) {
			dispatch(changeRange(fieldName, value[0], value[1]))
		}
		else {
			// Do changes to apollo cache
			let newBuyerCase = {
				...buyerCase
			}
			newBuyerCase['min' + fieldName] = value[0]
			newBuyerCase['max' + fieldName] = value[1]

			data.updateQuery(
				(prev) => (
					{
						...prev,
						ubUser: {
							...prev.ubUser,
							buyerCase: newBuyerCase
						}
					}
				)
			)
		}
	}

	const getOnToggle = (fieldName, action) => (id, prevList, prevValue, choicesList) => () => {
		const _setListFn = (newList) => {
			if (isNew) {
				dispatch(action(newList))
			}
			else {
				let newBuyerCase = {...buyerCase}
				newBuyerCase[fieldName] = newList
				data.updateQuery(() => (
						{
							ubUser: {...data.ubUser, buyerCase: newBuyerCase}
						}
					)
				)
			}
		}
		let newList = [...prevList]
		if (prevValue) {
			newList = newList.filter((x) => x.id!==id)
		}
		else {
			newList = [...newList, find(choicesList, {id: id})]
		}
		_setListFn(newList)
	}

	const onToggleTerms = () => {
		if (isNew) {
			dispatch(toggleTerms())
		}
		else {
			let newBuyerCase = {...buyerCase, termsAccepted: !buyerCase.termsAccepted}
			data.updateQuery(()=> (
				{
					ubUser: {...data.ubUser, buyerCase: newBuyerCase}
				}
			))
		}
	}

	const onShowModal = e => {
	    e.stopPropagation()
	    dispatch(setHelpModalContent({title: textData.siteText.headline, body: textData.siteText.text}))
    }


	const onSubmit = (e) => {
		e.preventDefault()
		let _mappedInput = {}
		for (const fieldName of INPUT_FIELD_NAMES) {
			_mappedInput[fieldName] = buyerCase[fieldName]
		}
		_mappedInput.requirements = utils.listByKey(buyerCase.requirements, 'id')
		_mappedInput.preferredAreas = utils.listByKey(buyerCase.preferredAreas, 'id')
		dispatch(submitBuyerCase(_mappedInput))
			.then(() => {
				if (nextUrl) {
					history.push(nextUrl)
				}
			})
	}

	return (
	<div className="buyerform-component">
		<section className="section u-lightgrey ">
			<div className="container">
				<h1 className="u-green-text section-title__block subsection">Udfyld dine ønsker til den nye drømmebolig</h1>
				<form onSubmit={onSubmit}>
					<div className="row bordered-columns">
						<div className="col-s-12 col-m-6">
							<div className="subsection">
								<RangeSlider
									label="Størrelse - min/max"
									value={getFieldValue('FloorArea')}
									min={MIN_SIZE}
									max={MAX_SIZE}
									onChange={onChangeRange('FloorArea')}
									formatValue={utils.plus(utils.kvm, MAX_SIZE)}
								/>
							</div>
							<div className="subsection">
								<RangeSlider
									label="Antal Rum"
									value={getFieldValue('Rooms')}
									min={MIN_ROOMS}
									max={MAX_ROOMS}
									onChange={onChangeRange('Rooms')}
									formatValue={utils.plus((val) => val, MAX_ROOMS)}
								/>
							</div>
							<div className="subsection">
								<RangeSlider
									label="Anskaffelsespris - min/max"
									value={getFieldValue('Price')}
									min={MIN_PRICE}
									max={MAX_PRICE}
									onChange={onChangeRange('Price')}
									formatValue={utils.plus(utils.kr, MAX_PRICE)}
								/>
							</div>
							<div className="subsection">
								<RangeSlider
									label="Husleje - min/max"
									value={getFieldValue('MonthlyFee')}
									min={MIN_RENT}
									max={MAX_RENT}
									onChange={onChangeRange('MonthlyFee')}
									formatValue={utils.plus(utils.kr, MAX_RENT)}
								/>
							</div>


						</div>
						<div className="col-s-12 col-m-6">
							<div className="subsection">
								<AreasBlockWithData
									title="Vælg Område"
									value={buyerCase.preferredAreas}
									onToggle={getOnToggle('preferredAreas', setBuyerCaseAreas)}
									labelName="name"
								/>
							</div>
							<div className="subsection">
								<RequirementsBlockWithData
									title="Vælg boligens faciliteter"
									value={buyerCase.requirements}
									onToggle={getOnToggle('requirements', setBuyerCaseRequirements)}
									labelName="facilityDescription"
								/>
							</div>
						</div>
					</div>

								<TermsCheckBox
									          value={buyerCase.termsAccepted}
									          onChange={onToggleTerms}

											  onShowModal={onShowModal}
									/>

					<button className="button" disabled={!buyerCase.termsAccepted}>Næste</button>
				</form>
			</div>
		</section>
	</div>
	);
}


const mapStateToProps = state => {
	return {newBuyerCase: state.buyerCase}
}

BuyerForm.displayName = 'BuyerForm';
BuyerForm.propTypes = {};
BuyerForm.defaultProps = {};


const BuyerFormWithData = compose(
    graphql(queries.UB_USER_QUERY, {
        name: 'userData'
    }),
    graphql(queries.SITE_TEXT_QUERY, {
        name: 'textData',
        options: {
            variables: {
                textId: "user_conditions_buyercase"
            }
        }
    })
)(BuyerForm)


export default withRouter(connect(mapStateToProps)(BuyerFormWithData))

import React from 'react';
import {FieldSection} from "../../shared/reduxFormsFields";
import {withRouter} from 'react-router-dom'
import * as actions from 'actions'
import {connect} from 'react-redux'
import * as queries from 'queries'
import {graphql} from 'react-apollo'
import {reduxForm, SubmissionError} from 'redux-form'
import * as validation from 'validation'
import {parseValidationErrors} from "../../../actions/index";
import * as _ from 'lodash'
import SubmitButtonWithLoader from "../../shared/forms/SubmitButtonWithLoader"

const userFields = [
    {

        label: 'Fornavn',
        name: 'firstName',
        required: true,
        type: "text",
        validate: [validation.required]
    },
    {

        name: 'lastName',
        label: 'Efternavn',
        type: 'text',
        required: true,
        validate: [validation.required]
    },
    {
        name: 'phone',
        label: 'Telefon nummer',
        type: 'number',
        required: true,
        validate: [validation.required, validation.phoneNumber]
    },

    {
        label: 'Jeg bekræfter at jeg er ejer af en andelsbolig',
        name: 'hasConfirmedIsApartmentOwner',
        type: 'checkbox',
        required: true,
        validate: [validation.validateIsTrue]
    }
]

const userContactFields = [
    {
        name: 'email',
        label: 'Email',
        type: 'email',
        required: true,
        validate: [validation.required, validation.email]
    },
    {
        label: 'Password',
        name: 'password',
        type: 'password',
        required: true,
        validate: [validation.required]
    }
]

let NewUserFormV2 = (props) => {
    const {invalid, handleSubmit, submitting, error, dispatch, successModal, disabledFieldNames, excludedFieldNames, subtitle, user, history, nextUrl, testMode} = props

    const _throwValidationErrors = validationErrors => {
        for (let fieldName in validationErrors) {
            let errorObj = {}
            errorObj[fieldName] = validationErrors[fieldName][0].message
            errorObj._error = "Ret venligst fejlen"
            throw new SubmissionError(
                errorObj
            )
        }
    }


    const onSubmit = (data) => {
        const email = data.email
        const password = data.password
        const _data = _.omit(data, disabledFieldNames)

        //data.isTestUser = true
        const _submitAction = user ? actions.modifyUserRFForm : actions.addUserRFForm
        const _postSubmitFn = user ? (res) => history.push(nextUrl):
            () => {
                dispatch(actions.login(email, password))
                    .then(() => dispatch(actions.setVisibleModal(successModal)))
            }

        const _return_value_name = user ? 'changeUbUserProfile' : 'addUbUser'

        return dispatch(_submitAction(_data, testMode))
            .then(res => {
                return parseValidationErrors(res.value.data[_return_value_name].validationErrors)
            })
            .then(_throwValidationErrors)
            .then(_postSubmitFn)

    }

    const _userFields = userFields.filter(x => excludedFieldNames.indexOf(x.name) === -1)
    const _userContactFields = userContactFields.filter(x => excludedFieldNames.indexOf(x.name) === -1)


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row bordered-columns">
                <div className="col-s-12 col-m-6">
                    <FieldSection fields={_userFields} disabledFieldNames={disabledFieldNames}/>
                </div>
                <div className="col-s-12 col-m-6">
                    <strong className="u-green-text">{subtitle}</strong>
                    <FieldSection fields={_userContactFields} disabledFieldNames={disabledFieldNames}/>
                </div>
                {error && <strong>{error}</strong>}
            </div>
            <SubmitButtonWithLoader label="Næste" disabled={invalid || submitting} loading={submitting}/>
        </form>
    )
}


NewUserFormV2 = reduxForm({
    // a unique name for the form
    form: 'newUserForm',
    destroyOnUnmount: false,
    enableReinitialize: true

})(NewUserFormV2)

NewUserFormV2 = connect()(NewUserFormV2)

let NewUserFormPage = ({dispatch, data, successModal, nextUrl, history, testMode}) => {
    if (data.loading) return <div className="section--loading u-full-height"></div>

    const user = data.ubUser || null
    const initialValues = user ? {
        'firstName': user.firstName,
        'lastName': user.lastName,
        'phone': user.phone,
        'email': user.user.email,
        'hasConfirmedIsApartmentOwner': user.hasConfirmedIsApartmentOwner
    } : undefined


    const disabledFieldNames = user ? ['firstName', 'lastName', 'phone', 'email'] : []
    const excludedFieldNames = user ? ['password'] : []
    const title = user ? "Bekræft venligst at du er ejer af en andelsbolig" :
        "Perfekt! Nu skal du oprettes i systemet"
    const subtitle = user ? "" : "Så skal vi blot bruge din email og et password for at kunne oprette dig som bruger"


    return (
        <div className="swap__createuser">
            <section className="section u-lightgrey">
                <div className="container flex-column">
                    <h1 className="u-green-text">{title}</h1>
                    <NewUserFormV2 successModal={successModal}
                                   initialValues={initialValues}
                                   disabledFieldNames={disabledFieldNames}
                                   excludedFieldNames={excludedFieldNames}
                                   subtitle={subtitle}
                                   user={user}
                                   nextUrl={nextUrl}
                                   history={history}
                                   testMode={testMode}
                    />
                </div>
            </section>
        </div>
    );
}

NewUserFormPage.displayName = 'NewUserFormPage';
NewUserFormPage.propTypes = {};
NewUserFormPage.defaultProps = {};


NewUserFormPage = graphql(queries.UB_USER_QUERY)(NewUserFormPage)

const mapStateToProps = state => {
    return {
        ...state.app
    }
}

export default withRouter(connect(mapStateToProps)(NewUserFormPage));
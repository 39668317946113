import React from "react"
import Modal from "./Modal"
import * as actions from "actions"
import { connect } from "react-redux"
import LoginForm from "components/shared/forms/LoginForm"
import SelfSaleFrontModal from "./SelfSaleFrontModal"
import PhotoPackageInfoModal from "./PhotoPackageInfoModal"
import UploadPageModal from "./UploadPageModal"
import Logout from "./Logout"
import { Link } from "react-router-dom"
import { URLS } from "urls"
import SuccessModal from "./SuccessModal"
import SuccessSelfSaleAddedModal from "./SuccessSelfSaleAddedModal"
import SubscriptionCancelModal from "./SubscriptionCancelModal"
import * as contactModals from "./ContactModalContent"
import BuildingInfoModalContent from "./BuildingInfoModalContent"
import CaseDocumentsModal from "./CaseDocumentsModal"
import SwapContactModal from "./SwapContactModal"
import SiteTextModal from "./SiteTextModal"
import ConfirmationModal from "./ConfirmationModal"
import DeletedModal from "./DeletedModal"

const mapStateToProps = (state) => {
  return state.app
}
// const url = 'asdf'
const UserCreated = ({ dispatch, next = "/" }) => (
  <div className="createdmodal-component modal__component">
    <img src="/assets/img/img-email-sent.png" alt="" />
    <h2>Du er nu oprettet i systemet, og vi har sendt dig en mail med dine profil-oplysninger.</h2>
    <Link to={next}>
      <button className="button" onClick={() => dispatch(actions.setVisibleModal(null))}>
        Ok - det lyder godt
      </button>
    </Link>
  </div>
)

const UserCreatedWithState = connect(mapStateToProps)(UserCreated)

const SendPasswordResetEmailSuccess = () => (
  <div>
    <h2>Mail på vej</h2>
    <p>Vi har sendt dig en mail med link til at nulstille din adgangskode</p>
    <Link className="button" to={URLS.HOME}>
      Ok - det lyder godt
    </Link>
  </div>
)

const ResetPasswordSuccess = () => (
  <div>
    <h2>Din adgangskode er blevet nulstillet</h2>
    <p>Du kan nu logge ind med din nye kode</p>
    <Link className="button" to={URLS.HOME}>
      Ok - det lyder godt
    </Link>
  </div>
)

const HelpModalContent = ({ helpModalContent }) => {
  if (helpModalContent === null) return <span></span>
  else
    return (
      <div>
        <h2>{helpModalContent.title}</h2>
        <p dangerouslySetInnerHTML={{ __html: helpModalContent.body }} />
      </div>
    )
}

const SelfSaleFrontModalWithState = connect()(SelfSaleFrontModal)

const MODALS_CONTENT = {
  LOGIN: <LoginForm />,
  LOGOUT: <Logout />,
  USER_CREATED: <UserCreatedWithState />,
  USER_CREATED_BUYER_INDEX: <UserCreatedWithState next={URLS.BUYER_INDEX.CREATE.ACTIVATION} />,
  USER_CREATED_SELF_SALE: <UserCreatedWithState next={URLS.SALE.SELF_SALE.ACTIVATION} />,
  USER_CREATED_SWAP: <UserCreatedWithState next={URLS.SWAP.CREATE.S2_ACTIVATION} />,
  BUYER_INDEX_SUCCESS: <SuccessModal nextUrl={URLS.PROFILE.BUYER_INDEX} />,
  SUCCESS_SELF_SALE_ADDED: <SuccessSelfSaleAddedModal nextUrl={URLS.PROFILE.SELF_SALE} />,
  SUBSCRIPTION_CANCEL: <SubscriptionCancelModal />,
  SELF_SALE_FRONT_MODAL: <SelfSaleFrontModalWithState />,
  PHOTO_PACKAGE_INFO_MODAL: <PhotoPackageInfoModal />,
  UPLOAD_PAGE_MODAL: <UploadPageModal />,
  RESET_PASSWORD_SUCCESS: <ResetPasswordSuccess />,
  SEND_RESET_PASSWORD_EMAIL_SUCCESS: <SendPasswordResetEmailSuccess />,
  CASE_CONTACT_MODAL: <contactModals.CaseContactModalContent />,
  BOOK_A_SHOW_MODAL: <contactModals.BookAShowModalContent />,
  BOOK_OPEN_HOUSE_MODAL: <contactModals.BookOpenHouseModalContent />,
  CO_SALE_CONTACT_MODAL: <contactModals.CoSaleContactModalContent />,
  TOTAL_SALE_CONTACT_MODAL: <contactModals.TotalSaleContactModalContent />,
  CASE_CONTACT_SUBMITTED_MODAL: <contactModals.CaseContactSubmittedContent />,
  BUILDING_INFO_MODAL: <BuildingInfoModalContent />,
  CASE_DOCUMENTS_MODAL: <CaseDocumentsModal />,
  SWAP_CONTACT_MODAL: <SwapContactModal />,
  SITE_TEXT_MODAL: <SiteTextModal />,
  CONFIRMATION_MODAL: <ConfirmationModal />,
  DELETED_MODAL: <DeletedModal />,
}

const VisibleModalContent = ({ visibleModal }) => {
  return visibleModal === null ? <span></span> : MODALS_CONTENT[visibleModal]
}

const Modals = ({ visibleModal, helpModalContent, dispatch }) => {
  const showModal = visibleModal !== null
  const onCloseModal = () => dispatch(actions.setVisibleModal(null))
  const onCloseHelpModal = () => dispatch(actions.setHelpModalContent(null))

  return (
    <div>
      <Modal show={showModal} onClose={onCloseModal}>
        <VisibleModalContent visibleModal={visibleModal} onCloseModal={onCloseModal} />
      </Modal>
      <Modal show={helpModalContent !== null} onClose={onCloseHelpModal}>
        <div>
          <HelpModalContent helpModalContent={helpModalContent} />
          <button className="button" onClick={onCloseHelpModal}>
            Ok - Forstået
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default connect(mapStateToProps)(Modals)

import React from 'react';
import Upload from 'components/shared/forms/Upload'


const DeletableDocument = ({id = null, fieldName, label, filename, onUpload, onDelete,}) => {
	return <li className="myselfsale__document">
		<p className="myselfsale__label">{label}</p>
		{id===null ? (
				<Upload
					fieldName={fieldName}
					onUpload={onUpload}
					label={'asdf'}/>
			)
			: (
				<div className="myselfsale__item">
					<div className="myselfsale__filename">{filename}</div>
					<button onClick={onDelete} className="myselfsale__remove">Slet</button>
				</div>

			)}
	</li>

}


export default DeletableDocument;
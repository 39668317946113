import React from 'react';

const Modal = ({show, onClose, children}) => {
    const className = show ? "modal modal-is-open" : "modal"
    const closeButton = onClose ? <button className="modal__close" onClick={onClose}></button> : ""
    return (
        <section className={className}>
            <div className="modal__inner">
                {closeButton}
                <div className="modal__content">
                    {children}
                </div>
            </div>
        </section>
    );
}

Modal.displayName = 'Modal';
Modal.propTypes = {};
Modal.defaultProps = {};

export default Modal;

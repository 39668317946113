import {setTokenAndId} from "../tokenManager";
import {responseErrors} from "../utils";

const initialState = {
    loginErrors: [],
    loading: false,
    id: null,
    rememberPassActive: false
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "ACTIVATE_USER_PENDING": {
            return {...state}
        }
        case "ACTIVATE_USER_REJECTED": {
            return {...state}
        }
        case "ACTIVATE_USER_FULFILLED": {
            const ubUser = action.payload.data.activateUbUser.ubUser
            return {
                ...ubUser
            }
        }

        case "FETCH_TOKEN_PENDING": {
            return {...state, id: null, loginErrors: []}
        }

        case "FETCH_TOKEN_REJECTED": {
            return {...state, id: null, loginErrors: ['Fejl i email eller adgangskode, prøv igen']}
        }

        case "FETCH_TOKEN_FULFILLED": {
            const data = action.payload.data
            setTokenAndId(data.token, data.id)
            return {...state}
        }
        case "REFRESH_TOKEN_PENDING": {
            return {...state}
        }

        case "REFRESH_TOKEN_REJECTED": {
            return {...initialState}
        }

        case "REFRESH_TOKEN_FULFILLED": {
            const data = action.payload.data
            setTokenAndId(data.token, data.id)
            return {...state}
        }

        case "UPDATE_USER_PENDING": {
            return {...state, loading: true}
        }

        case "UPDATE_USER_REJECTED": {
            return {...state, loading: false}
        }

        case "UPDATE_USER_FULFILLED": {
            return {...state}
        }

        case "SAVE_PROFILE_FULFILLED": {
            return {...state, ...action.payload.data.changeUbUserProfile.ubUser}
        }
        case "VERIFY_USER_FULFILLED": {
             return {...state, ...action.payload.data.activateUbUser.ubUser}
        }
        case "SET_REMEMBER_PASS_ACTIVE": {
            return {...state, rememberPassActive: action.payload, loginErrors: []}
        }
        case "LOGOUT": {
            setTokenAndId(null, null)
            return {...initialState}
        }

        case "SEND_PASSWORD_RESET_EMAIL_PENDING": {
            return {...state, loginErrors:[]}
        }

        case "SEND_PASSWORD_RESET_EMAIL_REJECTED": {
            return {...state, loginErrors: responseErrors(action.payload, 'Email findes ikke')}
        }

        case "SEND_PASSWORD_RESET_EMAIL_FULFILLED": {
            return {...state, loginErrors: [], rememberPassActive: false}
        }
    }
    return state
}

import React from "react"
import ReactDOM from "react-dom"
import {BrowserRouter} from 'react-router-dom'
import {ApolloProvider} from "react-apollo"
import App from "./components/App"
import client from "./client"
import store from "store"
import {Provider} from 'react-redux'

import './front_end/sass/main.sass'
import registerServiceWorker from "./registerServiceWorker";


const app = document.getElementById('approot')

const Root = () => (
    <ApolloProvider client={client}>
        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </ApolloProvider>
)


ReactDOM.render(<Root/>, app);

//registerServiceWorker()

if (navigator.serviceWorker){
  navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for (let registration of registrations) {
      registration.unregister()
    }
  })
}

import React from 'react';
import {connect} from 'react-redux'
import UploadWidget from '../../../shared/forms/UploadWidget'
import * as actions from 'actions'
import * as queries from 'queries'
import {graphql} from 'react-apollo'
import {uploadFile} from "uploadApi";
import {URLS} from 'urls'

const fields = [
    {
        label: 'Nyeste årsregnskab',
        fieldName: 'annualAccounts',
        required: true
    },
    {
        label: 'Gældende vedtægter',
        fieldName: 'rulesRegulations',
        required: true
    },
    {
        label: 'Referat fra seneste generalforsamling',
        fieldName: 'latestBoardMeetingReport',
        required: true
    },
    {
        label: 'Energimærke',
        fieldName: 'energyLabel',
        required: true
    },
    {
        label: 'Næste års budget',
        fieldName: 'nextYearsBudget',
        subLabel: 'Hvis budget er indeholdt i nyeste årsregnskab, bedes du uploade nyeste årsregnskab her',
        required: true
    },
    {
        label: 'Husorden',
        fieldName: 'houseRules',
        subLabel: 'Såfremt forefindes',
        required: false
    },

]


const UploadPage = ({dispatch, data}) => {
    if (data.loading) return <div className="section--loading"></div>
    const onToggleModal = (missingFiles) => () => dispatch(actions.setVisibleModal('UPLOAD_PAGE_MODAL', {
        missingFiles: missingFiles,
        next: URLS.SALE.SELF_SALE.SELL_FORM
    }))
    const onUpload = (fieldName) => (e) => uploadFile(e.target.files[0])
        .then((res) => {
            const input = {}
            input[fieldName] = res.data
            dispatch(actions.modifySelfSale(input))
        })
        .catch((err) => console.log(err))

    const user = data.ubUser
    if (user===null) return <div>Du skal være logget ind</div>

    const {selfSaleCase} = user
    if (selfSaleCase===null) return <div>Du har ikke noget selvsalg endnu</div>

    const getFileName = (fieldName) => {
        return selfSaleCase[fieldName]===null ? null : selfSaleCase[fieldName].filename
    }

    const getMissingFilesAsList = ({obj, fields}) => {
        let missingFiles = []
        for (const field of fields) {
            if (obj[field.fieldName]===null && field.required) {
                missingFiles.push(field.label)
            }
        }
        return missingFiles
    }

    const missingFiles = getMissingFilesAsList({obj: selfSaleCase, fields: fields})

    return (
        <div className="selfsale__upload">
            <section className="section subsection u-white">
                <div className="container">
                    <div className="row">
                        <div className="col-s-12 col-l-12">
                            <div className="flex-column u-center-text">
                                <h1 className="u-green-text">Upload evt. følgende dokumenter</h1>

                                <p>Hvis du ikke har adgang til dokumenterne nu, kan du altid uploade dem senere.</p>

                                <form className="selfsale__upload__form">
                                    <div className="row">
                                        {fields.map((field, index) => {
                                            return (
                                                <div key={index} className="col-s-12 col-m-6 col-l-4">
                                                    <div className="selfsale__upload__column flex-column u-lightgrey"
                                                         style={{position: 'relative'}}>
                                                        <h3 className="u-light-text u-green-text">{field.label}</h3>
                                                        <small style={{
                                                            position: 'relative',
                                                            top: '-23px',
                                                            'margin-bottom': '-23px'
                                                        }}>{field.subLabel}</small>
                                                        <UploadWidget count={index}
                                                                      key={index}
                                                                      {...field}
                                                                      fileName={getFileName(field.fieldName)}
                                                                      onUpload={onUpload(field.fieldName)}/>
                                                        <svg className="icon icon--green icon--question">
                                                            <use xlinkHref="#icon-question"></use>
                                                        </svg>

                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </form>
                                <button className="button" onClick={onToggleModal(missingFiles)}>Næste</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

UploadPage.displayName = 'UploadPage';
UploadPage.propTypes = {};
UploadPage.defaultProps = {};

const UploadPageWithData = graphql(queries.UB_USER_QUERY)(UploadPage)

const mapStateToProps = state => {
    return state.sale
}

export default connect(mapStateToProps)(UploadPageWithData);

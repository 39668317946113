import React from 'react'
import {isSelfSale} from "../../utils";

// Helper function to get key/value pair for seller info
export const getSellerFromCaseData = (caseData) => {
    // Is case selfsale or regular
    if (isSelfSale(caseData._typeName)){
        // Selfsale - seller is ubUser
        return {
            title: "Denne bolig sælges direkte af ejeren"

        }
    }
    else {
        // regular seller is agent or with medsalg seller owner
        // Medsalg - Owner
        if (caseData.sellerIsAgent){
            return {
                title: "Fremvises af ejer",
                name: caseData.sellerName,
                phone: caseData.showSellerPhone ? caseData.sellerPhone : undefined,
                email: caseData.sellerEmail
            }
        }
        // Totalsalg - Agent
        else {
            return {
                title: 'Mægler på denne sag',
                name: caseData.agent.fullName,
                phone: caseData.agent.cellPhone,
                email: caseData.agent.user.email
            }
        }
    }
}


/*
Seller info takes a case and from that will know who the seller is:
Sale types are:
SelfSale always owner
Sale
- medsalg: Owner
- others: Agent
 */

const SellerInfo = (caseData) => {
    const {title, name, phone, email} = getSellerFromCaseData(caseData)
    const renderPhone = phone ? <span>Mobil: {phone}<br></br></span> : null
    const renderEmail = email ? <a href={ "mailto:" + email}>{ email }</a> : null
    return (
    <p>
        <strong>{ title }</strong><br></br>
        {name}<br></br>
        { renderPhone }
        { renderEmail }
    </p>
)}

export default SellerInfo;
import React from 'react';
import Map from 'components/shared/Map'

const positionVesterbrogade = {lat: 55.673400, lng:12.560116}

const position = positionVesterbrogade
const street = 'Andelshandel'
const streetNumber = ''
const zoom = 15

const Contact = () => {
  return (
    <div className="contact animate--down">
        <section className="u-full-height u-lightgrey flex-center">
            <div className="container">
                <div className="row">
                    <div className="col-s-12 col-l-5">
                        <div className="contact__card u-lightgreen u-white-text ">
                            <div className="contact__card__img" style={{backgroundImage: "url(/assets/img/img-christian.png)"}}></div>
                            <p className="u-white-text ">Kontakt vores Direktør & Partner som træffes på hverdage mellem kl. 9 og 17</p>
                            <strong>Christian Weber</strong>
                            <p className="u-white-text">
                                <a href="tel://71996939" className="u-white-text">71996939</a><br />
                                <a href="mailto:cw@andelshandel.dk"  className="u-white-text">cw@andelshandel.dk</a>
                            </p>
                            <p className="small">Hovednummer</p>
                            <h1><a className="u-white-text" href="tel://71996939">Tlf. 71996939</a></h1>
                        </div>
                    </div>
                    <div className="col-s-12  col-l-7">
                        <div className="contact__map">
                            <div className="map">
                                <Map center={position} zoom={zoom} street={street} streetNumber={streetNumber} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
  );
}

Contact.displayName = 'Contact';
Contact.propTypes = {};
Contact.defaultProps = {};

export default Contact;

import React from 'react'


// Format text to html with paragraphs for each linebreak
const Text = ({text}) => {
    if (text===null){
        text=""
    }
    const newText = text.split('\n').map((item, i) => {
        return <p key={i}>{item}</p>;
    });
    return (
    <div>
        {newText}
    </div>
)}


export default Text;
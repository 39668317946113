import React from 'react';
import {connect} from 'react-redux';
import * as queries from 'queries'
import {RangeSlider} from "components/shared/forms/index"
import {changeRange, setBuyerCaseRequirements, setBuyerCaseAreas, submitBuyerCase} from "actions/buyerCaseActions"
import {withRouter} from "react-router-dom"
import {graphql} from 'react-apollo'
import {find} from 'lodash'
import * as utils from 'utils'
import CheckBoxBlock from 'components/shared/forms/CheckBoxBlock'
import Photo from 'components/shared/forms/Photo'
import {uploadImage} from 'uploadApi'
import {changeProfileDescription} from "../../../actions/buyerCaseActions";
import {RANGES} from 'config'

const INPUT_FIELD_NAMES = [
    'minFloorArea',
    'maxFloorArea',
    'minRooms',
    'maxRooms',
    'minMonthlyFee',
    'maxMonthlyFee',
    'minPrice',
    'maxPrice',
    'preferredAreas',
    'requirements',
    'profileDescription'
]


const RequirementsBlockWithData = graphql(queries.AVAILABLE_FACILITIES_UB_USER,
    {
        props: ({data: {loading, availableFacilitiesUbUser}}) => ({
            loading: loading,
            options: availableFacilitiesUbUser
        })
    }
)(CheckBoxBlock)


const AreasBlockWithData = graphql(queries.ALL_AREAS_QUERY,
    {
        props: ({data: {loading, allAreas}}) => ({
            loading: loading,
            options: allAreas
        })
    }
)(CheckBoxBlock)



const BuyerIndexFormWithProfile = ({data, newBuyerCase, dispatch, history, nextUrl}) => {
    if (data.loading) {
        return <div className="section--loader"></div>
    }

    const isNew = data.ubUser.buyerCase===null
    const buyerCase = isNew ? newBuyerCase : data.ubUser.buyerCase
    const ubUser = data.ubUser

    console.log(ubUser.buyerCase.wizardCompleted)
    const getFieldValue = (fieldName) => {
        return [buyerCase['min' + fieldName], buyerCase['max' + fieldName]]
    }

    //const getDefaultFieldValue = (fieldName) => selfSaleCase[fieldName]
    //const getErrors = (fieldName) => app.validationErrors[fieldName] || []

    const onChangeRange = (fieldName) => (value) => {
        if (isNew) {
            dispatch(changeRange(fieldName, value[0], value[1]))
        }
        else {
            // Do changes to apollo cache
            let newBuyerCase = {
                ...buyerCase
            }
            newBuyerCase['min' + fieldName] = value[0]
            newBuyerCase['max' + fieldName] = value[1]

            data.updateQuery(
                (prev) => (
                    {
                        ...prev,
                        ubUser: {
                            ...prev.ubUser,
                            buyerCase: newBuyerCase
                        }
                    }
                )
            )
        }
    }

    const onChangeDescription = (e) => {
        const value = e.target.value
        if (isNew) {
            dispatch(changeProfileDescription(value))
        }
        else {
            data.updateQuery(
                (prev) => (
                    {
                        ...prev,
                        ubUser: {
                            ...prev.ubUser,
                            buyerCase: {...buyerCase, profileDescription: value}
                        }
                    }
                )
            )
        }
    }

    const onChangeProfileImage = (e) => {
        const file = e.target.files[0]
        uploadImage(file)
            .then((res) => dispatch(submitBuyerCase({profileImage: res.data})))
            .catch((err) => console.log(err))
    }

    const getOnToggle = (fieldName, action) => (id, prevList, prevValue, choicesList) => () => {
        const _setListFn = (newList) => {
            if (isNew) {
                dispatch(action(newList))
            }
            else {
                let newBuyerCase = {...buyerCase}
                newBuyerCase[fieldName] = newList
                data.updateQuery(() => (
                        {
                            ubUser: {...data.ubUser, buyerCase: newBuyerCase}
                        }
                    )
                )
            }
        }
        let newList = [...prevList]
        if (prevValue) {
            newList = newList.filter((x) => x.id!==id)
        }
        else {
            newList = [...newList, find(choicesList, {id: id})]
        }
        _setListFn(newList)
    }
    const onSubmit = (e) => {
        e.preventDefault()
        let _mappedInput = {}
        for (const fieldName of INPUT_FIELD_NAMES) {
            _mappedInput[fieldName] = buyerCase[fieldName]
        }
        _mappedInput.requirements = utils.listByKey(buyerCase.requirements, 'id')
        _mappedInput.preferredAreas = utils.listByKey(buyerCase.preferredAreas, 'id')
        dispatch(submitBuyerCase(_mappedInput))
            .then(() => {
                if (nextUrl) {
                    history.push(nextUrl)
                }
            })
    }

    return (
        <div className="buyerform-component">
            <section className="section u-lightgrey">
                <div className="container">
                    <h2 className="u-darkgreen-text section-title__block subsection">Mit køberservice</h2>
                    <form onSubmit={onSubmit}>
                        <div className="row bordered-columns">
                            <div className="col-s-12 col-m-6">
                                <div className="subsection">
                                    <RangeSlider
                                        label="Størrelse - min/max"
                                        value={getFieldValue('FloorArea')}
                                        min={RANGES.MIN_SIZE}
                                        max={RANGES.MAX_SIZE}
                                        onChange={onChangeRange('FloorArea')}
                                        formatValue={utils.plus(utils.kvm, RANGES.MAX_SIZE)}
                                    />
                                </div>
                                <div className="subsection">
                                    <RangeSlider
                                        label="Antal Rum"
                                        value={getFieldValue('Rooms')}
                                        min={RANGES.MIN_ROOMS}
                                        max={RANGES.MAX_ROOMS}
                                        onChange={onChangeRange('Rooms')}
                                        formatValue={utils.plus((val) => val, RANGES.MAX_ROOMS)}
                                    />
                                </div>
                                <div className="subsection">
                                    <RangeSlider
                                        label="Anskaffelsespris - min/max"
                                        value={getFieldValue('Price')}
                                        min={RANGES.MIN_PRICE}
                                        max={RANGES.MAX_PRICE}
                                        onChange={onChangeRange('Price')}
                                        formatValue={utils.plus(utils.kr, RANGES.MAX_PRICE)}
                                    />
                                </div>
                                <div className="subsection">
                                    <RangeSlider
                                        label="Husleje - min/max"
                                        value={getFieldValue('MonthlyFee')}
                                        min={RANGES.MIN_RENT}
                                        max={RANGES.MAX_RENT}
                                        onChange={onChangeRange('MonthlyFee')}
                                        formatValue={utils.plus(utils.kr, RANGES.MAX_RENT)}
                                    />
                                </div>
                                <div className="subsection">
                                    <AreasBlockWithData
                                        title="Vælg Område"
                                        value={buyerCase.preferredAreas}
                                        onToggle={getOnToggle('preferredAreas', setBuyerCaseAreas)}
                                        labelName="name"
                                    />
                                </div>
                            </div>
                            <div className="col-s-12 col-m-6">
                                <div className="subsection">
                                    <RequirementsBlockWithData
                                        title="Vælg boligens faciliteter"
                                        value={buyerCase.requirements}
                                        onToggle={getOnToggle('requirements', setBuyerCaseRequirements)}
                                        labelName="facilityDescription"
                                    />
                                </div>
                                <div className="subsection">
                                    <div className="input-field__textarea">
                                        <textarea name="description" value={buyerCase.profileDescription} id=""
                                                  cols="30" rows="10" onChange={onChangeDescription}></textarea>
                                    </div>
                                </div>
                                <div className="subsection">
                                    <div className="">
                                        <Photo onChange={onChangeProfileImage}
                                               url={buyerCase.profileImage ? buyerCase.profileImage.url : null}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="button">Gem</button>
                    </form>
                </div>
            </section>
        </div>
    );
}


const mapStateToProps = state => {
    return {newBuyerCase: state.buyerCase}
}

BuyerIndexFormWithProfile.displayName = 'BuyerIndexFormWithProfile';
BuyerIndexFormWithProfile.propTypes = {};
BuyerIndexFormWithProfile.defaultProps = {};

const BuyerIndexFormWithProfileWithData = graphql(queries.UB_USER_QUERY)(BuyerIndexFormWithProfile)


export default withRouter(connect(mapStateToProps)(BuyerIndexFormWithProfileWithData))





import React from 'react';


const DoesNotExist = () => {
    return (
        <div>
            <section className="section u-lightgrey u-full-height flex-center">
                <div className="row">
                    <div className="col-s-12">
                        <h1>Siden findes ikke</h1>
                        <p>Du er kommet til en side der ikke findes!</p>
                    </div>
                </div>
            </section>
        </div>
    )
}
DoesNotExist.displayName = 'DoesNotExist';
DoesNotExist.propTypes = {};
DoesNotExist.defaultProps = {};

export default DoesNotExist;
import React from 'react';
import {validateField} from 'validation';

//import './input.css';

const Input = ({
	               key, label, className, placeholder, name,
	               value, errors = [], required = false,
	               onChange,
	               onShowHelp = () => console.log('Please Implement onShowHelp'),
	               defaultValue,
	               type = "text",
				   help = null, validators = [],
				   onDispatchErrors = () => undefined,
	               formatter
               }) => {

	const validate = (e) => {
		const value = e.target.value
		const _errors = validateField({value, fieldValidators: validators, required, type})
		let formattedErrors = {}
		formattedErrors[name] = _errors
		onDispatchErrors(formattedErrors)
	}

	const onChangeInput = (e) => {
		onChange(name, e.target.value)
		if (errors.length > 0) validate(e)
    }
	const onClickHelp = () => onShowHelp({title: label, body: help})

	const errorsList = errors===undefined ? [] : errors
	const inputClass = errors===false ? ("input-field__" + type) : ("has-error input-field__" + type)

	const formattedValue = formatter === undefined ? null : formatter(value)
	const style = formattedValue === null ? undefined : {color: 'white'}

	return (
		<div key={key} className={inputClass}>
			<label htmlFor={name} required={required}>{label}</label>
			<span>{formattedValue}</span>
			<input className={className}
			       placeholder={placeholder}
			       name={name} type={type}
			       defaultValue={defaultValue}
			       value={value}
			       onChange={onChangeInput}
			       onBlur={validate}
				   style={style}
			>
			</input>
			{errorsList.map((error, i) => <div key={i} className="error">{error.message}</div>)}
			{help!==null ?
				<svg onClick={onClickHelp} className="icon icon--green icon--question">
					<use xlinkHref="#icon-question"></use>
				</svg> : ''
			}
		</div>
	);
}

Input.displayName = 'Input';
Input.propTypes = {};
Input.defaultProps = {};

export default Input

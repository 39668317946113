import React from 'react';

const Upload = ({fieldName, label, count, onUpload, multiple=false}) =>  {
    return (
    <div className="input-field__upload">
        <label className="button button--darkgreen" htmlFor={'upload'+count+fieldName}>
            <svg className="icon icon--white icon--upload-arrow">
                <use xlinkHref="#icon-upload-arrow"></use>
            </svg>
            <svg className="icon icon--white icon--upload-bar">
                <use xlinkHref="#icon-upload-bar"></use>
            </svg>

            <input onChange={onUpload} id={'upload'+count+fieldName} name={fieldName} type="file" multiple={multiple}></input>
            <p>
                Upload her
            </p>
        </label>
      </div>
    );
}

Upload.displayName = 'Upload';
Upload.propTypes = {};
Upload.defaultProps = {};

export default Upload;

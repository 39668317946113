import * as validation from 'validation'
import * as utils from 'utils'
import {FLOOR_OPTIONS} from "../../../values";

const composeValidators = (...validators) => value =>
    validators.reduce((error, validator) => error || validator(value), undefined)


export const associationFieldsWithPlaceholders = [
    {
        placeholder: 'Navn på andelsboligforening',
        name: 'cooperativeName',
        required: true,
        type: "text",
        validate: composeValidators(validation.required)

    },
    {
        placeholder: 'Navn på boligadministrator (hvis kendes)',
        name: 'administrationAssociationName',
        type: 'text',
        required: false,
        validate: composeValidators()
    }
]

export const addressFields = [
    {
        label: 'Vejnavn',
        name: 'addressStreetName',
        required: true,
        type: "text",
        validate: composeValidators(validation.required)

    },
    {
        label: 'Nr.',
        name: 'addressNumber',
        required: true,
        type: "text",
        validate: composeValidators(validation.required)

    },
    {
        label: 'Etage',
        name: 'addressFloor',
        required: false,
        type: "select",
        placeholder: "-- Ingen etage --",
        validate: composeValidators(),
        options: Object.entries(FLOOR_OPTIONS)
    },
    {
        label: 'Post nr',
        name: 'zipCode',
        type: 'number',
        required: true,
        validate: composeValidators(validation.required, validation.zipCode)
    }
]

export const infoFields = [
    {
        label: 'Antal m2',
        name: 'apartmentSquareMetres',
        help: "Du kan finde oplysningen i ejendommens BBR meddelelse, som du finder på fx boligejer.dk eller via din forening. Oplysningen kan måske også stå i foreningens regnskab.",
        type: 'number',
        required: true,
        validate: composeValidators(validation.required, validation.number)
    },
    {
        label: 'Antal værelser',
        name: 'roomsCount',
        help: `<p>
Står i BBR meddelelsen som du kan på fx boligejer.dk. <br>
Har du lavet flere lovlige værelser eller nedlagt værelser, så ring til din kommune og få oplysningen rettet i BBR. <br>
</p> Bemærk at der er visse krav til et nyetableret værelse for at det kan godkendes som værelse. Er du tvivl så kontakt din kommunes teknisk forvaltning.`,
        type: 'number',
        required: true,
        validate: composeValidators(validation.required, validation.number)
    },
    {
        label: 'Forventet salgspris inkl. forbedringer',
        name: 'expectedApartmentValue',
        help: `Oplysningen kan du enten aflæse direkte i det nyeste årsregnskab for foreningen, eller du kan beregne den via oplysninger om andelskronen i regnskabet.`,
        type: 'text',
        format: utils.thousandSepparator,
        parse: utils.parseKr,
        required: true,
        validate: composeValidators(validation.required, validation.number)
    },
]

export const expensesFields = [
    {
        label: 'Boligafgift',
        name: 'monthlyFee',
        help: "Find oplysningen på din seneste opkrævning fra foreningen/administrator.",
        type: 'text',
        format: utils.thousandSepparator,
        parse: utils.parseKr,
        required: true,
        validate: composeValidators(validation.required, validation.number)
    },
    {
        label: 'A conto varme',
        name: 'utilitiesHeating',
        help: "Find oplysningen på din seneste opkrævning fra foreningen/administrator.",
        type: 'text',
        format: utils.thousandSepparator,
        parse: utils.parseKr,
        required: true,
        validate: composeValidators(validation.required, validation.number)
    },
    {
        label: 'A conto vand',
        name: 'utilitiesWater',
        help: "Find oplysningen på din seneste opkrævning fra foreningen/administrator.",
        type: 'text',
        format: utils.thousandSepparator,
        parse: utils.parseKr,
        required: false,
        validate: composeValidators(validation.number)
    }
]


export const descriptionFields = [
    {
        label: 'Overskrift - maks. 30 karakterer',
        name: 'heading',
        required: true,
        type: 'text',
        validate: composeValidators(validation.required)
    },
    {
        label: 'beskrivelse',
        name: 'description',
        required: true,
        type: 'textarea',
        validate: composeValidators(validation.required)
    }
]

export const wishesFields = [
    {name: 'minFloorArea'},
    {name: 'maxFloorArea'},
    {name: 'minRooms'},
    {name: 'maxRooms'},
    {name: 'minPrice'},
    {name: 'maxPrice'},
    {name: 'minMonthlyFee'},
    {name: 'maxMonthlyFee'}
]
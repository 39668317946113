import React from 'react'
import * as utils from 'utils'

const fields = [
    {
        label: 'Långiver',
        name: 'issuerName',
    },
    {
        label: 'Type',
        name: 'loanType',
    },
    {
        label: 'Restgæld',
        name: 'unpaidDebt',
        format: utils.kr
    },
    {
        label: 'Kurs værdi',
        name: 'quotedValue',
        format: utils.kr
    },
    {
        label: 'Dato',
        name: 'loanDate',
    },
    {
        label: 'Årlig rente',
        name: 'interestRate',
        format: utils.percentage
    },
    {
        label: 'Løbetid',
        name: 'loanPeriod'
    },
    {
        label: 'Hæftelse',
        name: 'liability'
    },
    // {
    //     label: 'Vilkår',
    //     name: 'terms',
    //
    // },
]

const LoanInfo = ({loanSet}) => {
    return (
        <div>
            <h3>LÅN:</h3>
            {loanSet.map((loanData, i) => {
                return <ul key={i} className="info-list">
                {fields.map((x, i) => utils.isEmpty(loanData[x.name]) ? "" : <li key={i}><span className="label">{x.label}: </span><span
                    className="value">{x.format===undefined ? loanData[x.name] : x.format(loanData[x.name])}</span>
                </li>)}
                <li key={i}><span className="label">Vilkår:  {loanData.terms}</span><span
                    className="value"></span>
                </li>
            </ul>
            })}

        </div>
        )
}


export default LoanInfo;
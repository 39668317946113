import React from 'react';


const SubmitButtonWithLoader = props => {
    const {label, disabled, loading, loadLabel} = props
    const _label = loadLabel && loading ? loadLabel : label

    return <button className="button" disabled={disabled}>
        {loading ? <i className="fa fa-circle-o-notch fa-spin"></i> : ""}
        {_label}
    </button>
}


export default SubmitButtonWithLoader;
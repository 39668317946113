import React from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {URLS} from 'urls'
import Radio from 'components/shared/forms/Radio'
import {graphql} from 'react-apollo'
import * as queries from 'queries'
import * as actions from 'actions'

const modalLink = {
    label: 'læs mere her',
    modalContent: {
        title: 'Hotline Pro',
        body: `
<p>Disse ting hjælper vi ofte selvsælgere med</p>
<ul>
    <li>Optimering af salgspris</li>
    <li>Hvordan vælger jeg bedst en køber ud?</li>
    <li>Hvordan sikrer jeg mig at køber ikke springer og hvilke omkostninger hænger jeg på hvis køber springer fra?</li>
    <li>Indsigelser fra købers bank og/eller advokat – Hvordan forholder jeg mig?</li>
    <li>Mangler, skal jeg udbedre? Give afslag? Hvad betaler sig bedst for mig?</li>
    <li>Hvornår får jeg mine penge udbetalt?</li>
    <li>Jeg skal købe ny bolig men har ikke solgt endnu, hvordan sikrer jeg mig IKKE at miste den nye bolig?</li>
    <li>Og meget andet ☺</li>
</ul>
<small>
Hotline Pro er ikke omfattet af solgt eller gratis.<br> 
Vi fremsender faktura på dit køb indenfor 1-2 uger.
</small>
`
    }
}

const SelectProHelp = ({dispatch, data}) => {
	if (data.loading) return <div className="section--loading"></div>
	if (data.ubUser==null) return <span>Du skal være logget ind</span>
	const selfSaleCase = data.ubUser.selfSaleCase
	const setFullService = (value) => () => {
		dispatch(actions.modifySelfSale({fullService: value}))
	}

	return (
		<div className="selectprohelp-component">
			<div className="selfsale__photo-package">
				<section className="section u-lightgrey u-full-height flex-center">
					<div className="container">
						<h1 className="u-green-text">Brug for en Hotline til din egen mægler? <br/>Vi hjælper dig så du undgår dumme og dyre fejl.</h1>
						<h4 className="u-darkgrey-text">
							Mange selvsælgere oplever et uventet behov for professionel hjælp undervejs. <br/>
							Med vores Hotline Pro får du adgang til hjælp fra den samme mægler under hele forløbet. <br/>
							Du kan bare ringe ind.
						</h4>
						<form>
							<Radio label="Hotline Pro" description="3.995,- inkl. moms" type="radio"
							       name='fullService' value={selfSaleCase.fullService}
							       onChange={setFullService(true)}
								   modalLink={modalLink}
							/>
							<Radio label="Nej tak" description="Jeg påtager mig gerne selv alt ansvar" type="radio"
							       name='fullService' value={!selfSaleCase.fullService}
							       onChange={setFullService(false)}/>
							<Link className="button" to={URLS.SALE.SELF_SALE.FINISH}>Næste</Link>
						</form>
					</div>
				</section>
			</div>

		</div>
	);
}

SelectProHelp.displayName = 'SelectProHelp';
SelectProHelp.propTypes = {};
SelectProHelp.defaultProps = {};

const mapStateToProps = state => {
	return state.sale
}

const SelectProHelpWithData = graphql(queries.UB_USER_QUERY)(SelectProHelp)

export default connect(mapStateToProps)(SelectProHelpWithData);

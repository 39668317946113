import React from 'react';
import BuyerIndexFormWithProfile from 'components/shared/forms/BuyerIndexFormWithProfile'

const BuyerIndexProfilePage = () => {

	return (
		<BuyerIndexFormWithProfile/>
	)
}

BuyerIndexProfilePage.displayName = 'BuyerIndexPage';
BuyerIndexProfilePage.propTypes = {};
BuyerIndexProfilePage.defaultProps = {};

export default BuyerIndexProfilePage;



import React from "react"
import {validateField} from 'validation'

const CheckBox = ({
                      label, name, value, onChange, required = false, type = "checkbox", errors=[], className = false, validators = [],
                      onDispatchErrors = () => undefined
                  }) => {

    const validate = (e) => {
        const value = e.target.value

        const _errors = validateField({value, fieldValidators: validators, required, type: "checkbox"})
        let formattedErrors = {}
        formattedErrors[name] = _errors
        onDispatchErrors(formattedErrors)
    }

    const _onChange = (e) => {
        e.preventDefault()
        onChange(e)
        validate(e)
    }

    return <div className={className ? "input-field__checkbox " + className : "input-field__checkbox"}
                onClick={_onChange}>
        <input id={name} className="check-list" name={name} type="checkbox" value={value}></input>
        <label htmlFor={name}> {label}
            <svg className="icon icon--black icon--check">
                <use xlinkHref="#icon-check"></use>
            </svg>
        </label>
    </div>
}

export default CheckBox;

import React from 'react';
import {connect} from 'react-redux'
import {FieldSection} from "../../../shared/reduxFormsFields";
import {withRouter, Redirect} from 'react-router-dom'
import * as actions from 'actions'
import * as queries from 'queries'
import {URLS} from 'urls'
import {graphql} from 'react-apollo'
import { associationFieldsWithPlaceholders } from "./saleFields";
import {reduxForm} from 'redux-form'


let AssociationForm = (props) => {
    const {invalid, handleSubmit} = props
    return (
        <form onSubmit={handleSubmit}>
            <FieldSection fields={associationFieldsWithPlaceholders}/>
            <button className="button" disabled={invalid}>Næste</button>
        </form>
    )
}

AssociationForm = reduxForm({
    // a unique name for the form
    form: 'associationFormCreate'
})(AssociationForm)

const AssociationFormPage = ({dispatch, history, data}) => {
    if (data.loading) return <div className="section--loading u-full-height"></div>
    const user = data.ubUser || null
    if (user===null) return <Redirect to={URLS.SALE.SELF_SALE.USER}/>
    if (!user.verified) return <Redirect to={URLS.SALE.SELF_SALE.ACTIVATION}/>

    const selfSaleCase = user.selfSaleCase
    const onSubmit = (data) => {

        selfSaleCase===null ?
            dispatch(actions.addSelfSale(data))
                .then(() => {
                    history.push(URLS.SALE.SELF_SALE.UPLOAD_PAGE)
                })
                .catch((err) => console.log(err.message, err.path)) :
            dispatch(actions.modifySelfSale(data))
                .then(() => {
                    history.push(URLS.SALE.SELF_SALE.UPLOAD_PAGE)
                })
                .catch((err) => console.log(err.message, err.path))
    }


    const cooperativeName = selfSaleCase===null ? "" : selfSaleCase.cooperativeName || ""
    const administrationAssiociationName = selfSaleCase===null ? "" : selfSaleCase.administrationAssociationName || ""
    return (
        <div className="selfsale__association ">
            <section className="section subsection u-lightgrey u-full-height flex-center">
                <div className="container">
                    <div className="row">
                        <div className="col-s-12 col-l-6 col-l-push-3 ">
                            <div className="flex-column u-center-text">
                                <img src="/assets/img/img-house.png" className="icon icon--padded icon--house"/>
                                <h1 className="u-green-text">Først skal du indtaste foreningens navn og hvem der er
                                    administrator</h1>
                                <AssociationForm onSubmit={onSubmit} initialValues={{cooperativeName: cooperativeName, administrationAssociationName: administrationAssiociationName}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

AssociationFormPage.displayName = 'AssociationFormPage';
AssociationFormPage.propTypes = {};
AssociationFormPage.defaultProps = {};

const mapStateToProps = state => {
    return state.sale
}

const AssociationFormWithData = graphql(queries.UB_USER_QUERY)(AssociationFormPage)

export default withRouter(connect(mapStateToProps)(AssociationFormWithData));

import gql from "graphql-tag"

export const SETTINGS_QUERY = gql`
  query settings {
    reepayPubApi
  }
`

export const STATS_QUERY = gql`
  query stats {
    numUbUsers
  }
`
// We use this in similar cases
export const ACTIVE_CASES_QUERY = gql`
  query allActiveCases {
    allActiveCases {
      id
      caseId
      size: livingSpace
      zip: zipCode
      city
      rooms: roomsCount
      finalPrice
      price: initialPrice
      openHouseUpcoming
      fullAddress
      floorSide
      latitude
      longitude
      street
      number
      floor
      startSaleDate
      caseimages {
        url: size2Url
      }
    }
  }
`

// Also include cases that are sold
export const CASE_FILTER_QUERY = gql`
  query activeCases(
    $livingSpace_Gte: Int!
    $livingSpace_Lte: Int!
    $maxPrice_Gte: Int!
    $maxPrice_Lte: Int!
    $roomsCount_Gte: Int!
    $roomsCount_Lte: Int!
    $zipCodeStreetOrCity: String!
  ) {
    activeCases(
      livingSpace_Gte: $livingSpace_Gte
      livingSpace_Lte: $livingSpace_Lte
      maxPrice_Gte: $maxPrice_Gte
      maxPrice_Lte: $maxPrice_Lte
      roomsCount_Gte: $roomsCount_Gte
      roomsCount_Lte: $roomsCount_Lte
      zipCodeStreetOrCity: $zipCodeStreetOrCity
    ) {
      id
      caseId
      status
      size: livingSpace
      zip: zipCode
      city
      rooms: roomsCount
      finalPrice
      price: initialPrice
      openHouseUpcoming
      isUnderBuyerRetraction
      fullAddress
      floorSide
      latitude
      longitude
      street
      number
      floor
      startSaleDate
      frontpageImage {
        id
        url: size2Url
      }
      plandrawings {
        id
        url: size2Url
      }
      caseimages {
        url: size2Url
      }
    }
  }
`

export const CASE_QUERY_CASE_PAGE = gql`
  query ($caseId: String!) {
    caseByCaseId(caseId: $caseId) {
      id
      caseId
      uuid
      fullAddress
      street
      city
      number
      commune
      caseHeadline
      caseDescription
      apartmentType
      status
      startSaleDate
      soldDate
      finalPrice
      takeOverDate
      isActive
      showExternally
      isUnderBuyerRetraction
      hideViewingBooking
      changeToOpenHouse
      cadastreApartmentNumber
      distributionNumberProperty
      distributionNumberRent
      utilisation
      livingSpace
      roomsCount
      floor
      floorSide
      zipCode
      floors
      inventoryFreeOfCharge
      inventoryToBuy
      inventoryPrice
      eSoftId
      openHouseUpcoming
      openHouseDateTime
      openHouseInfo
      initialPrice
      agent {
        id
        title
        fullName
        cellPhone
        user {
          id
          email
        }
      }
      agentEvaluation
      agentEvaluationDate
      partOfPool
      enhancements
      specialInventory
      conditionDeduction
      maxPrice
      deduction
      monthlyFee
      antennaFee
      tvFee
      internetFee
      utilitiesHeating
      utilitiesWater
      utilitiesTotalExport
      salary
      mortgageChangeFee
      bookingFeePercentage
      bookingFeeAbsolute
      averageNationalInterest
      monthlyStandardInstallment
      monthlyInterest
      grossFinancing
      netFinancing
      valuationFee
      grossNetCalculationFee
      payoutFinancing
      salesProceedsCalculationFee
      particularsPreparationFee
      advertisingFee
      contractPreparationFee
      informationProcuringFee
      photographyFee
      buyerContactFee
      thirdPartyOutlay
      otherPromotionFee
      salesBlanket
      salesBudget
      listingAgreement
      sellerName
      sellerEmail
      sellerPhone
      sellerBoligsidenOk
      sellerCprNr
      additionalSellerName
      additionalSellerEmail
      additionalSellerPhone
      additionalSellerBoligsidenOk
      additionalSellerCprNr
      sellerBankName
      sellerBankAddress
      sellerBankPhone
      sellerBankAccountantName
      sellerIsAgent
      showSellerPhone
      showSellerEmail
      buyerName
      buyerEmail
      buyerPhone
      buyerBoligsidenOk
      additionalBuyerName
      additionalBuyerEmail
      additionalBuyerPhone
      additionalBuyerBoligsidenOk
      createdDate
      latitude
      longitude
      associationName
      associationUrl
      documentUrl
      salesBlanket
      salesBlanketUrl
      caseimages {
        id
        size1Url
        size2Url
        size3Url
        size4Url
        size5Url
        resizeRule
        frontpage
        imageType
        esoftObjectId
        orientation
        plandrawingType
        sortOrder
        caption
      }
      facilities {
        id
        facilityDescription
      }
      hasDocuments
      caseDocuments {
        id
        url
        name
        extension
      }
      buildingDocuments {
        id
        url
        name
        extension
      }
      building {
        buildingName
        matricleNumber
        constructionYear
        totalCountApartments
        othersCount
        businessCount
        latestBoardMeeting
        valuationPrinciple
        latestValuation
        energyClass
        insuranceDescription
        buildingTotalDebt
        mortgageRate
        latestOperatingProfit
        petsPermitted
        loanSet {
          id
          issuerName
          loanType
          unpaidDebt
          quotedValue
          loanDate
          interestRate
          loanPeriod
          liability
          terms
        }
      }
    }
  }
`

export const SELF_SALE_CASE_FILTER_QUERY = gql`
  query activeSelfSaleCases(
    $livingSpace_Gte: Int!
    $livingSpace_Lte: Int!
    $maxPrice_Gte: Int!
    $maxPrice_Lte: Int!
    $roomsCount_Gte: Int!
    $roomsCount_Lte: Int!
    $zipCodeStreetOrCity: String!
  ) {
    activeSelfSaleCases(
      livingSpace_Gte: $livingSpace_Gte
      livingSpace_Lte: $livingSpace_Lte
      maxPrice_Gte: $maxPrice_Gte
      maxPrice_Lte: $maxPrice_Lte
      roomsCount_Gte: $roomsCount_Gte
      roomsCount_Lte: $roomsCount_Lte
      zipCodeStreetOrCity: $zipCodeStreetOrCity
    ) {
      caseId
      id
      createdDate
      lastModifiedBy
      uuid
      status
      frontpageImage {
        id
        url
      }
      user {
        id
      }
      cooperativeName
      administrationAssociationName
      annualAccounts {
        id
        url
      }
      energyLabel {
        id
        url
      }
      rulesRegulations {
        id
        url
      }
      houseRules {
        id
        url
      }
      latestBoardMeetingReport {
        id
        url
      }
      nextYearsBudget {
        id
        url
      }
      fullAddress
      zip: zipCode
      city
      rooms: roomsCount
      size: apartmentSquareMetres
      price: initialPrice
      enhancementsValue
      specialInventoryValue
      deductionForShortcomings
      monthlyFee
      additionalFees
      utilitiesWater
      utilitiesHeating
      utilitiesAdditional
      caseDescription: description
      externalPhotographer
      fullService
      isActive
      approvedByRealtor
      startSaleDate
      caseimages: selfsalecaseimages {
        id
        url
      }
      facilities {
        id
        facilityDescription
      }
    }
  }
`

export const COMPLETE_SELF_SALE_CASE_QUERY = gql`
  query completeCaseQuery($id: ID!) {
    selfSaleCase(id: $id) {
      id
      caseId
      createdDate
      lastModifiedBy
      uuid
      frontpageImage {
        id
        url
      }
      user {
        id
      }
      cooperativeName
      administrationAssociationName
      annualAccounts {
        id
        url
      }
      energyLabel {
        id
        url
      }
      rulesRegulations {
        id
        url
      }
      houseRules {
        id
        url
      }
      latestBoardMeetingReport {
        id
        url
      }
      fullAddress
      zip: zipCode
      city
      roomsCount
      size: apartmentSquareMetres
      initialPrice: initialPrice
      enhancementsValue
      specialInventoryValue
      deductionForShortcomings
      monthlyFee
      additionalFees
      utilitiesWater
      utilitiesHeating
      utilitiesAdditional
      caseDescription: description
      externalPhotographer
      fullService
      isActive
      approvedByRealtor
      documentUrl
      caseimages: selfsalecaseimages {
        id
        url
        size2Url: url
        size3Url: url
      }
      facilities {
        id
      }
    }
  }
`

export const SELF_SALE_CASE_QUERY_CASE_PAGE = gql`
  query ($caseId: String!) {
    selfSaleCaseByCaseId(caseId: $caseId) {
      id
      caseId
      createdDate
      lastModifiedBy
      uuid
      frontpageImage {
        id
        url
      }
      user {
        id
        fullName
        phone
        user {
          id
          email
        }
      }
      cooperativeName
      administrationAssociationName
      annualAccounts {
        id
        url
      }
      energyLabel {
        id
        url
      }
      rulesRegulations {
        id
        url
      }
      houseRules {
        id
        url
      }
      latestBoardMeetingReport {
        id
        url
      }
      fullAddress
      zip: zipCode
      city
      roomsCount
      size: apartmentSquareMetres
      initialPrice: initialPrice
      enhancementsValue
      specialInventoryValue
      deductionForShortcomings
      monthlyFee
      additionalFees
      utilitiesWater
      utilitiesHeating
      utilitiesAdditional
      caseDescription: description
      externalPhotographer
      fullService
      isActive
      approvedByRealtor
      documentUrl
      documents {
        id
        name
        url
      }
      hasDocuments
      latitude
      longitude
      caseimages: selfsalecaseimages {
        id
        url
        size2Url: url
        size3Url: url
      }
      facilities {
        id
        facilityDescription
      }
    }
  }
`

export const BUYER_CASE_QUERY = gql`
  query buyerCase($id: ID!) {
    buyerCase(id: $id) {
      id
      uuid
      minFloorArea
      maxFloorArea
      minRooms
      maxRooms
      minMonthlyFee
      maxMonthlyFee
      minPrice
      maxPrice
      requirements {
        id
      }
      preferredAreas {
        id
      }
    }
  }
`

export const TOKEN_IS_VALID_QUERY = gql`
  query tokenIsValid($token: String!) {
    tokenIsValid(token: $token) {
      id
    }
  }
`

/// SWAP CASE QUERIES
export const SWAP_CASE_MATCHES_QUERY = gql`
  query swapCaseMatches {
    swapCaseMatches {
      id
      uuid
      matchPercent
      matchOther
      matchTotal
      longitude
      latitude
    }
  }
`

export const ACTIVE_SWAP_CASES_QUERY = gql`
  query allActiveSwapCases {
    allActiveSwapCases {
      id
      uuid
      cooperativeName
      administrationAssociationName
      addressStreetName
      addressNumber
      addressFloor
      zipCode
      city
      apartmentSquareMetres
      roomsCount
      expectedApartmentValue
      heading
      description
      monthlyFee
      utilitiesWater
      utilitiesHeating
      frontpageImage {
        id
        url
        filename
        sortOrder
      }
      swapcaseimages {
        id
        url
        size2Url: url
        filename
        sortOrder
      }
    }
  }
`
// a smaller query to get only the necessary data
export const SWAP_CASE_QUERY_CASE_LIST = gql`
  query swapCaseByUuid($caseUuid: String!) {
    swapCaseByUuid(caseUuid: $caseUuid) {
      id
      uuid
      addressStreetName
      zipCode
      city
      apartmentSquareMetres
      roomsCount
      expectedApartmentValue
      matchPercent
      matchOther
      matchTotal
      swapcaseimages {
        id
        url
        size2Url: url
        filename
        sortOrder
      }
      frontpageImage {
        id
        url
        filename
        sortOrder
      }
      longitude
      latitude
    }
  }
`

export const SWAP_CASE_QUERY_CASE_PAGE = gql`
  query swapCaseByUuid($caseUuid: String!) {
    swapCaseByUuid(caseUuid: $caseUuid) {
      id
      uuid
      userEmail
      userFirstName
      user {
        id
      }
      cooperativeName
      administrationAssociationName
      addressStreetName
      addressNumber
      addressFloor
      zipCode
      city
      apartmentSquareMetres
      roomsCount
      expectedApartmentValue
      heading
      description
      monthlyFee
      utilitiesWater
      utilitiesHeating
      externalPhotographer
      termsAccepted
      matchPercent
      matchOther
      matchTotal
      fullAddress
      facilities {
        id
        facilityDescription
      }
      swapcaseimages {
        id
        url
        size2Url: url
        filename
        sortOrder
      }
      frontpageImage {
        id
        url
        filename
        sortOrder
      }
      minFloorArea
      maxFloorArea
      minRooms
      maxRooms
      minPrice
      maxPrice
      minMonthlyFee
      maxMonthlyFee
      requirements {
        id
      }
      preferredAreas {
        id
      }
      annualAccounts {
        id
        url
        filename
      }
      energyLabel {
        id
        url
        filename
      }
      rulesRegulations {
        id
        url
        filename
      }
      houseRules {
        id
        url
        filename
      }
      latestBoardMeetingReport {
        id
        url
        filename
      }
      nextYearsBudget {
        id
        url
        filename
      }
      longitude
      latitude
      documents {
        id
        url
        name
        extension
      }
      hasDocuments
      isRequestSentToUser
      isRequestReceivedFromUser
      swapResponse {
        id
        isInterested
        isMatch
      }
    }
  }
`

export const NEW_MATCHING_SWAP_CASES = gql`
  query newMatchingSwapCases {
    newMatchingSwapCases {
      id
      uuid
    }
  }
`

export const SWAP_MATCHES = gql`
  query swapMatches {
    swapMatches {
      id
      uuid
    }
  }
`

export const SWAP_RESPONSE_FILTER_QUERY = gql`
  query swapResponses($swapCaseOwn_Uuid: String, $swapCaseOther_Uuid: String, $isInterested: Int) {
    swapResponses(
      swapCaseOwn_Uuid: $swapCaseOwn_Uuid
      swapCaseOther_Uuid: $swapCaseOther_Uuid
      isInterested: $isInterested
    ) {
      isInterested
      swapCaseOther {
        uuid
      }
      swapCaseOwn {
        uuid
      }
    }
  }
`

export const ALL_FACILITIES_QUERY = gql`
  query allFacilities {
    availableFacilities {
      id
      facilityDescription
      order
    }
  }
`

export const FILTERED_FACILITIES_QUERY = gql`
  query filteredFacilities(
    $showOnSelfSale: Boolean
    $showOnBuyerCase: Boolean
    $showOnSwapCase: Boolean
    $showAsSwapCaseUltimateRequirement: Boolean
  ) {
    filteredFacilities(
      showOnSelfSale: $showOnSelfSale
      showOnBuyerCase: $showOnBuyerCase
      showOnSwapCase: $showOnSwapCase
      showAsSwapCaseUltimateRequirement: $showAsSwapCaseUltimateRequirement
    ) {
      id
      facilityDescription
      order
    }
  }
`

export const AVAILABLE_FACILITIES_UB_USER = gql`
  query availableFacilitiesUbUser {
    availableFacilitiesUbUser {
      id
      facilityDescription
    }
  }
`

export const AVAILABLE_FACILITIES_SWAP = gql`
  query availableFacilitiesSwap {
    availableFacilitiesSwap {
      id
      facilityDescription
    }
  }
`

export const AVAILABLE_FACILITIES_SWAP_REQUIREMENTS = gql`
  query availableFacilitiesSwapRequirements {
    availableFacilitiesSwapRequirements {
      id
      facilityDescription
    }
  }
`

export const ALL_AREAS_QUERY = gql`
  query allAreas {
    allAreas {
      id
      name
    }
  }
`

export const ALL_REGIONS_QUERY = gql`
  query allAreas {
    allRegions {
      id
      name
      areas {
        id
        name
      }
    }
  }
`

export const SUBSCRIPTION_STATUS_QUERY = gql`
  query ubUserReepayQuery {
    ubUser {
      id
      subscriptionStatus
    }
  }
`

export const INVOICES_QUERY = gql`
  query ubUserReepayQuery {
    ubUser {
      id
      invoices
    }
  }
`

export const PAYMENT_METHODS_QUERY = gql`
  query ubUserReepayQuery {
    ubUser {
      id
      paymentMethods
    }
  }
`

export const UB_USER_QUERY = gql`
  query ubUserQuery {
    ubUser {
      id
      firstName
      lastName
      verified
      phone
      profileDescription
      reepaySubscriptionHandle
      isTestUser
      hasConfirmedIsApartmentOwner
      profileImage {
        id
        url
      }
      user {
        id
        email
      }
      buyerCase {
        id
        minFloorArea
        maxFloorArea
        minRooms
        maxRooms
        minMonthlyFee
        maxMonthlyFee
        minPrice
        maxPrice
        profileDescription
        wizardCompleted
        termsAccepted
        wantLoanProposal
        loanProposalContactTimespan
        loanProposalTermsAccepted
        profileImage {
          id
          url
        }
        requirements {
          id
        }
        preferredAreas {
          id
        }
      }
      swapCase {
        id
        uuid
        cooperativeName
        administrationAssociationName
        addressStreetName
        addressNumber
        addressFloor
        zipCode
        apartmentSquareMetres
        roomsCount
        expectedApartmentValue
        heading
        description
        monthlyFee
        utilitiesWater
        utilitiesHeating
        externalPhotographer
        termsAccepted
        status
        facilities {
          id
        }
        swapcaseimages {
          id
          url
          filename
          sortOrder
        }
        swapCasesInterestedIn {
          id
        }
        mutualMatchingSwapCases {
          id
        }
        minFloorArea
        maxFloorArea
        minRooms
        maxRooms
        minPrice
        maxPrice
        minMonthlyFee
        maxMonthlyFee
        requirements {
          id
        }
        preferredAreas {
          id
        }
        annualAccounts {
          id
          url
          filename
        }
        energyLabel {
          id
          url
          filename
        }
        rulesRegulations {
          id
          url
          filename
        }
        houseRules {
          id
          url
          filename
        }
        latestBoardMeetingReport {
          id
          url
          filename
        }
        nextYearsBudget {
          id
          url
          filename
        }
        requestSenderSwapCases {
          id
          uuid
        }
        swapRequestsAsSender {
          id
          recipientSwapCase {
            uuid
          }
        }
        swapRequestsAsRecipient {
          id
          senderSwapCase {
            uuid
          }
        }
      }
      selfSaleCase {
        id
        caseId
        status
        cooperativeName
        administrationAssociationName
        fullAddress
        zipCode
        apartmentSquareMetres
        roomsCount
        apartmentValue
        enhancementsValue
        specialInventoryValue
        deductionForShortcomings
        additionalFees
        monthlyFee
        utilitiesHeating
        utilitiesWater
        utilitiesAdditional
        description
        externalPhotographer
        fullService
        wizardCompletedTermsAccepted
        isActive
        approvedByRealtor
        selfsalecaseimages {
          id
          url
          filename
          sortOrder
        }
        facilities {
          id
        }
        annualAccounts {
          id
          url
          filename
        }
        energyLabel {
          id
          url
          filename
        }
        rulesRegulations {
          id
          url
          filename
        }
        houseRules {
          id
          url
          filename
        }
        latestBoardMeetingReport {
          id
          url
          filename
        }
        nextYearsBudget {
          id
          url
          filename
        }
      }
    }
  }
`

export const NEW_CASES_QUERY = gql`
  query newCasesQuery {
    newCases {
      caseId
      id
      fullAddress
      street
      number
      floorSide
      livingSpace
      city
      caseHeadline
      status
      roomsCount
      initialPrice
      openHouseUpcoming
      isUnderBuyerRetraction
      frontpageImage {
        id
        url: size2Url
      }
      caseimages {
        id
        url: size2Url
        frontpage
        caption
      }
    }
  }
`
export const SITE_TEXT_QUERY = gql`
  query siteText($textId: String!) {
    siteText(textId: $textId) {
      id
      textId
      headline
      text
    }
  }
`

// TESTING SWAP CONFIG
export const MATCH_ALGORITHM_QUERY_2 = gql`
  query SwapAlgorithmMatch(
    $minRooms: Int
    $maxRooms: Int
    $minFloor_area: Int
    $maxFloor_area: Int
    $minPrice: Int
    $maxPrice: Int
    $minMontlhyFee: Int
    $maxMonthlyFee: Int
    $roomsCount: Int
    $livingSpace: Int
    $price: Int
    $zipCode: Int
    $monthlyFee: Int
  ) {
    swapAlgorithmMatch(
      minRooms: $minRooms
      maxRooms: $maxRooms
      minFloorArea: $minFloorArea
      maxFloorArea: $maxFloorArea
      minPrice: $minPrice
      maxPrice: $maxPrice
      minMontlhyFee: $minMontlhyFee
      maxMonthlyFee: $maxMonthlyFee
      roomsCount: $roomsCount
      livingSpace: $livingSpace
      price: $price
      zipCode: $zipCode
      monthlyFee: $monthlyFee
    )
  }
`

// TESTING SWAP CONFIG
export const MATCH_ALGORITHM_QUERY = gql`
  query SwapAlgorithmMatch(
    $minRooms: Int
    $maxRooms: Int
    $minFloorArea: Int
    $maxFloorArea: Int
    $minPrice: Int
    $maxPrice: Int
    $minMontlhyFee: Int
    $maxMonthlyFee: Int
    $requirements: [ID]
    $preferredAreas: [ID]
  ) {
    swapAlgorithmMatch(
      minRooms: $minRooms
      maxRooms: $maxRooms
      minFloorArea: $minFloorArea
      maxFloorArea: $maxFloorArea
      minPrice: $minPrice
      maxPrice: $maxPrice
      minMontlhyFee: $minMontlhyFee
      maxMonthlyFee: $maxMonthlyFee
      requirements: $requirements
      preferredAreas: $preferredAreas
    )
  }
`

import React from 'react';
import { Link } from 'react-router-dom';
import { URLS } from 'urls'
import { NEW_MATCHING_SWAP_CASES, SWAP_CASE_MATCHES_QUERY, SWAP_CASE_QUERY_CASE_PAGE } from "queries";
import { Query } from "react-apollo";
import SwapApartment from "components/pages/Swap/SwapApartment";
import { mapSwapApartmentProps } from "components/pages/Swap/swapHelpers";
import SwapApartmentCard from "components/pages/Swap/SwapApartmentCard";

const bgUrl = 'assets/img/img-swap-front.jpg'


const SwapHome = () => {
    return (
      <div>
        <Query query={NEW_MATCHING_SWAP_CASES}>
          {({loading, error, data}) => {
            if (loading) return <div className="section--loading"/>
            if (error) return `Error! ${error.message}`
            const {newMatchingSwapCases} = data
            const numCases = newMatchingSwapCases.length
            const casesText = newMatchingSwapCases.length === 1 ? 'ny byttebolig ' : 'nye bytteboliger '
            const numCards = Math.min(4, newMatchingSwapCases.length + 1)
            const maxAngle = 15

            return (
              <section className={"section animate--in apartments-grid grid "}>
                <div className="container">
                  <div className="row">
                    <div className="col-s-12 col-m-6 u-align-self-center">
                      {numCases === 0 ? <h2 className="u-no-margin animate--down animate--slow">Vi har i øjeblikket <span
                        className="u-bold-text u-lightgreen-text">ingen</span> {casesText}
                        som matcher din bolig</h2> : <h2 className="u-no-margin animate--down animate--slow">Vi har i øjeblikket <span
                        className="u-bold-text u-lightgreen-text">{numCases}</span> {casesText}
                        som matcher din bolig</h2>}
                      <p>Du kan altid se evt. matches og redigere i dine ønsker på din bytteprofil</p>
                    </div>
                    <div className="col-s-12 col-m-6">
                      <div className="flex-column">
                        {numCases>0 ?
                        <div className="stacked-images animate--in">
                          {newMatchingSwapCases.slice(0, numCards).map((item, index) => {
                              const swapCaseUuid = item.uuid
                              return <Query key={index} query={SWAP_CASE_QUERY_CASE_PAGE}
                                            variables={{caseUuid: swapCaseUuid}} fetchPolicy="network-and-cache">
                                {({loading, error, data}) => {
                                  if (loading) {
                                    return ""
                                  }
                                  const {swapCaseByUuid} = data
                                  const mappedProps = mapSwapApartmentProps(swapCaseByUuid)
                                  return <div style={{transform: `rotate(${maxAngle / (numCards) * index - maxAngle / 2}deg)`}}><SwapApartmentCard loading={loading} key={index} {...swapCaseByUuid} {...mappedProps}/></div>
                                }}
                              </Query>
                            }
                          )
                          }
                        </div> : ""}

                        {numCases > 0 ? <Link className="button center"
                                              to={URLS.SWAP.NEW}>Se dem her</Link> : <Link className="button center"
                                                                                           to={URLS.PROFILE.SWAP}>Gå til bytteprofil</Link>}

                      </div>
                    </div>
                  </div>
                </div>
              </section>

            );
          }}
        </Query>
      </div>
    )
}


SwapHome.displayName = 'SwapHome';
SwapHome.propTypes = {};
SwapHome.defaultProps = {};


export default SwapHome;

import React from 'react';
import {graphql} from 'react-apollo'
import {connect} from 'react-redux'
import {ACTIVE_CASES_QUERY} from 'queries'
import Apartment from './Apartment'

const SimilarCases = ({data}) => {
    if (data.loading) return <div className="section--loading"></div>
    if (data.error) return <div>{data.error.message}</div>
    const sixActiveCases = data.allActiveCases.slice(0, 3)

    return (
        <section className="section apartments-grid grid similar-cases">
            <div className="container">
                <div className="apartments-grid__wrapper">
                    <div className="apartments-grid__apartments">
                        <h2 className="u-lightgreen-text">Flere boliger</h2>
                        <div className="row">
                            {sixActiveCases.map((item, index) => <Apartment key={index} {...item} />)}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

SimilarCases.displayName = 'SimilarCases';
SimilarCases.propTypes = {};
SimilarCases.defaultProps = {};


const SimilarCasesWithData = graphql(ACTIVE_CASES_QUERY, {})(SimilarCases)


export default connect()(SimilarCasesWithData)


const initialState = {
  selected: "HOME",
  showMenu: false
}
export default function reducer(state=initialState, action) {
  switch(action.type) {
    case "SELECT_NAV_ITEM": {
      return {...state, selected: action.payload}
    }
    case "TOGGLE_MENU": {
      return {...state, showMenu: !state.showMenu}
    }
  }
  
  return state
}

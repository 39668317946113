import gql from "graphql-tag"

export const ADD_UB_USER_MUTATION = gql`
  mutation addUbUserMutation($input: AddUnikBoligsalgUserInput!) {
    addUbUser(input: $input) {
      validationErrors
      ubUser {
        user {
          email
        }
        firstName
        lastName
      }
    }
  }
`

export const VERIFY_USER_MUTATION = gql`
  mutation activateUserMutation($input: ActivateUbUserMutationInput!) {
    activateUbUser(input: $input) {
      clientMutationId
      ubUser {
        id
        verified
      }
    }
  }
`

export const CHANGE_USER_MUTATION = gql`
  mutation changeUbUserProfile($input: ModifyUnikBoligsalgUserInput!) {
    changeUbUserProfile(input: $input) {
      clientMutationId
      ubUser {
        id
        firstName
        lastName
        phone
        profileDescription
        profileImage {
          id
          url
        }
        verified
        user {
          id
          email
        }
      }
    }
  }
`

export const DELETE_USER_MUTATION = gql`
  mutation deleteUser($email: String!) {
    deleteUser(email: $email) {
      success
    }
  }
`

export const ADD_OR_MODIFY_BUYER_CASE_MUTATION = gql`
  mutation addOrModifyBuyerCase($input: AddOrModifyBuyerCaseInput!) {
    addOrModifyBuyerCase(input: $input) {
      clientMutationId
      buyerCase {
        id
        minFloorArea
        maxFloorArea
        minRooms
        maxRooms
        minMonthlyFee
        maxMonthlyFee
        minPrice
        maxPrice
        profileDescription
        wantLoanProposal
        loanProposalContactTimespan
        loanProposalTermsAccepted
        profileImage {
          id
          url
        }
        requirements {
          id
        }
        preferredAreas {
          id
        }
      }
    }
  }
`

export const ADD_SELF_SALE_CASE_MUTATION = gql`
  mutation addSelfSale($input: AddSelfSaleCaseInput!) {
    addSelfSale(input: $input) {
      clientMutationId
    }
  }
`

export const MODIFY_SELF_SALE_CASE_MUTATION = gql`
  mutation modifySelfSale($input: ModifySelfSaleCaseInput!) {
    modifySelfSale(input: $input) {
      clientMutationId
      selfSaleCase {
        facilities {
          id
        }
      }
    }
  }
`

export const DELETE_SELF_SALE_CASE_DOCUMENT = gql`
  mutation deleteSelfSaleCaseDocument($input: DeleteSelfSaleCaseDocumentInput!) {
    deleteSelfSaleCaseDocument(input: $input) {
      clientMutationId
      selfSaleCase {
        id
      }
    }
  }
`

export const DELETE_SELF_SALE_CASE_IMAGE = gql`
  mutation removeSelfSaleCaseImage($input: RemoveSelfSaleImageBySellerInput!) {
    removeSelfSaleCaseImage(input: $input) {
      clientMutationId
    }
  }
`

/// SWAP CASE
export const ADD_SWAP_CASE_MUTATION = gql`
  mutation addSwapCase($input: AddSwapCaseInput!) {
    addSwapCase(input: $input) {
      clientMutationId
    }
  }
`

export const MODIFY_SWAP_CASE_MUTATION = gql`
  mutation modifySwapCase($input: ModifySwapCaseInput!) {
    modifySwapCase(input: $input) {
      clientMutationId
      swapCase {
        facilities {
          id
        }
      }
    }
  }
`

export const DELETE_SWAP_CASE_IMAGE = gql`
  mutation removeSwapCaseImage($input: RemoveSwapImageBySellerInput!) {
    removeSwapCaseImage(input: $input) {
      clientMutationId
    }
  }
`

export const DELETE_SWAP_CASE_DOCUMENT = gql`
  mutation deleteSwapCaseDocument($input: DeleteSwapCaseDocumentInput!) {
    deleteSwapCaseDocument(input: $input) {
      clientMutationId
      swapCase {
        id
      }
    }
  }
`

export const CREATE_AND_SEND_SWAP_REQUEST = gql`
  mutation createAndSendSwapRequest($input: CreateAndSendSwapRequestInput!) {
    createAndSendSwapRequest(input: $input) {
      clientMutationId
      swapRequest {
        id
      }
    }
  }
`

// SWAP V2
/*

 */
export const NEW_SWAP_RESPONSE = gql`
  mutation newSwapResponse($input: NewSwapResponseInput!) {
    newSwapResponse(input: $input) {
      clientMutationId
      swapResponse {
        id
      }
    }
  }
`

export const UPDATE_SWAP_RESPONSE = gql`
  mutation UpdateSwapResponse($swapResponseId: ID!, $isInterested: Int!) {
    updateSwapResponse(swapResponseId: $swapResponseId, isInterested: $isInterested) {
      swapResponse {
        id
        isInterested
      }
    }
  }
`

// SOME PASSWORD STUFF
export const REQUEST_RESET_PASSWORD_LINK = gql`
  mutation requestResetPasswordLink($input: RequestResetPasswordLinkInput!) {
    requestResetPasswordLink(input: $input) {
      clientMutationId
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      success
    }
  }
`

export const RESEND_ACTIVATION_CODE = gql`
  mutation resendActivationCode($input: ResendActivationCodeInput!) {
    resendActivationCode(input: $input) {
      clientMutationId
    }
  }
`

export const REEPAY_SUBSCRIBE = gql`
  mutation subscribeUbUser($input: SubscribeUnikBoligsalgUserInput!) {
    subscribeUbUser(input: $input) {
      clientMutationId
    }
  }
`

export const REEPAY_UNSUBSCRIBE = gql`
  mutation unsubscribeUbUser($input: UnsubscribeUnikBoligsalgUserInput!) {
    unsubscribeUbUser(input: $input) {
      clientMutationId
    }
  }
`

export const SUBMIT_CASE_CONTACT_FORM = gql`
  mutation newCaseContact($input: NewCaseContactInput!) {
    newCaseContact(input: $input) {
      clientMutationId
    }
  }
`

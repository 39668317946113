import React from 'react';
import {Link} from 'react-router-dom'
import {URLS} from 'urls'
import * as actions from 'actions'
import {connect} from 'react-redux'

const PhotoPackageInfoModal = ({dispatch, data}) => {
    const {nextUrl} = data
    return (
        <div className="photopackageinfomodal-component">
            <h2>Du har bestilt fotopakke Pro - Godt valg!</h2>
            <p>Vores fotofirma vil kontakte dig per mobil, for at aftale en tid for fotografering af boligen.</p>
            <p>Vi glæder os til at se de lækre fotos og plantegning.</p>
            <Link onClick={() => dispatch(actions.setVisibleModal(null))} className="button"
                  to={nextUrl}>Ok - det lyder godt</Link>
        </div>
    );
}

PhotoPackageInfoModal.displayName = 'PhotoPackageInfoModal';
PhotoPackageInfoModal.propTypes = {};
PhotoPackageInfoModal.defaultProps = {};

const mapStateToProps = state => ({
    data: state.app.visibleModalData
})


export default connect(mapStateToProps)(PhotoPackageInfoModal);

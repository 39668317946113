import React from 'react';
import GoogleMapReact from 'google-map-react';
import {GMAP_API_KEY} from "../../../values";

const BuyMapComponent = ({street, streetNumber}) => {
    return (
    <div className="map__pin">
        <svg className="icon icon--map icon--lightgreen">
            <use xlinkHref="#icon-map"></use>
        </svg>
        <div className="map__pin__text">
            <span className="map__pin__street">
                {street}
            </span>
            <span className="map__pin__streetnumber">
                {streetNumber}
            </span>
        </div>
    </div>
    )
}
const API_KEY = GMAP_API_KEY

const createMapOptions = () => {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      styles: [{ stylers: [{ 'saturation': -100 }, { 'gamma': 0.8 }, { 'lightness': 4 }, { 'visibility': 'on' }] }]
  }
}        
const BuyMap = ({center, zoom, cases}) => {
    const GMap = <GoogleMapReact
        defaultCenter={center}
        defaultZoom={zoom}
        options={createMapOptions}
        bootstrapURLKeys={{
            key: API_KEY,
            language: 'da',
        }}>
      {cases.map((c,i) => {
        return (
        <BuyMapComponent 
          key={i}
          lat={c.latitude}
          lng={c.longitude}
          street={c.street}
          streetNumber={c.number} />
        )
      })}
    </GoogleMapReact>

    return (
    <div className="map">
        {GMap}
    </div>
    );

}


export default BuyMap

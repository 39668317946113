import React from "react"
import { Link } from "react-router-dom"
import Query from "data_dump"
import { URLS } from "urls"
import HTMLContent from "components/HTMLContent"

const mapUrl = "assets/img/img-map.jpg"
const bgUrl = "assets/img/img-buyerindex-front.jpg"

const BuyerIndexFront = () => {
  return (
    <div className="buyerindexfront-component">
      <section className="buyerindex">
        <div className="buyerindex__img bg-img bg-img--green" style={{ backgroundImage: `url( ${bgUrl} )` }}></div>
        <div className="container">
          <div className="row">
            <div className="col-s-12">
              <div className="buyerindex__wrapper">
                <div className="content-card">
                  <div className="row bordered-columns">
                    <div className="col-s-12 col-m-8 col-l-9">
                      <h1 className="sale__title title u-darkgreen-text">Få fordele med Waitly+</h1>
                      <h2 className="sale__title title u-green-text">
                        Gør boligjagten nemmere, hvis du søger en andelsbolig i København!
                      </h2>
                      <p>
                        Hvis du også synes at alting går stærkt på andelsboligmarkedet og det kan være svært at navigere
                        i - så er Waitly+ lige noget for dig!
                      </p>
                      <p>
                        Som medlem af Waitly+ får du fordele der hjælper dig godt fra start med boligjagten, hvad end du
                        leder efter en plads på listen til den helt rette forening eller måske har brug for det sidste
                        skub til at blive købeklar med Waitly's vidensbank.
                      </p>
                      <h4>Hvad er Waitly?</h4>
                      <p>
                        Vores samarbejdspartner, Waitly, faciliterer interesse- og ventelister for både andelsboliger,
                        lejeboliger og kolonihaver og repræsenterer mere end 15.000 andelsboliger og 330 forskellige
                        foreninger og udlejere.
                      </p>
                      <p>
                        Waitly har udviklet en medlemsklub, hvor du som boligsøgende på andelsboligmarkedet får en
                        hjælpende hånd, så du kan opnå boligdrømmen før andre!
                      </p>
                      <h4 className="inko">Få fat i den helt rette andelsbolig…</h4>
                      <p>
                        Andelshandel er mægler for andelshavere der sælger deres andelsbolig, og som medlem af Waitly+
                        får du tidligere adgang til nye boliger, altså inden de kommer op på vores hjemmeside
                        (andelshandel.dk), og nogle boliger vil aldrig nå at blive annonceret offentligt.
                      </p>
                      <div className="partner__list">
                        <h4 className="">Hvilke fordele er der?</h4>

                        <ul>
                          <li>
                            <p> Få tidligere adgang til nye boliger fra blandt andet Andelshandel.dk</p>{" "}
                          </li>
                          <li>
                            <p>Få besked om nye lister og foreninger 24 timer før alle andre</p>
                          </li>
                          <li>
                            <p>Få besked kl. 07 hver morgen om nye pladser på lukkede lister</p>
                          </li>
                          <li>
                            <p>Adgang til sneak-peek og vidensbank på Waitlys platform</p>
                          </li>
                        </ul>
                      </div>

                      {/* <HTMLContent textId="buyer_index_page" withHeadline={true} /> */}
                    </div>
                    <div className="col-s-12 col-m-4 col-l-3">
                      <div className="buyerindex__sidebar sidebar">
                        <div className="flex-center">
                          <span className="price-bubble price-bubble--big u-darkgreen">
                            <span className="number">69</span>
                            kr./md.*
                            <svg className="icon icon--price-bubble icon--darkgreen">
                              <use href="#icon-price-bubble"></use>
                            </svg>
                          </span>
                        </div>
                        <h3>Fordele med Waitly+</h3>
                        <ul>
                          <li>Eksklusive salgsopstillinger før alle andre.</li>
                          <li>Få besked 24 timer før alle andre om nye lister og foreninger.</li>
                          <li>Adgang til sneak-peek og vidensbank på Waitly.</li>
                        </ul>
                        {/* <Link className="button" to={URLS.BUYER_INDEX.CREATE.FRONT}>Køb adgang nu</Link> */}
                        <a className="button" href="https://waitly.dk/waitlyplus">
                          Tilmeld dig nu
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section--map">
        <div className="buyerindex__map" style={{ backgroundImage: `url(${mapUrl})` }}></div>
      </section>
    </div>
  )
}

BuyerIndexFront.displayName = "BuyerIndexFront"
BuyerIndexFront.propTypes = {}
BuyerIndexFront.defaultProps = { query: Query }

export default BuyerIndexFront

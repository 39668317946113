import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import CheckListItem from 'components/shared/CheckListItem'
import * as actions from 'actions'


const list = [
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis lectus",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis lectus",
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis lectus"
]

const TotalSalePage = ({dispatch}) => {
    const onToggleModal = () => dispatch(actions.setVisibleModal('SELF_SALE_FRONT_MODAL'))
    return (
      <div className="selfsale__front">
        <section className="section subsection u-lightgrey">
            <div className="container">
                <div className="row">
                    <div className="col-s-12 col-l-6 col-l-push-3 ">
                        <div className="flex-column u-center-text">
                            <img src="/assets/img/img-house.png" className="icon icon--padded icon--house"/>
                            <h1 className="u-green-text">Perfekt, vi hjælper dig igennem dit medsalg.</h1>
                            <button className="button" onClick={onToggleModal}>lad os gå igang</button>
                        </div>
                    </div>
                </div>
            </div>      
        </section>
        <section className="section u-white">
            <span className="price-bubble u-darkgreen">32500
                <svg className="icon icon--price-bubble icon--darkgreen">
                    <use href="#icon-price-bubble"></use>
                </svg>
            </span>
            <div className="container">
                <div className="row">
                    <div className="col-s-12 col-l-6 col-l-push-3 ">
                        <div className="flex-column u-center-text">
                            <h2 className="u-green-text">Medsalg er for dig  der vil selv.</h2>
                            <h3 className="u-light-text u-lightgreen-text">Vi garanterer:</h3>
                            <ul className="checklist">
                                { list.map( (string, index) => <CheckListItem counter={false} key={index} label={string} /> )}
                            </ul>
                        </div>
                    </div>
                </div>      
            </div>
        </section>
        <section className="section u-lightgrey">
            <div className="container">
                <div className="row">
                    <div className="col-s-12 col-l-6 col-l-push-3 ">
                        <div className="flex-column u-center-text">
                            <h3 className="u-light-text u-green-text">Det skal du huske</h3>
                            <ul className="checklist">
                                { list.map( (string, index) => <CheckListItem counter={true} key={index} label={string} /> )}
                            </ul>
                            <h2 className="u-green-text">Er du stadig i tvivl</h2>
                            <button className="button" onClick={onToggleModal}>lad os gå igang</button>
                        </div>
                    </div>
                </div>
            </div>      
        </section>
        <section className="section u-darkgreen">
            <div className="container">
                <div className="row">
                    <div className="col-m-6">
                        <h1 className="u-lightgreen-text"><strong>Vidste du at...</strong></h1>
                        <p className="u-white-text">Andelshandel hjælper dig med alt praktikken i vores medsalgs løsning.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis lectuLorem ipsum dolor sit amet, consconsectetur adipiscing elit. Fusce quis lectuLorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce quis lectusLorem</p>
                        <Link to={'/'} className="button">Fortæl mig mere</Link>
                    </div>
                    <div className="col-m-6">
                        <ul className="checklist">
                            { list.map( (string, index) => <CheckListItem counter={false} key={index} label={string} /> )}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
      </div>
    );
}

TotalSalePage.displayName = 'TotalSalePage';
TotalSalePage.propTypes = {};
TotalSalePage.defaultProps = {};

const mapStateToProps = state => {
  return state.sale
}

export default connect(mapStateToProps)(TotalSalePage);
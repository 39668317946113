import React from 'react';
import {Link} from 'react-router-dom';
import {URLS} from 'urls'
import HTMLContent from 'components/HTMLContent'
import {ReactComponent as I1User} from './icons/1-bruger-icon.svg';
import {ReactComponent as I2Create} from './icons/2-opret-bolig-icon.svg';
import {ReactComponent as I3Request} from './icons/3-anmod-icon.svg';
import {ReactComponent as I4Agree} from './icons/4-enighed-icon.svg';
import {ReactComponent as I5Deal} from './icons/5-tryghedsaftale-icon.svg';
import SimilarSwapCases from './SimilarSwapCases'

const bgUrl = '/assets/img/img-swap-front.jpg'


const SwapProcessItem = ({label, iconComponent, number}) => (
    <div className="swap-process-item">
        {iconComponent}
        <div className="swap-process-item-number u-green-text">{number}</div>
        <div className="swap-process-item-label">{label}</div>
    </div>
)


const SwapProcess = () => (
    <section className="swap-process-box u-lightgrey">
        <div className="container">
            <h2 className="swap-process-title u-green-text u-center">5 simple skridt til du har byttet din andelsbolig</h2>
            <div className="swap-process-container">
                <SwapProcessItem number="1" label="Opret dig som bruger" iconComponent={<I1User/>}/>
                <span className="swap-process-line"/>

                <SwapProcessItem number="2" label="Opret din bolig" iconComponent={<I2Create/>}/>
                <span className="swap-process-line"/>
                <SwapProcessItem number="3" label="Søg bolig og anmod om kontakt til ejer"
                                 iconComponent={<I3Request/>}/>
                <span className="swap-process-line"/>
                <SwapProcessItem number="4" label="Gensidig fremvisning, forhandling og enighed om bytte"
                                 iconComponent={<I4Agree/>}/>
                <span className="swap-process-line"/>
                <SwapProcessItem number="5" label="Afsluttende Tryghedsaftale via Andelshandel´s salgsteam"
                                 iconComponent={<I5Deal/>}/>
            </div>

        </div>
    </section>
)


const SwapFrontPage = () => {
    return <div>
        <section className="buyerindex animate--down">
            <div className="buyerindex__img bg-img bg-img--green" style={{backgroundImage: `url( ${bgUrl} )`}}></div>


            <SwapProcess/>
            <div className="container">
                <div className="row">
                    <div className="col-s-12">
                        <div className="buyerindex__wrapper">
                            <div className="content-card">
                                <div className="row bordered-columns">
                                    <div className="col-s-12 col-m-8 col-l-9">

                                        <HTMLContent textId="swap_index_page" withHeadline={true}/>
                                    </div>
                                    <div className="col-s-12 col-m-4 col-l-3">
                                        <div className="buyerindex__sidebar sidebar">
                                            <div className="flex-center">
                                                <span className="price-bubble price-bubble--big u-darkgreen">
                                                    <span className="number">0</span>
                                                    kr.
                                                    <svg className="icon icon--price-bubble icon--darkgreen">
                                                        <use href="#icon-price-bubble"></use>
                                                    </svg>
                                                </span>
                                            </div>
                                            <h3>Værd at vide</h3>
                                            <ul>
                                                <li>Du kan oprette din byttebolig - kvit og frit!</li>
                                                <li>Søg på boligmatch - nemt og enkelt</li>
                                                <li>Det kræver din byttebolig er oprettet for at se andre bytteboliger
                                                </li>
                                            </ul>
                                            <Link className="button" to={URLS.SWAP.CREATE.S1_USER}>Opret din byttebolig
                                                nu!</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <SimilarSwapCases/>

                {/*<div className="flex-center" style={{position: "relative", top: '-100px'}}>*/}
                {/*    <Link className="button center" to={URLS.SWAP.CREATE.S1_USER}>Opret din byttebolig*/}
                {/*        nu!</Link>*/}
                {/*</div>*/}
            </div>

        </section>

    </div>
}


SwapFrontPage.displayName = 'SwapFrontPage';
SwapFrontPage.propTypes = {};
SwapFrontPage.defaultProps = {};


export default SwapFrontPage;

import React from "react"
import { NavLink, withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { graphql } from "react-apollo"
import * as actions from "actions"
import * as queries from "queries"
import { URLS, pathNameToMenuTitle } from "urls"
import * as _ from "lodash"

const navItems = [
  {
    label: "Køb",
    url: URLS.BUY.FRONT,
  },
  {
    label: "Køberservice",
    url: URLS.BUYER_INDEX.FRONT,
  },
  {
    label: "Sælg",
    url: URLS.SALE.FRONT,
  },
  {
    label: "Bytte",
    url: URLS.SWAP.FRONT,
  },
  {
    label: "Partnere",
    url: URLS.PARTNERS,
  },
  {
    label: "Kontakt",
    url: URLS.CONTACT,
  },
]

const getNavItems = (testMode = false) => {
  return testMode ? navItems : navItems //navItems.filter(x=>x.label!=="Bytte")
}

const navProfileItems = [
  {
    label: "Min konto",
    url: URLS.PROFILE.HOME,
  },
  // {
  //   label: "Mit køberservice",
  //   url: URLS.PROFILE.BUYER_INDEX,
  // },
  {
    label: "Mit selvsalg",
    url: URLS.PROFILE.SELF_SALE,
  },
  {
    label: "Log ud",
    modal: "LOGOUT",
  },
]

const navProfileItemsForUSer = (user) => {
  return [
    {
      label: "Min konto",
      url: URLS.PROFILE.HOME,
      className: "",
    },
    // user.buyerCase !== null && user.buyerCase.wizardCompleted
    //   ? {
    //       label: "Mit køberservice",
    //       url: URLS.PROFILE.BUYER_INDEX,
    //     }
    //   : {
    //       label: "Tilmeld Køberservice",
    //       url: URLS.BUYER_INDEX.CREATE.FRONT,
    //     },
    user.selfSaleCase !== null && user.selfSaleCase.wizardCompletedTermsAccepted
      ? {
          label: "Mit selvsalg",
          url: URLS.PROFILE.SELF_SALE,
          activeStyle: { backgroundColor: "#2fab66", color: "white" },
          activeClass: "active-case",
        }
      : {
          label: "Opret selvsalg",
          url: URLS.SALE.SELF_SALE.FRONT,
        },
    user.swapCase !== null && user.swapCase.termsAccepted
      ? {
          label: "Min bytteservice",
          url: URLS.PROFILE.SWAP,
          activeStyle: { backgroundColor: "#2fab66", color: "white" },
          activeClass: "active-case",
        }
      : {
          label: "Opret byttebolig",
          url: URLS.SWAP.FRONT,
        },
    {
      label: "Log ud",
      modal: "LOGOUT",
    },
  ]
}

const NavLogo = () => (
  <Link to={URLS.HOME} className="navigation-bar__logo">
    <img className="icon icon--unik-logo-text icon--white" src={"/assets/img/andelshandel-payoff-white.svg"} alt="" />
    <svg className="icon icon--green icon--unik-logo">
      <use xlinkHref="#unik-logo"></use>
    </svg>
  </Link>
)

const NavMisc = ({ currentPage }) => {
  return (
    <div className="navigation-bar__misc">
      <div className="navigation-bar__current-page u-light-text">{currentPage}</div>
      <span className="navigation-bar__loader"></span>
    </div>
  )
}

// NAV MENU

const NavMenu = ({ items }) => {
  return (
    <ul className="navigation-bar__menu">
      {items.map((item, index) => (
        <NavMenuItem key={index} to={item.url}>
          {item.label}
        </NavMenuItem>
      ))}
    </ul>
  )
}

const NavMenuItem = ({ to, children, selected }) => {
  return (
    <li className={selected ? "navigation-bar__menu__item is-active" : "navigation-bar__menu__item"}>
      <NavLink to={to} className="navigation-bar__menu__item__label">
        {children}
      </NavLink>
    </li>
  )
}

// NAV PROFILE

const NavProfile = ({ user, onClick, testMode }) => {
  const isWideScreen = window.innerWidth >= 1024
  if (user === null)
    return (
      <div className="navigation-bar__profile">
        {isWideScreen ? (
          <a onClick={onClick} className="button button--invert button--login green-text">
            LOG IND
          </a>
        ) : (
          <a onClick={onClick} className="navigation-bar__profile__label green-text">
            LOG IND
          </a>
        )}
      </div>
    )
  const { firstName, lastName } = user
  let profileItems = testMode || user.isTestUser ? navProfileItemsForUSer(user) : navProfileItemsForUSer(user) //navProfileItemsForUSer(user).filter(x => x.label.indexOf('bytte') === -1)
  return (
    <div className="navigation-bar__profile">
      <a className="navigation-bar__profile__label green-text">
        {firstName} {lastName}
        <svg className="icon icon--green">
          <use xlinkHref="#icon-triangle"></use>
        </svg>
      </a>
      <ul className="navigation-bar__profile__sub-menu">
        {profileItems.map((item, index) => (
          <NavProfileItemWithState key={index} {...item}></NavProfileItemWithState>
        ))}
      </ul>
    </div>
  )
}

const NavProfileItem = ({ url, selected, modal, dispatch, label, activeStyle, activeClass }) => {
  const itemClass = selected
    ? `navigation-bar__profile__sub-menu__item ${activeClass} is-active`
    : `navigation-bar__profile__sub-menu__item ${activeClass}`
  return (
    <li className={itemClass} style={activeStyle}>
      {url !== undefined ? (
        <NavLink to={url} className="navigation-bar__profile__sub-menu__item__label">
          {label}
        </NavLink>
      ) : (
        <a
          className="navigation-bar__profile__sub-menu__item__label"
          onClick={() => dispatch(actions.setVisibleModal(modal))}>
          {label}
        </a>
      )}
    </li>
  )
}

const NavProfileItemWithState = connect()(NavProfileItem)

// LOG IN WINDOW LOCK
const NavLock = ({ onClick, user }) => {
  return (
    <button className={user === null ? "navigation-bar__lock" : "navigation-bar__lock is-logged-in"} onClick={onClick}>
      <svg className="icon icon--lock--bar icon--green">
        <use xlinkHref="#icon-lock-bar"></use>
      </svg>
      <svg className="icon icon--lock icon--green">
        <use xlinkHref="#icon-lock"></use>
      </svg>
    </button>
  )
}
{
  /*document.querySelector('.main-menu-is-open').style.transform = `translateY(${document.querySelector('.navigation-bar__inner').clientHeight}px)`*/
}

const NavToggle = ({ onMenuToggle }) => {
  return (
    <a onClick={onMenuToggle} id="menu-toggle">
      <span></span>
      <span></span>
      <span></span>
    </a>
  )
}

const TopNav = ({ loadingProcesses, data, actions, currentLocation, testMode }) => {
  const navigationClass = loadingProcesses > 0 || data.loading ? "navigation-bar is-loading" : "navigation-bar"
  const user = data.ubUser || null
  const navLockModal = user === null ? "LOGIN" : "LOGOUT"
  return (
    <nav className={navigationClass}>
      <div className="navigation-bar__header">
        <NavLogo />
        <NavMisc currentPage={pathNameToMenuTitle(currentLocation)} />
        <NavToggle onMenuToggle={() => actions.toggleMobileMenu()} />
      </div>
      <div className="navigation-bar__inner">
        <div className="navigation-bar__login">
          <NavProfile
            user={user}
            items={navProfileItems}
            onClick={() => actions.setVisibleModal("LOGIN")}
            testMode={testMode}
          />
          <NavLock user={user} onClick={() => actions.setVisibleModal(navLockModal)} />
        </div>
        <NavMenu items={getNavItems(testMode)} />
      </div>
    </nav>
  )
}

TopNav.displayName = "TopNav"
TopNav.propTypes = {}
TopNav.defaultProps = {}

const mapStateToProps = (state) => {
  return {
    ...state.app,
  }
}

const mapDispatchToActions = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  }
}

const TopNavWithData = graphql(queries.UB_USER_QUERY)(TopNav)

export default withRouter(connect(mapStateToProps, mapDispatchToActions)(TopNavWithData))

import React from 'react'
import * as utils from 'utils'
import ApartmentCard from 'components/shared/ApartmentCard'
import {SELF_SALE_CASE_TYPE_NAME} from "../../../values";

const Apartment = (props) => {
    const tempMapLocation = "assets/img/img-location.jpg"
    const url = utils.caseUrlFromTypename(props.__typename, props.caseId)
    const address =  props.__typename===SELF_SALE_CASE_TYPE_NAME ?
        props.fullAddress : utils.addressFromStreetNumberFloorSide(props.street, props.number, props.floorSide)
    const mappedProps = {
        img: utils.ensure_ssl(utils.getFrontImage(props.caseimages, props.frontpageImage).url),
        floor_plan: utils.ensure_ssl(utils.getFrontPlandrawing(props.caseimages, props.plandrawings).url),
        map: tempMapLocation,
        duration: utils.daysSince(props.startSaleDate),
        address: address,
        zip_city: props.zip + " " + props.city,
        size: props.size,
        rooms: props.rooms,
        price: props.price,
        lat: props.latitude,
        lng: props.longitude,
        openHouseUpcoming: props.openHouseUpcoming,
        reserved: false,
        url: url,
        caseId: props.caseId,
        isSelfSale: props.__typename===SELF_SALE_CASE_TYPE_NAME,
        id: props.id,
        status: props.status
    }
    return (
        <div className="col-s-12 col-m-6 col-l-4">
            <ApartmentCard {...props} {...mappedProps} />
        </div>
    )
}

export default Apartment
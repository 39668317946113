import React, {useState} from 'react';
import SwapApartment from './SwapApartment'
import SwapMap from './SwapMap'
import {Query} from "react-apollo";
import { NEW_MATCHING_SWAP_CASES, SWAP_CASE_MATCHES_QUERY, SWAP_CASE_QUERY_CASE_LIST, SWAP_CASE_QUERY_CASE_PAGE } from "queries";
import {Link, withRouter} from 'react-router-dom';
import {URLS} from "urls";
import { CaseSection, SwapPageByUuid } from "components/pages/Swap/SwapPage";
import { mapSwapApartmentProps } from "components/pages/Swap/swapHelpers";
import SwapApartmentCard from "components/pages/Swap/SwapApartmentCard";



const mapPosition = {lat: 55.5875312, lng: 12.1102368}
const zoom = 10

const NoMore = () => (
  <section className={"section animate--in apartments-grid grid "}>
    <div className="container">
      <div className="row">
        <div className="col-s-12 col-m-6 u-align-self-center">
          <h2 className="u-no-margin animate--down animate--slow">Der er ikke flere nye bytteboliger i denne omgang</h2>
        </div>
        <div className="col-s-12 col-m-6 u-align-self-center">
          <p>
            <strong>Du kan til hver en tid gå til din profil og se dine matches, samt ændre i dine kriterier</strong>
          </p>
          <Link className="button center"
                to={URLS.PROFILE.SWAP}>Gå til min profil</Link>
        </div>
      </div>
    </div>
  </section>
)

const SwapResult = () => {
    const [mapIsActive, setMapIsActive] = useState(false)
    const [index, setIndex] = useState(0)
    return (
      <Query query={NEW_MATCHING_SWAP_CASES}>
          {({loading, error, data}) => {
            if (loading) return <div className="section--loading"></div>
            if (error) return `Error! ${error.message}`
            const {newMatchingSwapCases} = data
            if (newMatchingSwapCases.length > index) {
              const nextMatchingSwapCase = newMatchingSwapCases[index]
              return SwapPageByUuid({
                uuid: nextMatchingSwapCase.uuid, onNext: () => {
                  document.body.scrollTo({top: 0, left: 0, behavior: "smooth"})
                  setIndex(index + 1);
                }, index: index, countAll: newMatchingSwapCases.length
              })
            }
            return NoMore()
          }}
      </Query>
    )
}


SwapResult.displayName = 'SwapResult';
SwapResult.propTypes = {};
SwapResult.defaultProps = {};


export default SwapResult




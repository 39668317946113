import React from "react"
import CreateUser from "../../User/CreateUser"
import Activation from "../../User/Activation"
import Whishes from "./Whishes"
import Profile from "./Profile"
import Payment from "./Payment"
import LoanProposal1 from "./LoanProposal1"
import LoanProposal2 from "./LoanProposal2"
import DoesNotExist from "../../DoesNotExist"
import { graphql } from "react-apollo"

import * as queries from "queries"
import { URLS } from "urls"

import { Route, Switch, Redirect, withRouter } from "react-router-dom"

const CreateBuyerIndex = ({ data }) => {
  if (data.loading) return <span></span>

  // const user = data.ubUser

  // // let redirectUrl = (user) => {
  // //   // Redirect to create user if user is not logged in
  // //   if (user === null) return URLS.BUYER_INDEX.CREATE.USER
  // //   // Redirect to activation if user is not verified
  // //   else if (user.verified === false) return URLS.BUYER_INDEX.CREATE.ACTIVATION
  // //   // Already a buyercase
  // //   else return URLS.BUYER_INDEX.CREATE.WHISHES
  // // }

  // // const UserRedirect = () => {
  // //   const url = redirectUrl(user)
  // //   if (url !== null) return <Redirect to={redirectUrl(user)} />
  // //   return <span></span>
  // // }

  return (
    <div>
      <Switch>
        {/* <Route path={URLS.BUYER_INDEX.CREATE.FRONT} exact component={UserRedirect} /> */}
        {/* <Route
          path={URLS.BUYER_INDEX.CREATE.USER}
          render={(props) => <CreateUser {...props} successModal="USER_CREATED_BUYER_INDEX" />}
        /> */}
        {/* <Route
          path={URLS.BUYER_INDEX.CREATE.WHISHES}
          render={(props) => <Whishes {...props} user={user} loading={data.loading} />}
        /> */}
        {/* <Route path={URLS.BUYER_INDEX.CREATE.PROFILE} component={Profile} /> */}
        {/* <Route path={URLS.BUYER_INDEX.CREATE.LOAN_PROPOSAL2} component={LoanProposal2} /> */}
        {/* <Route path={URLS.BUYER_INDEX.CREATE.LOAN_PROPOSAL1} component={LoanProposal1} /> */}
        {/* <Route
          path={URLS.BUYER_INDEX.CREATE.PAYMENT}
          render={(props) => <Payment {...props} user={user} loading={data.loading} />}
        /> */}

        <Route path={URLS.BUYER_INDEX.CREATE.FRONT} component={DoesNotExist} />
        <Route path={URLS.BUYER_INDEX.CREATE.ACTIVATION} component={DoesNotExist} />
        <Route path={URLS.BUYER_INDEX.CREATE.WHISHES} component={DoesNotExist} />
        <Route path={URLS.BUYER_INDEX.CREATE.PROFILE} component={DoesNotExist} />
        <Route path={URLS.BUYER_INDEX.CREATE.LOAN_PROPOSAL2} component={DoesNotExist} />
        <Route path={URLS.BUYER_INDEX.CREATE.LOAN_PROPOSAL1} component={DoesNotExist} />

        <Route path={URLS.BUYER_INDEX.CREATE.PAYMENT} component={DoesNotExist} />
      </Switch>
    </div>
  )
}

CreateBuyerIndex.displayName = "CreateBuyerIndex"
CreateBuyerIndex.propTypes = {}
CreateBuyerIndex.defaultProps = {}

const createBuyerIndexWithUser = graphql(queries.UB_USER_QUERY)(CreateBuyerIndex)

export default withRouter(createBuyerIndexWithUser)

import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom'
import {URLS} from 'urls'
import SelfSaleFrontPage from "./SelfSaleFrontPage"
import AssociationForm from './AssociationForm'
import UploadPage from './UploadPage'
import SellForm from './SellForm'
import SelectPhotoPackage from './SelectPhotoPackage'
import Activation from '../../User/Activation'
import SelectProHelp from './SelectProHelp'
import PhotoUpload from './PhotoUpload'
import CreateUser from '../../User/CreateUser'
import Finish from './Finish'
import * as queries from 'queries'
import {graphql} from 'react-apollo'



const SelfSale = ({data}) => {
	if (data.loading) return <div className="section--loading u-full-height"></div>
	const user = data.ubUser

	let redirectUrl = (user) => {
		// Redirect to create user if user is not logged in
		if (user === null) return URLS.SALE.SELF_SALE.USER
		// Redirect to activation if user is not verified
		else if (user.verified === false) return URLS.SALE.SELF_SALE.ACTIVATION
		// Already a selfSaleCase
		else if (user.selfSaleCase !== null && user.selfSaleCase.wizardCompletedTermsAccepted) return URLS.PROFILE.SELF_SALE
		else return URLS.SALE.SELF_SALE.ASSOCIATION
	}

	const UserRedirect = () => {
		const url = redirectUrl(user)
		if (url!==null) return <Redirect to={redirectUrl(user)}/>
		return <span></span>
	}


	return (
		<div className="router animate--up">
			<Switch>
				<Route path={URLS.SALE.SELF_SALE.FRONT} exact component={SelfSaleFrontPage}/>
				<Route path={URLS.SALE.SELF_SALE.CREATE} exact component={UserRedirect}/>
				<Route path={URLS.SALE.SELF_SALE.USER}
					   render={(props)=> <CreateUser {...props} successModal='USER_CREATED_SELF_SALE'/>}/>
				<Route path={URLS.SALE.SELF_SALE.ACTIVATION}
					   render={(props) => <Activation {...props} next={URLS.SALE.SELF_SALE.ASSOCIATION}/>}
				/>
				<Route path={URLS.SALE.SELF_SALE.ASSOCIATION} component={AssociationForm}/>
				<Route path={URLS.SALE.SELF_SALE.UPLOAD_PAGE} component={UploadPage}/>
				<Route path={URLS.SALE.SELF_SALE.SELL_FORM} component={SellForm}/>
				<Route path={URLS.SALE.SELF_SALE.SELECT_PHOTO_PACKAGE} component={SelectPhotoPackage}/>
				<Route path={URLS.SALE.SELF_SALE.PHOTO_UPLOAD}
				       render={() => (<PhotoUpload selfSaleCase={user.selfSaleCase} refetch={data.refetch}/>)}/>
				<Route path={URLS.SALE.SELF_SALE.SELECT_PRO_HELP} component={SelectProHelp}/>
				<Route path={URLS.SALE.SELF_SALE.FINISH} component={Finish}/>
			</Switch>
		</div>
	);
}

SelfSale.displayName = 'SelfSale';
SelfSale.propTypes = {};
SelfSale.defaultProps = {};

const SelfSaleWithData = graphql(queries.UB_USER_QUERY)(SelfSale)

export default SelfSaleWithData;

import React from "react"
import TopNav from "./TopNav"
import ContentContainer from "components/ContentContainer"
import Footer from "components/Footer"
import Modals from 'components/Modals'
import {connect} from 'react-redux'
import * as actions from 'actions'
import * as queries from 'queries'
import {withRouter} from 'react-router-dom'
import {getToken} from "../tokenManager";
import ReactGA from 'react-ga';
import {graphql, compose} from 'react-apollo'


const TestModeEnabled = () => (
    <div style={{background:"red", color:"white", textAlign:'center'}}>
        TESTMODE ENABLED
    </div>
)



class App extends React.Component {
    constructor(props) {
        super(props);
        const {dispatch} = props
        //Here we do all logic regarding
        document.addEventListener('touchmove', function (event) {
            if (event.scale !== 1) {
                event.preventDefault();
                console.log('scale prevented')
            }
        }, false);

        if (process.env.NODE_ENV === 'production') {
            ReactGA.initialize('UA-113378639-1')
            ReactGA.pageview(window.location.pathname)
        }

        this.props.history.listen((location, action) => {
            dispatch(actions.setCurrentLocation(location, action))
            dispatch(actions.setVisibleModal(null))
            dispatch(actions.setHelpModalContent(null))
            dispatch(actions.toggleMobileMenu(false))
            dispatch(actions.setGobalErrorMessage(null))
            window.scroll(0, 0)
            if (process.env.NODE_ENV === 'production') {
                ReactGA.pageview(window.location.pathname)
            }
        });
    }

    componentWillMount() {
        const {dispatch} = this.props

        // try to get session data here
        const token = getToken()
        // try refetching from token
        if (token==='null') {
            //dispatch(actions.logout())
        }
        else {
            dispatch(actions.refreshTokenAndUser(token))
                .catch(err => console.log(err))
        }
    }


    render() {
        const viewportClass = window.innerWidth > window.innerHeight ? "viewport-wide " : ""
        const mobileClass = this.props.mobileMenuIsOpen ? "menu-is-open " : ""
        const modalIsOpenClass = this.props.visibleModal!==null || this.props.helpModalContent!==null ? "modal-is-open-hide-on-small" : ""

        // Checking for test user mode
        const isTestUser = this.props.userData.ubUser && this.props.userData.ubUser.isTestUser


        if (this.props.data.loading === false && this.props.userData.loading === false) {
            if ((this.props.location.search === '?enable_test_mode' || isTestUser) && !this.props.testMode) {
                this.props.dispatch(actions.setTestMode(true))
                if (isTestUser) {
                    alert("Du kan nu teste nye features." + "\n" +
                        "Da din bruger er test-bruger"
                    )
                } else {

                    alert("Du kan nu teste nye features." + "\n" +
                        "Når du opretter nye brugere vil de være test-brugere." + "\n" +
                        "Brug rigtige emails, da du stadig skal aktivere."
                    )
                }
            } else if (this.props.location.search === '?disable_test_mode' && !isTestUser && this.props.testMode) {
                this.props.dispatch(actions.setTestMode(false))
                alert("Testmode er stoppet")
            }
        }




        const testmode = this.props.testMode ? <TestModeEnabled/> : ''


        // A little hack to prevent scroling of body which is outside react app whenever a modal is visible
        document.body.style.overflowY = this.props.helpModalContent !== null || this.props.visibleModal !== null ? "hidden" : "scroll"
        document.body.style.overflowX = 'hidden'
        return (
            <div id="app" className={viewportClass + mobileClass}>
                <div className={modalIsOpenClass}>
                    <TopNav/>
                    {testmode}
                    <ContentContainer/>
                    <Footer/>
                </div>
                <Modals/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.app
    }
}

const AppWithData =  connect(mapStateToProps)(
    compose(
        graphql(queries.SETTINGS_QUERY),
        graphql(queries.UB_USER_QUERY, {
        name: 'userData'
        }))(App))

export default withRouter(AppWithData)

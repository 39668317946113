import React from 'react';
import * as actions from 'actions'
import * as queries from 'queries'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import LoginRequiredPage from "components/pages/LoginRequiredPage";


const ProfileForm = ({data, dispatch}) => {
    if (data.loading) return <div className="section--loading"></div>
    if (data.ubUser==null) return <LoginRequiredPage/>

    const ubUser = data.ubUser

    const onSubmit = (e) => {
        e.preventDefault()
        const firstName = e.target.firstname.value
        const lastName = e.target.lastname.value
        const phone = e.target.phone.value
        dispatch(actions.modifyUser({firstName: firstName, lastName: lastName, phone: phone}))
    }
    return (
        <section className="subsection profile-form">
            <h3 className="u-green-text">Profil</h3>
            <form onSubmit={onSubmit}>
                <label htmlFor="firstname">Fornavn</label>
                <input id="firstname" name="firstname" type="text" className="profile-form__firstname"
                       placeholder="Fornavn" defaultValue={ubUser.firstName}/>
                <label htmlFor="lastname">Efternavn</label>
                <input id="lastname" name="lastname" type="text" className="profile-form__lastname"
                       placeholder="Efternavn" defaultValue={ubUser.lastName}/>
                <label htmlFor="phone">Telefon</label>
                <input id="phone" name="phone" type="text" className="profile-form__phone" placeholder="mobil"
                       defaultValue={ubUser.phone}
                />
                <label htmlFor="mail">Email</label>
                <input id="mail" name="mail" type="text" className="profile-form__mail" placeholder={ubUser.user.email} disabled={true}/>
                <button type="submit" className="button profile-form__submit">Gem ændringer</button>
            </form>
        </section>
    )
};


const ProfileFormWithUser = graphql(queries.UB_USER_QUERY)(ProfileForm)


ProfileForm.displayName = 'ProfileForm';
ProfileForm.propTypes = {};
ProfileForm.defaultProps = {};

export default connect()(ProfileFormWithUser);


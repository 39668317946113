import React from 'react';
import { Link } from 'react-router-dom'
import {connect} from 'react-redux'

const SuccessSelfSaleAddedModal = ({nextUrl}) => {
  return (
    <div className="successmodal-component">
        <h2>Sådan!</h2>
        <p>Din salgsannonce er nu oprettet.</p>
        <p>I løbet af 24 timer vil en af vores mæglere kigge annoncen igennem og aktivere den såfremt alt er ok.</p>
        <p>Viser det sig at der skulle være mangler, vil du blive kontaktet på mail.</p>
        <p>Du kan altid tjekke om dit selvsalg er blevet godkendt under "Mit selvsalg", hvor du også kan rette i billeder og tekst.</p>
        <Link className="button" to={nextUrl}>Gå til "Mit selvsalg"</Link>
    </div>
  );
}

SuccessSelfSaleAddedModal.displayName = 'SuccessSelfSaleAddedModal';
SuccessSelfSaleAddedModal.propTypes = {};
SuccessSelfSaleAddedModal.defaultProps = {};

export default connect()(SuccessSelfSaleAddedModal);

import axios from 'axios'

export function changeNewUserField(fieldName, value) {
    const type = "CHANGE_NEW_USER_" + fieldName.toUpperCase()
    return {
        type: type,
        payload: value
    }
}

export function fetchToken(email, password) {
    const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "Content-Type"
    }
    const data = {email: email.toLowerCase().replace(' ',''), password: password}
    return {
        type: "FETCH_TOKEN",
        payload: axios.post('/api-token-auth/', data, headers)
    }

}




import React from 'react';
import {FieldSection, CheckBoxBlock} from "../../../shared/finalFormFields";
import {URLS} from 'urls'
import {graphql} from 'react-apollo'
import {Form, Field} from 'react-final-form'
import {AVAILABLE_FACILITIES_SWAP, FILTERED_FACILITIES_QUERY} from "queries";
import {connectAll, getSwapSubmitFn} from "../swapHelpers";
import * as swapFields from '../swapFields'
import * as _ from 'lodash'


const FacilitiesBlockWithData = graphql(AVAILABLE_FACILITIES_SWAP,
    {
        props: ({data: {loading, availableFacilitiesSwap}}) => ({
            loading: loading,
            options: availableFacilitiesSwap,
            labelName: "facilityDescription"
        })
    }
)(CheckBoxBlock)


let SwapOwnResidenceForm = props => {
    const {handleSubmit, submitting, invalid, pristine, initialValues, values} = props
    return (
        <section className="section subsection u-lightgrey u-full-height flex-center">
            <div className="container flex-column">
                <h1 className="u-green-text">Så skal vi blot bruge lidt info om din byttebolig</h1>
                <form onSubmit={handleSubmit}>
                    <div className="row bordered-columns">
                        <div className="col-s-12 col-m-6">
                            <FieldSection fields={swapFields.addressFields} title="Adresse"/>
                            <FieldSection fields={swapFields.infoFields} title="Info om boligen"/>
                            <FieldSection fields={swapFields.expensesFields} title="Generelle udgifter"/>
                        </div>
                        <div className="col-s-12 col-m-6">
                            <FieldSection fields={swapFields.descriptionFields} title="Overskrift og beskrivelse af Bolig"/>
                            <div className="subsection">
                                <Field name='facilities'
                                       title="Har din bolig?"
                                       component={FacilitiesBlockWithData}
                                />
                            </div>
                            <div>
                                <button className="button" disabled={invalid || submitting}>Næste</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    )
}



let SwapOwnResidence = ({dispatch, history, data}) => {
    if (data.loading || data.ubUser.swapCase === undefined ) return <div
        className="section--loading u-full-height"></div>
    const user = data.ubUser || null
    if (!user) {
        history.push("/")
    }
    const swapCase = user.swapCase



    if (!swapCase) return <div>Byttebolig bliver oprettet</div>
    const initialFacilities = _.map(swapCase.facilities, 'id')

    let initialValues = _.pick(swapCase, _.map([...swapFields.addressFields, ...swapFields.infoFields, ...swapFields.descriptionFields, ...swapFields.expensesFields], 'name'))
    initialValues.facilities = initialFacilities

    return (
        <div className="swap__createuser">
            <Form onSubmit={getSwapSubmitFn({
                dispatch,
                history,
                swapCase,
                nextUrl: URLS.SWAP.CREATE.S5_SELECT_PHOTO_PACKAGE
            })} initialValues={initialValues} keepDirtyOnReinitialize={true}>
                {props => <SwapOwnResidenceForm
                        {...props}
                    />
                }

            </Form>
            </div>
    )
}

SwapOwnResidence.displayName = 'SwapOwnResidence';
SwapOwnResidence.propTypes = {};
SwapOwnResidence.defaultProps = {};


export default connectAll(SwapOwnResidence)
import React from 'react';
import {Link} from 'react-router-dom'
import {URLS} from "../../../urls";


const content = "Vores vision er at skabe Danmarks primære markedsplads for handel med andelsboliger, <wbr/>for såvel købere som sælgere, via udnyttelse af den nyeste it-teknologi, høj brugervenlighed og services tilpasset brugernes forskellige behov. <br/><br/>Vi samler markedet i et sikkert miljø der skaber overblik og sikre handler!"
const linkText = "Lær os bedre at kende"
const imageUrl = "../assets/img/img-meeting.png"
const url = URLS.CONTACT

const ImageHalfSection = () => {
    return (
        <section className="section image-block-half u-lightgrey" style={{backgroundImage: `url( ${imageUrl} )`}}>
            <div className="container">
                <div className="row">
                    <div className="col-s-12 col-m-6 col-m-push-6">
                        <div className="image-block-half__content">
                            <h2>
                                <span className="u-bold-text u-lightgreen-text">Andelshandel</span><br/>
                                <span className="u-bold-text u-darkgreen-text">Her mødes køber og sælger</span>
                            </h2>
                            <p dangerouslySetInnerHTML={{__html: content}}/>
                            <Link className="button card__button" to={url}>{linkText}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

ImageHalfSection.displayName = 'ImageHalf';
ImageHalfSection.propTypes = {};
ImageHalfSection.defaultProps = {};

export default ImageHalfSection;


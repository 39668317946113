import React from 'react';
import {Link, withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import * as actions from 'actions'
import {Form} from 'react-final-form'
import {FFInput} from "../shared/finalFormFields";
import {Mutation} from "react-apollo";
import {CREATE_AND_SEND_SWAP_REQUEST} from "../../mutations";
import {URLS} from "../../urls";


const SwapContactModal = ({dispatch, data, history}) => {
    const caseData = data
    return (
        <div className="successmodal-component">
            <div className="modal__content">
                <h2>Ønsker du at sende en forespørgsel om bytte på denne bolig?</h2>
                <p>Der vil blive afsendt en mail til brugeren som har oprettet boligen med dine kontaktoplysninger.
                    Hvis
                    modtageren er interesseret i at bytte vil du blive kontaktet, og I kan lave aftale om at se
                    hinandens
                    boliger.</p>
                <Mutation mutation={CREATE_AND_SEND_SWAP_REQUEST}>
                    {(createAndSendSwapRequest, {data}) => (
                        <Form onSubmit={ (data) => {
                            const inputData = {
                                recipientSwapCaseId: caseData.id,
                                mailContent: data.content ? data.content : ""
                            }
                            createAndSendSwapRequest({variables: {input: inputData}})
                                .then(res => history.push(URLS.SWAP.CONTACT_REQUEST_CREATED))
                                .catch(err => console.log(err))
                        }}
                              render={({invalid, handleSubmit, pristine}) => (
                                  <form onSubmit={handleSubmit}>
                                      <FFInput name="content" type="textarea" placeholder="Skriv evt. en besked her"/>
                                      <button className="button align-right">Ja tak, send forespørgsel</button>
                                      <button className="button button--grey" onClick={(e) => {
                                          e.preventDefault();
                                          dispatch(actions.setVisibleModal(null))
                                      }}>Nej tak
                                      </button>
                                  </form>

                              )}
                        />)
                    }
                </Mutation>

            </div>
        </div>
    );
}

SwapContactModal.displayName = 'SwapContactModal';
SwapContactModal.propTypes = {};
SwapContactModal.defaultProps = {};

const mapStateToProps = state => ({
    data: state.app.visibleModalData
})

export default withRouter(connect(mapStateToProps)(SwapContactModal));

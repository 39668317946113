import React from 'react';
import {graphql} from 'react-apollo'
import {ACTIVE_SWAP_CASES_QUERY} from 'queries'
import SwapApartment from './SwapApartment'

import "./style.css"
import { shuffle } from "../../../utils";

const SimilarSwapCases = ({data}) => {
    if (data.loading) return <div className="section--loading"></div>
    if (data.error) return <div>{data.error.message}</div>
    if (data.allActiveSwapCases.length < 3) return <div style={{'height': '100px'}} ></div>

    const casesWithImages = shuffle(data.allActiveSwapCases.filter(x => x.swapcaseimages.length>0 && x.addressStreetName != (null || undefined)))
    const sixActiveCases = casesWithImages.slice(0, 3)
    return (
        <section className="subsection apartments-grid grid similar-cases">
            <div className="apartments-grid__wrapper">
                <div className="">
                    <h2 className="u-lightgreen-text">Udvalgte bytteboliger</h2>

                    <div className="row">
                        {sixActiveCases.map((item, index) => {
                                item.showUrl = false
                                // item.addressStreetName = 'Vises efter oprettelse'
                                // item.city = 'Vises efter oprettelse'
                                // item.zipCode = ''
                                return <SwapApartment key={index} {...item} />
                            }
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}

SimilarSwapCases.displayName = 'SimilarCases';
SimilarSwapCases.propTypes = {};
SimilarSwapCases.defaultProps = {};


const SimilarSwapCasesWithData = graphql(ACTIVE_SWAP_CASES_QUERY, {})(SimilarSwapCases)


export default SimilarSwapCasesWithData


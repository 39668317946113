import React from 'react';
import {Link} from 'react-router-dom'
import {URLS} from 'urls'
import {connect} from 'react-redux'
import * as actions from 'actions'


const SubscriptionAsStatus = ({dispatch, subscriptionData}) => {
    return subscriptionData.is_cancelled ? (
            <div>
                <h3 className="u-green-text">Abonnement</h3>
                <p className="green-text">Du har opsagt dit køberservice</p>
                <button className="submit button subscription__submit"
                        onClick={() => dispatch(actions.reepaySubscribe({cardToken: ''}))}>
                    Tilmeld Køberservice
                </button>
            </div>
        ) :
        (
            <div>
                <h3 className="u-green-text">Abonnement</h3>
                <p className="green-text">Køberservice 49 kr / md</p>
                <button className="submit button subscription__submit"
                        onClick={() => dispatch(actions.setVisibleModal('SUBSCRIPTION_CANCEL'))}>Opsig
                    abonnement
                </button>
            </div>
        )
}

const SubscriptionForm = ({user, dispatch, subscriptionData}) => {
    const {reepaySubscriptionHandle, buyerCase} = user
    return (
        <section className="subsection subscription-form">
            {buyerCase === null ? (
                <div>
                    <h3 className="u-green-text">Abonnement</h3>
                    <p className="green-text">Du har ikke oprettet dig i vores køberservice endnu</p>
                    <Link to={URLS.BUYER_INDEX.FRONT} className="submit button subscription__submit">Opret dig
                        her</Link>
                </div>
            ) : (
                reepaySubscriptionHandle === null ? (
                    <div>
                        <h3 className="u-green-text">Abonnement</h3>
                        <p className="green-text">Du har p.t. ikke noget abonement</p>
                        <Link to={URLS.BUYER_INDEX.CREATE.FRONT} className="submit button subscription__submit">Tilmeld dig
                            Køberservice</Link>
                    </div>
                ) : (
                    <div>
                        <SubscriptionAsStatus subscriptionData={subscriptionData} dispatch={dispatch}/>
                        <br/>
                        <p>
                            <a href={subscriptionData.hosted_page_links.payment_info} target="_blank">Skift
                                betalingskort</a>
                        </p>
                    </div>
                )
            )}
        </section>
    )
}
SubscriptionForm.displayName = 'SubscriptionForm';
SubscriptionForm.propTypes = {};
SubscriptionForm.defaultProps = {};

export default connect()(SubscriptionForm)


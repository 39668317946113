import { applyMiddleware, createStore, compose } from "redux"
import thunk from "redux-thunk"
import promise from "redux-promise-middleware"

import reducer from "./reducers"

const error = () => (next) => (action) => {
	try {
		next(action);
	} catch(e) {
		console.log("AHHH!!", e);
	}
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = applyMiddleware(promise(), thunk, error)

export default createStore(reducer, /* preloadedState, */ composeEnhancers(middleware))


// Put all default content in here
const DEFAULT_SITE_TEXTS = {
    buyer_index_page: {
        headline: "Køberservice",
        text: `
<h1>Køberservice</h1>
<h4>Tilmeld dig vores køberservice, og stå forrest i køen når du skal købe andelslejlighed</h4>
<h4>FØRSTE MÅNED GRATIS</h4>
<p>Det går RIGTIG stærkt på andelsboligmarkedet i dag, og det kan være enormt svært selv som købeklar boligkøber at navigere i. 
Andelsboligerne sælges ofte straks de udbydes og de kan være svære overhovedet at få fremvist.
Det er generelt en udfordring for boligkøbere at få overblik over hvad der overhovedet er til salg!</p>
<p>”<i>At skabe den dominerende markedsplads for køb og salg af andelsboliger” </i><br/>– Det er visionen hos Andelshandel.</p>
<p>Til de boligsælgere der selv vil sælge har vi udviklet en GRATIS platform. Platformen giver selvsælgerne en række professionelle værktøjer, såsom topmoderne boligannonce, dokumentarkiv, salgsopstilling samt et sikkert og guidende salgsmiljø.</p>
<p>Selvsælgere via foreningshjemmesiderne fra ProBo (ca. 1100 foreninger) tilbydes nu en helt ny service, hvor de i ro og mag kan udvælge og invitere FÅ UDVALGTE købere som de føler sig trygge ved, til et præ åbent hus. Sælgerne ser din profil og vælger den til eller fra. Vi sørger for at der er en match mellem dig og boligen, så det er relevante boliger du matches med.</p>
<p>Vi tilbyder naturligvis også andelsboligsælgere andre produkter såsom salgsrådgivning, Medsalg og Totalsalg. Disse boliger får du også et forspring på i form af en email straks de kommer til salg.</p>
<p>Som ABONNENT PÅ VORES KØBER APP får du gavn af en række fordele. Din profil præsenteres altså direkte for selvsælgerne (såfremt boligen matcher) allerede mens de opretter deres boligannonce. Selvsælgerne har allerede her mulighed for at invitere dig og enkelte andre ud på et eksklusivt åbent hus. Du kan arbejde aktivt med din køberprofil og gøre den så attraktiv for sælgere som muligt, fx ved at uploade et godt profilbillede, fortælle hvem du er og gøre din profil levende og personlig.</p>
<p>På ALLE boliger der kommer til salg hos Andelshandel modtager du som abonnent også en direkte mail, allerede umiddelbart inden nye andelsboliger annonceres på de store portaler. Så er det op til dig at være hurtig, forberedt og sympatisk i din kontakt til sælger.</p>
<p>Køberserviceet åbnes indledningsvist op for et begrænset antal abonnenter. Vi ønsker at sikre en god balance mellem udbud og efterspørgsel på boliger. Køberkartoteket vil løbende blive videreudviklet med nye spændende funktioner.</p>
 <h4 class="u-green-text">Vi samarbejder med ejendomsadministrationer der repræsenterer mere end 1100 andelsboligforeninger i Storkøbenhavn.</h4>`
    }

}

export const getDefault = (textId) => {
    return DEFAULT_SITE_TEXTS[textId] || {headline: "Ups!", text: 'Ups. der skete en fejl, prøv at genindlæse siden'}
}

import React, { useState, useRef, useEffect, useMemo } from "react"
import { Link, withRouter } from "react-router-dom"
import SimilarCases from "./SimilarCases"
import ImageFullSection from "components/shared/ImageFullSection"
import CaseMap from "./CaseMap"
import Slider from "react-slick"
import * as queries from "queries"
import { graphql } from "react-apollo"
import * as actions from "actions"
import { connect } from "react-redux"
import {
  ensure_ssl,
  getFrontImage,
  listByKey,
  kr,
  kvm,
  extractValues,
  addressFromStreetNumberFloorSide,
} from "../../../utils"
import moment from "moment"
import Text from "components/shared/Text"
import { CASE_STATUS_TYPES } from "../../../values"
import { URLS } from "../../../urls"
import SellerInfo from "../../shared/SellerInfo"
import { DEFAULT_AGENT_EMAIL } from "../../../values"
import Banner from "../../shared/Banner"

moment.locale("da")

const zoom = 17

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SlickNextArrow />,
  prevArrow: <SlickPrevArrow />,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: true,
        dots: true,
      },
    },
  ],
}

function SlickNextArrow(props) {
  const { onClick } = props
  return (
    <svg onClick={onClick} className="icon icon--bordered-arrow slider--next">
      <use xlinkHref="#icon-arrow"></use>
    </svg>
  )
}

function SlickPrevArrow(props) {
  const { onClick } = props
  return (
    <svg onClick={onClick} className="icon icon--bordered-arrow slider--prev">
      <use xlinkHref="#icon-arrow"></use>
    </svg>
  )
}

const Slide = ({ image }) => {
  return (
    <div className="case__slider__slide">
      <img className="case__slider__slide__img" src={ensure_ssl(image.size2Url)} alt="" />
      <div
        className="case__slider__slide__img-full"
        style={{ backgroundImage: `url(${ensure_ssl(image.size2Url)})` }}></div>
    </div>
  )
}

const caseCardList = [
  {
    key: "initialPrice",
    label: "pris",
    format: kr,
  },
  {
    key: "monthlyFee",
    label: "Boligafgift",
    format: kr,
  },
  {
    key: "utilitiesTotalExport",
    label: "Forbrugsudgifter",
    format: kr,
  },
  {
    key: "building.constructionYear",
    label: "Byggeår",
  },
  {
    key: "livingSpace",
    label: "Bolig m2",
    format: kvm,
  },
  {
    key: "roomsCount",
    label: "Værelser",
  },
  {
    key: "building.energyClass",
    label: "Energimærke",
  },
]

const CaseSlider = ({ sliderOpen, images, caseType, dispatch, caseData, onToggleSlider }) => {
  const setDimensionClass = () => {
    if (window.innerWidth > window.innerHeight) {
      document.querySelector("#app").classList.add("viewport-wide")
    } else {
      document.querySelector("#app").classList.remove("viewport-wide")
    }
  }
  window.addEventListener("orientationchange", setDimensionClass)

  const imageCount = images.length
  const [currentSlide, setCurrentSlide] = useState(0)
  const sliderRef = useRef(null)
  const shouldGoToSlide = useRef(false)

  const onShowDocumentsModal = () => {
    dispatch(actions.setVisibleModal("CASE_DOCUMENTS_MODAL", caseData))
  }

  const onShowSelfSaleDocumentsModal = () => {
    dispatch(
      actions.setVisibleModal("CASE_DOCUMENTS_MODAL", {
        caseDocuments: caseData.documents,
        buildingDocuments: [],
        documentUrl: null,
      })
    )
  }

  const openSlider = () => {
    if (sliderRef.current && imageCount > 0) {
      setCurrentSlide(0)
      onToggleSlider()
      shouldGoToSlide.current = true
    }
  }

  const openSliderLast = () => {
    if (sliderRef.current && imageCount > 0) {
      setCurrentSlide(imageCount - 1)
      onToggleSlider()
      shouldGoToSlide.current = true
    }
  }

  useEffect(() => {
    const goToSlide = () => {
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(currentSlide)
        shouldGoToSlide.current = false
      }
    }

    if (shouldGoToSlide.current) {
      const delay = setTimeout(goToSlide, 0)
      return () => {
        clearTimeout(delay)
      }
    }
  }, [currentSlide])

  return (
    <div>
      <div
        className={`${
          caseType === "SelfSaleCaseNode"
            ? "case__gallery-button-container--selfsale"
            : "case__gallery-button-container"
        }`}>
        <button onClick={() => openSlider()} className="button case__slider__button--open">
          BILLEDER
          <svg className="icon icon--gallery icon--white">
            <use xlinkHref="#icon-gallery"></use>
          </svg>
        </button>
        {caseType !== "SelfSaleCaseNode" && (
          <button
            onClick={() => openSliderLast()}
            className="button button--invert case__slider__button--open case__slider__button--last">
            PLANTEGNING
            <svg className="icon icon--floor-plan icon--green">
              <use xlinkHref="#icon-floor-plan"></use>
            </svg>
          </button>
        )}

        {(caseType === "SelfSaleCaseNode" || caseData.hasDocuments) && (
          <button
            onClick={caseType === "SelfSaleCaseNode" ? onShowSelfSaleDocumentsModal : onShowDocumentsModal}
            className="button button--invert case__slider__button--open">
            DOKUMENTER
            <svg className="icon icon--documents icon--green">
              <use xlinkHref="#icon-documents"></use>
            </svg>
          </button>
        )}
      </div>
      {/* <Banner {...caseData} isSelfSale={isSelfSaleCase} /> */}
      <div className={sliderOpen ? "case__slider is-active" : "case__slider"}>
        <Slider ref={sliderRef} {...sliderSettings}>
          {images.map((image, index) => (
            <div key={index}>
              <Slide image={image} />
            </div>
          ))}
        </Slider>
        <button onClick={() => onToggleSlider()} className="case__slider__button--close"></button>
      </div>
    </div>
  )
}

const lhiAddTag = (caseData) => {
  if (window.lhi) {
    window.lhi("addTag", {
      type: "ViewProperty",
      price: caseData.initialPrice,
      propertyType: "apartment", // ”string” - (apartment, house, land, townhouse, cooperative, house_apartment, terraced_house, recreational, project)
      city: caseData.city, // ”string” – by //
      offeringType: "sale",
      lat: caseData.latitude,
      lon: caseData.longitude,
      squareMeters: caseData.livingSpace,
    })

    window.lhi("viewContent", {
      content_name: caseData.fullAddress, //'Geelsmark 1A, 2840 Holte',
      content_ids: [caseData.caseId],
      content_type: "product",
      value: caseData.initialPrice,
      currency: "DKK",
    })
  }
}

// const lhiViewContent = (data) => {
//
// }

const CaseSection = ({ data, caseSliderIsOpen, dispatch, isSelfSaleCase = false }) => {
  if (data.loading) return <div className="section--loading"></div>
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const caseData = useMemo(() => {
    return isSelfSaleCase
      ? {
          ...data.selfSaleCaseByCaseId,
          street: data.selfSaleCaseByCaseId.fullAddress,
          caseHeadline: "Andelsbolig sælges",
        }
      : data.caseByCaseId
  }, [isSelfSaleCase])
  if (caseData === undefined) {
    setTimeout(() => {
      data.refetch()
    }, 0)
    return <div className="section--loading"></div>
  }

  if (caseData === null) return <div>Findes ikke</div>

  lhiAddTag(caseData)

  const address = isSelfSaleCase
    ? caseData.fullAddress
    : addressFromStreetNumberFloorSide(caseData.street, caseData.number, caseData.floorSide)

  const bgImageUrl = isSelfSaleCase
    ? getFrontImage(caseData.caseimages, caseData.frontpageImage).url
    : getFrontImage(caseData.caseimages, caseData.frontpageImage).size3Url

  const facilities = listByKey(caseData.facilities, "facilityDescription")

  const onToggleSlider = () => {
    dispatch(actions.toggleCaseSlider())
    !caseSliderIsOpen
      ? document.body.parentNode.classList.add("slider-is-open")
      : document.body.parentNode.classList.remove("slider-is-open")
  }

  const onShowBuildingInfo = () => {
    dispatch(actions.setVisibleModal("BUILDING_INFO_MODAL", caseData))
  }

  const onShowDocumentsModal = () => {
    dispatch(actions.setVisibleModal("CASE_DOCUMENTS_MODAL", caseData))
  }

  const onShowSelfSaleDocumentsModal = () => {
    dispatch(
      actions.setVisibleModal("CASE_DOCUMENTS_MODAL", {
        caseDocuments: caseData.documents,
        buildingDocuments: [],
        documentUrl: null, /// caseData.documentUrl // not working right now
      })
    )
  }

  const sideBarFacilities = (
    <div className="sidebar__column">
      <h4>Faciliteter</h4>
      <ul>
        {facilities.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  )

  // If sold or selfsale
  const sideBarLinks =
    caseData.status === CASE_STATUS_TYPES.SOLD ? (
      ""
    ) : isSelfSaleCase ? (
      <div className="sidebar__column">
        {/* {caseData.hasDocuments ?
                        <a onClick={onShowSelfSaleDocumentsModal} className="button">Dokumenter</a> : ""
                    } */}
      </div>
    ) : (
      <div className="sidebar__column">
        <h4>Om foreningen</h4>
        <ul>
          {caseData.associationUrl === null ? (
            ""
          ) : (
            <li>
              <a href={caseData.associationUrl}>Foreningens hjemmeside</a>
            </li>
          )}
          <li>
            <a onClick={onShowBuildingInfo}>Foreningens nøgletal</a>
          </li>
        </ul>
        {/* {caseData.hasDocuments ?
                        <a onClick={onShowDocumentsModal} className="button">Dokumenter</a> : ""
                    } */}

        {caseData.salesBlanketUrl === null || undefined ? null : (
          <a href={caseData.salesBlanketUrl} className="button button--invert" target="_blank">
            Salgsopstilling
          </a>
        )}

        {caseData.changeToOpenHouse === true ? (
          <button
            className="button"
            onClick={() => dispatch(actions.setVisibleModal("BOOK_OPEN_HOUSE_MODAL", caseData))}>
            TILMELD TIL ÅBENT HUS
          </button>
        ) : caseData.hideViewingBooking === true ? (
          ""
        ) : (
          <button className="button" onClick={() => dispatch(actions.setVisibleModal("BOOK_A_SHOW_MODAL", caseData))}>
            BOOK FREMVISNING
          </button>
        )}
      </div>
    )

  // TODO Phone
  // If case is sold return different
  const sideBarContact = isSelfSaleCase ? (
    <div className="sidebar__column">
      <h4>Denne bolig sælges direkte af ejeren</h4>
      <p>Har du spørgsmål til boligen eller har du kommentarer, så kontakt ejeren her:</p>
      <a
        href={
          "mailto:" +
          caseData.user.user.email +
          "?subject=Vedr. din andel " +
          caseData.fullAddress +
          " på andelshandel.dk&bcc=" +
          DEFAULT_AGENT_EMAIL
        }>
        Skriv til sælger {caseData.user.fullName}
      </a>

      <div>
        eller ring på tlf. <a href={"callto:" + caseData.user.phone}>{caseData.user.phone}</a>
      </div>
    </div>
  ) : caseData.status === CASE_STATUS_TYPES.SOLD ? (
    <div className="sidebar__column">
      <h4>BOLIGEN ER SOLGT!</h4>
      <p>
        Hvis du ærger dig over at boligen ikke længere er på markedet, så husk at du gennem vores køberservice kan
        oprette en profil med netop dine boligønsker.
      </p>
      <Link className="button" to={URLS.BUYER_INDEX.FRONT}>
        LÆS MERE HER
      </Link>
    </div>
  ) : (
    <div className="sidebar__column">
      <SellerInfo {...caseData} />
    </div>
  )

  const filteredImages = caseData.caseimages.filter((image) => image.size2Url !== null)
  const caseSlider =
    caseData.caseimages.length > 0 ? (
      <CaseSlider
        sliderOpen={caseSliderIsOpen}
        images={filteredImages}
        caseData={caseData}
        onToggleSlider={onToggleSlider}
        caseType={caseData.__typename}
        dispatch={dispatch}
      />
    ) : (
      ""
    )

  const caseMap =
    caseData.latitude === null || caseData.longitude === null ? (
      ""
    ) : (
      <div className="subsection">
        <CaseMap center={{ lat: caseData.latitude, lng: caseData.longitude }} zoom={zoom} address={address} />
      </div>
    )

  const openHouse = caseData.openHouseUpcoming ? (
    <div>
      <strong>
        Åbent hus d. {moment(caseData.openHouseDateTime).format("DD/MM")}, kl.{" "}
        {moment(caseData.openHouseDateTime).format("HH:mm")}
      </strong>
      <p>{caseData.openHouseInfo}</p>
    </div>
  ) : (
    ""
  )

  const sold =
    caseData.status === CASE_STATUS_TYPES.SOLD ? (
      <div>
        <p>
          <strong>SOLGT</strong>
        </p>
      </div>
    ) : (
      ""
    )

  {
    window.faci = facilities
  }
  return (
    <section className="case">
      <div className="case__img">
        <Slider {...sliderSettings}>
          {filteredImages.map((image, index) => (
            <div key={index}>
              <Slide image={image} />
            </div>
          ))}
        </Slider>
      </div>
      <Banner {...caseData} isSelfSale={isSelfSaleCase} />

      <div className="container">
        {caseSlider}
        <div className="row">
          <div className="col-s-12">
            <div className="case__wrapper">
              <div className="content-card">
                <div className="row bordered-columns">
                  <div className="col-s-12 col-m-8 col-l-9">
                    <div className="case__content">
                      <div className="subsection">
                        <h1 className="case__title title u-green-text">{caseData.caseHeadline}</h1>
                        {openHouse}
                        {sold}
                        <div className="case__content">
                          <Text text={caseData.caseDescription} />
                        </div>
                      </div>
                      {caseMap}
                    </div>
                  </div>
                  <div className="col-s-12 col-m-4 col-l-3">
                    <div className="case__card card">
                      <div className="case__card__header u-lightgreen">
                        <h3 className="case__card__title u-white-text">{address} </h3>
                        <h3 className="case__card__title u-white-text">
                          {caseData.zipCode} {caseData.city}{" "}
                        </h3>
                        <p className="case__card__caseid">Sagsnr: {caseData.caseId}</p>
                      </div>
                      <ul className="case__card__list">
                        {extractValues(caseCardList, caseData).map((item, index) => {
                          return (
                            <li key={index} className="case__card__list__item case__">
                              <p className="case__card__list__label">{item.label}</p>
                              <p className="case__card__list__value">{item.value}</p>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                    <div className="case__sidebar sidebar">
                      {facilities.length ? sideBarFacilities : ""}
                      {sideBarLinks}
                      {sideBarContact}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const CaseSectionWithData = graphql(queries.CASE_QUERY_CASE_PAGE, {
  options: (props) => {
    return {
      variables: {
        caseId: props.caseId,
      },
    }
  },
})(CaseSection)

const SelfSaleCaseSectionWithData = graphql(queries.SELF_SALE_CASE_QUERY_CASE_PAGE, {
  options: (props) => {
    return {
      variables: {
        caseId: props.caseId,
      },
      fetchPolicy: "cache-and-network",
    }
  },
})(CaseSection)

const mapStateToProps = (state) => {
  return {
    ...state.app,
  }
}

const CaseSectionWithDataAndState = connect(mapStateToProps)(CaseSectionWithData)

const SelfSaleCaseSectionWithDataAndState = connect(mapStateToProps)(SelfSaleCaseSectionWithData)

const CasePage = ({ match, isSelfSaleCase = false }) => {
  const caseSection = isSelfSaleCase ? (
    <SelfSaleCaseSectionWithDataAndState caseId={match.params.id} isSelfSaleCase={true} />
  ) : (
    <CaseSectionWithDataAndState caseId={match.params.id} isSelfSaleCase={false} />
  )
  return (
    <div className="casepage-component animate--down">
      {caseSection}
      <SimilarCases />
      <ImageFullSection />
    </div>
  )
}

CasePage.displayName = "CasePage"
CasePage.propTypes = {}
CasePage.defaultProps = {}

export default withRouter(CasePage)

import React from 'react';

const imageUrl = "/assets/img/img-al-banner.jpg"

const AlSection = () => (
        <section className="u-lightgrey" style={{textAlign: "center"}}>
                <a className="center" target="_blank" href="https://www.al-bank.dk/privat/bolig/andelsboliglaan/#utm_source=display&utm_campaign=andelsfordel_fu&utm_content=banner" >
                    <img src={imageUrl} alt="" style={{maxWidth: "100%" }}/>
                </a>
        </section>
    )

AlSection.displayName = 'AlSection';
AlSection.propTypes = {};
AlSection.defaultProps = {};

export default AlSection;



import React from 'react';
import {connect} from 'react-redux'
import * as utils from 'utils'
import SellerInfo from 'components/shared/SellerInfo'
import LoanInfo from 'components/shared/LoanInfo'

const fields = [
    {
        label: 'Navn på andelsboligforening',
        name: 'buildingName'
    },
    {
        label: 'Matrikelnummer',
        name: 'matricleNumber'
    },
    {
        label: 'Opført år',
        name: 'constructionYear'
    },
    {
        label: 'Antal boliger',
        name: 'totalCountApartments',
        format: utils.stk
    },
    {
        label: 'Antal erhverv',
        name: 'othersCount',
        format: utils.stk
    },
    {
        label: 'Antal restlejligheder',
        name: 'businessCount',
        format: utils.stk
    },
    {
        label: 'Seneste generalforsamling',
        name: 'latestBoardMeeting'
    },
    {
        label: 'Vurderingsprincip',
        name: 'valuationPrinciple'
    },
    {
        label: 'Seneste vurdering',
        name: 'latestValuation',
        format: utils.kr
    },
    {
        label: 'Energimærke',
        name: 'energyClass'
    },
    {
        label: 'Forsikringsforhold',
        name: 'insuranceDescription'
    },
    {
        label: 'Ejendommens samlede gæld',
        name: 'buildingTotalDebt',
        format: utils.kr
    },
    {
        label: 'Belåningsgrad',
        name: 'mortgageRate',
        format: utils.percentage
    },
    {
        label: 'Seneste driftsresultat',
        name: 'latestOperatingProfit',
        format: utils.kr
    },
    {
        label: 'Hysdyrtilladelse',
        name: 'petsPermitted'
    },
]


const BuildingInfoModalContent = ({data}) => {
    const {building} = data
    return (
        <div className="modal__component">
            <h3>FORENINGENS NØGLETAL</h3>

            <ul className="info-list">
                {fields.map((x, i) => utils.isEmpty(building[x.name]) ? null : <li key={i}><span className="label">{x.label}: </span><span
                    className="value">{x.format===undefined ? building[x.name] : x.format(building[x.name])}</span>
                </li>)}
            </ul>

            <p>Har du spørgsmål til boligen eller har du kommentarer, så kontakt os endelig!</p>
            <p>Vi vil meget gerne gøre vores for, at du bliver hjulpet godt og får svar på alt hvad der måtte være.</p>
            <LoanInfo loanSet={building.loanSet}/>
            <SellerInfo {...data}/>
        </div>
    );
}

BuildingInfoModalContent.displayName = 'BuildingInfoModalContent';
BuildingInfoModalContent.propTypes = {};
BuildingInfoModalContent.defaultProps = {};

const mapStateToProps = state => ({
    data: state.app.visibleModalData
})

export default connect(mapStateToProps)(BuildingInfoModalContent);

import React from 'react';
import Input from 'components/shared/forms/Input/index'
import * as actions from 'actions/index'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {graphql, Mutation} from 'react-apollo'
import * as queries from 'queries'
import LoginRequiredPage from "../LoginRequiredPage";
import {RESEND_ACTIVATION_CODE} from "../../../mutations";


const Activation = ({dispatch, next, history, data}) => {
    if (data.loading) return <div className="section--loading"></div>

    const user = data.ubUser

    const onChangeActivation = (fieldName, value) => {
        //const key = value
    }
    const submit = (e) => {
        e.preventDefault()
        const code = e.target.code.value
        dispatch(actions.verifyUser(code))
            .then((res) => {
                console.log(res)
                if (res.value.data.activateUbUser.ubUser.verified) {
                    history.push(next)
                } else {
                    dispatch(actions.setGobalErrorMessage(
                        'Der er fejl i aktiverings koden, prøv igen'
                    ))
                }
            })
            .catch((() => {
                dispatch(actions.setGobalErrorMessage(
                    'Der skete en fejl, prøv igen'
                ))
            }))
    }

    if (!user) {
        return (
            <LoginRequiredPage/>
        )
    }
    if (user.verified) {
        history.push(next)
    }


    return (
        <div className="buyerindex__activation">
            <section className="section u-lightgrey u-full-height flex-center">
                <div className="container">
                    <div className="row">
                        <div className="col-s-12 col-l-6 col-l-push-3">
                            <h1 className="u-green-text u-center-text">Indtast aktiveringskoden som vi har fremsendt på
                                mail</h1>
                            <form onSubmit={submit}>
                                <div className="flex-column">
                                    <Input label="Aktiveringskode" type='number' name="code"
                                           onChange={onChangeActivation}/>
                                    <Mutation mutation={RESEND_ACTIVATION_CODE}>
                                        {(resendActivationCode, {data}) => (
                                            <a href="#" onClick={e => {
                                                e.preventDefault()
                                                resendActivationCode({variables:{
                                                        input: {}
                                                    }
                                                })
                                                    .then((res) => {
                                                        dispatch(actions.setVisibleModal('SITE_TEXT_MODAL', {textId: "activation_resend_modal", withHeadline: true}))
                                                    })
                                            }}>Gensend aktiveringskode</a>
                                        )}
                                    </Mutation>
                                    <button className="button">Næste</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

Activation.displayName = 'Activation';
Activation.propTypes = {};
Activation.defaultProps = {};

const ActivationWithData = graphql(queries.UB_USER_QUERY)(Activation)

export default withRouter(connect()(ActivationWithData));

import React from 'react';
import Input from 'components/shared/forms/Input/index'
import CheckBox from 'components/shared/forms/CheckBox'
import {changeNewUserField} from 'actions/userActions'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import * as actions from 'actions/index'
import * as validation from 'validation'
import {validateIsTrue} from "../../../validation";


const NewUserForm = ({newUser, app, dispatch, successModal, confirmOwner = false}) => {
    const {email, password, firstName, lastName, phone, hasConfirmedIsApartmentOwner} = newUser
    const onChange = () => (fieldName, value) => {
        dispatch(changeNewUserField(fieldName, value))
    }
    const onDispatchErrors = (errors) => dispatch(actions.addFieldValidationErrors(errors))
    const isFormValid = () => {
        return validation.validateForm({
            fieldNames: ['firstname', 'lastname', 'email', 'password', 'phone', 'hasconfirmedisapartmentowner'],
            errors: app.validationErrors
        })
    }




    const confirmOwnerInput = confirmOwner ?
        <CheckBox label="Jeg bekræfter at jeg er ejer af en andelsbolig" name="hasconfirmedisapartmentowner"
                  value={hasConfirmedIsApartmentOwner}
                  onChange={(e) => {e.preventDefault(); dispatch(changeNewUserField("hasConfirmedIsApartmentOwner", !hasConfirmedIsApartmentOwner))}}
                  required={true}
                  errors={app.validationErrors['hasconfirmedisapartmentowner']}
                  onDispatchErrors={onDispatchErrors}
                  validators={[validateIsTrue]}
        /> : ""

    const formIsValid = isFormValid()

    console.log(formIsValid)

    const onSubmit = (e) => {
        e.preventDefault()
        if (!isFormValid()) return
        const testUser = app.testMode
        dispatch(actions.addUser(firstName, lastName, email, password, phone, testUser))
            .then((res) => actions.parseValidationErrors(res.value.data.addUbUser.validationErrors))
            .then((res) => validation.validateFormAsPromise({
                fieldNames: ['firstname', 'lastname', 'email', 'password', 'phone'],
                errors: res
            }))
            .then(() => {
                dispatch(actions.login(email, password))
                    .then(() => dispatch(actions.setVisibleModal(successModal)))
            })
            .catch(errors => dispatch(actions.setValidationErrors(errors)))
    }
    return (
        <div className="buyerindex__createuser">
            <section className="section u-lightgrey">
                <div className="container flex-column">
                    <h1 className="u-green-text">Perfekt! Nu skal du oprettes i systemet</h1>
                    <form onSubmit={formIsValid ? onSubmit : () => alert("Formen er ikke valid")}>
                        <div className="row bordered-columns">
                            <div className="col-s-12 col-m-6">
                                <div className="subsection">
                                    <Input label="Fornavn" name="firstname" value={firstName}
                                           onChange={onChange("firstname")}
                                           required={true}
                                           errors={app.validationErrors['firstname']}
                                           onDispatchErrors={onDispatchErrors}
                                    />
                                </div>
                                <div className="subsection">
                                    <Input label="Efternavn" name="lastname" value={lastName}
                                           onChange={onChange("lastname")}
                                           required={true}
                                           errors={app.validationErrors['lastname']}
                                           onDispatchErrors={onDispatchErrors}
                                    />
                                </div>
                                <div className="subsection">
                                    <Input label="Mobil nummer"
                                           name="phone"
                                           required={true}
                                           value={phone}
                                           onChange={onChange("phone")} type="number"
                                           errors={app.validationErrors['phone']}
                                           onDispatchErrors={onDispatchErrors}
                                    />
                                </div>

                            </div>

                            <div className="col-s-12 col-m-6">
                                <div className="subsection">
                                    <strong className="u-green-text">Så skal vi blot
                                        bruge din email og et password for at kunne oprette dig som bruger</strong>
                                </div>
                                <div className="subsection">
                                    <Input label="Email adresse" name="email" value={email} onChange={onChange("email")}
                                           errors={app.validationErrors['email']}
                                           required={true}
                                           onDispatchErrors={onDispatchErrors}
                                    />
                                </div>

                                <div className="subsection">
                                    <Input label="Password" name="password" type="password" value={password}
                                           onChange={onChange("password")}
                                           required={true}
                                           errors={app.validationErrors['password']}
                                           onDispatchErrors={onDispatchErrors}
                                    />
                                </div>


                            </div>
                        </div>
                        <div className="subsection">
                            {confirmOwnerInput}
                        </div>

                        <button className="button" >Næste</button>
                    </form>
                </div>
            </section>
        </div>
    )
        ;
}


NewUserForm.displayName = 'NewUserForm';
NewUserForm.propTypes = {};
NewUserForm.defaultProps = {};

const mapStateToProps = state => {
    return {
        newUser: state.newUser,
        app: state.app
    }
}

const NewUserFormWithDataAndState = withRouter(connect(mapStateToProps)(NewUserForm))

export default NewUserFormWithDataAndState

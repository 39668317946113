import {step} from '../utils'

const initialState = {
    sizeRange: [30, 200],
    priceRange: [0, 5000000],
    roomsRange: [1, 5],
    rentalRange: [1000, 20000],
    streetPostalCity: "",
    sortByFilter: 'latest',
    advancedIsOpen: false,
    search: {
	    sizeRange: [30, 200],
	    priceRange: [0, 5000000],
	    roomsRange: [1, 5],
        streetPostalCity: "",
    },
    toggledMaps: [],
    toggledFloorPlans: [],
    mapToggled: false
}



export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "CHANGE_PRICE_RANGE": {
            return {...state, priceRange: [step(action.payload[0], 50000), step(action.payload[1], 50000)]}
        }
        case "CHANGE_RANGE": {
            let newState = {}
            const fieldName = action.payload.fieldName
            newState[fieldName] = [...action.payload.range]
            return {...state, ...newState}
        }
        case "CHANGE_RENTAL_RANGE": {
          return {...state, rentalRange: [action.payload[0], action.payload[1]]}
        }
        case "CHANGE_STREET_POSTAL_CITY": {
            return {...state, streetPostalCity: action.payload}
        }
        case "CHANGE_RENTAL_RANGE": {
            return {...state, rentalRange: action.payload}
        }
        case "SELECT_SORT_BY_FILTER": {
            return {...state, sortByFilter: action.payload}
        }
        case "INIT_SEARCH": {
            const newSearch = {
                sizeRange: [...state.sizeRange],
                priceRange: [...state.priceRange],
                roomsRange: [...state.roomsRange],
                rentalRange: [...state.rentalRange],
                streetPostalCity: state.streetPostalCity
            }
            return {...state, search: newSearch}
        }

        case "RESET_SEARCH": {
            const newRanges = {
                sizeRange: [...initialState.sizeRange],
                priceRange: [...initialState.priceRange],
                roomsRange: [...initialState.roomsRange],
                rentalRange: [...state.rentalRange],
                streetPostalCity: initialState.streetPostalCity
            }
            return {...state, ...newRanges, search: {...newRanges}}
        }
        case "TOGGLE_ADVANCED": {
            return {...state, advancedIsOpen: !state.advancedIsOpen }
        }

        case "TOGGLE_MAP_ON_APARTMENT_CARD": {
            const id = action.payload
            const nextValue = state.toggledMaps.indexOf(id)===-1
            const newToggledMaps = nextValue ? [...state.toggledMaps, id] : [...state.toggledMaps].filter(x=>x!=id)
            const newToggledFloorPlans = [...state.toggledFloorPlans].filter(x=>x!=id)
            return {...state,  toggledMaps: newToggledMaps, toggledFloorPlans: newToggledFloorPlans}
        }
	    case "TOGGLE_FLOOR_PLAN_ON_APARTMENT_CARD": {
		    const id = action.payload
		    const nextValue = state.toggledFloorPlans.indexOf(id)===-1
		    const newToggledFloorPlans = nextValue ? [...state.toggledFloorPlans, id] : [...state.toggledFloorPlans].filter(x=>x!=id)
		    const newToggledMaps = [...state.toggledMaps].filter(x=>x!=id)
		    return {...state, toggledMaps: newToggledMaps, toggledFloorPlans: newToggledFloorPlans }
	    }

	    case "TOGGLE_MAP_ON_SEARCH": {
		    return {...state, mapToggled: action.payload}
	    }
    }
    return state
}

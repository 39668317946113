import React from 'react';

const PaymentItem = ({date, title, price, status}) => {
	return (
		<div className="payment-history__item">
			<span className="payment-history__date">{ date }</span>
			<span className="payment-history__title">{ title }</span>
			<span className="payment-history__price">{ price }</span>
			<span className="payment-history__status">{ status }</span>
		</div>			
	)
}		

const PaymentHistory = ({items}) => {
	return (
		<section className="subsection payment-history" >
			<h3 className="u-green-text">Betalingsoversigt</h3>
			{items.map( (item, index) => <PaymentItem key={index} {...item} /> )}
		</section>
	)
}
PaymentHistory.displayName = 'PaymentHistory';
PaymentHistory.propTypes = {};
PaymentHistory.defaultProps = {};

export default PaymentHistory;


import React from 'react';
import { withRouter } from 'react-router-dom'
import {connect} from 'react-redux'


const UploadPageModal = ({visibleModalData, history}) => {
	const {missingFiles, next} = visibleModalData
	const onClickNext = () => {
		if (next) {
			history.push(next)
		}
	}
    return (
        <div className="SelfSaleFrontmodal-component modal__component">
            <h2 className="u-green-text">Super!</h2>
            <p>Husk at du kan altid tilføje/fjerne dokumenter under din profil senere.</p>
	        {/*{missingFiles.map((name,i)=><p key={i}>{name}</p>)}*/}
            <button onClick={onClickNext} className="button">Ok - forstået</button>
        </div>
    )
}
UploadPageModal.displayName = 'UploadPageModal';
UploadPageModal.propTypes = {};
UploadPageModal.defaultProps = {};

const mapStateToProps = state => {
	return {
		visibleModalData: state.app.visibleModalData
	}
}

export default withRouter(connect(mapStateToProps)(UploadPageModal));
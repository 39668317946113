import React from 'react';
import SaleFrontCards from './SaleFrontCards'


const title = "Skal du sælge din andelsbolig?"
const subtitle = "Vi hjælper dig trygt og godt igennem dit boligsalg. Vælg én af de tre nedenstående pakker"
const content = `<p>Har du masser af tid, lyst og nerver til at sælge din andelsbolig selv, så vælg Selvsalg. Det er gratis og vi stiller professionelle værktøjer til rådighed, såsom salgsannonce, online salgsopstilling og dokumentarkiv. Vi har mange opskrevne købere, og så er professionel hjælp kun ét klik væk hvis du skulle have brug for hjælp undervejs.</p>
<p>Skal vi ordne hele molevitten for dig, fra A-Z, så vælg trygt Totalsalg og du kan sove HELT roligt om natten - du er i trygge hænder hos os! </p>
<p>Medsalg giver dig det bedste fra begge verdener. Her får du høj sikkerhed, en smidig og sikker salgsproces samt en stor besparelse ved selv at have kundekontakten og fremvise din bolig. Max valuta for pengene.</p>
<p>Hvilken pakke vælger DU?</p>`
const imageUrl = "assets/img/img-salefront.jpg"

const SaleFrontPage = () => (
    <div className="sale-component">
        <section className="sale">
            <div className="sale__img bg-img bg-img--green" style={{backgroundImage: `url( ${imageUrl} )`}}></div>
            <div className="container">
                <div className="row">
                    <div className="col-s-12">
                        <div className="sale__content content-card">
                            <h1 className="sale__title title u-green-text">{title}</h1>
                            <h4 className="sale__subtitle u-green-text">{subtitle}</h4>
                            <p className="sale__content" dangerouslySetInnerHTML={{__html: content}}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <SaleFrontCards/>
    </div>
)

SaleFrontPage.displayName = 'SaleFrontPage';
SaleFrontPage.propTypes = {};
SaleFrontPage.defaultProps = {};

export default SaleFrontPage;

// This file contains the url paths constants

const HOME = '/'
const BUY = '/koeb'
const PROFILE = '/profil'
const SALE = '/saelg'
const SELF_SALE = '/saelg/selvsalg'
const SELF_SALE_CREATE = SELF_SALE + '/opret'
const BUYER_INDEX = '/koeberservice'
const BUYER_INDEX_CREATE = BUYER_INDEX + '/opret'
const CONTACT = '/kontakt'
const PARTNERS = "/partnere"
const SWAP = '/bytte'
const SWAP_CREATE = SWAP + '/opret'


const mappedPathNames = {}
mappedPathNames[SELF_SALE] = 'Selvsalg'
//mappedPathNames[HOME] = ''
mappedPathNames[BUY] = 'Køb'
mappedPathNames[PROFILE] = 'Profil'
mappedPathNames[SALE] = 'Sælg'
mappedPathNames[BUYER_INDEX] = 'Køberservice'
mappedPathNames[PARTNERS] = "Partnere"
mappedPathNames[CONTACT] = 'Kontakt'


export function pathNameToMenuTitle(pathname) {
    // console.log(pathname)
    if (pathname in mappedPathNames) return mappedPathNames[pathname]
    for (const key in mappedPathNames) {
        if (pathname.indexOf(key) === 0) {
            return mappedPathNames[key]
        }
    }
    return 'Andelshandel'
}

export const URLS = {
    HOME: HOME,
    CONTACT: '/kontakt',
    TEST: '/test',
    PARTNERS: "/partnere",
    BUY: {
        FRONT: BUY,
        CASE: BUY + '/sag/:id',
        SELF_SALE_CASE: BUY + '/selvsalg/:id'
    },
    BUYER_INDEX: {
        FRONT: BUYER_INDEX,
        CREATE: {
            FRONT: BUYER_INDEX_CREATE,
            USER: BUYER_INDEX_CREATE + '/bruger',
            USER_MODAL: BUYER_INDEX_CREATE + '/bruger/success',
            ACTIVATION: BUYER_INDEX_CREATE + '/aktiver',
            WHISHES: BUYER_INDEX_CREATE + '/oensker',
            PROFILE: BUYER_INDEX_CREATE + '/profil',
            PAYMENT: BUYER_INDEX_CREATE + '/betaling',
            SUCCESS_MODAL: BUYER_INDEX_CREATE + '/betaling/success',
            LOAN_PROPOSAL1: BUYER_INDEX_CREATE + '/bank',
            LOAN_PROPOSAL2: BUYER_INDEX_CREATE + '/bank/accept',
        }
    },
    SALE: {
        FRONT: SALE,
        SELF_SALE: {
            FRONT: SELF_SALE,
            CREATE: SELF_SALE_CREATE,
            USER: SELF_SALE_CREATE + '/bruger',
            ACTIVATION: SELF_SALE_CREATE + '/aktiver',
            ASSOCIATION: SELF_SALE_CREATE + '/forening',
            UPLOAD_PAGE: SELF_SALE_CREATE + '/dokumenter',
            SELL_FORM: SELF_SALE_CREATE + '/opretsalg',
            SELECT_PHOTO_PACKAGE: SELF_SALE_CREATE + '/fotopakke',
            PHOTO_UPLOAD: SELF_SALE_CREATE + '/billeder',
            SELECT_PRO_HELP: SELF_SALE_CREATE + '/proff',
            FINISH: SELF_SALE_CREATE + '/feardig',
        }
    },
    SWAP: {
        FRONT: SWAP,
        NEW: SWAP + '/nye',
        CASE: SWAP + '/bolig/:uuid',
        CONTACT_REQUEST_CREATED: SWAP + '/afsendt',

        CREATE: {
            S1_USER: SWAP_CREATE + '/bruger',
            S2_ACTIVATION: SWAP_CREATE + '/aktiver',
            S3_ASSOCIATION: SWAP_CREATE + '/forening',
            S4_OWN_RESIDENCE: SWAP_CREATE + '/bolig',
            S5_SELECT_PHOTO_PACKAGE: SWAP_CREATE + '/fotopakke',
            S6_PHOTO_UPLOAD: SWAP_CREATE + '/billeder',
            S7_WHISHES: SWAP_CREATE + '/oensker',
            S8_CONDITIONS: SWAP_CREATE + '/vilkaar'
        }
    },
    PROFILE: {
        HOME: PROFILE,
        BUYER_INDEX: PROFILE + '/koeberkartotek',
        SELF_SALE: PROFILE + '/selvsalg',
        SWAP: PROFILE + '/bytte'
    },
    RESET_PASSWORD: '/resetpassword/:token'
}

import React from 'react';


export const unpackQraphQLErrors = graphQLErrors => {
    return graphQLErrors.map( error => JSON.parse(error.message.replace("'", '"')))
}

const Errors= ({errors}) => {
    if (errors === null) return <span></span>
    else return (
        <div className="errors">
            {errors.map((e, i) => {
             const msg = typeof e === 'string' ? e : e.message

             return <div key={i} className="error">{msg}</div>})
            }
        </div>
    )
}

export default Errors



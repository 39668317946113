import React from 'react';
import { connect } from 'react-redux'
import CheckListItem from '../../../shared/CheckListItem'
import * as actions from 'actions'


const guaranteeList = [
  "Et sikkert og guidende miljø der hjælper dig trygt gennem dit salg.",
  "Flot, professionel mægler-annonce på sitet, som du kan dele på de sociale medier.",
  "Professionelle salgsværktøjer såsom salgsopstilling og dokumentarkiv.",
  "Mangler du en køber så finder vi gerne en for dig.",
  "Professionel hjælp er kun et opkald, sms eller mail væk.",
]

const rememberList = [
	"Du er selv 100% ansvarlig for at alle oplysninger i annoncen er korrekte.",
	"Orientere dig grundigt om hvilke krav din forening har til el-, vvs-, og vurderingsrapport",
	"Sætte dig ind i netop din forenings salgsprocedure.",	
	"Sikre dig at du overholder eventuelle vedtægtsbestemte regler omkring ventelister og forkøbsret",
	"Ønsker du vi tager ansvaret, så vælg <a href='javascript:history.go(-1)'>Medsalg</a> i stedet.",
]


const SelfSaleFrontPage = ({dispatch}) => {
    const onToggleModal = () => dispatch(actions.setVisibleModal('SELF_SALE_FRONT_MODAL'))
    return (
      <div className="selfsale__front">
		<section className="section subsection u-lightgrey">
			<div className="container">
				<div className="row">
					<div className="col-s-12 col-l-6 col-l-push-3 ">
						<div className="flex-column u-center-text">
							<img src="/assets/img/img-house.png" className="icon icon--padded icon--house"/>
					  		<h1 className="u-green-text">Perfekt, vi hjælper dig igennem dit selvsalg.</h1>
							<button className="button" onClick={onToggleModal}>Lad os gå igang</button>
						</div>
					</div>
				</div>
			</div>		
		</section>
		<section className="section u-white">
			<span className="price-bubble u-darkgreen">0
				<svg className="icon icon--price-bubble icon--darkgreen">
					<use href="#icon-price-bubble"></use>
				</svg>
			</span>

			<div className="container">
				<div className="row subsection">
					<div className="col-s-12 col-l-5 ">
						<div className="sale__height-wrapper">
					  		<h2 className="u-green-text u-light-text">Selvsalg er for dig  der vil selv, og har tiden til det.</h2>
					  		<h4 className="u-green-text">Vi garanterer:</h4>
						</div>
				  		<ul className="checklist">
				  			{ guaranteeList.map( (string, index) => <CheckListItem counter={false} key={index} label={string} /> )}
				  		</ul>
					</div>
					<div className="col-s-12 col-l-6 col-l-push-1">
						<div className="sale__height-wrapper">
				  			<h2 className="u-light-text u-green-text">Det skal du huske</h2>
				  		</div>
				  		<ul className="checklist">
				  			{ rememberList.map( (string, index) => <CheckListItem counter={true} key={index} label={string} /> )}
				  		</ul>
					</div>
				</div>
				<div className="row">
					<div className="col-s-12 col-l-6 col-l-push-3">
						<div className="flex-column u-center-text">

							<h2 className="u-green-text">Er du stadig i tvivl?</h2>
							<button className="button" onClick={onToggleModal}>Lad os gå igang</button>
						</div>
					</div>
				</div>
			</div>		
		</section>
      </div>
    );
}

SelfSaleFrontPage.displayName = 'SelfSaleFrontPage';
SelfSaleFrontPage.propTypes = {};
SelfSaleFrontPage.defaultProps = {};

const mapStateToProps = state => {
  return state.sale
}

export default connect(mapStateToProps)(SelfSaleFrontPage);

import React from "react"
import * as utils from "utils"

const CaseInfo = ({ street, zipCode, city, price, caseId, type, number, floorSide }) => {
  if (type === "openHouse") {
    return (
      <p>
        <strong>
          {street} {number}, {floorSide}
        </strong>
        <br></br>
        {zipCode} {city}
        <br></br>
        Sagnr.: {caseId}
        <br></br>
      </p>
    )
  }

  return (
    <p>
      <strong>
        {street} {number}, {floorSide}
      </strong>
      <br></br>
      {zipCode} {city}
      <br></br>
      Sagnr.: {caseId}
      <br></br>
      Pris {utils.kr(price)}
    </p>
  )
}

export default CaseInfo

import React from 'react';

const UploadWidget = ({fieldName, label, count, fileName=null, onUpload, multiple=false, accept=""}) =>  {

    return (
    <div className="input-field__upload">
        <label className="button button--darkgreen" htmlFor={'upload'+count}>
			<svg className="icon icon--white icon--upload-arrow">
				<use xlinkHref="#icon-upload-arrow"></use>
			</svg>
			<svg className="icon icon--white icon--upload-bar">
				<use xlinkHref="#icon-upload-bar"></use>
			</svg>

            <input multiple={multiple} onChange={onUpload} id={'upload'+count} name={fieldName} type="file" accept={accept}></input>

        	<p>
		        {fileName===null ? "Upload her" : fileName}
        	</p>
        </label>
      </div>
    );
}

UploadWidget.displayName = 'UploadWidget';
UploadWidget.propTypes = {};
UploadWidget.defaultProps = {};

export default UploadWidget;

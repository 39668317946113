import React from 'react'
import * as utils from 'utils'
import * as values from 'values'
import SwapApartmentCard from './SwapApartmentCard'
import "./style.css"
import { mapSwapApartmentProps } from "components/pages/Swap/swapHelpers";

const getAddress =  ({addressStreetName, addressNumber, addressFloor}) => {
    return `${addressStreetName} ${addressNumber} ${addressFloor ? values.FLOOR_OPTIONS[addressFloor] : ""}`

}
const SwapApartment = (props) => {
    if (props.loading) return (
        <div className="col-s-12 col-m-6 col-l-4">
            <div className="apartment-card is-loading"></div>
        </div>
    )

    const tempMapLocation = "assets/img/img-location.jpg"
    const showUrl = props.showUrl == undefined ? true : props.showUrl
    const url = showUrl ? utils.caseUrlFromTypename(values.SWAP_CASE_TYPE_NAME, props.uuid) : false

    const mappedProps = mapSwapApartmentProps(props, url)
    return (
        <div className="col-s-12 col-m-6 col-l-4">
            <SwapApartmentCard {...props} {...mappedProps} />
        </div>
    )
}

export default SwapApartment
import axios from 'axios'
import {getToken} from "./tokenManager";

export function uploadFile(file){
	const token = getToken();
	const formData = new FormData()
	formData.append('file', file)
	return axios({
			method: 'post',
			url: '/files/upload/file/',
			data: formData,
			headers: {
				'authorization': token ? `JWT ${token}` : null,
				'Content-Type': 'multipart/form.data'
			},
		}
	)
}


export function uploadImage(file){
	const token = getToken();
	const formData = new FormData()
	formData.append('file', file)
	return axios({
			method: 'post',
			url: '/files/upload/image/',
			data: formData,
			headers: {
				'authorization': token ? `JWT ${token}` : null,
				'Content-Type': 'multipart/form.data'
			},
		}
	)
}

export function uploadSelfSaleImages(files, self_sale_id){
	const token = getToken();
	const formData = new FormData()
	for (const i in files){
		formData.append('file_'+i, files[i])
	}
	return axios({
			method: 'post',
			url: '/files/upload/selfsale_images/'+ self_sale_id + '/',
			data: formData,
			headers: {
				'authorization': token ? `JWT ${token}` : null,
				'Content-Type': 'multipart/form.data'
			},
		}
	)
}


export function uploadSwapImages(files, swap_id){
	const token = getToken();
	const formData = new FormData()
	for (const i in files){
		formData.append('file_'+i, files[i])
	}
	return axios({
			method: 'post',
			url: '/files/upload/swap_images/' + swap_id + '/',
			data: formData,
			headers: {
				'authorization': token ? `JWT ${token}` : null,
				'Content-Type': 'multipart/form.data'
			},
		}
	)
}

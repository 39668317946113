import React, { useState } from 'react'
import {graphql} from 'react-apollo'
import * as queries from 'queries'
import {getDefault} from 'content'



const HTMLContent = ({data, textId, className="html-content", withHeadline=false, breakPoint=false}) => {
    const [isCollapsed, setCollapsed] = useState(true)
    if (data.loading) return <div className="section--loading"></div>
    let siteText = data.siteText
    if (siteText===null || siteText===undefined){
        siteText = getDefault(textId)
    }
    const headline = withHeadline ? <h1>{siteText.headline}</h1> : ""
    const style = breakPoint && isCollapsed ? {
        maxHeight: breakPoint + 'px'
    } : {}

    return (
        <div className="align-center">
            <div className={className + (breakPoint && isCollapsed ? ' collapsed' : '')} style={style}>
                {headline}
                <div dangerouslySetInnerHTML={{__html: siteText.text}}>
                </div>
            </div>
            {breakPoint && isCollapsed ? <button className="button button--clear u-center" onClick={e => setCollapsed(!isCollapsed)}>Se Mere</button> : "" }
        </div>
    )
}

export default graphql(queries.SITE_TEXT_QUERY, {
    options: ({textId}) => ({
            variables: {
                textId: textId
            }
        })

})(HTMLContent)

import React from 'react';
const CheckListItem = ({label, counter=false}) => {
	if (counter) {
		return (
			<li className="checklist-item counter" >
			<span dangerouslySetInnerHTML={{__html: label }}/>
			</li>
		)
	} else {
		return (
		  <li className="checklist-item">
			<svg className="icon icon--check icon--lightgreen">
			  <use xlinkHref="#icon-check"></use>
			</svg>
			<span dangerouslySetInnerHTML={{__html: label }} />
		  </li>
		)
	}
}
CheckListItem.displayName = 'CheckListItem';
CheckListItem.propTypes = {};
CheckListItem.defaultProps = {};

export default CheckListItem;

import React from 'react';
import { Link } from 'react-router-dom'
import { URLS } from 'urls'
import * as actions from 'actions'

const SelfSaleFrontModal = ({dispatch}) => {
  return (
    <div className="SelfSaleFrontmodal-component modal__component">
        <h2 className="u-green-text">Bemærk!</h2>
        Du skal selv undersøge hvilke rapporter der kræves udarbejdet i din forening. <br/>
        Se i vedtægterne, eller kontakt din andelsformand / administrator.
        <Link onClick={() => dispatch(actions.setVisibleModal(null))} className="button" to={ URLS.SALE.SELF_SALE.CREATE }>Ok - det lyder godt</Link>
    </div>
  );
}

SelfSaleFrontModal.displayName = 'SelfSaleFrontModal';
SelfSaleFrontModal.propTypes = {};
SelfSaleFrontModal.defaultProps = {};

export default SelfSaleFrontModal;

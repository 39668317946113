import React from 'react';
import {CASE_STATUS_TYPES} from "../../values";
import * as values from 'values'

const Banner = (props) => {
    const {status, isSelfSale=false, openHouseUpcoming=false, isUnderBuyerRetraction=false, isSwap=false,
        swapBannerState=undefined, loading=false} = props
    const isSold = status === CASE_STATUS_TYPES.SOLD
    if (loading) return <span className="banner loading">Solgt</span>
    if (isSold) return <span className="banner sold">Solgt</span>
    if (isSelfSale) return <span className="banner selfsale">Selvsalg</span>
    if (openHouseUpcoming) return <span className="banner open-house">Åbent hus</span>
    if (isUnderBuyerRetraction) return <span className="banner reserved">Reserveret</span>
    if (isSwap) {
        switch(swapBannerState){
            case values.SWAP_BANNER_STATES.SENT:
                return <span className="banner banner-swap-sent">Kontaktet</span>
            case values.SWAP_BANNER_STATES.RECEIVED:
                return <span className="banner banner-swap-received">Modtaget</span>
            default:
                return <span className="banner banner-swap">Bytte</span>
        }
    }
    return null
}

export default Banner
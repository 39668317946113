import client from 'client'
import * as mutations from 'mutations'
import * as queries from 'queries'


export function changeRange(fieldName, min, max) {
    return {
        type: "CHANGE_BUYER_RANGE",
        payload: {fieldName: fieldName, min:min, max:max}
    }
}

export function setBuyerCaseRequirements(requirements) {
    return {
        type: "SET_BUYER_REQUIREMENTS",
        payload: requirements
    }
}

export function setBuyerCaseAreas(areas) {
    return {
        type: "SET_BUYER_AREAS",
        payload: areas
    }
}

export function changeProfileDescription(value) {
	return {
		type: 'CHANGE_BUYER_PROFILE_DESCRIPTION',
		payload: value
	}
}

export function toggleTerms() {
    return {
        type: 'TOGGLE_BUYER_TERMS'
    }
}


export function submitBuyerCase(input) {
    return dispatch => dispatch({
        type: "SUBMIT_BUYER_CASE",
        payload: client.mutate({
            mutation: mutations.ADD_OR_MODIFY_BUYER_CASE_MUTATION,
            variables : {input:input},
	        refetchQueries: [{
		        query: queries.UB_USER_QUERY
	        }]
        })

    })
	    //.then(() => dispatch(actions.updateUser()))
}


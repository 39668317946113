import moment from 'moment'
import {BACKEND_URL} from "config";
import * as values from 'values'

export function daysSince(dateString) {
    return moment().diff(moment(dateString), 'days')
}

export function step(value, steps) {
    return Math.round(value / steps) * steps
}

export function absolute_url_backend(path) {
    return BACKEND_URL + path
}

export function listByKey(list, key) {
    let l = []
    for (const elem of list) {
        l.push(elem[key])
    }
    return l
}

export function getProperty(propertyName, object) {
    /*
    extracts value from object using dot notation, returns undefined if not found
     */
    var parts = propertyName.split("."),
        length = parts.length,
        i,
        property = object || this;

    for (i = 0; i < length; i++) {
        if (property===undefined) return undefined
        property = property[parts[i]];
    }

    return property;
}



// Extracting values from obj by dot notation so we can
// define our fields even in nested objects
export function extractValues(fields, obj) {
    let list = []
    for (const field of fields) {
        const value = getProperty(field.key, obj)
        if (value!==undefined) {
            if (field.format===undefined || null) {
                list.push({label: field.label, value: value})
            }
            else {
                list.push({label: field.label, value: field.format(value)})
            }
        }
    }
    return list
}


// Getting address
export function addressFromStreetNumberFloorSide(street, number, floorSide){

    if ([null, undefined, ""].includes(floorSide)){
        return `${street} ${number}`
    }
    return `${street} ${number}. ${floorSide}`
}

export function mapSubmitToInputForFields(e, fields) {
    let mappedInput = {}
    for (const field of fields) {
        mappedInput[field.name] = e.target[field.name].value
    }
    return mappedInput
}

export function thousandSepparator(val, name) {
    if (val === null || val === undefined) return undefined
    return val.toString().replace(/(?!^)(\d{3})(?=(?:\d{3})*$)/g, '.$1')
}

export function parseKr(value, name) {
    if (value==="")
        return null
    return value.split('.').join('')
}


export function kr(val) {
    if (val < 1000) return `${val} kr.`
    var newVal = val.toString().replace(/(?!^)(\d{3})(?=(?:\d{3})*$)/g, '.$1')
    return `${newVal} kr.`
}

export function kvm(val) {
    return `${val} m²`
}

export function stk(val) {
    return `${val} stk.`

}

export function percentage(val) {
    return `${val} %`
}

export const plus = (fn, max) => (val) => {
    if (val===max) return fn(val) + ' +'
    return fn(val)
}

export function ensure_ssl(url) {
    if (url === null || url === undefined) return null
    const cdnUrl = url.replace('cdn.m2', 'cdn-m2').replace(/^http:\/\//i, 'https://')
    return cdnUrl
}

export function caseUrlFromTypename(typename, caseId) {
    switch(typename) {
        case values.SELF_SALE_CASE_TYPE_NAME:
            return '/koeb/selvsalg/' + caseId
        case values.SWAP_CASE_TYPE_NAME:
            return '/bytte/bolig/' + caseId
        default:
            return '/koeb/sag/' + caseId
    }
}

// basic filter for getting front image
export const getFrontImage = (caseImages, frontpageImage) => {
    if (frontpageImage !== null && frontpageImage !== undefined) return frontpageImage
    const frontpages = caseImages.filter((img) => img.frontpage===true)
    if (frontpages.length > 0) {
        return frontpages[0]
    }
    else if (caseImages.length > 1) return caseImages[1]
    else if (caseImages.length > 0) return caseImages[0]
    else return {url: '/assets/img/img-no-image.jpg'}
}

export const getFrontPlandrawing = (caseImages, plandrawings = []) => {
    if (plandrawings.length > 0) return plandrawings[0]
    else if (caseImages.length > 1) return caseImages[1]
    else if (caseImages.length > 0) return caseImages[0]
    else return {url: '/assets/img/img-no-image.jpg'}
}

// Errors
export const responseErrors = (res, defaultError) => {
    try {
        return res.graphQLErrors.map(x => x.message)
    }
    catch (e) {
        let errorMessages = []
        if (defaultError !== undefined) {
            errorMessages.push(defaultError)
        }
        else {
            errorMessages.push('Der skete en fejl')
        }
        return errorMessages
    }
}


export const isSelfSale = caseTypeName => caseTypeName === values.SELF_SALE_CASE_TYPE_NAME
export const isRegularSale = caseTypeName => caseTypeName === values.CASE_TYPE_NAME

// enums from graphql are formatted 'A_2
export const enumToInt = (e) => parseInt(e.substring(2))
// medsalg

export const isEmpty = (value) => [null, undefined, ""].indexOf(value)!==-1

export const shuffle = (array) => {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}
import React from 'react';
import SearchForm from 'components/shared/forms/SearchForm'
import SearchResult from './SearchResult'
import ImageFullSection from 'components/shared/ImageFullSection'


const BuyFront = () => {
    if ( window.innerWidth < 768 ) {
        window.scroll(0,700)
    }
  return (
    <div className="buysearch-component">
      <SearchForm />
      <SearchResult />
      <ImageFullSection />
    </div>
  );
}

BuyFront.displayName = 'BuyFront';
BuyFront.propTypes = {};
BuyFront.defaultProps = {};

export default BuyFront;

import React from 'react';
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import * as actions from 'actions'
import Errors, { unpackQraphQLErrors } from "../../shared/Errors";
import { TOKEN_IS_VALID_QUERY, UB_USER_QUERY } from "queries";
import SwapResult from "../Swap/SwapResult";
import SwapFrontPage from "../Swap/SwapFrontPage";
import { URLS } from "../../../urls";
import SwapRequestCreated from "../Swap/SwapRequestCreated";
import SwapPage from "../Swap/SwapPage";
import CreateSwap from "../Swap/CreateSwap";
import { DoesNotExist } from "../index";
import { Query, Mutation } from "react-apollo";
import { RESET_PASSWORD } from "../../../mutations";

const ResetPassword = ({app, match, dispatch}) => {
  const token = match.params.token
  const onSubmit = (e) => {
    e.preventDefault()
    const password = e.target.password.value
    dispatch(actions.resetPassword({token, password}))
  }

  const onComplete = ({resetPassword}) => {
    if (resetPassword && resetPassword.success){
      dispatch(actions.setVisibleModal('RESET_PASSWORD_SUCCESS'))
    }
  }
  return (
    <div className="reset-password">
      <section className="section u-lightgrey flex-center u-full-height">
        <div className="container flex-center">
          <Query query={TOKEN_IS_VALID_QUERY} variables={{token: token}}>
            {({loading, error, data}) => {
              if (loading) return <div className="section--loading"></div>
              if (error) {
                return `Linket virker ikke mere`
              }

              return (
                <Mutation mutation={RESET_PASSWORD} onCompleted={onComplete} onError={(e) => console.log(e)}>
                  {(resetPassword, {loading, error}) => {
                    if (loading) return 'loading...'
                    var error_messages = []
                    if (error) {
                      let messages = error.graphQLErrors.map(x => JSON.parse(x.message.replace(/'/g,'"')))
                      error_messages = messages[0].map(m => {return {message: m}})
                    }
                    return (
                      <form onSubmit={e => {
                        e.preventDefault()
                        const password = e.target.password.value
                        resetPassword({variables: {input: {token: token, password: password}}})
                      }}>
                        <h2 className="u-green-text section-title__block section-title">Nulstil adgangskode</h2>
                        { error ? <Errors errors={error_messages}/> : ""}

                        <label htmlFor="password">Ny adgangskode</label>
                        <input type="password" label="Adgangskode" name="password"></input>
                        <button className="button">Nulstil adgangskode</button>
                      </form>
                    )
                  }
                  }

                </Mutation>
              )
            }
            }

          </Query>

        </div>
      </section>
    </div>
  );
}


ResetPassword.displayName = 'ResetPassword';
ResetPassword.propTypes = {};
ResetPassword.defaultProps = {};

const mapStateToProps = state => ({
  app: state.app
})


const ResetPasswordWithDataAndState = withRouter(connect(mapStateToProps)(ResetPassword))

export default ResetPasswordWithDataAndState



export const BACKEND_URL = process.env.HOST

export const GMAP_API_KEY = "AIzaSyC_HccusTEaqj76G_BqiD0kpxwOTmZzD-Q"

export const RANGES = {
  MIN_SIZE: 30,
  MAX_SIZE: 200,
  MIN_ROOMS: 1,
  MAX_ROOMS: 5,
  MIN_PRICE: 0,
  MAX_PRICE: 5000000,
  MIN_RENT: 1000,
  MAX_RENT: 20000,
}

// Look in cases.models.CaseContact
export const CONTACT_TYPES = {
  CONTACT_AGENT: "2",
  BOOK_SHOW: "3",
  BIDDING: "4",
  CO_SALE: "5",
  TOTAL_SALE: "6",
  OPEN_HOUSE: "7",
}

// Look in cases.models.selfSaleCase
export const STATUS_TYPES = {
  BEEING_CREATED: "A_1",
  ACTIVE: "A_2",
  SOLD: "A_3",
  REMOVED: "A_4",
  PAUSE: "A_5",
}

// This is the enum conversion of status types on cases see: cases.models.case
export const CASE_STATUS_TYPES = {
  BEEING_CREATED: "A_1",
  ACTIVE: "A_2",
  SOLD: "A_3",
  REMOVED: "A_4",
}

export const LOAN_PROPOSAL_CONTACT_TIMESPAN_TYPES = {
  NOT_INTERESTED: "A_0",
  WEEK_9_16: "A_1",
  WEEK_16_19: "A_2",
  WEEKEND_9_13: "A_3",
}

export const FLOOR_OPTIONS = {
  ST: "st.",
  A_1: "1.",
  A_2: "2.",
  A_3: "3.",
  A_4: "4.",
  A_5: "5.",
  A_6: "6.",
}

export const SWAP_BANNER_STATES = {
  NONE: 0,
  RECEIVED: 1,
  SENT: 2,
}

export const SWAP_IS_INTERESTED_CHOICES = {
  NOT_INTERESTED: "A_0",
  INTERESTED: "A_1",
  LATER: "A_2",
}

export const DEFAULT_AGENT_EMAIL = "cw@andelshandel.dk"

export const SELF_SALE_CASE_TYPE_NAME = "SelfSaleCaseNode"
export const SWAP_CASE_TYPE_NAME = "SwapCaseNode"

export const CASE_TYPE_NAME = "CaseNode"

export const terms = `
<?xml version="1.0" encoding="utf-8"?>
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN"
"http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" lang="en" xml:lang="en">
<head>
<!-- 2017-11-13 Mon 15:11 -->
<meta http-equiv="Content-Type" content="text/html;charset=utf-8" />
<meta name="viewport" content="width=device-width, initial-scale=1" />
<title></title>
<meta name="generator" content="Org mode" />
<meta name="author" content="Jeppe Tuxen" />
<style type="text/css">
 <!--/*--><![CDATA[/*><!--*/
  .title  { text-align: center;
             margin-bottom: .2em; }
  .subtitle { text-align: center;
              font-size: medium;
              font-weight: bold;
              margin-top:0; }
  .todo   { font-family: monospace; color: red; }
  .done   { font-family: monospace; color: green; }
  .priority { font-family: monospace; color: orange; }
  .tag    { background-color: #eee; font-family: monospace;
            padding: 2px; font-size: 80%; font-weight: normal; }
  .timestamp { color: #bebebe; }
  .timestamp-kwd { color: #5f9ea0; }
  .org-right  { margin-left: auto; margin-right: 0px;  text-align: right; }
  .org-left   { margin-left: 0px;  margin-right: auto; text-align: left; }
  .org-center { margin-left: auto; margin-right: auto; text-align: center; }
  .underline { text-decoration: underline; }
  #postamble p, #preamble p { font-size: 90%; margin: .2em; }
  p.verse { margin-left: 3%; }
  pre {
    border: 1px solid #ccc;
    box-shadow: 3px 3px 3px #eee;
    padding: 8pt;
    font-family: monospace;
    overflow: auto;
    margin: 1.2em;
  }
  pre.src {
    position: relative;
    overflow: visible;
    padding-top: 1.2em;
  }
  pre.src:before {
    display: none;
    position: absolute;
    background-color: white;
    top: -10px;
    right: 10px;
    padding: 3px;
    border: 1px solid black;
  }
  pre.src:hover:before { display: inline;}
  /* Languages per Org manual */
  pre.src-asymptote:before { content: 'Asymptote'; }
  pre.src-awk:before { content: 'Awk'; }
  pre.src-C:before { content: 'C'; }
  /* pre.src-C++ doesn't work in CSS */
  pre.src-clojure:before { content: 'Clojure'; }
  pre.src-css:before { content: 'CSS'; }
  pre.src-D:before { content: 'D'; }
  pre.src-ditaa:before { content: 'ditaa'; }
  pre.src-dot:before { content: 'Graphviz'; }
  pre.src-calc:before { content: 'Emacs Calc'; }
  pre.src-emacs-lisp:before { content: 'Emacs Lisp'; }
  pre.src-fortran:before { content: 'Fortran'; }
  pre.src-gnuplot:before { content: 'gnuplot'; }
  pre.src-haskell:before { content: 'Haskell'; }
  pre.src-java:before { content: 'Java'; }
  pre.src-js:before { content: 'Javascript'; }
  pre.src-latex:before { content: 'LaTeX'; }
  pre.src-ledger:before { content: 'Ledger'; }
  pre.src-lisp:before { content: 'Lisp'; }
  pre.src-lilypond:before { content: 'Lilypond'; }
  pre.src-lua:before { content: 'Lua'; }
  pre.src-matlab:before { content: 'MATLAB'; }
  pre.src-mscgen:before { content: 'Mscgen'; }
  pre.src-ocaml:before { content: 'Objective Caml'; }
  pre.src-octave:before { content: 'Octave'; }
  pre.src-org:before { content: 'Org mode'; }
  pre.src-oz:before { content: 'OZ'; }
  pre.src-plantuml:before { content: 'Plantuml'; }
  pre.src-processing:before { content: 'Processing.js'; }
  pre.src-python:before { content: 'Python'; }
  pre.src-R:before { content: 'R'; }
  pre.src-ruby:before { content: 'Ruby'; }
  pre.src-sass:before { content: 'Sass'; }
  pre.src-scheme:before { content: 'Scheme'; }
  pre.src-screen:before { content: 'Gnu Screen'; }
  pre.src-sed:before { content: 'Sed'; }
  pre.src-sh:before { content: 'shell'; }
  pre.src-sql:before { content: 'SQL'; }
  pre.src-sqlite:before { content: 'SQLite'; }
  /* additional languages in org.el's org-babel-load-languages alist */
  pre.src-forth:before { content: 'Forth'; }
  pre.src-io:before { content: 'IO'; }
  pre.src-J:before { content: 'J'; }
  pre.src-makefile:before { content: 'Makefile'; }
  pre.src-maxima:before { content: 'Maxima'; }
  pre.src-perl:before { content: 'Perl'; }
  pre.src-picolisp:before { content: 'Pico Lisp'; }
  pre.src-scala:before { content: 'Scala'; }
  pre.src-shell:before { content: 'Shell Script'; }
  pre.src-ebnf2ps:before { content: 'ebfn2ps'; }
  /* additional language identifiers per "defun org-babel-execute"
       in ob-*.el */
  pre.src-cpp:before  { content: 'C++'; }
  pre.src-abc:before  { content: 'ABC'; }
  pre.src-coq:before  { content: 'Coq'; }
  pre.src-groovy:before  { content: 'Groovy'; }
  /* additional language identifiers from org-babel-shell-names in
     ob-shell.el: ob-shell is the only babel language using a lambda to put
     the execution function name together. */
  pre.src-bash:before  { content: 'bash'; }
  pre.src-csh:before  { content: 'csh'; }
  pre.src-ash:before  { content: 'ash'; }
  pre.src-dash:before  { content: 'dash'; }
  pre.src-ksh:before  { content: 'ksh'; }
  pre.src-mksh:before  { content: 'mksh'; }
  pre.src-posh:before  { content: 'posh'; }
  /* Additional Emacs modes also supported by the LaTeX listings package */
  pre.src-ada:before { content: 'Ada'; }
  pre.src-asm:before { content: 'Assembler'; }
  pre.src-caml:before { content: 'Caml'; }
  pre.src-delphi:before { content: 'Delphi'; }
  pre.src-html:before { content: 'HTML'; }
  pre.src-idl:before { content: 'IDL'; }
  pre.src-mercury:before { content: 'Mercury'; }
  pre.src-metapost:before { content: 'MetaPost'; }
  pre.src-modula-2:before { content: 'Modula-2'; }
  pre.src-pascal:before { content: 'Pascal'; }
  pre.src-ps:before { content: 'PostScript'; }
  pre.src-prolog:before { content: 'Prolog'; }
  pre.src-simula:before { content: 'Simula'; }
  pre.src-tcl:before { content: 'tcl'; }
  pre.src-tex:before { content: 'TeX'; }
  pre.src-plain-tex:before { content: 'Plain TeX'; }
  pre.src-verilog:before { content: 'Verilog'; }
  pre.src-vhdl:before { content: 'VHDL'; }
  pre.src-xml:before { content: 'XML'; }
  pre.src-nxml:before { content: 'XML'; }
  /* add a generic configuration mode; LaTeX export needs an additional
     (add-to-list 'org-latex-listings-langs '(conf " ")) in .emacs */
  pre.src-conf:before { content: 'Configuration File'; }

  table { border-collapse:collapse; }
  caption.t-above { caption-side: top; }
  caption.t-bottom { caption-side: bottom; }
  td, th { vertical-align:top;  }
  th.org-right  { text-align: center;  }
  th.org-left   { text-align: center;   }
  th.org-center { text-align: center; }
  td.org-right  { text-align: right;  }
  td.org-left   { text-align: left;   }
  td.org-center { text-align: center; }
  dt { font-weight: bold; }
  .footpara { display: inline; }
  .footdef  { margin-bottom: 1em; }
  .figure { padding: 1em; }
  .figure p { text-align: center; }
  .inlinetask {
    padding: 10px;
    border: 2px solid gray;
    margin: 10px;
    background: #ffffcc;
  }
  #org-div-home-and-up
   { text-align: right; font-size: 70%; white-space: nowrap; }
  textarea { overflow-x: auto; }
  .linenr { font-size: smaller }
  .code-highlighted { background-color: #ffff00; }
  .org-info-js_info-navigation { border-style: none; }
  #org-info-js_console-label
    { font-size: 10px; font-weight: bold; white-space: nowrap; }
  .org-info-js_search-highlight
    { background-color: #ffff00; color: #000000; font-weight: bold; }
  .org-svg { width: 90%; }
  /*]]>*/-->
</style>
<script type="text/javascript">
/*
@licstart  The following is the entire license notice for the
JavaScript code in this tag.

Copyright (C) 2012-2017 Free Software Foundation, Inc.

The JavaScript code in this tag is free software: you can
redistribute it and/or modify it under the terms of the GNU
General Public License (GNU GPL) as published by the Free Software
Foundation, either version 3 of the License, or (at your option)
any later version.  The code is distributed WITHOUT ANY WARRANTY;
without even the implied warranty of MERCHANTABILITY or FITNESS
FOR A PARTICULAR PURPOSE.  See the GNU GPL for more details.

As additional permission under GNU GPL version 3 section 7, you
may distribute non-source (e.g., minimized or compacted) forms of
that code without the copy of the GNU GPL normally required by
section 4, provided you include this license notice and a URL
through which recipients can access the Corresponding Source.


@licend  The above is the entire license notice
for the JavaScript code in this tag.
*/
<!--/*--><![CDATA[/*><!--*/
 function CodeHighlightOn(elem, id)
 {
   var target = document.getElementById(id);
   if(null!==target) {
     elem.cacheClassElem = elem.className;
     elem.cacheClassTarget = target.className;
     target.className = "code-highlighted";
     elem.className   = "code-highlighted";
   }
 }
 function CodeHighlightOff(elem, id)
 {
   var target = document.getElementById(id);
   if(elem.cacheClassElem)
     elem.className = elem.cacheClassElem;
   if(elem.cacheClassTarget)
     target.className = elem.cacheClassTarget;
 }
/*]]>*///-->
</script>
</head>
<body>
<div id="content">
<div id="table-of-contents">
<h2>Table of Contents</h2>
<div id="text-table-of-contents">
<ul>
<li><a href="#org9f028a1">1. Vilkår ved selvsalg:</a>
<ul>
<li><a href="#orga36c43e">1.1. Oprettelse</a></li>
<li><a href="#org5f28cb8">1.2. Indhold i annonce:</a></li>
<li><a href="#orgfdf547b">1.3. Billedmateriale:</a></li>
<li><a href="#orgb432941">1.4. Opsigelse af annonce:</a></li>
<li><a href="#org3a21984">1.5. Kontakt:</a></li>
<li><a href="#org8a9f762">1.6. Vilkår Pro Fotopakke:</a></li>
<li><a href="#org82de3d5">1.7. Vilkår Mægler Hotline:</a></li>
</ul>
</li>
<li><a href="#org88b7bc7">2. Generelle vilkår for brug af sitet:</a>
<ul>
<li><a href="#org383c576">2.1. Regler for indholdet:</a></li>
<li><a href="#org376fbe3">2.2. Desuden forpligter du dig til IKKE at:</a></li>
<li><a href="#orgfbf5731">2.3. Ingen garanti for indholdet</a></li>
</ul>
</li>
</ul>
</div>
</div>
<div id="outline-container-org9f028a1" class="outline-2">
<h2 id="org9f028a1"><span class="section-number-2">1</span> Vilkår ved selvsalg:</h2>
<div class="outline-text-2" id="text-1">
</div><div id="outline-container-orga36c43e" class="outline-3">
<h3 id="orga36c43e"><span class="section-number-3">1.1</span> Oprettelse</h3>
<div class="outline-text-3" id="text-1-1">
<p>
Alle kan oprette sig som bruger af andelshandel.dk. Som bruger accepterer du at have ansvaret for alle handlinger på sitet, som foretages i dit brugernavn. Som udbyder forbeholder vi os ret til at stoppe din brugeradgang på ethvert tidspunkt og med enhver begrundelse.
Som sælger af en bolig bekræfter du at du enten er ejer af boligen eller har fuldmagt fra ejer af boligen til at sætte den til salg. Ejer du boligen sammen med en anden, eller bor sammen med en ægtefælle eller i et fast parforhold og i deler bopæl, bekræfter du med din oprettelse at have samtykke af denne til salg af boligen.
Ansvar:
Du er selv ansvarlig for korrektheden af alt indhold på din boligannonce samt indholdets korrekthed i de uploadede dokumenter samt information afgivet af dig under fremvisning, sms, mail og samtaler via andre medier, herunder mobil samtale. Andelshandel påtager sig ikke nogen formidlingsrolle i forhold til dit selvsalg, men stiller alene en online service til din rådighed som oprettet bruger af sitet andelshandel.dk.
Andelshandel kan derfor på ingen måde gøres ansvarlig, hverken helt eller delvist, i forhold til eventuelle tab, erstatningsansvar, juridiske omkostninger og lignende som du som bruger måtte blive ramt af, som følge af ukorrekte, upræcise, fejlagtige eller manglende oplysninger afgivet eller undladt i forbindelse med dit selvsalg.
</p>
</div>
</div>


<div id="outline-container-org5f28cb8" class="outline-3">
<h3 id="org5f28cb8"><span class="section-number-3">1.2</span> Indhold i annonce:</h3>
<div class="outline-text-3" id="text-1-2">
<p>
Du forpligter dig til kun at lægge indhold af relevant og retvisende karaktér for dit boligsalg  op på din boligannonce.
Du accepterer ikke at bruge anstødende sprogbrug eller ordvalg i din beskrivelser.
Du forpligtiger dig til ikke at lægge en bolig up til salg som du ikke enten er eneejer af, medejer af og med accept for salg af øvrige ejere eller en bolig for hvilken du har fuldmagt fra ejer til at forestå salg af boligen.
Andelshandel forbeholder sig retten til ubetinget og uden særskilt årsag at nedtage en boligannonce såfremt tekstbeskrivelse ikke lever op til de standarder som Andelshandel på ethvert givent tidspunkt måtte finde relevante. Andelshandel kan i den forbindelse ikke gøres ansvarlige for nogen former for tab, bristede forventninger, eller lignende forårsaget af annoncens nedtagning.
</p>
</div>
</div>




<div id="outline-container-orgfdf547b" class="outline-3">
<h3 id="orgfdf547b"><span class="section-number-3">1.3</span> Billedmateriale:</h3>
<div class="outline-text-3" id="text-1-3">
<p>
Du forpligter dig til at lægge retvisende billeder op af boligen af god karaktér som tydeligt, klart og god kvalitet viser hvordan boligen fremstår på salgstidspunktet.
Andelshandel forbeholder sig retten til ubetinget og uden særskilt årsag at nedtage en boligannonce såfremt billedmaterialet ikke lever op til de standarder som Andelshandel på ethvert givent tidspunkt måtte finde relevante. Andelshandel kan i den forbindelse ikke gøres ansvarlige for nogen former for tab, bristede forventninger, eller lignende forårsaget af annoncens nedtagning.
Kundekontakt:
Som bruger af andelshandel.dk / selvsælger, forpligter du dig til at tage kontakt/besvare de kundehenvendelser du modtager. Det være sig per mail, mobilopkald eller sms. Du forpligter dig til at omgås alle kundehenvendelser positivt og med respekt, god tone, undlade brug af grimt sprog og generelt behandle alle kundehenvendelser med respekt for modparten.
Andelshandel forbeholder sig retten til ubetinget og uden særskilt årsag at nedtage en boligannonce såfremt sælgers kommunikation overfor købekunder ikke lever op til de standarder som Andelshandel på ethvert givent tidspunkt måtte finde relevante. Andelshandel kan i den forbindelse ikke gøres ansvarlige for nogen former for tab, bristede forventninger, eller lignende forårsaget af annoncens nedtagning.
</p>
</div>
</div>


<div id="outline-container-orgb432941" class="outline-3">
<h3 id="orgb432941"><span class="section-number-3">1.4</span> Opsigelse af annonce:</h3>
<div class="outline-text-3" id="text-1-4">
<p>
Du kan opsige/nedtage din boligannonce uden varsel via ”Min Konto” uden noget ansvar overfor Andelshandel.
</p>
</div>
</div>


<div id="outline-container-org3a21984" class="outline-3">
<h3 id="org3a21984"><span class="section-number-3">1.5</span> Kontakt:</h3>
<div class="outline-text-3" id="text-1-5">
<p>
Med din oprettelse af gratis selvsalgsannonce på andelshandel.dk giver du samtidig Unik Boligsalg ret til at kontakte dig per mail, sms eller mobilopkald såfremt de ønsker at komme i kontakt med dig i følgende situationer/generelt med nedenstående indhold:
</p>
<ul class="org-ul">
<li>Forslag til rettelser i dit generelle materiale (billeder, tekst o.lign)</li>
<li>Drøftelse af prissætning på boligen, status på kundeaktivitet,  mm</li>
<li>Tilbud om at indstille en konkret potentiel køber som Unik Boligsalg måtte være i kontakt med. Du er dog som sælger på ingen måde forpligtiget til at sælge til denne person.</li>
<li>Tilbud om køb af tillægsydelser såsom men ikke begrænset til tilbud om;
<ul class="org-ul">
<li>Fotomateriale</li>
<li>Rådgivningsydelser</li>
<li>Tilbud om opgradering til Medsalg &amp; Totalsalg</li>
<li>Kampagnetilbud generelt</li>
<li>Øvrige tilbud der kan relateres til dit boligsalg</li>
<li>Tilbud om rådgivningsmøde med en bank eller flere som Unik Boligsalg måtte indgå samarbejdsaftaler med</li>
</ul></li>
</ul>
</div>
</div>


<div id="outline-container-org8a9f762" class="outline-3">
<h3 id="org8a9f762"><span class="section-number-3">1.6</span> Vilkår Pro Fotopakke:</h3>
<div class="outline-text-3" id="text-1-6">
<p>
Denne ydelse er ikke omfattet af solgt eller gratis, og betaling herfor vil blive opkrævet af Unik Boligsalg umiddelbart efter fotografen  har været på besøg.
Unik Boligsalg benytter sig af tredjepart ti at udføre fotoopgaven. Unik Boligsalg garanterer at der benyttes ét af Danmarks førende firmaer til opgaven, men det står Unik Boligsalg frit for at indgå aftale om fotografering af din bolig med det firma som Unik Boligsalg ønsker at indgå aftale med. Unik Boligsalg benytter sig pt af fotofirmaet Esoft.
Enhver klage over kvalitet, antal billeder, uønskede objekter på billeder og des lige vil blive præsenteret for Unik Boligsalgs samarbejdende fotofirma, men det vil være det samarbejdende fotofirma der træffer afgørelse i om klagen er berettiget eller ej, og Unik Boligsalg kan ikke gøres ansvarlig herfor.
Sælger er berettiget til at få de betalte udleveret før, under eller efter salget af boligen. Også ved manglende salg eller opsigelse.
</p>
</div>
</div>




<div id="outline-container-org82de3d5" class="outline-3">
<h3 id="org82de3d5"><span class="section-number-3">1.7</span> Vilkår Mægler Hotline:</h3>
<div class="outline-text-3" id="text-1-7">
<p>
Unik Boligsalg påtager sig med denne serviceydelse ingen form for formidlingsopgave og repræsentere ikke dig som sælger i forbindelse med dit boligsalg. Selvom du har tilkøbt produktet ”Mægler Hotline” er du fortsat selv entydigt selv ansvarlig for alt indhold mm jævnfør ovenstående punkter.
”Mægler Hotline” er en tilkøbt serviceydelse hvor Unik Boligsalg forpligter sig til at besvare og informere som man kan forvente af en ejendomsmægler omkring generelle forhold i forbindelse med salg af en andelsbolig, og specifikt omring salget af din andelsbolig UD FRA DET MATERIALE du som sælger har stillet til rådighed i form af beskrivende tekster og uploadede dokumenter i dit ”Dokumentarkiv” på det tidspunkt hvor rådgivningen fandt sted.
Unik Boligsalg er ikke ved sælger tilkøb af ”Mægler Hotline” forpligtiget til at udføre reelle opgaver for boligsælger såsom indhentelse af dokumenter og rapporter, indhentelse af andre oplysninger, deltagelse på fremvisninger, kontakt til kundeemner eller udføre andre opgaver på vegne af sælger, men alene at bistå sælger med professionel råd og vejledning, herunder fx procesmæssige spørgsmål,  faglige spørgsmål relateret til dit boligsalg, best practice for behandling af indsigelser,  rådgivning i forbindelse med konfliktløsning, rådgivning i forbindelse med forhandling.
Vilkår køberservice:
</p>
</div>
</div>
</div>






<div id="outline-container-org88b7bc7" class="outline-2">
<h2 id="org88b7bc7"><span class="section-number-2">2</span> Generelle vilkår for brug af sitet:</h2>
<div class="outline-text-2" id="text-2">
</div><div id="outline-container-org383c576" class="outline-3">
<h3 id="org383c576"><span class="section-number-3">2.1</span> Regler for indholdet:</h3>
<div class="outline-text-3" id="text-2-1">
<p>
Du accepterer, at du aldrig må uploade, e-maile eller på anden måde levere indhold:
</p>
<ul class="org-ul">
<li>som er ulovligt, skadeligt, truende, chikanerende, nedsættende, ærekrænkende, injurierende, pornografisk, eller som krænker en andens privatliv eller på nogen måde skader mindreårige,</li>
<li>som krænker, fornedrer, intimiderer eller er hadefuldt over for individer eller grupper af individer på baggrund af deres religion, køn, seksuelle orientering, race, etnicitet, alder eller handicap,</li>
<li>som foregiver at være fra en anden person eller gruppe end dig selv, eller som på et falsk grundlag angiver eller antyder din relation til en anden person eller gruppe, som indeholder personlige eller identificerende oplysninger om en anden person uden dennes udtrykkelige tilladelse,</li>
<li>som er usandt, vildledende, bedragerisk, misinformerende eller er et forsøg på under falske forudsætninger at lokke læsere ind på andre hjemmesider,</li>
<li>som krænker et patent, varemærke, forretningshemmelighed, copyright eller anden rettighed, eller som du ikke har lovgivningsmæssig eller kontraktlig ret til at offentliggøre,</li>
<li>som udgør eller indeholder annoncer eller anden form for markedsføring, herunder links til andre web-tjenester med henblik på at henvise brugere hertil, junk mail, spam, kædebreve, pyramidespil eller uopfordrede reklamer,</li>
<li>som udgør eller indeholder nogen form for annoncering eller kundehvervning, hvis indholdet offentliggøres på den besøgte hjemmeside, som ikke er beregnet til netop det formål, eller hvis indholdet er e-mailes til brugere af den besøgte hjemmeside, som har frabedt sig at blive kontaktet om andre tjenester, produkter eller kommercielle interesser,</li>
<li>som indeholder links til kommercielle tjenester eller hjemmesider, hvor det ikke udtrykkeligt er tilladt,</li>
<li>som annoncerer for enhver ulovlig tjeneste eller for ethvert produkt, som det ved lov er forbudt at sælge,</li>
<li>som indeholder virus eller anden computer-kode, filer eller programmer, som har til formål at afbryde, ødelægge eller begrænse brugen af computer-software, hardware eller telekommunikationsudstyr,</li>
<li>som med en overdreven mængde af meddelelser forstyrrer den normale strøm af kommunikation på den besøgte hjemmeside eller på anden måde hæmmer andre brugeres mulighed for at anvende denne web-tjeneste, eller</li>
<li>som udnytter vildledende e-mailadresser, fordrejede overskrifter eller andre lignende manipulationer til at skjule den virkelige oprindelse af dette indhold.</li>
</ul>
</div>
</div>


<div id="outline-container-org376fbe3" class="outline-3">
<h3 id="org376fbe3"><span class="section-number-3">2.2</span> Desuden forpligter du dig til IKKE at:</h3>
<div class="outline-text-3" id="text-2-2">
<ul class="org-ul">
<li>kontakte nogen, som har bedt om ikke at blive kontaktet,</li>
<li>forfølge eller på anden måde genere andre,</li>
<li>indsamle personlige oplysninger om andre med henblik på kommercielle eller ulovlige formål,</li>
<li>bruge automatiske metoder, inklusiv scripts, spiders, robotter, crawlere, værktøjer til data mining eller lignende til at downloade data fra eller uploade data til den besøgte hjemmeside,</li>
<li>uploade irrelevant indhold, gentage upload af det samme eller lignende indhold eller på anden måde belaste vores infrastruktur med urimelige eller uforholdsmæssigt store datamængder, eller</li>
<li>forsøge at opnå uautoriseret adgang til vores system eller netværk, eller deltage i enhver aktivitet, som søger at forstyrre, forringe eller belaste vores tjenester.</li>
</ul>
</div>
</div>


<div id="outline-container-orgfbf5731" class="outline-3">
<h3 id="orgfbf5731"><span class="section-number-3">2.3</span> Ingen garanti for indholdet</h3>
<div class="outline-text-3" id="text-2-3">
<p>
Unik Boligsalg garanterer ikke for, at indholdet på hjemmesiden er korrekt. Unik Boligsalg påtager sig intet erstatningsansvar for fejl og udeladelser i indholdet på den besøgte hjemmeside.
Brug af siden er for egen risiko
Brugen af den besøgte hjemmeside sker på egen risiko. Hverken Unik Boligsalg eller andre, som er involveret i at designe, fremstille eller publicere hjemmesiden, er ansvarlige for direkte, indirekte, hændelige eller følge skader, der måtte opstå som følge af din adgang til eller brug af hjemmesiden. Unik Boligsalg kan således ikke blive erstatningsansvarlige for direkte eller indirekte tab, som følge af din adgang til eller brug af hjemmesiden.
Unik Boligsalg er heller ikke ansvarlig for, og påtager sig ikke erstatningsansvaret for, skader eller virus, der inficerer dit computerudstyr eller anden ejendom på grund af din adgang til eller brug af hjemmesiden.
Det samme gælder for din eventuelle downloading af tekst, billeder, materiale, data, video eller lyd fra hjemmesiden.
</p>
</div>
</div>
</div>
</div>
<div id="postamble" class="status">
<p class="author">Author: Jeppe Tuxen</p>
<p class="date">Created: 2017-11-13 Mon 15:11</p>
<p class="validation"><a href="http://validator.w3.org/check?uri=referer">Validate</a></p>
</div>
</body>
</html>
`

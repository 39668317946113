import React from 'react';
import Apartment from './Apartment'
import {graphql, compose} from 'react-apollo'
import {connect} from 'react-redux'
import {selectSortByFilter} from 'actions/searchActions'
import {CASE_FILTER_QUERY, SELF_SALE_CASE_FILTER_QUERY} from 'queries'
import {toggleMapOnSearch} from "../../../actions/searchActions";
import BuyMap from './BuyMap'
import {orderBy} from 'lodash'

const mapPosition = {lat: 55.5875312, lng: 12.1102368}
const zoom = 10

const filterItems = [
    {
        value: "latest",
        label: "Seneste",
        filter: (cases) => orderBy(cases, ['status','__typename', 'startSaleDate'], ['asc', 'asc', 'desc'])
    },
    {
        value: "price",
        label: "Pris",
        filter: (cases) => orderBy(cases, ['status', '__typename', 'price'], ['asc', 'asc', 'asc'])
    },
    {
        value: "size",
        label: "Størrelse",
        filter: (cases) => orderBy(cases, ['status', '__typename', 'size'], ['asc', 'asc', 'desc'])
    }
]

const getSortFilter = (sortByFilter) => {
    return filterItems.find((x) => x.value===sortByFilter).filter
}


const SearchFilter = ({items, onChange, value}) => {
    return (
        <div className="grid__filter">
            <p className="small">SORTER EFTER:</p>
            <div className="grid__filter__date">
                <select name="grid__filter__date" id="grid__filter__date" value={value}
                        className="grid__filter__date__select" onChange={onChange}>
                    {items.map((item, index) => <option key={index} value={item.value}>{item.label}</option>)}
                </select>
                <svg className="icon icon--arrow icon--lightgreen">
                    <use xlinkHref="#icon-arrow"></use>
                </svg>
            </div>
        </div>
    )
}


const FilteredResults = ({cases, sortByFilter, maxLength=undefined}) => {
    let filteredCaseNodes = getSortFilter(sortByFilter)([...cases])
    if (maxLength) {
        filteredCaseNodes.length = maxLength
    }
    return <div className="row">{filteredCaseNodes.map((item, index) => <Apartment key={index} {...item} />)}</div>
}


const SearchResult = ({selfSaleCaseQuery, caseQuery, search, dispatch}) => {
    if (selfSaleCaseQuery.loading || caseQuery.loading ) return <div className="section--loading"></div>
    const {activeCases} = caseQuery
    const {activeSelfSaleCases} = selfSaleCaseQuery
    const cases = [...activeCases, ...activeSelfSaleCases]
    const casesMaxLength = 30
    const casesAmountText = cases.length > casesMaxLength ? `${casesMaxLength}+` : cases.length

    const casesText = cases.length===1 ? 'andelsbolig ' : 'andelsboliger '
    const onChangeSortFilter = (e) => dispatch(selectSortByFilter(e.target.value))
    const onToggleMap = (value) => () => dispatch(toggleMapOnSearch(value))
    const mapIsActive = search.mapToggled
    const mapIsActiveClass = mapIsActive ? 'grid-map-is-active' : ''
    return (
        <section className={"section animate--in apartments-grid grid " + mapIsActiveClass}>
            <div className="container">
                <div className="row">
                    <div className="col-s-12 col-m-5 col-l-4">
                        <div className="grid__title">
                            <h2 className="u-no-margin"><span
                                className="u-bold-text u-lightgreen-text">{casesAmountText}</span> {casesText}
                                matcher din søgning</h2>
                        </div>
                    </div>
                    <div className="col-s-12 col-m-3 col-l-3 col-l-push-2">
                        <SearchFilter items={filterItems} value={search.sortByFilter} onChange={onChangeSortFilter}/>
                    </div>
                    <div className="col-s-12 col-m-4 col-l-3">
                        <div className="grid__view">
                            <button className={"grid__view__button grid--overview "} onClick={onToggleMap(false)}>
                                Oversigt
                            </button>
                            <button className={"grid__view__button grid--map "} onClick={onToggleMap(true)}>Kort
                            </button>
                        </div>
                    </div>
                </div>
                <div className="apartments-grid__wrapper">
                    <div className="apartments-grid__apartments">
                        <FilteredResults cases={cases} sortByFilter={search.sortByFilter} maxLength={casesMaxLength}/>
                    </div>
                    <div className="apartments-grid__map">
                        { mapIsActive ? <BuyMap center={mapPosition} zoom={zoom} cases={cases}/> : ""}
                    </div>
                </div>
            </div>
        </section>
    );
}

SearchResult.displayName = 'SearchResult';
SearchResult.propTypes = {};
SearchResult.defaultProps = {};


const SearchResultWithData = compose(
    graphql(CASE_FILTER_QUERY, {
        name: 'caseQuery',
        options: ({search}) => ({
            variables: {
                livingSpace_Gte: search.search.sizeRange[0],
                livingSpace_Lte: search.search.sizeRange[1] < 200 ? search.search.sizeRange[1] : 10000,
                maxPrice_Gte: search.search.priceRange[0],
                maxPrice_Lte: search.search.priceRange[1] < 5000000 ? search.search.priceRange[1] : 100000000,
                roomsCount_Gte: search.search.roomsRange[0],
                roomsCount_Lte: search.search.roomsRange[1] < 5 ? search.search.roomsRange[1] : 200,
                zipCodeStreetOrCity: search.search.streetPostalCity
            },
            fetchPolicy: 'cache-and-network'
        })
    }),
    graphql(SELF_SALE_CASE_FILTER_QUERY, {
        name: 'selfSaleCaseQuery',
        options: ({search}) => ({
            variables: {
                livingSpace_Gte: search.search.sizeRange[0],
                livingSpace_Lte: search.search.sizeRange[1] < 200 ? search.search.sizeRange[1] : 10000,
                maxPrice_Gte: search.search.priceRange[0],
                maxPrice_Lte: search.search.priceRange[1] < 5000000 ? search.search.priceRange[1] : 100000000,
                roomsCount_Gte: search.search.roomsRange[0],
                roomsCount_Lte: search.search.roomsRange[1] < 5 ? search.search.roomsRange[1] : 200,
                zipCodeStreetOrCity: search.search.streetPostalCity
            },
            fetchPolicy: 'cache-and-network'
        })
    })
)(SearchResult)

const mapStateToProps = state => {
    return {
        search: state.search
    }
}

export default connect(mapStateToProps)(SearchResultWithData)




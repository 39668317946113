import axios from "axios"
import { fetchToken } from "./userActions"
import client from "client"
import * as mutations from "mutations"
import * as queries from "queries"
import { uploadFile as uploadFileApi } from "uploadApi"
import { setTokenAndId } from "../tokenManager"
import { SWAP_CASE_QUERY_CASE_PAGE } from "queries"

export function setVisibleModal(visibleModalKey, data = {}) {
  return (dispatch) =>
    dispatch({
      type: "SET_VISIBLE_MODAL",
      payload: { key: visibleModalKey, data: data },
    })
}

export function setTestMode(value) {
  return {
    type: "SET_TEST_MODE",
    payload: value,
  }
}

export function updateUser() {
  return {
    type: "UPDATE_USER",
    payload: client.query({
      query: queries.UB_USER_QUERY,
    }),
  }
}

export function deleteUser(input) {
  return async () => {
    try {
      await client.mutate({
        mutation: mutations.DELETE_USER_MUTATION,
        variables: { email: input.email },
        refetchQueries: [{ query: queries.UB_USER_QUERY }],
      })
    } catch (error) {
      console.error("GraphQL Client Error: ", error.message)
    }
  }
}

export function initiateApp() {
  return {
    type: "INITIATE_APP",
  }
}

export function globalReset() {
  return {
    type: "GLOBAL_RESET",
  }
}

export function login(email, password) {
  return (dispatch) =>
    dispatch(fetchToken(email, password))
      .then(() => client.resetStore())
      //.then(() => dispatch(updateUser()))
      .then(() => dispatch(setVisibleModal(null)))
}

export function refreshTokenAndUser(token) {
  return (dispatch) =>
    dispatch(refreshToken(token))
      //.then(() => dispatch(updateUser()))
      .catch((e) => {
        return dispatch(logout())
      })
}

export function refreshToken(token) {
  const headers = {
    "Content-Type": "application/json",
    //"Access-Control-Allow-Origin": process.env.HOST,
    "Access-Control-Allow-Headers": "Content-Type",
  }
  const data = { token }

  return {
    type: "REFRESH_TOKEN",
    payload: axios.post("/api-token-refresh/", data, headers),
  }
}

export function addUser(firstName, lastName, email, password, phone, testUser) {
  return {
    type: "ADD_UB_USER",
    payload: client.mutate({
      mutation: mutations.ADD_UB_USER_MUTATION,
      variables: {
        input: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          password: password,
          phone: phone,
          isTestUser: testUser,
        },
      },
      refetchQueries: ["ubUserQuery"],
    }),
  }
}

export function addUserRFForm(data, isTestUser = false) {
  return {
    type: "ADD_UB_USER",
    payload: client.mutate({
      mutation: mutations.ADD_UB_USER_MUTATION,
      variables: {
        input: { ...data, isTestUser: isTestUser },
      },
      refetchQueries: ["ubUserQuery"],
    }),
  }
}

export function modifyUserRFForm(input) {
  return {
    type: "MODIFY_UB_USER",
    payload: client.mutate({
      mutation: mutations.CHANGE_USER_MUTATION,
      variables: {
        input: input,
      },
      refetchQueries: [
        {
          query: queries.UB_USER_QUERY,
        },
      ],
    }),
  }
}

export function modifyUser(input) {
  return (dispatch) =>
    dispatch({
      type: "MODIFY_UB_USER",
      payload: client.mutate({
        mutation: mutations.CHANGE_USER_MUTATION,
        variables: {
          input: input,
        },
        refetchQueries: [
          {
            query: queries.UB_USER_QUERY,
          },
        ],
      }),
    })
}

export function addUserThenLoginThenShowModal(firstName, lastName, email, password) {
  return (dispatch) =>
    dispatch(addUserThenLogin(firstName, lastName, email, password)).then(() =>
      dispatch(setVisibleModal("USER_CREATED"))
    )
}

export function addUserThenLogin(firstName, lastName, email, password) {
  return (dispatch) =>
    dispatch(addUser(firstName, lastName, email, password)).then(() => dispatch(login(email, password)))
}

export function verifyUser(activationKey) {
  return (dispatch) =>
    dispatch({
      type: "VERIFY_USER",
      payload: client.mutate({
        mutation: mutations.VERIFY_USER_MUTATION,
        variables: { input: { activationKey: activationKey } },
        refetchQueries: [
          {
            query: queries.UB_USER_QUERY,
          },
        ],
      }),
    })
}

export const parseValidationErrors = (validationErrors) => {
  if (validationErrors) {
    return JSON.parse(validationErrors)
  }
  return []
}

export const setValidationErrors = (validationErrors) => {
  return {
    type: "SET_VALIDATION_ERRORS",
    payload: validationErrors,
  }
}

export const addFieldValidationErrors = (fieldValidationErrors) => {
  return {
    type: "ADD_FIELD_VALIDATION_ERRORS",
    payload: fieldValidationErrors,
  }
}

// export function handleValidationErrors(validationErrorsFromServer) {
//     return dispatch => new Promise((resolve, reject) => {
//         const validationErrors = _getValidationErrors(validationErrorsFromServer)
//         dispatch(setValidationErrors(validationErrors))
//         if (validationErrors===[]) {
//             resolve()
//         }
//         reject()
//     })
// }

const resetTokenAndId = () => {
  return new Promise((resolve) => {
    setTokenAndId(null, null)
    resolve()
  })
}

export function logout() {
  return (dispatch) =>
    resetTokenAndId()
      .then(() => client.cache.writeData({ data: { ubUser: null } }))
      .then(() => dispatch({ type: "LOGOUT" }))
      .then(() => dispatch(setTestMode(false)))
}

export function setHelpModalContent(content) {
  return {
    type: "SET_HELP_MODAL_CONTENT",
    payload: content,
  }
}

export function uploadFile(file) {
  return {
    type: "UPLOAD_FILE",
    payload: uploadFileApi(file),
  }
}

// SelfSaleCase

export function addSelfSale(input) {
  return (dispatch) =>
    dispatch({
      type: "ADD_SELF_SALE",
      payload: client.mutate({
        mutation: mutations.ADD_SELF_SALE_CASE_MUTATION,
        variables: { input: input },
        refetchQueries: [
          {
            query: queries.UB_USER_QUERY,
          },
        ],
      }),
    })
}

export function modifySelfSale(input) {
  return (dispatch) =>
    dispatch({
      type: "MODIFY_SELF_SALE",
      payload: client.mutate({
        mutation: mutations.MODIFY_SELF_SALE_CASE_MUTATION,
        variables: { input: input },
        refetchQueries: [
          {
            query: queries.UB_USER_QUERY,
          },
        ],
      }),
    })
}

export function deleteSelfSaleCaseDocument(input) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_SELF_SALE_CASE_DOCUMENT",
      payload: client.mutate({
        mutation: mutations.DELETE_SELF_SALE_CASE_DOCUMENT,
        variables: { input: input },
        refetchQueries: [
          {
            query: queries.UB_USER_QUERY,
          },
        ],
      }),
    })
}

export function deleteSelfSaleCaseImage(input) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_SELF_SALE_CASE_IMAGE",
      payload: client.mutate({
        mutation: mutations.DELETE_SELF_SALE_CASE_IMAGE,
        variables: { input: input },
        refetchQueries: [
          {
            query: queries.UB_USER_QUERY,
          },
        ],
      }),
    })
}

/// SwapCase

export function addSwapCase(input) {
  return (dispatch) =>
    dispatch({
      type: "ADD_SWAP_CASE",
      payload: client.mutate({
        mutation: mutations.ADD_SWAP_CASE_MUTATION,
        variables: { input: input },
        refetchQueries: [
          {
            query: queries.UB_USER_QUERY,
          },
        ],
      }),
    })
}

export function modifySwapCase(input) {
  return (dispatch) =>
    dispatch({
      type: "MODIFY_SWAP_CASE",
      payload: client.mutate({
        mutation: mutations.MODIFY_SWAP_CASE_MUTATION,
        variables: { input: input },
        refetchQueries: [
          {
            query: queries.UB_USER_QUERY,
          },
        ],
      }),
    })
}

export function deleteSwapCaseImage(input) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_SWAP_CASE_IMAGE",
      payload: client.mutate({
        mutation: mutations.DELETE_SWAP_CASE_IMAGE,
        variables: { input: input },
        refetchQueries: [
          {
            query: queries.UB_USER_QUERY,
          },
        ],
      }),
    })
}

export function deleteSwapCaseDocument(input) {
  return (dispatch) =>
    dispatch({
      type: "DELETE_SWAP_CASE_DOCUMENT",
      payload: client.mutate({
        mutation: mutations.DELETE_SWAP_CASE_DOCUMENT,
        variables: { input: input },
        refetchQueries: [
          {
            query: queries.UB_USER_QUERY,
          },
        ],
      }),
    })
}

export function newSwapResponse(input, swap_case_uuid) {
  console.log("XXXX", input)
  return (dispatch) =>
    dispatch({
      type: "NEW_SWAP_RESPONSE",
      payload: client.mutate({
        mutation: mutations.NEW_SWAP_RESPONSE,
        variables: { input: input },
        refetchQueries: [
          {
            query: queries.SWAP_CASE_QUERY_CASE_PAGE,
            variables: {
              caseUuid: swap_case_uuid,
            },
          },
        ],
      }),
    })
}

export function updateSwapResponse(swapResponseId, isInterested) {
  return (dispatch) =>
    dispatch({
      type: "UPDATE_SWAP_RESPONSE",
      payload: client.mutate({
        mutation: mutations.UPDATE_SWAP_RESPONSE,
        variables: { swapResponseId: swapResponseId, isInterested: isInterested },
      }),
    })
}

export function toggleCaseSlider() {
  return {
    type: "TOGGLE_CASE_SLIDER",
  }
}

export function toggleMobileMenu(state = null) {
  return {
    type: "TOGGLE_MOBILE_MENU",
    payload: state,
  }
}

export function setCurrentLocation(location) {
  const pathname = location.pathname
  return {
    type: "SET_CURRENT_LOCATION",
    payload: pathname,
  }
}

export function setGobalErrorMessage(message) {
  return {
    type: "SET_GLOBAL_ERROR_MESSAGE",
    payload: message,
  }
}

export function setRememberPass(value) {
  return {
    type: "SET_REMEMBER_PASS_ACTIVE",
    payload: value,
  }
}

export function sendPasswordResetEmail(email) {
  const input = { email: email }
  return {
    type: "SEND_PASSWORD_RESET_EMAIL",
    payload: client.mutate({
      mutation: mutations.REQUEST_RESET_PASSWORD_LINK,
      variables: { input: input },
    }),
  }
}

export function resetPassword({ token, password }) {
  return {
    type: "RESET_PASSWORD",
    payload: client.mutate({
      mutation: mutations.RESET_PASSWORD,
      variables: { input: { token, password } },
    }),
  }
}

export function reepaySubscribe({ cardToken }) {
  return {
    type: "REEPAY_SUBSCRIBE",
    payload: client.mutate({
      mutation: mutations.REEPAY_SUBSCRIBE,
      variables: { input: { cardToken } },
      refetchQueries: [
        {
          query: queries.UB_USER_QUERY,
        },
        {
          query: queries.SUBSCRIPTION_STATUS_QUERY,
        },
      ],
    }),
  }
}

export function reepayUnsubscribe() {
  return {
    type: "REEPAY_UNSUBSCRIBE",
    payload: client.mutate({
      mutation: mutations.REEPAY_UNSUBSCRIBE,
      variables: { input: { random: "asdsad" } },
      refetchQueries: [
        {
          query: queries.UB_USER_QUERY,
        },
        {
          query: queries.SUBSCRIPTION_STATUS_QUERY,
        },
      ],
    }),
  }
}

export function submitCaseContactForm(input) {
  return {
    type: "SUBMIT_CASE_CONTACT_FORM",
    payload: client.mutate({
      mutation: mutations.SUBMIT_CASE_CONTACT_FORM,
      variables: { input: input },
    }),
  }
}

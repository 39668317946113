import React from "react"
import ImageFull from "./ImageFull.js"
import { URLS } from "../../urls"
import { graphql } from "react-apollo"
import * as queries from "queries"

const content = {
  title: 254,
  subtitleStart: "Prøv Waitly+ ligesom ",
  subtitleEnd: " i København",
  list: [
    "Få eksklusive tilbud på andelsboliger",
    "Få besked 24 timer før andre om nye lister",
    "Få adgang til sneak peek og vidensbank",
  ],
  linkText: "Læs mere om Waitly+",
  imageUrl: "/assets/img/img-lady-with-another-lady.jpg",
  linkUrl: "https://waitly.dk/waitlyplus",
}

const ImageFullSection = ({ data }) => {
  const content_data = { ...content, title: data.numUbUsers }
  return <ImageFull {...content_data} />
}

ImageFullSection.displayName = "ImageFullSection"
ImageFullSection.propTypes = {}
ImageFullSection.defaultProps = {}

export default graphql(queries.STATS_QUERY)(ImageFullSection)

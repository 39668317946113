const initialState = null

export default function reducer(state=initialState, action) {
  switch(action.type) {
    case "SET_TOKEN": {
      return {...state, token: action.payload}
    }
  }
  return state
}

import React from "react"
import { Link } from "react-router-dom"
import { kr } from "utils"
import Map from "components/shared/Map"
import PercentageCircle from "./PercentageCircle"
import { SwapBanner } from "./SwapComponents"

const mapZoom = window.innerWidth > 767 ? 14 : 12

const SwapApartmentCard = (props) => {
  const {
    id,
    img,
    matchPercent,
    matchTotal,
    matchOther,
    floor_plan,
    address,
    zip_city,
    apartmentSquareMetres,
    roomsCount,
    price,
    lat,
    lng,
    url,
    isRequestReceivedFromUser,
    isRequestSentToUser,
  } = props
  const mapToggled = false //search.toggledMaps.indexOf(id)!==-1
  const floorPlanToggled = false //earch.toggledFloorPlans.indexOf(id)!==-1
  let apartmentCardClass = ""
  apartmentCardClass += mapToggled ? "map-is-active " : floorPlanToggled ? "floor-plan-is-active " : ""
  const mapView = (
    <div className="apartment-card__map">
      <Map zoom={mapZoom} center={{ lng, lat }} />
    </div>
  )

  const floorPlanView = (
    <div className="apartment-card__floor-plan" style={{ backgroundImage: `url(  ${floor_plan} ) ` }}></div>
  )

  const onMapToggle = () => {
    console.log("implement")
  }

  const onFloorPlanToggle = () => {
    console.log("implement")
  }

  const mapToggleButton = (
    <a href="#" className="apartment-card__map--button" onClick={onMapToggle}>
      <svg className="icon icon--map icon--lightgreen">
        <use xlinkHref="#icon-map"></use>
      </svg>
    </a>
  )

  const floorPlanToggleButton = (
    <a href="#" className="apartment-card__floor-plan--button" onClick={onFloorPlanToggle}>
      <svg className="icon icon--floor-plan icon--white">
        <use xlinkHref="#icon-floor-plan"></use>
      </svg>
    </a>
  )

  return (
    <div className={"apartment-card " + apartmentCardClass}>
      <div className="apartment-card__img__wrapper">
        {url ? (
          <Link to={url} className="apartment-card__img" style={{ backgroundImage: `url(  ${img} ) ` }} />
        ) : (
          <div className="apartment-card__img" style={{ backgroundImage: `url(  ${img} ) `, cursor: "default" }}></div>
        )}

        {mapView}
        {floorPlanView}
        {/*{mapToggleButton}*/}
        {/*{floorPlanToggleButton}*/}
        <SwapBanner caseData={props} />
      </div>

      <div className="apartment-card__info">
        {matchTotal ? (
          <div className="flex-wrapper-evenly">
            <span className="flex-single-circle">
              <PercentageCircle percentage={matchTotal} />
            </span>
            <span className="apartment-card__match-value">Samlet match på denne bolig</span>
          </div>
        ) : (
          ""
        )}
        <table className="apartment-card__table">
          <tbody>
            <tr className="apartment-card__row">
              <td className="apartment-card__label" rowSpan="2">
                {" "}
                Adresse
              </td>
              <td className="apartment-card__value">{address}</td>
            </tr>
            <tr className="apartment-card__row">
              <td className="apartment-card__value apartment-card--zip-city">{zip_city}</td>
            </tr>
            <tr className="apartment-card__row">
              <td className="apartment-card__label">Kvm</td>
              <td className="apartment-card__value apartment-card--zip-city">{apartmentSquareMetres} m²</td>
            </tr>
            <tr className="apartment-card__row">
              <td className="apartment-card__label">Rum</td>
              <td className="apartment-card__value apartment-card--zip-city">{roomsCount}</td>
            </tr>
            <tr className="apartment-card__row u-green-text">
              <td className="apartment-card__label">Pris</td>
              <td className="apartment-card__value apartment-card--price">{kr(price)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {url ? (
        <Link to={url} className="apartment-card__link">
          SE BOLIG
        </Link>
      ) : (
        ""
      )}
    </div>
  )
}
SwapApartmentCard.displayName = "SwapApartmentCard"
SwapApartmentCard.propTypes = {}
SwapApartmentCard.defaultProps = {}

export default SwapApartmentCard

import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'
import * as actions from 'actions/searchActions'
import {kr} from "../../utils";
import Map from './Map'
import Banner from "components/shared/Banner"

const mapZoom = window.innerWidth > 767 ? 14 : 12


const ApartmentCard = (props) => {
    const {dispatch, search, id, img, floor_plan, address, isSelfSale, zip_city, size, rooms, price, lat, lng, reserved, openHouseUpcoming, url, status} = props
    const mapToggled = search.toggledMaps.indexOf(id)!==-1
    const floorPlanToggled = search.toggledFloorPlans.indexOf(id)!==-1

    let apartmentCardClass = ''
    apartmentCardClass += mapToggled ? 'map-is-active ' :
        floorPlanToggled ? 'floor-plan-is-active ' : ''
    apartmentCardClass += openHouseUpcoming ? 'open-house' :
        reserved ? 'reserved' : ''
    const onMapToggle = (e) => {
        e.preventDefault();
        dispatch(actions.toggleMapOnApartmentCard(id))
    }
    const onFloorPlanToggle = (e) => {
        e.preventDefault();
        dispatch(actions.toggleFloorPlanOnApartmentCard(id))
    }

    const mapView = isSelfSale || !mapToggled ? "" : (
        <div className="apartment-card__map">
            <Map zoom={mapZoom} center={{lng, lat}}/>
        </div>
    )
    const floorPlanView = isSelfSale ? "" : (
        <div className="apartment-card__floor-plan" style={{backgroundImage: `url(  ${ floor_plan } ) `}}></div>
    )

    const mapToggleButton = isSelfSale ? "" : (
        <a href="#" className="apartment-card__map--button" onClick={onMapToggle}>
            <svg className="icon icon--map icon--lightgreen">
                <use xlinkHref="#icon-map"></use>
            </svg>
        </a>
    )

    const floorPlanToggleButton = isSelfSale ? "" : (
        <a href="#" className="apartment-card__floor-plan--button" onClick={onFloorPlanToggle}>
            <svg className="icon icon--floor-plan icon--white">
                <use xlinkHref="#icon-floor-plan"></use>
            </svg>
        </a>
    )


    return (
        <div className={"apartment-card " + apartmentCardClass}>
            <div className="apartment-card__img__wrapper">
                <Link to={ url } className="apartment-card__img" style={{backgroundImage: `url(  ${ img } ) `}}></Link>
                { mapView }
                { floorPlanView }
                { mapToggleButton }
                { floorPlanToggleButton }
                <Banner
                    {...props}
                    status={status}
                    isSelfSale={isSelfSale}
                    openHouseUpcoming={openHouseUpcoming}
                />
            </div>

            <div className="apartment-card__info">
                <table className="apartment-card__table">
                    <tbody>
                    <tr className="apartment-card__row">
                        <td className="apartment-card__label" rowSpan="2"> Adresse</td>
                        <td className="apartment-card__value">{address}</td>
                    </tr>
                    <tr className="apartment-card__row">
                        <td className="apartment-card__value apartment-card--zip-city">{zip_city}</td>
                    </tr>
                    <tr className="apartment-card__row">
                        <td className="apartment-card__label">Kvm</td>
                        <td className="apartment-card__value apartment-card--zip-city">{size} m²</td>
                    </tr>
                    <tr className="apartment-card__row">
                        <td className="apartment-card__label">Rum</td>
                        <td className="apartment-card__value apartment-card--zip-city">{rooms}</td>
                    </tr>
                    <tr className="apartment-card__row u-green-text">
                        <td className="apartment-card__label">Pris</td>
                        <td className="apartment-card__value apartment-card--price">{kr(price)}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <Link to={url} className="apartment-card__link">SE BOLIG</Link>
        </div>
    )
}
ApartmentCard.displayName = 'ApartmentCard';
ApartmentCard.propTypes = {};
ApartmentCard.defaultProps = {};

const mapStateToProps = state => {
    return {
        search: state.search
    }
}

export default connect(mapStateToProps)(ApartmentCard);

import React from 'react';
import {Link} from 'react-router-dom'
import CheckListItem from './CheckListItem'
import {connect} from 'react-redux'
import {setVisibleModal} from "../../actions/index";

const SellCard = ({ dispatch, price, title, subtitle, list, linkText, url, modal }) => {
	const button = modal===undefined ? <Link className="button card__button" to={url}>{ linkText }</Link> :
		<button className="button card__button" onClick={()=> dispatch(setVisibleModal(modal))}>{ linkText }</button>
	return (
		<div className="card sell-card">
			<span className="card__price-bubble price-bubble u-lightgreen">{price}
				<svg className="icon icon--price-bubble icon--lightgreen">
					<use xlinkHref="#icon-price-bubble"></use>
				</svg>
			</span>
			<div className="card__header">
				<h3 className="card__title">{ title }</h3>
				<p className="card__tagline" dangerouslySetInnerHTML={{__html: subtitle }}/>
			</div>
			<ul className="card__list">
				{ list.map( (string, index) => <CheckListItem key={index} label={string} /> )}
			</ul>
			{ button }
		</div>
	)
};


SellCard.displayName = 'SellCard';
SellCard.propTypes = {};
SellCard.defaultProps = {};

export default connect()(SellCard);


import React from 'react';
import {connect} from 'react-redux';
import {graphql} from 'react-apollo'
import {Upload} from "components/shared/forms"
import {STATUS_TYPES, SELF_SALE_CASE_TYPE_NAME} from "../../../values";

import {ALL_FACILITIES_QUERY} from 'queries'
import * as actions from 'actions'
import {Form, Field} from 'react-final-form'
import * as queries from 'queries'
import {pick} from 'lodash'
import * as utils from 'utils'

import DeletableImage from 'components/shared/forms/DeletableImage'
import DeletableDocument from 'components/shared/forms/DeletableDocument'

import {uploadFile, uploadSelfSaleImages} from 'uploadApi'
import {Link} from 'react-router-dom'
import {FieldSection, CheckBoxBlock} from "../../shared/finalFormFields"
import {
    associationFields,
    addressFields,
    infoFields,
    expensesFields,
    descriptionFields
} from "../Sale/SelfSale/saleFields";
import {enumToInt} from "../../../utils";


const formBlocks = [
    {
        title: 'Forening og administrator',
        fields: associationFields,
        formName: 'mySelfSaleAssociationForm'
    }, {
        title: 'Din bolig',
        fields: addressFields,
        formName: 'mySelfSaleAssociationForm1'
    },
    {
        title: 'Info om Boligen',
        fields: infoFields,
        formName: 'mySelfSaleAssociationForm3'
    },
    {
        title: 'Generelle udgifter',
        fields: expensesFields,
        formName: 'mySelfSaleAssociationForm4'
    },
]


const descriptionBlock = {
    title: 'Yderligere beskrivelser af din bolig',
    fields: descriptionFields,
    formName: 'mySelfSaleAssociationForm5'
}


const documents = [
    {
        label: 'Nyeste Årsregnskab',
        key: 'annualAccounts'
    },
    {
        label: 'Gældende vedtægter',
        key: 'rulesRegulations'
    },
    {
        label: 'Referat fra seneste generalforsamling',
        key: 'latestBoardMeetingReport',
    },

    {
        label: 'Energimærke',
        key: 'energyLabel'
    },
    {
        label: 'Næste års budget',
        key: 'nextYearsBudget',

    },

    {
        label: 'Husorden',
        key: 'houseRules'
    },
    {
        label: 'Referat fra seneste generalforsamling',
        key: 'latestBoardMeetingReport',
    }
]


const FacilitiesBlockWithData = graphql(ALL_FACILITIES_QUERY,
    {
        props: ({data: {loading, availableFacilities}}) => ({
            loading: loading,
            options: availableFacilities,
            labelName: "facilityDescription"
        })
    }
)(CheckBoxBlock)


let FacilitiesForm = ({handleSubmit, pristine}) => (
    <form onSubmit={handleSubmit}>
        <div>
            <Field name='facilities'
                   title="Vælg boligens faciliteter"
                   component={FacilitiesBlockWithData}
            />
        </div>
        <button className="button" disabled={pristine}>Gem</button>
    </form>
)


let StatusForm = props => {
    const {initialValues} = props
    switch (initialValues.status) {
        case STATUS_TYPES.BEEING_CREATED:
            return (
                <form>
                    <Field name="status" component="select" style={{width: '100%', color: 'red'}}>
                        <option value={STATUS_TYPES.BEEING_CREATED} disabled>• Din annonce er inaktiv - afventer
                            godkendelse
                        </option>
                    </Field>
                </form>
            )
        case STATUS_TYPES.ACTIVE:
            return (
                <form>
                    <Field name="status" component="select" style={{width: '100%', color: 'green'}} onChange={props.onChange}>
                        <option value={STATUS_TYPES.ACTIVE}>• Din annonce er aktiv</option>
                        <option value={STATUS_TYPES.SOLD}>Sæt til solgt</option>
                        <option value={STATUS_TYPES.REMOVED}>Tag ned - vil ikke sælge alligevel</option>
                    </Field>
                </form>
            )
        case STATUS_TYPES.SOLD:
            return (
                <form>
                    <Field name="status" component="select" style={{width: '100%', color: 'red'}} onChange={props.onChange}>
                        <option value={STATUS_TYPES.SOLD}>• Din annonce er inaktiv - sat til solgt</option>
                        <option value={STATUS_TYPES.ACTIVE}>Aktivér igen</option>
                    </Field>
                </form>
            )
        case STATUS_TYPES.REMOVED:
            return (
                <form>
                    <Field name="status" component="select" style={{width: '100%', color: 'red'}} onChange={props.onChange}>
                        <option value={STATUS_TYPES.REMOVED}>• Din annonce er nedtaget</option>
                        <option value={STATUS_TYPES.ACTIVE}>Aktivér igen</option>
                        <option value={STATUS_TYPES.SOLD}>Sæt til solgt</option>
                    </Field>
                </form>
            )
    }
    return null
}

// StatusForm = reduxForm({
//     form: 'status', // a unique identifier for this form
//     enableReinitialize: true,
//     onChange: ((values, dispatch) => {
//         dispatch(actions.modifySelfSale({status: enumToInt(values.status)}))
//     })
// })(StatusForm)

let StatusSection = props => {
    const {selfSaleCase, dispatch} = props
    return (
        <div className="subsection">
            <h3 className="u-green-text">Status</h3>
            <Form onSubmit={(e) => console.log("Is required but not used here")}
                  initialValues={{status: selfSaleCase.status}}
                  onChange={(e) => {
                      dispatch(actions.modifySelfSale({status: enumToInt(e.target.value)}))
                  }}
            >
                {props => <StatusForm {...props}/>}
            </Form>

            <Link to={utils.caseUrlFromTypename(SELF_SALE_CASE_TYPE_NAME, selfSaleCase.caseId)}><a>Se din boligannonce
                her</a></Link>
        </div>
    )
}

StatusSection = connect()(StatusSection)


const FormSection = ({title, fields, formName, initialObject, onSubmit}) => {

    const initialValues = pick(initialObject, utils.listByKey(fields, 'name'))
    const FinalForm = () => (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({invalid, handleSubmit, pristine}) => (
                <div className="subsection">
                    <form onSubmit={handleSubmit}>
                        <FieldSection title={title} fields={fields} className=""/>
                        <button className="button" disabled={invalid || pristine}>Gem</button>
                    </form>
                </div>
            )}
        />
    )
    return <FinalForm/>
}

const MySelfSale = ({dispatch, data}) => {
    if (data.loading) {
        return <div className="section--loading"></div>
    }

    const ubUser = data.ubUser
    if (ubUser === null || ubUser === undefined) return <div>Du er ikke logget ind</div>
    const selfSaleCase = ubUser.selfSaleCase
    if (selfSaleCase === null) return <div>Du har ikke noget selvsalg endnu</div>

    const onSubmit = (data) => {
        dispatch(actions.modifySelfSale(data))
    }


    const onUploadDocument = (fieldName) => (e) => uploadFile(e.target.files[0])
        .then((res) => {
            const input = {}
            input[fieldName] = res.data
            dispatch(actions.modifySelfSale(input))
        })
        .catch((err) => console.log(err))

    const onDeleteDocument = (id) => () => {
        dispatch(actions.deleteSelfSaleCaseDocument({id: id}))
    }

    const onUploadImages = (e) => uploadSelfSaleImages(e.target.files, selfSaleCase.id)
        .then(() => {
            data.refetch()
        })
        .catch((err) => console.log(err))

    const onDeleteImage = (id) => () => {
        dispatch(actions.deleteSelfSaleCaseImage({id: id}))
    }


    return (
        <div className="selfsale__sellform myselfsale">
            <section className="section u-lightgrey">
                <div className="container">
                    <h2 className="u-darkgreen-text section-title__block subsection">Mit selvsalg</h2>
                    <div className="row bordered-columns">
                        <div className="col-s-12 col-m-6">
                            <StatusSection selfSaleCase={selfSaleCase}/>
                            {formBlocks.map((x, i) => <FormSection
                                key={i}
                                {...x}
                                initialObject={selfSaleCase}
                                onSubmit={onSubmit}/>
                            )}
                        </div>
                        <div className="col-s-12 col-m-6">
                            <div className="subsection">
                                <Form
                                    onSubmit={onSubmit}
                                    initialValues={{facilities: utils.listByKey(selfSaleCase.facilities, 'id')}}>
                                    {props => <FacilitiesForm {...props}/>}
                                </Form>

                            </div>

                            <FormSection
                                {...descriptionBlock}
                                initialObject={selfSaleCase}
                                onSubmit={onSubmit}
                            />
                            <div className="subsection">
                                <h3 className="u-green-text">Dokumenter</h3>
                                <ul className="myselfsale__documents">
                                    {documents.map((x, i) => {
                                            return (
                                                <DeletableDocument
                                                    key={i}
                                                    fieldName={x.key}
                                                    label={x.label}
                                                    onDelete={selfSaleCase[x.key] === null ? null : onDeleteDocument(selfSaleCase[x.key].id)}
                                                    onUpload={onUploadDocument(x.key)}
                                                    {...selfSaleCase[x.key]} />
                                            )
                                        }
                                    )}

                                </ul>
                            </div>
                            <div className="subsection">
                                <h3 className="u-green-text">Billeder</h3>
                                {selfSaleCase.selfsalecaseimages.map((x, i) => <DeletableImage
                                    key={i} {...x}
                                    onDelete={onDeleteImage(x.id)}/>)}
                                <Upload onUpload={onUploadImages} multiple={true} fieldName='imagesUploader'/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        app: state.app,
        selfSaleForm: state.selfSaleForm,
    }
}


MySelfSale.displayName = 'MySelfSale';
MySelfSale.propTypes = {};
MySelfSale.defaultProps = {};


const MySelfSaleWithData = graphql(queries.UB_USER_QUERY)(MySelfSale)

export default connect(mapStateToProps)(MySelfSaleWithData);




import React from 'react';

const Photo = ({url, onChange}) => {
	return (
		<div className="subsection profile-photo">
			<h3 className="u-green-text">Profilfoto</h3>
			<div className="input-field__photo">
				<input type="file" name="profile-photo" accept="image/*" onChange={onChange}/>
				{url===null ? (
					<div>
						<svg className="icon icon--photo icon--white">
							<use xlinkHref="#icon-photo"></use>
						</svg>
						<svg className="icon icon--add icon--lightgreen">
							<use xlinkHref="#icon-add"></use>
						</svg>
					</div>
					) : (
					<div data-img={url} className="input-field__photo__img" style={{backgroundImage: `url(${url})`}}></div>
					)
				}
			</div>
		</div>
	)
}
Photo.displayName = 'Photo';
Photo.propTypes = {};
Photo.defaultProps = {};

export default Photo;

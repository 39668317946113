const initialState = {
	minFloorArea: 30,
	maxFloorArea: 200,
	minRooms: 1,
	maxRooms: 5,
	minMonthlyFee: 1000,
	maxMonthlyFee: 20000,
	minPrice: 0,
	maxPrice: 5000000,
	preferredAreas: [],
	requirements: [],
	profileDescription: "",
	profileImage: null,
	termsAccepted: false,
	wantLoanProposal: true,
	loanProposalContactTimespan: 0,
	wizardCompleted: false
}
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "CHANGE_BUYER_RANGE": {
            let newState = {...state}
            const fieldName = action.payload.fieldName
            newState['min' + fieldName] = action.payload.min
            newState['max' + fieldName] = action.payload.max
            return {...state, ...newState}
        }

        case "CHANGE_BUYER_PROFILE_DESCRIPTION": {
            return {...state, profileDescription: action.payload}
        }

        case "SET_BUYER_REQUIREMENTS": {
            return {...state, requirements: action.payload}
        }

        case "SET_BUYER_AREAS": {
            return {...state, preferredAreas: action.payload}
        }
        case "TOGGLE_BUYER_TERMS": {
            return {...state, termsAccepted: !state.termsAccepted}
        }
    }
    return state
}

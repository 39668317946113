import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom'
import {URLS} from 'urls'
import Activation from '../../User/Activation'
import NewUserFormV2 from '../../User/NewUserFormV2'
import SwapWishes from "./SwapWhishes"
import * as queries from 'queries'
import {graphql} from 'react-apollo'
import SwapConditions from "./SwapConditions";
import SwapAssociation from "./SwapAssociation"
import SwapOwnResidence from "./SwapOwnResidence"
import SwapSelectPhotoPackage from "./SwapSelectPhotoPackage"
import SwapPhotoUpload from "./SwapPhotoUpload"


const CreateSwap = ({data}) => {
    if (data.loading) return <div className="section--loading u-full-height"></div>
    const user = data.ubUser
    let redirectUrl = (user) => {
        // Redirect to create user if user is not logged in
        if (user === null) return URLS.SWAP.CREATE.USER
        console.log(user)
        // Redirect to activation if user is not verified
        // else if (user.verified === false) return URLS.SWAP.CREATE.ACTIVATION
        // // Already a selfSaleCase
        // else if (user.selfSaleCase !== null && user.selfSaleCase.wizardCompletedTermsAccepted) return URLS.PROFILE.SELF_SALE
        // else return URLS.SALE.SELF_SALE.ASSOCIATION
    }

    const UserRedirect = () => {
        const url = redirectUrl(user)
        if (url!==null) return <Redirect to={redirectUrl(user)}/>
        return <span></span>
    }


    return (
        <div className="router animate--up">
            {/*<UserRedirect/>*/}
            <Switch>
                <Route path={URLS.SWAP.CREATE.S1_USER}
                       render={(props) => <NewUserFormV2 {...props} successModal='USER_CREATED_SWAP' nextUrl={URLS.SWAP.CREATE.S2_ACTIVATION}/>}/>
                <Route path={URLS.SWAP.CREATE.S2_ACTIVATION}
                       render={(props) => <Activation {...props} next={URLS.SWAP.CREATE.S3_ASSOCIATION}/>}/>
                <Route path={URLS.SWAP.CREATE.S3_ASSOCIATION}
                       render={(props) => <SwapAssociation {...props}/>}/>
                <Route path={URLS.SWAP.CREATE.S4_OWN_RESIDENCE}
                       render={(props) => <SwapOwnResidence {...props}/>}/>
                <Route path={URLS.SWAP.CREATE.S5_SELECT_PHOTO_PACKAGE}
                       render={(props) => <SwapSelectPhotoPackage {...props}/>}/>
                <Route path={URLS.SWAP.CREATE.S6_PHOTO_UPLOAD}
                       render={(props) => <SwapPhotoUpload {...props} swapCase={user.swapCase}
                                                           refetch={data.refetch}/>}/>
                <Route path={URLS.SWAP.CREATE.S7_WHISHES}
                       render={(props) => <SwapWishes {...props}/>}/>
                <Route path={URLS.SWAP.CREATE.S8_CONDITIONS}
                       render={(props) => <SwapConditions {...props}/>}/>
            </Switch>
        </div>
    );
}

CreateSwap.displayName = 'CreateSwap';
CreateSwap.propTypes = {};
CreateSwap.defaultProps = {};

const CreateSwapWithData = graphql(queries.UB_USER_QUERY)(CreateSwap)

export default CreateSwapWithData;
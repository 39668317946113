import {ApolloClient} from 'apollo-client';
import {createHttpLink} from 'apollo-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {getToken} from "./tokenManager";
import {setContext} from 'apollo-link-context';


const middlewareLink = setContext(() => {
    const token = getToken();
    return ({
        headers: {
            authorization: token ? `JWT ${token}` : null
        }
    })
})


const httpLink = createHttpLink({uri: '/graphql'})

const link = middlewareLink.concat(httpLink);

const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
    credentials: 'same-origin',
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
            errorPolicy: 'all',
        },
        query: {
            fetchPolicy: 'cache-and-network',
            errorPolicy: 'all',
        },
        mutate: {
            errorPolicy: 'all'
        }
    }

});


export default client

import React from 'react';
import {URLS} from "../../../urls";
import HTMLContent from "../../HTMLContent";


const SwapRequestCreated = ({history}) => {
    return (
        <div>
            <section className="section u-full-height flex-center">
                    <div className="col-s-6">
                        <img src="/assets/img/img-email-sent.png" alt=""/>
                        <HTMLContent textId="swap_request_send" withHeadline={true}/>
                        <div className="flex-column">
                            <button className="button" onClick={() => history.push(URLS.SWAP.FRONT)}>Se flere bytteboliger</button>
                        </div>
                    </div>
            </section>
        </div>
    )
}
SwapRequestCreated.displayName = 'SwapRequestCreated';
SwapRequestCreated.propTypes = {};
SwapRequestCreated.defaultProps = {};


export default SwapRequestCreated;
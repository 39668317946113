export const getPersistant = () => {
    const persitant = window.localStorage.getItem('persistant')
    return (persitant==='true')
}

const getStorage = () => {
    if (getPersistant()) {
        console.log('persistant')
        return window.localStorage
    }
    return window.sessionStorage
}

export const setPersistant = (isPersistant) => {
    if (isPersistant) {
        window.localStorage.setItem('persistant', true)
        console.log('setting persistance')
    }
    else {
        window.localStorage.setItem('persistant', false)
        window.localStorage.setItem('ubUserId', null)
        window.localStorage.setItem('ubToken', null)
    }
}

export const setTokenAndId = (token, id) => {
    const storage = getStorage()
    storage.setItem('ubToken', token)
    storage.setItem('ubUserId', id)
}


export const getToken = () => {
    return getStorage().getItem('ubToken')
}

export const getId = () => {
    return getStorage().getItem('ubUserId')
}

import React from 'react';
import SwapWishesForm from './SwapWhishesForm'
import Modal from "../../../Modals/Modal";
import {connectAll, getSwapSubmitFn} from "../swapHelpers";
import {URLS} from "../../../../urls";


class SwapWhishes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            requirementsModalHasBeenShown: false,
            showRequirementsModal: false
        }
        this.onChangeForm = this.onChangeForm.bind(this)
        this.onCloseModal = this.onCloseModal.bind(this)
    }

    onChangeForm(data) {
        if (data && data.requirements.length === 1 && !this.state.requirementsModalHasBeenShown) {
            this.setState({
                requirementsModalHasBeenShown: true,
                showRequirementsModal: true
            })
        }
    }

    onCloseModal() {
        this.setState({showRequirementsModal: false})
    }


    render() {
        const {data} = this.props
        const {ubUser, loading} = data
        if (loading) return <div className="section--loading"></div>

        let initialValues = {
            minFloorArea: 64,
            maxFloorArea: 148,
            minRooms: 1,
            maxRooms: 5,
            minPrice: 300000,
            maxPrice: 1400000,
            minMonthlyFee: 3600,
            maxMonthlyFee: 10000
        }

        if (ubUser && ubUser.swapCase) {
            for (const k in initialValues) {
                initialValues[k] = ubUser.swapCase[k] ? ubUser.swapCase[k] : initialValues[k]
            }
        }
        initialValues.requirements = []
        initialValues.preferredAreas = []

        if (ubUser && ubUser.swapCase) {
            initialValues.requirements = ubUser.swapCase.requirements.map(x => x.id)
            initialValues.preferredAreas = ubUser.swapCase.preferredAreas.map(x => x.id)
        }


        const onSubmit = getSwapSubmitFn({dispatch: this.props.dispatch, nextUrl: URLS.SWAP.CREATE.S8_CONDITIONS, history: this.props.history, swapCase: ubUser.swapCase})

        return (
            <div className="whishes-component">
                <section className="section u-lightgrey">
                    <div className="container">
                        <SwapWishesForm onChange={this.onChangeForm} initialValues={initialValues} onSubmit={onSubmit} includePristine={false}/>

                    </div>
                </section>
            </div>
        );
    }
}


SwapWhishes.displayName = 'SwapWhishes';
SwapWhishes.propTypes = {};
SwapWhishes.defaultProps = {};


export default connectAll(SwapWhishes);

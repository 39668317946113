import React from 'react';
import SwapFrontPage from "./SwapFrontPage"
import CreateSwap from './CreateSwap'
import {Route, Switch} from 'react-router-dom'
import {URLS} from 'urls'
import {DoesNotExist} from 'components/pages'
import {Query} from "react-apollo";
import {UB_USER_QUERY} from "queries";
import SwapResult from "./SwapResult";
import SwapPage from "./SwapPage"
import SwapRequestCreated from "./SwapRequestCreated";
import SwapTestPage from "./SwapTestPage";
import SwapHome from "./SwapHome"


const SwapIndex = () => {
    return (
        <Query query={UB_USER_QUERY}>
            {({loading, error, data}) => {
                if (loading) return 'loading...'
                if (error) return `Error! ${error.message}`
                const FrontComponent = data.ubUser && data.ubUser.swapCase !== null && data.ubUser.swapCase.termsAccepted ? SwapHome : SwapFrontPage
                return (
                    <div className="animate--down">
                        <Switch>
                            <Route path={URLS.SWAP.FRONT} exact component={FrontComponent}/>
                            <Route path={URLS.SWAP.NEW} component={SwapResult}/>
                            <Route path={URLS.SWAP.CONTACT_REQUEST_CREATED} exact component={SwapRequestCreated}/>
                            <Route path={ URLS.SWAP.CASE } component={ SwapPage } />
                            <Route path={URLS.SWAP.CREATE.FRONT} component={CreateSwap}/>
                            <DoesNotExist/>
                        </Switch>
                    </div>
                )

            }
            }
        </Query>
    );
}
SwapIndex.displayName = 'SwapIndex';
SwapIndex.propTypes = {};
SwapIndex.defaultProps = {};

export default SwapIndex;


import React from 'react';

const HeroSection = () => {
  return (
    <section className="section hero">
    <div className="container">
      <div className="row">
        <div className="col-s-12 col-m-5">
          <h1 className="hero__title">Den nye handelsportal for <strong className="u-green-text">andelsboliger</strong></h1>
        </div>
        <div className="col-s-12 col-m-5 col-m-push-1">
          <div className="hero__content" >
            <p>
              <span className="u-green-text">Den nye superportal</span><br/> samler køber og sælger ét sted, <br/>og gør markedspladsen sikker, tilgængelig og enkel.
              </p>
          </div>
          <span className="hero__img" style={{ backgroundImage: "url(../assets/img/img-christian.png)" }} ></span>
        </div>
      </div>
    </div>
</section>
  );
}


HeroSection.displayName = 'HeroSection';
HeroSection.propTypes = {};
HeroSection.defaultProps = {};

export default HeroSection;



const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    mutationId: null,
    errors: [],
    showCreatedModal: false,
    hasConfirmedIsApartmentOwner: false
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "CHANGE_NEW_USER_FIRSTNAME": {
            return {...state, firstName: action.payload}
        }
        case "CHANGE_NEW_USER_LASTNAME": {
            return {...state, lastName: action.payload}
        }
        case "CHANGE_NEW_USER_EMAIL": {
            return {...state, email: action.payload}
        }
        case "CHANGE_NEW_USER_PHONE": {
            return {...state, phone: action.payload}
        }
        case "CHANGE_NEW_USER_PASSWORD": {
            return {...state, password: action.payload}
        }
        case "CHANGE_NEW_USER_HASCONFIRMEDISAPARTMENTOWNER": {
            return {...state, hasConfirmedIsApartmentOwner: action.payload}
        }
    }
    return state
}

import React from "react"

const Partners = () => {
  return (
    <div className="partner">
      <section className="u-full-height u-lightgrey">
        <div className="container">
          <div className="row">
            <div className="col-s-12">
              <div className="partner__description">
                <h1 className="sale__title title u-green-text">Om Andelshandel og vores gode samarbejdspartnere</h1>
                <p>
                  Andelshandel A/S blev grundlagt i 2013 med en vision om at levere specialiseret service til
                  andelsboligmarkedet. Vores primære fokus har altid været at sikre en effektiv og gennemsigtig
                  handelsproces for sælgende andelshavere, hvor vores samarbejde med administrationsselskaber sikrer
                  fordele for sælgende andelshaver.
                </p>

                <p>
                  Administrationsselskaber oplever en række fordele ved at samarbejde med Andelshandel A/S. Vi tilbyder
                  en professionel mæglerbetjening med fokus kun på andelsboligmarkedet. Vores dedikation til udelukkende
                  at håndtere andelsboliger sikrer en høj kvalitet i vores service og indsigt i markedet, samtidig med
                  vi medvirker til at mindske administrationsselskabernes tidsforbrug. Vi tilbyder desuden
                  mæglerbetjening til andelshaverne til et fast lavt honorar, hvilket sikrer gennemsigtighed om
                  omkostningerne og forudsigelighed i processen.
                </p>

                <p>
                  Blandt vores stiftere finder vi respekterede navne som f.eks. ØENS Ejendomsadministration, Cobblestone
                  A/S og Boligexperten Administration A/S. Andelshandel A/S er primært ejet af administrationsselskaber,
                  hvilket understreger vores tætte forbindelse til administrationsbranchen.
                </p>
              </div>
              <div className="partner__list">
                <h4 className="">Vi er stolte af at samarbejde med bl.a. følgende administrationsselskaber:</h4>

                <ul>
                  <li>
                    <a href="https://www.adlexbolig.dk/">ADLEX Boligadministration</a>
                  </li>
                  <li>
                    <a href="https://www.advodan.dk/da/advodan-holbaek-kobenhavn/advodan-holbaek-kobenhavn/">
                      ADVODAN Holbæk / København
                    </a>
                  </li>
                  <li>
                    <a href="http://www.bjd-advokater.dk/">Advokaterne i Rosenborggade</a>
                  </li>
                  <li>
                    <a href="https://boelskifteadministration.dk/">Boelskifte Administration</a>
                  </li>
                  <li>
                    <a href="https://www.boadm.dk/">Boligadministratorerne</a>
                  </li>
                  <li>
                    <a href="https://www.boligexperten.dk/">Boligexperten Administration</a>
                  </li>
                  <li>
                    <a href="https://www.bricksandblocks.dk/">Bricks and Blocks</a>
                  </li>
                  <li>
                    <a href="https://www.ceacph.dk/">CEA Ejendomsadministration</a>
                  </li>
                  <li>
                    <a href="https://cobblestone.dk/">Cobblestone</a>
                  </li>
                  <li>
                    <a href="https://www.hh-administration.dk/">HHH Administration</a>
                  </li>
                  <li>
                    <a href="https://www.hplaw.dk/">Hupfeld Ejendomsadministration</a>
                  </li>
                  <li>
                    <a href="https://www.ret-raad.dk/hilleroed/">Ret&Råd Advokater</a>
                  </li>
                  <li>
                    <a href="https://sjeldani.dk/">SJELDANI Boligadministration</a>
                  </li>
                  <li>
                    <a href="https://www.advokat-marker.dk/ejendomsadministration/">SMEjendomme</a>
                  </li>
                  <li>
                    <a href="https://toftadm.dk/">Toft Administration</a>
                  </li>
                  <li>
                    <a href="https://www.veba.dk/">VEBA</a>
                  </li>
                  <li>
                    <a href="https://oadv.dk/">ØENS Ejendomsadministration</a>
                  </li>
                </ul>
              </div>
              <p>
                Vi ser frem til at fortsætte vores partnerskab med disse respekterede administrationsselskaber og til at
                fortsætte med at tilbyde enestående service til andelsboligmarkedet.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

Partners.displayName = "Partners"
Partners.propTypes = {}
Partners.defaultProps = {}

export default Partners

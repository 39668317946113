import React from 'react';
import {connect} from 'react-redux'
import {setHelpModalContent} from "../../../../actions/index";

const Radio = ({dispatch, label, description, className, name, value, onChange, modalLink, type = "radio"}) => {
    const onChangeInput = () => onChange(name, value)
    const renderReadMore = modalLink ? <a className="u-green-text" onClick={(e) => {e.stopPropagation();dispatch(setHelpModalContent(modalLink.modalContent))}}>{modalLink.label}</a> : ""

    return (
        <div className="input-field__radio" onClick={onChangeInput}>
            <input className={className} name={name} type={type} value={value}></input>
            <label htmlFor={name}>
                <h4 className="u-green-text">{label}</h4>
                <h4 className="u-darkgrey-text">{description}</h4>
                {renderReadMore}
            </label>
        </div>
    );
}

Radio.displayName = 'Radio';
Radio.propTypes = {};
Radio.defaultProps = {};

export default connect()(Radio)

import React from 'react';
import ReactSlider from 'react-slider';


//https://www.npmjs.com/package/react-slider

const RangeSlider = ({label,
		 value=[0,100],
		 min=0,
		 max=100,
		 minDistance=1,
         onTouchStart = () => {
            console.log('touch')
        },        
		 onChange=e=>{
            console.log(e)
            document.addEventListener('scroll', function(event) {
                event.preventDefault();
                console.log('scroll')
            }, false)
         },
		 formatValue=val=>`${val}`}) => {
  return (
    <div className="slider-component">
      <label>{label}</label>
      <ReactSlider ontouchstart={onTouchStart} className="horizontal-slider" value={value} min={min} max={max} minDistance={minDistance} withBars onChange={e=>onChange(e)} />
      <div className="values-container">
    	<div className="min-val">{formatValue(value[0])}</div>
    	<div className="max-val">{formatValue(value[1])}</div>
      </div>
    </div>
  );
}

RangeSlider.displayName = 'Slider';
RangeSlider.propTypes = {};
RangeSlider.defaultProps = {};

export default RangeSlider

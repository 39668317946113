import React from "react"
import { Link } from "react-router-dom"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-s-12 col-m-10 col-l-5">
            <img className="icon icon--unik-logo icon--white" src={"/assets/img/img-unik-logo-text.svg"} alt="" />
            <div className="content">
              Andelshandel A/S er stiftet i 2013 og i dag overvejende ejet af ejendoms administrations branchen.
              Formålet med selskabet er at servicere sælgende andelshavere bedst muligt, uagtet om de selv ønsker at stå
              for salget eller få professionel hjælp hertil. Kommende andelshavere tilbydes en attraktiv markedsplads
              med overblik over udbuddet samt muligheden for at få unikke fordele via vores køberservice.
            </div>
          </div>
          <div className="col-s-12 col-m-3 col-l-2 col-l-push-2">
            <address>
              <h4>Kontakt os</h4>
              <p>Vesterbrogade 12</p>
              <p className="zip">1655 København V</p>
              <div>
                <a href="tel://71996939">71996939</a>
              </div>

              <div>
                <a href="mailto:info@andelshandel.dk">info@andelshandel.dk</a>
              </div>
            </address>
          </div>
          <div className="col-s-12 col-m-3 col-l-3">
            <div className="quick__links">
              <h4>Quik Links</h4>
              <div className="quik__link">
                <Link to={"/koeb"}>Køb</Link>
              </div>
              {/* <div className="quik__link">
                <Link to={"/koeberservice"}>Køberservice</Link>
              </div> */}
              <div className="quik__link">
                <Link to={"/saelg"}>Sælg</Link>
              </div>
              <div className="quik__link">
                <Link to={"/partnere"}>Partnere</Link>
              </div>
              <div className="quik__link">
                <Link to={"/kontakt"}>Kontakt</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="row">
          <div className="col-s-12 col-m-6">
            <div className="credit-cards">
              <img className="icon credit-cards__card" src={"/assets/img/img-visa.svg"} alt="" />
              <img className="icon credit-cards__card" src={"/assets/img/img-mastercard.svg"} alt="" />
            </div>
          </div>
          <div className="col-s-12 col-m-3 col-m-push-3">
            <a href="www.moonbird.dk" className="designedby white-text">
              Designed by Moonbird
            </a>
          </div>
          <button className="elevator">
            Back to top
            <svg className="icon icon--arrow icon--white">
              <use href="#arrow"></use>
            </svg>
          </button>
        </div>
      </div>
    </footer>
  )
}

Footer.displayName = "Footer"
Footer.propTypes = {}
Footer.defaultProps = {}

export default Footer

import React from 'react';
import {connect} from 'react-redux'


const CaseDocumentsModal = ({data}) => {
    const caseData = data
    const documents = [...caseData.caseDocuments, ...caseData.buildingDocuments]

    return (
        <div className="modal__component">
            <ul className="file-list">
                {documents.map((x,i)=> (
                <li key={i}>
                    <a href={x.url} target="_blank">
                        <svg className="icon icon--doc">
                            <use xlinkHref="#icon-doc"></use>
                        </svg>
                        <span>
                            {x.name}
                        </span>
                    </a>
                </li>
                ))}
            </ul>
            <br></br>
            { documents.length > 1 && caseData.documentUrl ? <a href={caseData.documentUrl} download>Download samlet som .zip</a> : ""}
        </div>
    );
}

CaseDocumentsModal.displayName = 'CaseDocumentsModal';
CaseDocumentsModal.propTypes = {};
CaseDocumentsModal.defaultProps = {};

const mapStateToProps = state => ({
    data: state.app.visibleModalData
})

export default connect(mapStateToProps)(CaseDocumentsModal);

import React from 'react'
import { reduxForm } from 'redux-form'
import * as validation from 'validation'
import { RFInput } from "../reduxFormsFields";

let CaseContactForm = props => {
    const {handleSubmit, invalid, withCurrentAddressFields = false} = props

    const currentAddressFields = withCurrentAddressFields ? (
        <div className="row">
                <div className="col-s-12">
                    <RFInput name="address" type="text" label="Adresse"/>
                </div>
                <div className="col-s-12 col-m-6">
                    <RFInput name="city" type="text" label="By"/>
                </div>
                <div className="col-s-12 col-m-6">
                    <RFInput name="zipCode" type="number" label="Post nr."/>
                </div>
                <div className="col-s-12">
                    <RFInput name="associationName" type="text" label="Andelsforening"/>
                </div>
        </div>
    ) : ""
    return (
        <form onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-s-12">
                    <RFInput name="firstName" type="text" label="Navn" required={true}
                             validate={[validation.required]}/>
                </div>
            </div>
            <div className="row">
                <div className="col-s-12 col-m-6">
                    <RFInput name="phone" type="number" label="Mobil" required={true} validate={[validation.required]}/>
                </div>
                <div className="col-s-12 col-m-6">
                    <RFInput name="email" type="email" label="Email" required={true} validate={[validation.required, validation.email]}/>
                </div>
            </div>
            { currentAddressFields }
            <div className="row">
                <div className="col-s-12">
                    <RFInput name="message" type="textarea" label="Besked"/>
                </div>
            </div>
            <button className="button" type="submit" disabled={invalid} >Send</button>
        </form>
    )
}

CaseContactForm = reduxForm({
    // a unique name for the form
    form: 'caseContact'
})(CaseContactForm)


export default CaseContactForm;
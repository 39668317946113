import React from 'react';
import * as actions from 'actions';
import { connect } from 'react-redux';

const DeleteUserForm = ({ dispatch }) => {

    const onSubmit = async (e) => {
        e.preventDefault();
        dispatch(actions.setVisibleModal('CONFIRMATION_MODAL'));
    };
    
    return (
        <section className="subsection delete-user-form">
          <form onSubmit={onSubmit}>
            <button type="submit" className="button button--red delete-user-form__submit">
              Slet bruger
            </button>
          </form>
        </section>
      );
}

DeleteUserForm.displayName = 'DeleteUserForm';
DeleteUserForm.propTypes = {};
DeleteUserForm.defaultProps = {};

export default connect()(DeleteUserForm);

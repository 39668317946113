import React from 'react';
import { Route } from 'react-router-dom' 
import BuyFrontPage from './BuyFrontPage'
import CasePage from './CasePage'
import { URLS } from 'urls'


const Buy = () => {
  return (
    <div className="router animate--down">
      <Route path={ URLS.BUY.FRONT } exact component={ BuyFrontPage }/>
      <Route path={ URLS.BUY.CASE } component={ CasePage } />
      <Route path={ URLS.BUY.SELF_SALE_CASE} render={() => <CasePage isSelfSaleCase={true}/>}/>
    </div>
  );
}

Buy.displayName = 'Buy';
Buy.propTypes = {};
Buy.defaultProps = {};

export default Buy
